import { Modal } from "react-bootstrap";
import { useState, ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import ApprMenu, { SignTree} from "./ApprMenu";

interface ApprProps {
    show: boolean,
    setShow: Function,
    vac_id: string,
    searchFunc?: Function,
    currPage?: number, 
    rowSize?: number, 
    isDetail?: boolean
}


function ApprSignList({show, setShow, vac_id, searchFunc, currPage, rowSize, isDetail}: ApprProps) {
    const [toggleHeader, setToggleHeader] = useState<SignTree[]>([]);
    const location = useLocation();
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isPage = location.pathname === '/appr/ApprSignList';
    
    let searchMap: Map<String, string>;

    const state = location.state;
    if (state) {
        vac_id = state.vac_id ? state.vac_id : vac_id;
        show = typeof(state.show) != 'undefined' ? !state.show : show;
        currPage = typeof(state.currPage) != 'undefined' ? state.currPage : currPage;
        rowSize = typeof(state.rowSize) != 'undefined' ? state.rowSize : rowSize;
        searchMap = state.searchMap ? state.searchMap : new Map<string, string>();
        if (!searchMap.get('searchStr'))
            searchMap.set('searchStr', '');
        if (!searchMap.get('docStatus'))
            searchMap.set('docStatus', 'all');
        if (!searchMap.get('searchCol'))
            searchMap.set('searchCol', 'vac_title');
    }
    const navigate = useNavigate();

    
    function closeModal() {
        
        if (isPage) {
            if (location.state)
                navigate('/appr/easMain', {state: {
                                                currPage: currPage,
                                                rowSize: rowSize,
                                                isDetail: false,
                                                searchMap: searchMap
                                            }, replace: true});
            else
                navigate('/appr/easMain');
        } else {
            setShow(false);
            if (searchFunc && typeof(currPage) !== 'undefined' 
                && typeof(rowSize) !== 'undefined' && typeof(isDetail) !== 'undefined')
                searchFunc(currPage, rowSize, true);
        };
    }

    function modalWrap(content: any) {
        return (
            <Modal show={show} onHide={() => closeModal()}
                aria-labelledby="contained-modal-title-vcenter" 
                backdrop="static" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {'기안작성'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content}
                    
                </Modal.Body>
            </Modal>
        )
    }
    const rtn: ReactElement = modalWrap(<ApprMenu vac_id={vac_id} show={show} closeFunc={closeModal} toggleHeader={toggleHeader}
        setToggleHeader={setToggleHeader} />);
    if (!isDesktop) {
        return (
            <>
                <ApprMenu vac_id={vac_id} show={show} closeFunc={closeModal} toggleHeader={toggleHeader}
                                    setToggleHeader={setToggleHeader} />
                </>
        )
    } else {
        return rtn;
    }
}

export default ApprSignList;