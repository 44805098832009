import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import axios from 'axios';
import SearchBarV2 from '../../module/SearchBarV2';
import DataTableV2 from '../../module/DataTableV2';
import Paginate from '../../module/Paginate';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../module/Loading';
import { CellProps } from 'react-table';
import { Button, Card, CardGroup, Col, Modal, Row } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import ApprInfoArea from '../appr/signbox/ApprInfoArea';

function MngDelayApprDoc() {
    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });

    //큰 화면용 list를 위한 변수
    const [detailDataList, setDetailDataList] = useState<DelayApprDocVO[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    //작은 화면용 list를 위한 변수
    const [simpleDataList, setSimpleDataList] = useState<DelayApprDocVO[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);

    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    const [period, setPeriod] = useState([0, 0, 1000000]);
    const [docCaseName, setDocCaseName] = useState<{ id: Number; name: string; drft_st_dt: number; drft_ed_dt: number; cnt: number }[]>([
        { id: 0, name: '전체 진행 건수', drft_st_dt: 0, drft_ed_dt: 1000000, cnt: 0 },
        { id: 1, name: '1일 지연', drft_st_dt: 0, drft_ed_dt: 1, cnt: 0 },
        { id: 2, name: '5일 지연', drft_st_dt: 2, drft_ed_dt: 5, cnt: 0 },
        { id: 3, name: '10일 지연', drft_st_dt: 6, drft_ed_dt: 10, cnt: 0 },
        { id: 4, name: '20일 지연', drft_st_dt: 11, drft_ed_dt: 20, cnt: 0 },
        { id: 5, name: '30일 지연', drft_st_dt: 21, drft_ed_dt: 30, cnt: 0 },
        { id: 6, name: '30일 초과', drft_st_dt: 31, drft_ed_dt: 1000000, cnt: 0 },
    ]);

    // 결재문서 상세보기
    const [showApprDetail, setShowApprDetail] = useState(false);
    const [apprDetail, setApprDetail] = useState<{sign_id: string, frm_id: string, frm_name: string, mode: string, vacation_yn: string, cooperation_yn: string} | null>(null);

    const selectDataList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/admin/selectDelayApprDocList',
            {
                drft_st_dt: period[1],
                drft_ed_dt: period[2],
                lang: "ko",
                currPage: currPage,
                rowSize: rowSize,
                sortStr: sortStr,
                sortDir: sortDir,
            },
            { headers: { "Content-Type": 'application/json' } }
        )
        const addData = response.data.content.map((item: DelayApprDocVO) => ({
            ...item,
            drafter: `${item.drft_user_nm}/${item.drft_user_posit_nm}/${item.drftper_dept_nm}`,
            approver: `${item.sign_user_nm}/${item.sign_user_posit_nm}/${item.sign_user_dept_nm}`

        }));
        let all = 0, one = 0, five = 0, ten = 0, twenty = 0, thirty = 0, over = 0;
        response.data.delayCount?.forEach((item: any) => {
            all++;
            if (Number(item.exceed_day) >= 0 && Number(item.exceed_day) < 2) {
                one++;
            } else if (Number(item.exceed_day) >= 2 && Number(item.exceed_day) < 6) {
                five++;
            } else if (Number(item.exceed_day >= 6 && item.exceed_day) < 11) {
                ten++;
            } else if (Number(item.exceed_day) >= 11 && Number(item.exceed_day) < 21) {
                twenty++;
            } else if (Number(item.exceed_day) >= 21 && Number(item.exceed_day) < 31) {
                thirty++;
            } else if (Number(item.exceed_day) >= 31) {
                over++;
            }
        })
        setDocCaseName((prevState) => prevState.map((item) => (
            item.id === 0 ? { ...item, cnt: all } :
                item.id === 1 ? { ...item, cnt: one } :
                    item.id === 2 ? { ...item, cnt: five } :
                        item.id === 3 ? { ...item, cnt: ten } :
                            item.id === 4 ? { ...item, cnt: twenty } :
                                item.id === 5 ? { ...item, cnt: thirty } :
                                    item.id === 6 ? { ...item, cnt: over } :
                                        item
        )));
        if (isDetail) {
            setDetailDataList(addData);
            setPageCount(response.data.totalPages);
            setRowCount(response.data.rowCount);
        } else {
            if (simplePage === 0) {
                setSimpleDataList(response.data.content);
            } else {
                setSimpleDataList(prevPosts => [...prevPosts, ...response.data.content]);
            }
            setHasMore(!response.data.last);
        }
        setLoading(false);
    }

    function search() {
        if (detailPage === 0) {
            selectDataList(0, rowSize, true);
        } else {
            setDetailPage(0);
        }
        if (simplePage === 0) {
            selectDataList(0, 25, false);
        } else {
            setSimplePage(0);
        }
    };

    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map<string, string>([['searchCol', 'priv_cd']]));

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDataList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDataList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬/기간 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            search();
        };
        loadPosts();
    }, [sort, period]);

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    const excelDown = async () => {
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/admin/selectDelayApprDocList',
            {
                drft_st_dt: period[1],
                drft_ed_dt: period[2],
                lang: "ko",
                currPage: 0,
                rowSize: rowCount,
                sortStr: sortStr,
                sortDir: sortDir,
            },
            { headers: { "Content-Type": 'application/json' } }
        )
        const newArray: { [key: string]: any }[] = response.data.content.map((item: DelayApprDocVO) => ({
            '양식명': item.frm_name,
            '제목': item.sign_doc_title,
            '기안자': item.drft_user_nm + '/' + item.drft_user_posit_nm + '/' + item.drftper_dept_nm,
            '기안일': item.drft_dt,
            '다음 결재자': item.sign_user_nm + '/' + item.sign_user_posit_nm + '/' + item.sign_user_dept_nm,
            '지연일수': item.exceed_day,
        }));
        const workbook = XLSX.utils.book_new(); // 객체 생성
        const worksheet = XLSX.utils.json_to_sheet(newArray); // data
        XLSX.utils.book_append_sheet(workbook, worksheet, "결재지연관리"); // sheet
        XLSX.writeFile(workbook, "결재지연관리.xlsx"); // file
    }

    // 결재문서 클릭
    const handleApprRowClick = (vo: any) => {
        setApprDetail({
            sign_id: vo.sign_id,
            frm_id: vo.frm_id,
            frm_name: vo.frm_name,
            mode: 'sView',
            vacation_yn: vo.vacation_form_yn,
            cooperation_yn: vo.cooperation_yn,
        });
    };
    // 결재문서 값이 바뀌면 상세보기 열기
    useEffect(() => {
        if (apprDetail) {
            setShowApprDetail(true);
        }
    }, [apprDetail]);

    return (
        <div className='content'>
            <h2>결재지연관리</h2>
            <h5>결재문서의 처리 지연 현황을 관리할 수 있습니다.</h5>
            <Row>
                <Col md={12}>
                    {isDesktop ? (
                        <CardGroup style={{ textAlign: 'center', fontSize: '0.9rem', verticalAlign: 'middle' }}>
                            {docCaseName.map((item, index) =>
                                <Card key={index} onClick={() => setPeriod([index, item.drft_st_dt, item.drft_ed_dt])}
                                    style={{ backgroundColor: period[0] === item.id ? '#f4f4f4' : '', cursor: 'pointer' }}>
                                    <Card.Body>
                                        <Card.Text>{item.name}</Card.Text>
                                        <Card.Title>{item.cnt}</Card.Title>
                                    </Card.Body>
                                </Card>
                            )}
                        </CardGroup>
                    ) : (
                        <div className="mb-2 me-2" style={{ overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
                            <CardGroup style={{ display: 'inline-block' }}>
                                {docCaseName.map((item, index) => (
                                    <Card key={index} onClick={() => setPeriod([index, item.drft_st_dt, item.drft_ed_dt])}
                                        style={{ backgroundColor: period[0] === item.id ? '#f4f4f4' : '', textAlign: 'center', display: 'inline-block', width: 'max-content', cursor: 'pointer' }}>
                                        <Card.Body>
                                            <Card.Text>{item.name}</Card.Text>
                                            <Card.Title>{item.cnt}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </CardGroup>
                        </div>
                    )}
                </Col>
            </Row>
            <div className='delayApprDocSearch'>
                <SearchBarV2
                    setRowSize={setRowSize}
                    rowCount={rowCount}
                    searchMap={searchMap}
                    setSearchMap={setSearchMap}
                    search={search}
                    leftChildren={<></>
                    }
                    rightChildren={
                        // <>
                        <Col xs="auto">
                            <Button onClick={excelDown}>엑셀 다운로드</Button>
                        </Col>
                        // </>
                    } />
            </div>
            {isDesktop ? (
                <>
                    <DataTableV2
                        loading={loading} columns={detail_columns} data={detailDataList} sort={sort} setSortDir={setSortDir} keyField={'sign_id'} onRowClick={(vo) => { handleApprRowClick(vo) }} />
                    {pageCount > 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleDataList.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={simple_columns} data={simpleDataList} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => { handleApprRowClick(vo) }} />
                </InfiniteScroll>
            )}
            <Modal show={showApprDetail} onHide={()=>{setShowApprDetail(false);}} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {apprDetail?.frm_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ApprInfoArea 
                        boxTpSignUserListCheck={''}
                        recentApprListCheck={[]}
                        mode={'view'}
                        currUserInfo={{
                            USER_POSIT_NM: "",
                            DRFT_DT: "",
                            USER_NM: "",
                            USER_POSIT_CD: "",
                            USER_DEPT_NM: "",
                            APPR_SIGNATURE_USE_YN: "",
                            USER_ID: "",
                            USER_DEPT_CD: "",
                            APPR_SIGNATUR_PATH: ""
                        }} 
                        signForm={{
                            sign_line_type: "",
                            retention: 0,
                            cooperation_yn: "",
                            receive_yn: "",
                            fixReceptionList: [],
                            fix_dept_nm: "",
                            fix_dept_cd: "",
                            deptList: [],
                            dept_auth_cd: "",
                            dept_auth_nm: "",
                            formCompList: [],
                            modify_yn: "",
                            frm_ctg_name: "",
                            sec_level_cd: "",
                            output_file: "",
                            use_yn: "",
                            frm_num: "",
                            revision_num: 0,
                            doc_tp_cd: ""
                        }} 
                        subWorkerList={[]} 
                        actionStatus= {JSON.stringify(apprDetail)}
                        varStatus={false} 
                        status={false} 
                        lastSeq={0}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setShowApprDetail(false);}}>닫기</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

type DelayApprDocVO = {
    no?: number;
    doc_sts_cd: string;
    docno: string;
    drft_dt: string;
    drft_user_id: string;
    drft_user_nm: string;
    drft_user_posit_cd: string;
    drft_user_posit_nm: string;
    drftper_dept_cd: string;
    drftper_dept_nm: string;
    exceed_day: string;
    frm_id: string;
    frm_name: string;
    retention: string;
    progress: string[];
    sign_doc_title: string;
    sign_id: string;
    sign_sts_cd: string;
    sign_user_dept_nm: string;
    sign_user_nm: string;
    sign_user_posit_nm: string;
    [key: string]: any;
}

const detail_columns = [
    {
        Header: 'NO',
        accessor: 'no'
    },
    {
        Header: '문서번호',
        accessor: 'docno'
    },
    {
        Header: '양식명',
        accessor: 'frm_name'
    },
    {
        Header: '제목',
        accessor: 'sign_doc_title'
    },
    {
        Header: '기안자',
        accessor: 'drafter'
    },
    {
        Header: '기안일',
        accessor: 'drft_dt'
    },
    {
        Header: '다음결재자',
        accessor: 'approver'
    },
    {
        Header: '일수',
        accessor: 'exceed_day'
    },
    {
        Header: 'Progress',
        accessor: 'option',
        Cell: ({ row }: CellProps<DelayApprDocVO>) => (
            <div style={{ display: 'inline-flex' }}>
                {(row.original.progress?.map((item, index) =>
                    <span key={index} style={{ border: '1px solid black', backgroundColor: item === 'W1' ? 'lightgray' : item === 'C1' ? 'lightblue' : item === 'R1' ? 'lightcoral' : item === 'G1' ? 'wheat' : '', width: '22px', marginLeft: '1px', textAlign: 'center' }}>{index}</span>
                ))}
                {/* W1 대기, C1 승인, R1 반려, G1 진행 */}
            </div>
        ),
    }
];

const simple_columns = [
    {
        Header: '양식명',
        accessor: 'frm_name'
    },
    {
        Header: '기안자',
        accessor: 'drft_user_nm'
    },
    {
        Header: '기안일',
        accessor: 'drft_dt'
    },
    {
        Header: '다음결재자',
        accessor: 'sign_user_nm'
    },
    {
        Header: '일수',
        accessor: 'exceed_day'
    },
];


export default MngDelayApprDoc;