import { Button, Modal } from 'react-bootstrap';

const FileUploadChoose = ({ show, setShow}: { show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>> }): React.ReactElement => {
    return (
        <Modal show={show} onHide={() => setShow(false)} >
            <Modal.Header closeButton>
                <Modal.Title>{'사진 업로드 방식 선택'}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{textAlign:'center'}}>
                <Button onClick={() => {document.getElementById('imageUpload1')?.click();}} style={{marginRight:'10px'}}>촬영하기</Button>
                <Button onClick={() => {document.getElementById('imageUpload2')?.click();}}>앨범에서 선택하기</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={() => setShow(false)}>
                    닫기
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default FileUploadChoose;