import React from "react";
import { useState, useEffect } from "react";
import "../../css/appr/Eas_Main.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Table, Card, OverlayTrigger, Tooltip, CardGroup, Button } from "react-bootstrap";
import Loading from "../../module/Loading";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ApprInfoArea from "./signbox/ApprInfoArea";
import { Modal } from "react-bootstrap";

const board_main: string = "/api/appr/signBox/signMain.menu"; // 전자결재 매인 페이지

function EasMain() {
    const itemsMap = [
        { key: "apprWait", value: "결재대기함", move_id: "GW0102", var: 'sign_box_cd=B04&returnPage=/appr/signbox/apprWaitBox'},
        { key: "apprProc", value: "결재진행함", move_id: "GW0103", var: 'sign_box_cd=B05&returnPage=/appr/signbox/apprProcBox'},
        { key: "apprReject", value: "반송함", move_id: "GW0104", var: 'sign_box_cd=B08&returnPage=/appr/signbox/apprRejectBox'},
        { key: "apprComp", value: "결재완료함", move_id: "GW0106", var: 'sign_box_cd=B06&returnPage=/appr/signbox/apprCompBox'},
        { key: "apprTemp", value: "임시저장함", move_id: "GW0501", var: 'sign_box_cd=B09&returnPage=/appr/signbox/apprTempBox'},
        { key: "apprRead", value: "참조확인함", move_id: "GW0500", var: 'sign_box_cd=B07&returnPage=/appr/signbox/apprReadBox'},
        { key: "apprArrival", value: "도착문서함", move_id: "GW0109", var: 'sign_box_cd=B01&returnPage=/appr/signbox/apprArrivalBox'},
        { key: "apprReceipt", value: "접수문서함", move_id: "GW0110", var: 'sign_box_cd=B02&returnPage=/appr/signbox/apprReceiptBox'},
        { key: "apprReturn", value: "반송배포함", move_id: "GW0111", var: 'sign_box_cd=B03&returnPage=/appr/signbox/apprReturnBox'},
    ];

    // waitList 결재대기함
    // procList 결재진행함
    // readList 참조확인함
    // compList 결재완료
    // waitList 반송함
    // arrivalList 도착문서
    // labelList 라벨

    const [countMap, setCountMap] = useState(new Map<string, string>());
    const [waitList, setWaitList] = useState<any[]>([]);
    const [procList, setProcList] = useState<any[]>([]);
    const [readList, setReadList] = useState<any[]>([]);
    const [compList, setCompList] = useState<any[]>([]);
    const [rejectList, setRejectList] = useState<any[]>([]);
    const [arrivalList, setArrivalList] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const [job_name] = useState("");

    const isScroll = useMediaQuery({ maxWidth: 1128 });
    const [title, setTitle] = useState('');
    const [modalData, setModalData] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => setIsModalOpen(false);
    const clicksetting = (sign_id: string, frm_id: string, vacation_form_yn: string, cooperation_yn: string, frm_nm: string, title: string) => {
        const param = new Map<string, string>();
        if(title === '결재완료함'){
            param.set("mode", 'sView');
        }else{
            param.set("mode", 'View');
        }
        param.set("sign_id", sign_id);
        param.set("frm_id", frm_id);
        
        param.set("vacation_yn", vacation_form_yn);
        param.set("cooperation_yn", cooperation_yn);
        setTitle(frm_nm);
        setModalData(JSON.stringify(Object.fromEntries(param)));
        setIsModalOpen(true);
    };
    const renderApprInfoArea = () => (
        <ApprInfoArea 
            boxTpSignUserListCheck={''}
            recentApprListCheck={[]}
            mode={'view'}
            currUserInfo={{
                USER_POSIT_NM: "",
                DRFT_DT: "",
                USER_NM: "",
                USER_POSIT_CD: "",
                USER_DEPT_NM: "",
                APPR_SIGNATURE_USE_YN: "",
                USER_ID: "",
                USER_DEPT_CD: "",
                APPR_SIGNATUR_PATH: ""
            }} 
            signForm={{
                sign_line_type: "",
                retention: 0,
                cooperation_yn: "",
                receive_yn: "",
                fixReceptionList: [],
                fix_dept_nm: "",
                fix_dept_cd: "",
                deptList: [],
                dept_auth_cd: "",
                dept_auth_nm: "",
                formCompList: [],
                modify_yn: "",
                frm_ctg_name: "",
                sec_level_cd: "",
                output_file: "",
                use_yn: "",
                frm_num: "",
                revision_num: 0,
                doc_tp_cd: ""
            }} 
            subWorkerList={[]} 
            actionStatus={modalData} 
            varStatus={false} 
            status={false} 
            lastSeq={0}
        />
    );
    interface Doc {
        sign_id: string;
        sign_doc_title: string;
        frm_nm: string;
        drft_user_nm: string;
        drft_dt: string;
        final_sign_user_nm: string;
        final_appr_dt: string;
    }
    interface CustomCardProps {
        title: string;
        list: Doc[];
    }

    const navigate = useNavigate();

    function goPage(menu: any) {
        const url = '/appr/signbox/'+menu.key+'Box'
        navigate(url, { state: { menu_cd: menu.move_id, menu_var: menu.var, menu_nm: menu.value } });
    }

    useEffect(() => {
        const getData_main = async () => {
            try {
                setLoading(true);
                const response = await axios.post(
                    board_main,
                    {
                        user_id: sessionStorage.getItem("user_id"),
                        lang: "ko",
                    },
                    { headers: { "Content-Type": "application/json" } }
                );
                const rtn = response.data;
                if (rtn.result === "success") {
                    setCountMap(new Map(Object.entries(rtn.countMap)));
                    setWaitList(rtn.waitList);
                    setProcList(rtn.procList);
                    setReadList(rtn.readList);
                    setCompList(rtn.compList);
                    setRejectList(rtn.rejectList);
                    setArrivalList(rtn.arrivalList);

                    // waitList 결재대기함
                    // procList 결재진행함
                    // readList 참조확인함
                    // compList 결재완료
                    // rejectList  반송함
                    // arrivalList 도착문서
                    // labelList 라벨
                } else if (rtn.result === "failed") {
                    alert(job_name + " 데이터 추출 실패");
                } else {
                    alert(
                        "서버 오류로 인한 " +
                        job_name +
                        " 추출 실패, 관리자에게 문의 부탁 드립니다."
                    );
                }
            } catch (error) {
                // console.error("Failed axios : " + job_name, error);
                alert("Failed axios : " + job_name);
            } finally {
                setLoading(false);
            }
        };

        getData_main();
    }, []);
    

    const CustomCard: React.FC<CustomCardProps> = ({ title, list }) => {
        const tableColumns: string[] = ['sign_doc_title', 'drft_user_nm', 'drft_dt'];
        return (
            <Col>
                <Card>
                    <Card.Header as={'h5'} className="d-flex justify-content-between">
                        {title}<FaAngleRight style={{height: '1.5rem', color: 'gray', cursor: 'pointer'}} onClick={()=> {let menu = {}; itemsMap.map((item)=> title === item.value ? menu = item : '' ); goPage(menu)}}/>
                    </Card.Header>
                    <Card.Body style={{padding: '3px'}}>
                        <div style={{height: '182px', overflowY: 'auto', padding: '2px'}}>
                        <Table hover responsive style={{margin: 0}}>
                            <tbody>
                                {list.length === 0 ? (
                                    <tr>
                                        <td colSpan={tableColumns?.length} style={{borderBottom: '0'}}>
                                            조회결과가 없습니다.
                                        </td>
                                    </tr>
                                ) : (
                                    list.map((item: any, rowIndex: number) => (
                                        <OverlayTrigger placement="auto" delay={100} overlay={
                                            <Tooltip id={item.sign_id}>
                                                <h5>{item.sign_doc_title}</h5>
                                                문서종류: {item.frm_nm}<br/>
                                                기안자: {item.drft_user_nm}<br/>
                                                기안일: {item.drft_dt}<br/>
                                                최종 결재자: {item.final_sign_user_nm}<br/>
                                                최종 결재일: {item.final_appr_dt}
                                            </Tooltip>} key={rowIndex}>
                                            <tr onClick={() => clicksetting(item.sign_id, item.frm_id, item.vacation_form_yn, item.cooperation_yn, item.frm_nm, title)}>
                                            {tableColumns.map((col, colIndex) => (
                                                <td key={colIndex} style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
                                                    {col.includes("_dt") && item[col] ? item[col].substring(0, 10) : item[col]}
                                                </td>
                                            ))}   
                                            </tr>
                                        </OverlayTrigger>
                                    ))
                                )}
                            </tbody>
                        </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        )
    }
    
    return (
        <div className="content">
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Row className="mb-2">
                        <Col md={12}>
                            { !isScroll ? 
                                <CardGroup>
                                    {itemsMap.map((item) => (
                                        <Card key={item.key} style={{textAlign: 'center', minWidth: '120px', cursor: 'pointer'}}
                                        onClick={() => goPage(item)}>
                                            <Card.Body>{item.value} {countMap.get(item.key)}</Card.Body>
                                        </Card>
                                    ))}
                                </CardGroup>
                            :
                                <div className="me-2" style={{overflowX: 'auto', whiteSpace: 'nowrap', width: '100%'}}>
                                    <CardGroup style={{display: 'inline-block'}}>
                                        {itemsMap.map((item) => (
                                            <Card key={item.key} style={{textAlign: 'center', display: 'inline-block', minWidth: '115px', cursor: 'pointer'}}
                                            onClick={() => goPage(item)}>
                                                <Card.Body>{item.value} {countMap.get(item.key)}</Card.Body>
                                            </Card>
                                        ))}
                                    </CardGroup>
                                </div>
                            }
                        </Col>
                    </Row>
                    <Row xs={1} md={2} className="g-2 mb-2">
                        <CustomCard title="결재대기함" list={waitList}/>
                        <CustomCard title="결재진행함" list={procList}/>
                        <CustomCard title="참조확인함" list={readList}/>
                        <CustomCard title="결재완료함" list={compList}/>
                        <CustomCard title="반송함" list={rejectList}/>
                        <CustomCard title="도착문서함" list={arrivalList}/>
                    </Row>
                </>
            )}
        <Modal show={isModalOpen} onHide={closeModal} size="xl" backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderApprInfoArea()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>닫기</Button>
            </Modal.Footer>
        </Modal>
    </div>
    );
}

export default EasMain;
