import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CellProps } from 'react-table';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import DataTableV2 from '../../module/DataTableV2';
import Paginate from '../../module/Paginate';
import Loading from '../../module/Loading';
import AlertModal from '../../module/AlertModal';
import SearchBarV2 from '../../module/SearchBarV2';

function MngDetailCode() {
    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });

    //큰 화면용 list를 위한 변수
    const [detailList, setDetailList] = useState<CodeVO[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    //작은 화면용 list를 위한 변수
    const [simpleList, setSimpleList] = useState<CodeVO[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);

    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    //Modal Popup 변수
    const [showModal, setShowModal] = useState(false);
    const [isMod, setIsMod] = useState(false);
    const [selectDetailCode, setSelectDetailCode] = useState<CodeVO>({
        comm_code: '',
        comm_code_nm: '',
        detail_code: '',
        detail_code_nm: '',
        detail_code_val: '',
        upper_comm_code: '',
        upper_detail_code: '',
        use_yn: '',
        use_str: '',
        detail_code_order: 0,
        register: '',
        rgsde: '',
        updde: '',
        updusr: ''
    });

    //AlertModal 변수
    const [showAlert, setShowAlert] = useState(false);

    //상세코드 삭제함수 변수
    const [deleteCd, setDeleteCd] = useState('');

    const [commCodeList, setCommCodeList] = useState<CodeVO[]>();

    //큰화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
    //DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
    const detail_columns = [
        {
            Header: '순서',
            accessor: 'no'
        },
        {
            Header: '공통코드',
            accessor: 'comm_code'
        },
        {
            Header: '공통코드명',
            accessor: 'comm_code_nm'
        },
        {
            Header: '상세코드',
            accessor: 'detail_code'
        },
        {
            Header: '상세코드명',
            accessor: 'detail_code_nm'
        },
        {
            Header: '사용여부',
            accessor: 'use_str'
        },
        {
            Header: '등록자',
            accessor: 'register'
        },
        {
            Header: '등록일',
            accessor: 'rgsde'
        },
        {
            Header: '삭제',
            accessor: 'option1',
            Cell: ({ row }: CellProps<CodeVO>) => (
                <Button size="sm" onClick={(e) => deleteFunc(row.original)}>
                    삭제
                </Button>
            ),
        }
    ];

    //작은화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
    //DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
    const simple_columns = [
        {
            Header: '공통코드',
            accessor: 'comm_code'
        },
        {
            Header: '공통코드명',
            accessor: 'comm_code_nm'
        },
        {
            Header: '상세코드',
            accessor: 'detail_code'
        },
        {
            Header: '상세코드명',
            accessor: 'detail_code_nm'
        },
        {
            Header: '삭제',
            accessor: 'option1',
            Cell: ({ row }: CellProps<CodeVO>) => (
                <Button size="sm" onClick={(e) => deleteFunc(row.original)}>
                    삭제
                </Button>
            ),
        }
    ];

    //상세코드 리스트 검색 함수
    const selectDetailCodeList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const searchStr = searchMap.get("searchStr");
        const searchCol = searchMap.get("searchCol");
        const select_comm_code = searchMap.get("select_comm_code") !== 'none' ? searchMap.get("select_comm_code") : '';
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/admin/selectDetailCodeList',
            {
                "currPage": currPage,
                "rowSize": rowSize,
                "searchStr": searchStr,
                "searchCol": searchCol,
                "useYn": useYn,
                "sortStr": sortStr,
                "sortDir": sortDir,
                "select_comm_code": select_comm_code
            },
            { headers: { "Content-Type": 'application/json' } }
        );
        const data = await response.data;
        if (isDetail) {
            setDetailList(data.content);
            setPageCount(data.totalPages);
            setRowCount(data.rowCount);
        } else {
            if (simplePage === 0) {
                setSimpleList(data.content);
            } else {
                setSimpleList(prevPosts => [...prevPosts, ...data.content]);
            }
            setHasMore(!data.last);
        }
        setLoading(false);
    };

    function popReg() {
        setSelectDetailCode({
            comm_code: '',
            comm_code_nm: '',
            detail_code: '',
            detail_code_nm: '',
            detail_code_val: '',
            upper_comm_code: '',
            upper_detail_code: '',
            use_yn: '',
            use_str: '',
            detail_code_order: 0,
            register: '',
            rgsde: '',
            updde: '',
            updusr: ''
        });
        setIsMod(false);
        setShowModal(true);
    };

    function search() {
        if (detailPage === 0) {
            selectDetailCodeList(0, rowSize, true);
        } else {
            setDetailPage(0);
        }
        if (simplePage === 0) {
            selectDetailCodeList(0, 25, false);
        } else {
            setSimplePage(0);
        }
    };

    //searchMap에는 검색어와 검색컬럼이 SearchBar에서 세팅된다.
    //검색어는 SearchBar에 searchstr로 지정되어있으므로 바꿀수 없고,
    //select box 선택시 검색컬럼 지정은 하위 selectTypes의 key 에 매핑(해당페이지는 searchCol)
    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map<string, string>([['searchCol', 'detail_code'], ['select_comm_code', 'none']]));
    const [useYn, setUseYn] = useState<string>('');

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDetailCodeList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDetailCodeList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            search();
        };
        loadPosts();
    }, [sort]);

    useEffect(() => {
        const loadPosts = async () => {
            const response = await axios.post("/api/admin/selectCommCodeListForSelectBox");
            const data = response.data;
            setCommCodeList(data);
        };
        loadPosts();
    }, []);

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    const deleteFunc = async (vo: CodeVO) => {
        setDeleteCd(vo.detail_code);
        setShowAlert(true);
    }

    async function confirm() {
        const response = await axios.post("/api/admin/deleteDetailCode", null, { params: { "detail_code": deleteCd } });
        const data = response.data;
        if (data === 1) {
            toast("삭제가 완료되었습니다.", { position: 'top-center', autoClose: 2000 });
            setShowAlert(false);
            search();
        } else {
            toast("삭제 실패", { position: 'top-center', autoClose: 2000 });
        }
    }

    const detailModalPopup = (vo: CodeVO) => {
        setSelectDetailCode({ ...vo, pre_ordr_code: vo.detail_code_order, pre_comm_code: vo.comm_code });
        setIsMod(true);
        setShowModal(true);
    }

    return (
        <div className='content'>
            <h2>상세코드</h2>
            <h5>상세코드의 추가 등록 및 관리를 할 수 있습니다.</h5>
            <SearchBarV2
                setRowSize={setRowSize}
                rowCount={rowCount}
                searchMap={searchMap}
                setSearchMap={setSearchMap}
                search={search}
                leftChildren={
                    <>
                        <Col sm="auto">
                            <Form.Select name='select_comm_code' value={searchMap.get('select_comm_code')} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option value="none">공통코드선택</option>
                                {commCodeList?.map((item: CodeVO) =>
                                    <option key={item.comm_code} value={item.comm_code}>{item.comm_code}</option>
                                )}
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select name='searchCol' value={searchMap.get('searchCol')} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option value='detail_code'>상세코드</option>
                                <option value='detail_code_nm'>상세코드명</option>
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select name='useYn' value={useYn} onChange={(e) => { setUseYn(e.target.value); }}>
                                <option value=''>전체</option>
                                <option value='Y'>사용</option>
                                <option value='N'>미사용</option>
                            </Form.Select>
                        </Col>
                    </>
                }
                rightChildren={
                    <Col xs="auto">
                        <Button onClick={() => popReg()}>등록</Button>
                    </Col>
                } />
            {isDesktop ? (
                <>
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailList} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => detailModalPopup(vo)} />
                    {pageCount > 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleList.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={simple_columns} data={simpleList} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => detailModalPopup(vo)} />
                </InfiniteScroll>
            )}
            <PopupModal show={showModal} setShow={setShowModal} isMod={isMod} selectDetailCode={selectDetailCode} setSelectDetailCode={setSelectDetailCode} commCodeList={commCodeList} search={search} />
            <AlertModal title='정말 삭제하시겠습니까?' show={showAlert} setShow={setShowAlert} agree='삭제' func={() => confirm()} />
        </div>
    );
}

const PopupModal = ({ show, setShow, isMod, selectDetailCode, setSelectDetailCode, commCodeList, search }: { show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, isMod: boolean, selectDetailCode: CodeVO, setSelectDetailCode: React.Dispatch<React.SetStateAction<CodeVO>>, commCodeList: CodeVO[] | undefined, search: () => void; }): React.ReactElement => {

    const [dupCheck, setDupCheck] = useState(false);

    async function detailCodePost() {
        if (!isMod && !dupCheck) {
            toast("상세코드 중복확인을 해주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        if (selectDetailCode.detail_code_nm === '') {
            toast("상세코드명을 입력해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        const url = isMod ? '/api/admin/updateDetailCode' : '/api/admin/insertDetailCode';
        const response = await axios.post(url, selectDetailCode, { headers: { "Content-Type": 'application/json' }, params: { "userId": sessionStorage.getItem("user_id") } });
        const data = response.data;
        if (data === 1) {
            toast(isMod ? "수정이 완료되었습니다." : "등록이 완료되었습니다.", { position: 'top-center', autoClose: 2000 });
            setShow(false);
            search();
        } else {
            toast(isMod ? "수정 실패" : "등록 실패", { position: 'top-center', autoClose: 2000 });
        }

    };


    async function getCodeOrder(e: React.ChangeEvent<any>) {
        const comm_code = e.target.value;
        if (comm_code !== 'none') {
            const comm_code_nm = commCodeList!.find(option => option.comm_code === comm_code)!.comm_code_nm
            const response = await axios.post('/api/admin/getCodeOrder', null, { params: { "comm_code": comm_code } });
            const data = await response.data;
            setSelectDetailCode({ ...selectDetailCode, detail_code_order: data, comm_code: comm_code, comm_code_nm: comm_code_nm });
        }
    };

    async function codeDupCheck() {
        if (selectDetailCode.detail_code === "") {
            toast("상세코드가 공백입니다.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        const response = await axios.post('/api/admin/detailCodeDupCheck', { 'comm_code': selectDetailCode.comm_code, 'detail_code': selectDetailCode.detail_code }, { headers: { "Content-Type": 'application/json' } });
        const data = response.data;
        if (data === 0) {
            setDupCheck(true);
        } else {
            toast("중복된 상세코드 입니다.", { position: 'top-center', autoClose: 2000 });
        }
    };

    function changeCodeMap(e: React.ChangeEvent<any>) {
        setSelectDetailCode({ ...selectDetailCode, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setDupCheck(false);
    }, [show]);

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{isMod ? '상세코드 - 상세조회' : '상세코드 - 등록'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col xs='4'>
                            공통코드
                        </Col>
                        <Col>
                            <Form.Select name='comm_code' value={selectDetailCode.comm_code} onChange={(e) => { getCodeOrder(e); }}>
                                <option value="none">공통코드선택</option>
                                {commCodeList?.map((item: CodeVO) =>
                                    <option key={item.comm_code} value={item.comm_code}>{item.comm_code_nm}</option>
                                )}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            상세코드
                        </Col>
                        {isMod ?
                            <Col>
                                <Form.Control type="textBox" name="detail_code" value={selectDetailCode.detail_code} disabled />
                            </Col>
                            :
                            <>
                                <Col>
                                    <Form.Control type="textBox" name="detail_code" value={selectDetailCode.detail_code} onChange={(e) => { setDupCheck(false); changeCodeMap(e); }} />
                                </Col>
                                <Col xs="auto">
                                    <Button size="sm" variant={dupCheck ? 'success' : 'danger'} onClick={() => codeDupCheck()}>  {dupCheck ? "확인완료" : "중복확인"}</Button>
                                </Col>
                            </>
                        }
                    </Row>
                    <Row>
                        <Col xs='4'>
                            상세코드명
                        </Col>
                        <Col>
                            <Form.Control type="textBox" name="detail_code_nm" value={selectDetailCode.detail_code_nm} onChange={(e) => changeCodeMap(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            상세코드값
                        </Col>
                        <Col>
                            <Form.Control type="textBox" name="detail_code_val" value={selectDetailCode.detail_code_val ?? ""} onChange={(e) => changeCodeMap(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            상위공통코드
                        </Col>
                        <Col>
                            <Form.Control type="textBox" name="upper_comm_code" value={selectDetailCode.upper_comm_code ?? ""} onChange={(e) => changeCodeMap(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            상위상세코드
                        </Col>
                        <Col>
                            <Form.Control type="textBox" name="upper_detail_code" value={selectDetailCode.upper_detail_code ?? ""} onChange={(e) => changeCodeMap(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            정렬순서
                        </Col>
                        <Col>
                            <Form.Control type="number" name="detail_code_order" value={selectDetailCode.detail_code_order} onChange={(e) => changeCodeMap(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            사용여부
                        </Col>
                        <Col>
                            <Form.Select name='use_yn' value={selectDetailCode.use_yn} onChange={(e) => changeCodeMap(e)}>
                                <option value='Y'>사용</option>
                                <option value='N'>미사용</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    {isMod &&
                        <>
                            <Row>
                                <Col xs='4'>
                                    등록자
                                </Col>
                                <Col>
                                    <Form.Control type="textBox" name="register" defaultValue={selectDetailCode.register} disabled />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs='4'>
                                    등록일
                                </Col>
                                <Col>
                                    <Form.Control type="textBox" name="rgsde" defaultValue={selectDetailCode.rgsde} disabled />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs='4'>
                                    수정자
                                </Col>
                                <Col>
                                    <Form.Control type="textBox" name="updusr" defaultValue={selectDetailCode.updusr} disabled />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs='4'>
                                    수정일
                                </Col>
                                <Col>
                                    <Form.Control type="textBox" name="updde" defaultValue={selectDetailCode.updde} disabled />
                                </Col>
                            </Row>
                        </>
                    }
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    닫기
                </Button>
                <Button variant="primary" onClick={() => detailCodePost()}>
                    {isMod ? '수정' : '등록'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

//DataTable에서 사용하는 데이터의 형식 (최하단의 [key: string]: any; 는 공통로직에서 key값에 map으로 접근하기 위해 필수)
type CodeVO = {
    no?: number;
    comm_code: string;
    comm_code_nm: string;
    detail_code: string;
    detail_code_nm: string;
    detail_code_val: string;
    upper_comm_code: string;
    upper_detail_code: string;
    detail_code_order: number;
    pre_ordr_code?: number;
    pre_comm_code?: string;
    use_yn: string;
    use_str: string;
    rgsde: string;
    register: string;
    updde: string;
    updusr: string;
    [key: string]: any;
}

export default MngDetailCode;
