import { Modal, Button } from "react-bootstrap";
import { ReactElement, useEffect, useState  } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import ApprInfoArea from "./ApprInfoArea";

interface ApprProps {
    show: boolean,
    setShow: Function,
    searchFunc?: Function,
    currPage?: number, 
    rowSize?: number, 
    isDetail?: boolean,
    mode: string
}

function SignAppr({show, setShow, searchFunc, currPage, rowSize, isDetail, mode}: ApprProps) {
    
    const location = useLocation();
    const isDesktop = useMediaQuery({ minWidth: 768 });

    const [modalData, setModalData] = useState<string>('');
    const subWorkerList:deptMemListVO[] = [];
    const recentApprList:recentApprListVO[] = [];
    const signForm = ({
        sign_line_type: '',
        retention: 0,
        cooperation_yn: '',
        receive_yn: '',
        fixReceptionList: [],
        fix_dept_nm: '',
        fix_dept_cd: '',
        deptList: [],
        dept_auth_cd: '',
        dept_auth_nm: '',
        formCompList: [],
        modify_yn: '',
        frm_ctg_name: '',
        sec_level_cd: '',
        output_file: '',
        use_yn: '',
        frm_num: '',
        revision_num: 0,
        doc_tp_cd: ''
    });
    const currUserInfo = ({
        USER_POSIT_NM: '',
        DRFT_DT: '',
        USER_NM:'',
        USER_POSIT_CD:'',
        USER_DEPT_NM:'',
        APPR_SIGNATURE_USE_YN:'',
        USER_ID:'',
        USER_DEPT_CD:'',
        APPR_SIGNATUR_PATH:'',
    });

    const varStatus = { last: true };
    const status = { last: true };
    const lastSeq = 0; // 예제 인덱스
    const location_id = location.state?.id;
    const location_text = location.state?.text;
    const location_mode = mode;
    const isPage = location.pathname === '/appr/SignAppr';
    let searchMap: Map<String, string>;
    
    const navigate = useNavigate();
    useEffect(() => {
        const getData_main = async () => {
            try {
                const param = new Map();
                param.set("frm_id", location_id);
                param.set("mode", "edit");
                param.set("vacation_yn", location.state?.vacation_yn);
                param.set("cooperation_yn", location.state?.cooperation_yn);
                setModalData(JSON.stringify(Object.fromEntries(param)));
            } catch (error) {
                alert("Failed axios : ");
            } finally {

            }
        };
        getData_main();
    }, []);


    function closeModal() {
        
        if (isPage) {
            if (location.state)
                navigate('/appr/easMain', {state: {
                                                currPage: currPage,
                                                rowSize: rowSize,
                                                isDetail: false,
                                                searchMap: searchMap
                                            }, replace: true});
            else
                navigate('/appr/easMain');
        } else {
            setShow(false);
            if (searchFunc && typeof(currPage) !== 'undefined' 
                && typeof(rowSize) !== 'undefined' && typeof(isDetail) !== 'undefined')
                searchFunc(currPage, rowSize, true);
        };
    }

    function modalWrap(apprInfo: any) {
        return (
            <Modal show={show} onHide={() => closeModal()}
                size="xl" aria-labelledby="contained-modal-title-vcenter" 
                backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {location_text}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0"> 
                    {apprInfo}                
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>닫기</Button>
            </Modal.Footer>
            </Modal>
        )
    }
    const rtn: ReactElement = modalWrap(<ApprInfoArea boxTpSignUserListCheck={''}
        recentApprListCheck = {recentApprList}
        currUserInfo = {currUserInfo}
        signForm = {signForm}
        subWorkerList = {subWorkerList}
        actionStatus = {modalData}
        varStatus={varStatus.last}
        status={status.last}
        lastSeq={lastSeq} 
        mode={location_mode}
        />);
    if (!isDesktop && modalData) {

        return (
            <>
            <ApprInfoArea
                boxTpSignUserListCheck={''}
                recentApprListCheck = {recentApprList}
                currUserInfo = {currUserInfo}
                signForm = {signForm}
                subWorkerList = {subWorkerList}
                actionStatus = {modalData}
                varStatus={varStatus.last}
                status={status.last}
                lastSeq={lastSeq} 
                mode={location_mode} 
                />
            </>
        )
    } else {

        if(modalData){
            return rtn;
        }else{
            return null;
        }
        
    }
}

type recentApprListVO = {
    SIGN_USER_DEPT_NM: string;
    SIGN_USER_ID: string;
    FILE_UPDE_AUTH_YN: string;
    LINE_UPDE_AUTH_YN: string;
    SIGN_SEQ: number;
    SIGN_USER_POSIT_CD: string;
    DOC_UPDE_AUTH_YN: string;
    SIGN_USER_POSIT_NM: string;
    SIGN_TP_CD: string;
    SIGN_TP_NM: string;
    SIGN_USER_NM: string;
    SIGN_USER_DEPT_CD: string;

    [key: string]: any;
}

type deptMemListVO = {
    USER_ID: string;
    USER_NM: string;
    USER_POSIT_NM: string;
    USER_DEPT_NM: string;
    [key: string]: any;
}

export default SignAppr;