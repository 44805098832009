import { useMediaQuery } from 'react-responsive';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Col, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap';
import { TreeComponent } from '../../module/TreeViewParentSelect';

const AlertModal: React.FC<any> = ({ show, setShow, category }) => {

    const [selectCategory, setSelectCategory] = useState<CategoryVO>({} as CategoryVO);

    useEffect(() => {
    }, [selectCategory]);


    const handleClose = useCallback(() => setShow(false), [setShow]);
    const handleSave = () => {

        const { dept_upper_code, ctg_name } = selectCategory;

        if (!dept_upper_code || dept_upper_code === 'none') {
            alert("상위 카테고리를 선택 하세요.");
            return;
        }

        if (!ctg_name || ctg_name.trim() === '') {
            alert("하위 카테고리를 작성 하세요.");
            return;
        }

        selectCategory.ctg_depth = dept_upper_code === '0000000000000000' ? 1 : 2;
        const urlSave = '/api/admin/create';
        axios.post(urlSave, {
            frm_name: selectCategory.ctg_name,
            ctg_deth: selectCategory.ctg_depth,
            frm_type: selectCategory.dept_upper_code,
            reg_user_id: sessionStorage.getItem("user_id"),
        }, {
            headers: { "Content-Type": 'application/json' }
        })
            .then(response => {
                window.location.reload();
                alert("등록 하였습니다.");
            })
            .catch(error => {
                alert("오류가 발생하였습니다. 재확인 부탁 드립니다.")
            });
    };
    const handleChange = (field: keyof CategoryVO, value: any) => {
        const updatedCategory = { ...selectCategory, [field]: value };
        setSelectCategory(updatedCategory);
    };

    const handleChange2 = (field: keyof CategoryVO, value: any) => {
        const updatedCategory = { ...selectCategory, [field]: value };
        setSelectCategory(updatedCategory);
    };
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>카테고리 등록</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <tbody>
                        <tr>

                            <td><Form.Label>
                                상위 카테고리
                            </Form.Label></td>
                            <td>
                                <Form.Select name="dept_upper_code" style={{ width: '38%' }} onChange={(e) => handleChange2("dept_upper_code", e.target.value)}>
                                    <option key="none" value="none">카테고리선택</option>
                                    <option key="0000000000000000" value="0000000000000000">신규</option>
                                    {category.map((ctg: any) => (
                                        <option key={ctg.ctg_id} value={ctg.ctg_id}>
                                            {ctg.ctg_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Form.Label>
                                    하위 카테고리
                                </Form.Label>
                            </td>
                            <td>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => handleChange("ctg_name", e.target.value)}
                                    />
                                </InputGroup>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSave}>
                    저장
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


function MngCategory() {
    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const [category, setCategory] = useState<CategoryVO[]>([]);
    const [selectCategory, setSelectCategory] = useState<CategoryVO>();
    const [form, setForm] = useState<FormVO[]>([]);
    const [openedCategories, setOpenedCategories] = useState(['']);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const [showAlert, setShowAlert] = useState(false);
    // 리스트 데이터 가져오기
    const selectDataList = async () => {
        const response = await axios.post(
            '/api/admin/selectCategory',
            {},
            { headers: { "Content-Type": 'application/json' } }
        )
        const newData = response.data.content.map((item: CategoryVO) => {
            const children = response.data.content.filter((child: CategoryVO) => child.up_ctg_id === item.ctg_id);

            return {
                ...item,
                key: item.ctg_id,
                menu_id: item.ctg_id,
                children: item.up_ctg_id === item.ctg_id
                    ? [...children, { ...item, children: [] }]
                    : children,
            };
        }).filter((item: CategoryVO) => item.ctg_depth <= 1 && item.ctg_depth !== 0);


        setCategory(newData)

    }

    useEffect(() => {
        const loadPosts = async () => {
            selectDataList();
        };
        loadPosts();
    }, []);
    // 카테고리 선택
    const handleCategoryClick = async (node: any) => {
        setSelectCategory(node);
        setSelectedItems([]);
        var response = await axios.post(
            '/api/admin/selectForm',
            {
                frm_ctg_id: node.ctg_id
            },
            { headers: { "Content-Type": 'application/json' } }
        )
        setForm(response.data.content);
        if (openedCategories.includes(node.ctg_id)) {
            setOpenedCategories(openedCategories.filter((id) => id !== node.ctg_id));
        } else {
            setOpenedCategories([...openedCategories, node.ctg_id]);
        }
    };
    // 체크박스 선택
    const handleCheckboxChange = (id: string) => {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };
    // 전체선택
    const handleSelectAll = () => {
        setSelectedItems(form.map((item) => item.frm_id));
    };
    // 전체해제
    const handleDeselectAll = () => {
        setSelectedItems([]);
    };

    const handleChange = (field: keyof CategoryVO, value: any) => {
        if (selectCategory) {
            const updatedCategory = { ...selectCategory, [field]: value };
            setSelectCategory(updatedCategory);
        }
    };

    const updateCategory = () => {
        const dept_upper_code = selectCategory?.dept_upper_code;
        const selectedItemsLen = selectedItems.length;
        if (!dept_upper_code || dept_upper_code === 'none') {
            alert("카테고리를 선택 하세요.");
            return;
        }
        if (selectedItemsLen === 0) {
            alert("카테고리를 체크 하세요.");
            return;
        }
        const url = '/api/admin/modCate';
        axios.post(url, {
            arr: selectedItems,
            frm_ctg_id: selectCategory.dept_upper_code,
            reg_user_id: sessionStorage.getItem("user_id"),
        }, {
            headers: { "Content-Type": 'application/json' }
        })
            .then(response => {
                window.location.reload();
                alert("수정 하였습니다.");
            })
            .catch(error => {
                alert("오류가 발생하였습니다. 재확인 부탁 드립니다.")
            });
    }
    const addBtn = () => {
        setShowAlert(true);
    }

    const modifyBtn = () => {
        axios.post('/api/admin/modify', {
            frm_ctg_id: selectCategory?.ctg_id,
            frm_name: selectCategory?.ctg_name,
            use_yn: selectCategory?.use_yn,
            reg_user_id: sessionStorage.getItem("user_id"),
        }, {
            headers: { "Content-Type": 'application/json' }
        })
            .then(response => {
                window.location.reload();
                alert("수정 하였습니다.");
            })
            .catch(error => {
                console.error('Error modifying category:', error);
            });
    }

    const delBtn = () => {
        axios.post('/api/admin/forDelete', {
            frm_ctg_id: selectCategory?.ctg_id,
            reg_user_id: sessionStorage.getItem("user_id"),
        }, {
            headers: { "Content-Type": 'application/json' }
        })
            .then(response => {
                window.location.reload();
                alert("삭제 하였습니다.");
            })
            .catch(error => {
                console.error('Error modifying category:', error);
            });
    }

    return (
        <div className='content'>
            <h2>카테고리관리</h2>
            <Row>
                <Col md={6} xs={12} style={{ marginBottom: isDesktop ? '' : '1rem' }}>
                    <Card>
                        <Card.Header as="h4" style={{ height: '3rem' }}>결재양식 카테고리</Card.Header>
                        <Card.Body>

                            <TreeComponent nodes={category} idField={"ctg_id"} nameField={"ctg_name"} onNodeClick={(node) => { handleCategoryClick(node); }} defaultExpend={true} />


                        </Card.Body>
                        <Card.Footer style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <Button onClick={addBtn}>신규</Button>
                            <Button onClick={modifyBtn}>편집</Button>
                            <Button onClick={delBtn}>삭제</Button>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card className={isDesktop ? 'h-100' : ''}>
                        <Card.Header as="h4" style={{ height: '3rem' }}>{selectCategory?.ctg_name}</Card.Header>
                        <Card.Body>
                            {form.map((item) =>
                                <div><Form.Check style={{ paddingLeft: '2rem' }} key={item.frm_id} value={item.frm_id} label={item.frm_name} id={item.frm_id} checked={selectedItems.includes(item.frm_id)} onChange={() => { handleCheckboxChange(item.frm_id) }} onClick={(e) => e.stopPropagation()} /></div>
                            )}
                        </Card.Body>
                        <Card.Footer style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Button onClick={handleSelectAll}>전체선택</Button>
                            <Button onClick={handleDeselectAll}>전체해제</Button>
                            <Form.Select name="dept_upper_code" style={{ width: '38%' }} onChange={(e) => handleChange("dept_upper_code", e.target.value)}>
                                <option key={"none"} value="none">카테고리선택</option>
                                {category.map((ctg: any) => (
                                    <option key={ctg.ctg_id} value={ctg.ctg_id}>
                                        {ctg.ctg_name}
                                    </option>
                                ))}
                            </Form.Select>
                            <Button onClick={updateCategory}>변경</Button>
                        </Card.Footer>

                    </Card>
                </Col>
            </Row>

            <Table style={{ marginTop: '1rem' }} responsive>
                <colgroup>
                    <col width={'25%'}></col>
                    <col width={'25%'}></col>
                    <col width={'25%'}></col>
                    <col width={'25%'}></col>
                </colgroup>
                <thead>
                    <tr>
                        <th>카테고리ID</th>
                        <td>{selectCategory?.ctg_id}</td>
                        <th>Auth</th>
                        <td>{selectCategory?.dept_auth}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>카테고리명</th>
                        <td><InputGroup>
                            <Form.Control
                                type="text"
                                value={selectCategory?.ctg_name}
                                onChange={(e) => handleChange("ctg_name", e.target.value)}
                            />
                        </InputGroup> </td>
                        <th>수정자</th>
                        <td>{selectCategory?.edit_user_id}</td>
                    </tr>
                    <tr>
                        <th>Depth</th>
                        <td>{selectCategory?.ctg_depth}</td>
                        <th>수정일</th>
                        <td>{selectCategory?.edit_dt}</td>
                    </tr>
                    <tr>
                        <th>카테고리SEQ</th>
                        <td>{selectCategory?.ctg_order}</td>
                        <th>등록자</th>
                        <td>{selectCategory?.reg_user_id}</td>
                    </tr>
                    <tr>
                        <th>사용여부</th>
                        <td>
                            <input type="radio" name="use_yn" value="Y" checked={selectCategory?.use_yn === 'Y'}
                                onChange={(e) => {
                                    if (selectCategory) {
                                        const updatedCategory = { ...selectCategory, [e.target.name]: e.target.value };
                                        setSelectCategory(updatedCategory);
                                    }
                                }}
                            />
                            <span>사용</span>

                            <input type="radio" name="use_yn" value="N" checked={selectCategory?.use_yn === 'N'}
                                onChange={(e) => {
                                    if (selectCategory) {
                                        const updatedCategory = { ...selectCategory, [e.target.name]: e.target.value };
                                        setSelectCategory(updatedCategory);
                                    }
                                }}
                            />
                            <span>미사용</span>
                        </td>
                        <th>등록일</th>
                        <td>{selectCategory?.reg_dt}</td>
                    </tr>
                </tbody>
            </Table>

            <AlertModal show={showAlert} setShow={setShowAlert} category={category} />
        </div>
    );
}


type CategoryVO = {
    ctg_depth: number;
    ctg_id: string;
    ctg_name: string;
    ctg_order: number;
    dept_auth: string;
    edit_dt: string;
    edit_user_id: string;
    menu_id: string;
    formList: {
        edit_id: string;
        edit_user_id: string;
        frm_body_dat: string;
        frm_ctg_id: string;
        frm_desc: string;
        frm_id: string;
        frm_name: string;
        frm_type: string;
        reg_de: string;
        reg_user_id: string;
    }[];
    left_ctg_id: string;
    left_up_ctg_id: string;
    old_ctg_orcer: number;
    old_up_ctg_id: string;
    dept_upper_code: string;
    reg_dt: string;
    reg_user_id: string;
    up_ctg_id: string;
    use_yn: string;
    children?: CategoryVO[];
}

type FormVO = {
    edit_id: string;
    edit_user_id: string;
    frm_body_dat: string;
    frm_ctg_id: string;
    frm_desc: string;
    frm_id: string;
    frm_name: string;
    frm_type: string;
    reg_de: string;
    reg_user_id: string;
}


export default MngCategory;