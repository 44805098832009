import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    Form,
    Button,
    Row,
    Col,
    Container
} from "react-bootstrap";
import Loading from "../../module/Loading";
import { toast } from 'react-toastify';

function ViewProxyEmailSendYn() {

    const [loading, setLoading] = useState(false);


    const [apprEmailUseYn, setApprEmailUseYn] = useState<boolean>(false);


    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setApprEmailUseYn(event.target.checked);
    };

    useEffect(() => {
        const getData_main = async () => {
            try {
                setLoading(true);
                const response = await axios.post(
                    "/api/cmn/setting/viewProxyUserInfo.menu",
                    {
                        user_id: sessionStorage.getItem("user_id")
                    },
                    { headers: { "Content-Type": "application/json" } }
                );
                const rtn = response.data;

                const myProxyUserInfo = rtn.myProxyUserInfo;

                myProxyUserInfo.email_send_yn === 'N' || null ? setApprEmailUseYn(false) : setApprEmailUseYn(true);


            } catch (error) {
                alert(
                    "서버 오류로 인한 " +
                    " 추출 실패, 관리자에게 문의 부탁 드립니다."
                );
            } finally {
                setLoading(false);
            }
        };

        getData_main();
    }, []);

    async function proxySave() {


        try {
            setLoading(true);
            const saveProxyRes = await axios.post(
                "/api/cmn/setting/emailSendYn.ajax",
                {
                    user_id: sessionStorage.getItem("user_id"),
                    "email_yn": apprEmailUseYn ? 'Y' : 'N',
                },
                { headers: { "Content-Type": "application/json" } }
            );
            <Loading />
            const rtn2 = saveProxyRes.data;
            if (rtn2.result === "success") {

            } else if (rtn2.result === "failed") {
                alert("failed");
            }
            toast.success("저장이 완료되었습니다.", {
                position: 'top-center',
                autoClose: 1000
            });
        } catch (error) {
            alert("Failed axios ");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            {loading && (
                <Loading />
            )}

            {!loading && (
                <Form>

                    <h3 style={{ margin: "15px 0", fontWeight: "bold" }}>
                        결재 알림조건 지정
                    </h3>
                    <p>
                        결재 알림은 기안상신 문서의 결재가 진행중이거나 완료 시에 메일로 발송됩니다.
                    </p>
                    <hr />
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={8} lg={6}>
                            <Form.Check
                                type="checkbox"
                                label="결재 요청이 들어오면 메일을 통해 알람을 받겠습니다."
                                value={apprEmailUseYn ? 'Y' : 'N'}
                                checked={apprEmailUseYn}
                                onChange={handleCheckboxChange}
                                inline
                            />
                        </Col>
                    </Form.Group>


                    <Button variant="primary" className="mt-3" onClick={proxySave}>
                        저장
                    </Button>
                </Form>
            )}
        </Container>
    )
}

export default ViewProxyEmailSendYn;
