import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import DataTableV2 from '../../module/DataTableV2';
import axios from 'axios';
import { CellProps } from 'react-table';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchBarV2 from '../../module/SearchBarV2';
import Paginate from '../../module/Paginate';
import Loading from '../../module/Loading';
import { toast } from 'react-toastify';
import AlertModal from '../../module/AlertModal';
import * as XLSX from 'xlsx';
import ApprInfoArea from '../appr/signbox/ApprInfoArea';

function MngApprDoc() {
    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });

    //큰 화면용 list를 위한 변수
    const [detailDataList, setDetailDataList] = useState<ApprDocVO[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    //작은 화면용 list를 위한 변수
    const [simpleDataList, setSimpleDataList] = useState<ApprDocVO[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);

    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map<string, string>([['searchCol', 'title']]));
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});

    const [frmNameList, setFrmNameList] = useState<{ frm_id: string; frm_name: string; }[]>([]);
    const [frmName, setFrmName] = useState<string>('');
    const [docStsList, setdocStsList] = useState<{ detail_code: string; detail_code_nm: string; }[]>([]);
    const [docStatus, setDocStatus] = useState<string>('');
    const [retention, setRetention] = useState<string>('');
    const [startDate, setStartDate] = useState<string>('20150101');
    const [endDate, setEndDate] = useState<string>(new Date().getFullYear().toString() + (new Date().getMonth() + 1).toString().padStart(2, '0') + new Date().getDate().toString().padStart(2, '0'));
    const offset = 1000 * 60 * 60 * 9
    const todayKR = (new Date((new Date()).getTime() + offset)).toISOString().split("T")[0];

    // 삭제 확인 모달
    const [showAlert, setShowAlert] = useState(false);

    // 결재문서 상세보기
    const [showApprDetail, setShowApprDetail] = useState(false);
    const [apprDetail, setApprDetail] = useState<{sign_id: string, frm_id: string, frm_name: string, mode: string, vacation_yn: string, cooperation_yn: string} | null>(null);

    const selectDataList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const searchStr = searchMap.get("searchStr");
        const searchCol = searchMap.get("searchCol");
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/admin/selectApprDocList',
            {
                // drft_user_nm: '',
                frm_name: frmName,
                doc_sts_cd: docStatus,
                retention: retention,
                drft_st_dt: startDate,
                drft_ed_dt: endDate,
                lang: "ko",
                searchCol: searchCol,
                searchStr: searchStr,
                currPage: currPage,
                rowSize: rowSize,
                sortStr: sortStr,
                sortDir: sortDir,
            },
            { headers: { "Content-Type": 'application/json' } }
        )
        setFrmNameList(response.data.frmNm);
        setdocStsList(response.data.stsCd);
        const addData = response.data.content.map((item: ApprDocVO) => ({
            ...item,
            drafter: `${item.drft_user_nm}/${item.drft_user_posit_nm}/${item.drftper_dept_nm}`,
        }));
        if (isDetail) {
            setDetailDataList(addData);
            setPageCount(response.data.totalPages);
            setRowCount(response.data.rowCount);
        } else {
            if (simplePage === 0) {
                setSimpleDataList(response.data.content);
            } else {
                setSimpleDataList(prevPosts => [...prevPosts, ...response.data.content]);
            }
            setHasMore(!response.data.last);
        }
        setLoading(false);
        setCheckedItems({});
    }
    function search() {
        setCheckedItems({});
        if (detailPage === 0) {
            selectDataList(0, rowSize, true);
        } else {
            setDetailPage(0);
        }
        if (simplePage === 0) {
            selectDataList(0, 25, false);
        } else {
            setSimplePage(0);
        }
    };

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDataList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDataList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            search();
        };
        loadPosts();
    }, [sort]);

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    function changePeriod(e: string) {
        const today = new Date();
        const oneWeekAgoDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        const oneMonthAgoDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        const threeMonthsAgoDate = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
        const sixMonthsAgoDate = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
        const convertDateFormat = (date: Date) => {
            return date.getFullYear().toString() + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0')
        }
        setEndDate(convertDateFormat(today))
        if (e === '') {
            setStartDate('');
            setEndDate('');
        } else if (e === '1w') {
            setStartDate(convertDateFormat(oneWeekAgoDate))
        } else if (e === '1m') {
            setStartDate(convertDateFormat(oneMonthAgoDate))
        } else if (e === '3m') {
            setStartDate(convertDateFormat(threeMonthsAgoDate))
        } else if (e === '6m') {
            setStartDate(convertDateFormat(sixMonthsAgoDate))
        }
        setShowInput(e === 'in')
    }
    const [showInput, setShowInput] = useState<boolean>(false);

    // 폐기
    const disuseDoc = async () => {
        const sign_id_list: string[] = [];
        const data = isDesktop ? detailDataList : simpleDataList;

        for (const checkedKey in checkedItems) {
            if (checkedItems[checkedKey] && data.some((item) => item.doc_sts_cd_kor !== '폐기')) {
                sign_id_list.push(checkedKey);
            } else {
                setCheckedItems({});
                toast("이미 폐기된 문서입니다.", { position: 'top-center', autoClose: 2000 });
                return;
            }
        }
        if (sign_id_list.length > 0) {
            await axios.post(
                '/api/admin/disuseDoc',
                {
                    sign_id_list: sign_id_list,
                },
                { headers: { "Content-Type": 'application/json' } }
            )
            search();
        } else {
            toast("폐기할 문서를 선택해 주세요.", { position: 'top-center', autoClose: 2000 });
        }
    }
    // 삭제
    const deleteDoc = async () => {
        const sign_id_list: string[] = [];
        const data = isDesktop ? detailDataList : simpleDataList;

        for (const checkedKey in checkedItems) {
            if (checkedItems[checkedKey] && data.some((item) => item.doc_sts_cd_kor === '폐기')) {
                sign_id_list.push(checkedKey);
            } else {
                setCheckedItems({});
                toast("폐기된 문서만 삭제할 수 있습니다.", { position: 'top-center', autoClose: 2000 });
                return;
            }
        }
        if (sign_id_list.length > 0) {
            await axios.post(
                '/api/admin/deleteDoc',
                {
                    sign_id_list: sign_id_list,
                },
                { headers: { "Content-Type": 'application/json' } }
            )
            search();
        } else {
            toast("삭제할 문서를 선택해 주세요.", { position: 'top-center', autoClose: 2000 });
        }
        setShowAlert(false);
    }
    // 복구
    const restoreDoc = async () => {
        const sign_id_list: string[] = [];
        const data = isDesktop ? detailDataList : simpleDataList;

        for (const checkedKey in checkedItems) {
            if (checkedItems[checkedKey] && data.some((item) => item.doc_sts_cd_kor === '폐기')) {
                sign_id_list.push(checkedKey);
            } else {
                setCheckedItems({});
                toast("폐기된 문서만 복구할 수 있습니다.", { position: 'top-center', autoClose: 2000 });
                return;
            }
        }
        if (sign_id_list.length > 0) {
            await axios.post(
                '/api/admin/restoreDoc',
                {
                    sign_id_list: sign_id_list,
                },
                { headers: { "Content-Type": 'application/json' } }
            )
            search();
        } else {
            toast("복구할 문서를 선택해 주세요.", { position: 'top-center', autoClose: 2000 });
        }
    }

    const excelDown = async () => {
        const searchStr = searchMap.get("searchStr");
        const searchCol = searchMap.get("searchCol");
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/admin/selectApprDocList',
            {
                // drft_user_nm: '',
                frm_name: frmName,
                doc_sts_cd: docStatus,
                retention: retention,
                drft_st_dt: startDate,
                drft_ed_dt: endDate,
                lang: "ko",
                searchCol: searchCol,
                searchStr: searchStr,
                currPage: 0,
                rowSize: rowCount,
                sortStr: sortStr,
                sortDir: sortDir,
            },
            { headers: { "Content-Type": 'application/json' } }
        )
        const newArray: { [key: string]: any }[] = response.data.content.map((item: ApprDocVO) => ({
            '문서번호': item.docno,
            '양식명': item.frm_name,
            '제목': item.sign_doc_title,
            '기안자': item.drft_user_nm + '/' + item.drft_user_posit_nm + '/' + item.drftper_dept_nm,
            '기안일': item.drft_dt,
            '최종결재일': item.sign_dt,
            '상태': item.doc_sts_cd_kor,
        }));
        const workbook = XLSX.utils.book_new(); // 객체 생성
        const worksheet = XLSX.utils.json_to_sheet(newArray); // data
        XLSX.utils.book_append_sheet(workbook, worksheet, "결재지연관리"); // sheet
        XLSX.writeFile(workbook, "결재지연관리.xlsx"); // file
    }

    // 결재문서 클릭
    const handleApprRowClick = (vo: any) => {
        setApprDetail({
            sign_id: vo.sign_id,
            frm_id: vo.frm_id,
            frm_name: vo.frm_name,
            mode: 'sView',
            vacation_yn: vo.vacation_form_yn,
            cooperation_yn: vo.cooperation_yn,
        });
    };
    // 결재문서 값이 바뀌면 상세보기 열기
    useEffect(() => {
        if (apprDetail) {
            setShowApprDetail(true);
        }
    }, [apprDetail]);

    return (
        <div className='content'>
            <h4>결재문서관리</h4>
            <SearchBarV2
                setRowSize={setRowSize}
                rowCount={rowCount}
                searchMap={searchMap}
                setSearchMap={setSearchMap}
                search={search}
                leftChildren={
                    <>
                        <Col sm="auto">
                            <Form.Select name='frm_name' value={frmName} onChange={(e) => { setFrmName(e.target.value) }}>
                                <option value={''}>양식명</option>
                                {frmNameList.map((item) =>
                                    <option key={item.frm_id}>{item.frm_name}</option>
                                )}
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select name='doc_status' value={docStatus} onChange={(e) => { setDocStatus(e.target.value) }}>
                                <option value={''}>문서상태</option>
                                {docStsList.map((item) =>
                                    <option key={item.detail_code} value={item.detail_code}>{item.detail_code_nm}</option>
                                )}
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select name='retention' value={retention} onChange={(e) => { setRetention(e.target.value) }}>
                                <option value={''}>보존연한</option>
                                <option value={'1'}>1년</option>
                                <option value={'3'}>3년</option>
                                <option value={'5'}>5년</option>
                                <option value={'10'}>10년</option>
                                <option value={'15'}>15년</option>
                                <option value={'20'}>20년</option>
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select name='period' onChange={(e) => { changePeriod(e.target.value) }}>
                                <option value={''}>전체기간</option>
                                <option value={'1w'}>1주일</option>
                                <option value={'1m'}>1개월</option>
                                <option value={'3m'}>3개월</option>
                                <option value={'6m'}>6개월</option>
                                <option value={'in'}>직접입력</option>
                            </Form.Select>
                        </Col>
                        {showInput && (
                            <>
                                <Col xs="auto">
                                    <Form.Control type='date' defaultValue={''} min={'2015-01-01'} max={endDate.substring(0, 4) + '-' + endDate.substring(4, 6) + '-' + endDate.substring(6)} onChange={(e) => { setStartDate(e.target.value.replaceAll('-', '')); }} />
                                </Col>
                                <Col xs="auto">~</Col>
                                <Col xs="auto">
                                    <Form.Control type='date' defaultValue={''} min={startDate.substring(0, 4) + '-' + startDate.substring(4, 6) + '-' + startDate.substring(6)} max={todayKR.substring(0)} onChange={(e) => { setEndDate(e.target.value.replaceAll('-', '')); }} />
                                </Col>
                            </>
                        )}
                        <Col sm="auto">
                            {/* 기안자 text input 양식명 select 내용 select 내용 text */}
                            <Form.Select name='searchCol' value={searchMap.get('searchCol')} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option value='title'>제목</option>
                                <option value='drafter'>기안자</option>
                                <option value='content'>본문</option>
                                <option value='file'>첨부파일</option>
                            </Form.Select>
                        </Col>
                    </>
                }
                rightChildren={
                    <>
                        <Col xs="auto">
                            <Button onClick={disuseDoc}>폐기</Button>
                        </Col>
                        <Col xs="auto">
                            <Button onClick={() => setShowAlert(true)}>영구삭제</Button>
                        </Col>
                        <Col xs="auto">
                            <Button onClick={restoreDoc}>복구</Button>
                        </Col>
                        <Col xs="auto">
                            <Button onClick={excelDown}>엑셀 다운로드</Button>
                        </Col>
                    </>
                } />
            {isDesktop ? (
                <>
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailDataList} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => { handleApprRowClick(vo) }}
                        keyField='sign_id' checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
                    {pageCount > 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleDataList.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={simple_columns} data={simpleDataList} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => { handleApprRowClick(vo) }}
                        keyField='sign_id' checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
                </InfiniteScroll>
            )}
            <AlertModal title='정말 삭제하시겠습니까?' show={showAlert} setShow={setShowAlert} agree='삭제' func={deleteDoc} />
            <Modal show={showApprDetail} onHide={()=>{setShowApprDetail(false);}} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {apprDetail?.frm_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ApprInfoArea 
                        boxTpSignUserListCheck={''}
                        recentApprListCheck={[]}
                        mode={'view'}
                        currUserInfo={{
                            USER_POSIT_NM: "",
                            DRFT_DT: "",
                            USER_NM: "",
                            USER_POSIT_CD: "",
                            USER_DEPT_NM: "",
                            APPR_SIGNATURE_USE_YN: "",
                            USER_ID: "",
                            USER_DEPT_CD: "",
                            APPR_SIGNATUR_PATH: ""
                        }} 
                        signForm={{
                            sign_line_type: "",
                            retention: 0,
                            cooperation_yn: "",
                            receive_yn: "",
                            fixReceptionList: [],
                            fix_dept_nm: "",
                            fix_dept_cd: "",
                            deptList: [],
                            dept_auth_cd: "",
                            dept_auth_nm: "",
                            formCompList: [],
                            modify_yn: "",
                            frm_ctg_name: "",
                            sec_level_cd: "",
                            output_file: "",
                            use_yn: "",
                            frm_num: "",
                            revision_num: 0,
                            doc_tp_cd: ""
                        }} 
                        subWorkerList={[]} 
                        actionStatus= {JSON.stringify(apprDetail)}
                        varStatus={false} 
                        status={false} 
                        lastSeq={0}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setShowApprDetail(false);}}>닫기</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

type ApprDocVO = {
    doc_sts_cd: string;
    doc_sts_cd_kor: string;
    docno: string;
    drft_dt: string;
    drft_st_dt: string;
    drft_ed_dt: string;
    drft_user_id: string;
    drft_user_nm: string;
    drft_user_posit_cd: string;
    drft_user_posit_nm: string;
    drftper_dept_cd: string;
    drftper_dept_nm: string;
    frm_id: string;
    frm_name: string;
    progress: string[];
    retention: string;
    sign_doc_title: string;
    sign_dt: string;
    sign_id: string;
    sign_sts_cd: string;
    [key: string]: any;
}

const detail_columns = [
    {
        Header: 'NO',
        accessor: 'no'
    },
    {
        Header: '문서번호',
        accessor: 'docno'
    },
    {
        Header: '양식명',
        accessor: 'frm_name'
    },
    {
        Header: '제목',
        accessor: 'sign_doc_title'
    },
    {
        Header: '기안자',
        accessor: 'drafter'
    },
    {
        Header: '기안일',
        accessor: 'drft_dt'
    },
    {
        Header: '최종결재일',
        accessor: 'sign_dt'
    },
    {
        Header: '상태',
        accessor: 'doc_sts_cd_kor'
    },
    {
        Header: 'Progress',
        accessor: 'option',
        Cell: ({ row }: CellProps<ApprDocVO>) => (
            <div style={{ display: 'inline-flex' }}>
                {(row.original.progress?.map((item, index) =>
                    <span key={index} style={{ border: '1px solid black', backgroundColor: item === 'W1' ? 'lightgray' : item === 'C1' ? 'lightblue' : item === 'R1' ? 'lightcoral' : item === 'G1' ? 'wheat' : '', width: '22px', marginLeft: '1px', textAlign: 'center' }}>{index}</span>
                ))}
                {/* W1 대기, C1 승인, R1 반려, G1 진행 */}
            </div>
        ),
    }
];

const simple_columns = [
    {
        Header: '양식명',
        accessor: 'frm_name'
    },
    {
        Header: '기안자',
        accessor: 'drft_user_nm'
    },
    {
        Header: '기안일',
        accessor: 'drft_dt'
    },
    {
        Header: '상태',
        accessor: 'doc_sts_cd_kor'
    },
];

export default MngApprDoc;