import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button, Col, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CellProps } from 'react-table';
import InfiniteScroll from 'react-infinite-scroll-component';
import DataTableV2 from '../../module/DataTableV2';
import axios from 'axios';
import Paginate from '../../module/Paginate';
import Loading from '../../module/Loading';
import SearchBarV2 from '../../module/SearchBarV2';
import AlertModal from '../../module/AlertModal';
import PopupShow from '../myinfo/MyInfoPopup';

function MngUser() {
    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });

    //큰 화면용 list를 위한 변수
    const [detailUser, setDetailUser] = useState<UserVO[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    //작은 화면용 list를 위한 변수
    const [simpleUser, setSimpleUser] = useState<UserVO[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);

    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    //AlertModal 변수
    const [showAlert, setShowAlert] = useState(false);

    //사용자 정보 변경을 위한 변수
    const [infoData, setInfo] = useState<{ [key: string]: any; }>([]);
    const [showModal, setShowModal] = useState(false);

    //유저 삭제함수 변수
    const [userId, setUserId] = useState('');

    //팝업 구분 변수
    const [category, setCategory] = useState(2);

    //큰화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
    //DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
    const detail_columns = [
        {
            Header: '순서',
            accessor: 'no'
        },
        {
            Header: '아이디',
            accessor: 'user_id'
        },
        {
            Header: '이름',
            accessor: 'user_nm'
        },
        {
            Header: '직급',
            accessor: 'position_nm'
        },
        {
            Header: '부서',
            accessor: 'dept_nm'
        },
        {
            Header: '사무실번호',
            accessor: 'office_phone'
        },
        {
            Header: '휴대폰번호',
            accessor: 'mobl_phone'
        },
        {
            Header: '이메일',
            accessor: 'email'
        },
        {
            Header: '활성여부',
            accessor: 'use_str'
        },
        {
            Header: '퇴사여부',
            accessor: 'retire_str'
        },
        {
            Header: '삭제',
            accessor: 'option1',
            Cell: ({ row }: CellProps<UserVO>) => (
                <Button size="sm" onClick={(e) => deleteFunc(row.original)}>
                    삭제
                </Button>
            ),
        }
    ];

    //작은화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
    //DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
    const simple_columns = [
        {
            Header: '아이디',
            accessor: 'user_id'
        },
        {
            Header: '이름',
            accessor: 'user_nm'
        },
        {
            Header: '직급',
            accessor: 'position_nm'
        },
        {
            Header: '부서',
            accessor: 'dept_nm'
        },
        {
            Header: '삭제',
            accessor: 'option1',
            Cell: ({ row }: CellProps<UserVO>) => (
                <Button size="sm" onClick={(e) => deleteFunc(row.original)}>
                    삭제
                </Button>
            ),
        }
    ];
    //유저 리스트 검색 함수
    const selectUserList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const searchStr = searchMap.get("searchStr");
        const searchCol = searchMap.get("searchCol");
        const retire_yn = searchMap.get("retire_yn");
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/admin/selectUserList',
            {
                "currPage": currPage,
                "rowSize": rowSize,
                "searchStr": searchStr,
                "searchCol": searchCol,
                "useYn": useYn,
                "retire_yn": retire_yn,
                "sortStr": sortStr,
                "sortDir": sortDir
            },
            { headers: { "Content-Type": 'application/json' } }
        );
        const data = await response.data;
        if (isDetail) {
            setDetailUser(data.content);
            setPageCount(data.totalPages);
            setRowCount(data.rowCount);
        } else {
            if (simplePage === 0) {
                setSimpleUser(data.content);
            } else {
                setSimpleUser(prevPosts => [...prevPosts, ...data.content]);
            }
            setHasMore(!data.last);
        }
        setLoading(false);
    };

    function search() {
        if (detailPage === 0) {
            selectUserList(0, rowSize, true);
        } else {
            setDetailPage(0);
        }
        if (simplePage === 0) {
            selectUserList(0, 25, false);
        } else {
            setSimplePage(0);
        }
    };

    //searchMap에는 검색어와 검색컬럼이 SearchBar에서 세팅된다.
    //검색어는 SearchBar에 searchstr로 지정되어있으므로 바꿀수 없고,
    //select box 선택시 검색컬럼 지정은 하위 selectTypes의 key 에 매핑(해당페이지는 searchCol)
    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map<string, string>([['searchCol', 'user_id'], ['retire_yn', 'N']]));
    const [useYn, setUseYn] = useState<string>('');

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectUserList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectUserList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            search();
        };
        loadPosts();
    }, [sort]);

    //팝업이 종료됐을 때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        if (!showModal && (category === 4 || category === 2)) {
            const loadPosts = async () => {
                search();
            };
            loadPosts();
        }
    }, [showModal, category]);

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    const deleteFunc = async (vo: UserVO) => {
        setUserId(vo.user_id);
        setShowAlert(true);
    }

    async function confirm() {
        const response = await axios.post("/api/admin/deleteUser", null, { params: { "user_id": userId } });
        const data = response.data;
        if (data === 1) {
            toast("삭제가 완료되었습니다.", { position: 'top-center', autoClose: 2000 });
            setShowAlert(false);
            search();
        } else {
            toast("삭제 실패", { position: 'top-center', autoClose: 2000 });
        }
    }

    const registModalPopup = () => {
        setCategory(4);
        setInfo([]);
        setShowModal(true);
    }

    const detailModalPopup = (vo: UserVO) => {
        setCategory(2);
        setInfo(vo);
        setShowModal(true);
    }

    return (
        <div className='content'>
            <h2>사용자 관리</h2>
            <h5>사용자의 추가 등록 및 관리를 할 수 있습니다.</h5>
            <SearchBarV2
                setRowSize={setRowSize}
                rowCount={rowCount}
                searchMap={searchMap}
                setSearchMap={setSearchMap}
                search={search}
                leftChildren={
                    <>
                        <Col sm="auto">
                            <Form.Select name='retire_yn' value={searchMap.get('retire_yn')} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option value='N'>퇴사자 미포함</option>
                                <option value='Y'>퇴사자 포함</option>
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select name='searchCol' value={searchMap.get('searchCol')} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option value='user_id'>아이디</option>
                                <option value='user_nm'>이름</option>
                                <option value='position_nm'>직급</option>
                                <option value='dept_nm'>부서</option>
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select name='useYn' value={useYn} onChange={(e) => { setUseYn(e.target.value); }}>
                                <option value=''>전체</option>
                                <option value='Y'>활성</option>
                                <option value='N'>비활성</option>
                            </Form.Select>
                        </Col>
                    </>
                }
                rightChildren={
                    <>
                        <Col xs="auto">
                            <Button onClick={() => registModalPopup()}>등록</Button>
                        </Col>
                    </>
                } />
            {isDesktop ? (
                <>
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailUser} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => detailModalPopup(vo)} />
                    {pageCount > 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleUser.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={simple_columns} data={simpleUser} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => detailModalPopup(vo)} />
                </InfiniteScroll>
            )}
            <AlertModal title='정말 삭제하시겠습니까?' show={showAlert} setShow={setShowAlert} agree='삭제' func={() => confirm()} />
            <PopupShow show={showModal} setShow={setShowModal} category={category} infoData={infoData} setInfoData={setInfo} setCategory={setCategory} />
        </div>
    );
}

//DataTable에서 사용하는 데이터의 형식 (최하단의 [key: string]: any; 는 공통로직에서 key값에 map으로 접근하기 위해 필수)

type UserVO = {
    no?: number;
    user_id: string;
    user_pw: string;
    user_nm: string;
    birth_dt: string;
    lunar_yn: string;
    gender: string;
    dept_cd: string;
    dept_nm: string;
    position_cd: string;
    position_nm: string;
    job_title_cd: string;
    job_title_nm: string;
    use_yn: string;
    use_str: string;
    officce_phone: string;
    mobl_phone: string;
    fax: string;
    email: string;
    zipcode_tp: string;
    zipcode: string;
    address_tp: string;
    address: string;
    hire_dt: string;
    retire_yn: string;
    retire_str: string;
    retire_dt: string;
    [key: string]: any;
    priv_cd: string;
    priv_nm: string;
    photo_path: string;
}

export default MngUser;
