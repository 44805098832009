import { Route, Routes } from "react-router-dom";
import MngDept from "./MngDept";
import MngUser from "./MngUser";
import HolidaySettings from "./HolidaySettings";
import HolidayState from "./HolidayState";
import MngPriv from "./MngPriv";
import MngCommCode from "./MngCommCode";
import MngDetailCode from "./MngDetailCode";
import MngMenu from "./MngMenu";
import HolidaySearch from "./HolidaySearch";
import DaysSettings from "./DaysSettings";
import PostMngList from "./PostMngList";
import MngDelayApprDoc from "./MngDelayApprDoc";
import MngApprDoc from "./MngApprDoc";
import MngCategory from "./MngCategory";
import MngBoardMenu from "./MngBoardMenu";
function Admin({ getHeader }: { getHeader: () => void; }) {
    return (
        <div className="content-container">
            <Routes>
                <Route path="mngBoardMenu" element={<MngBoardMenu getHeader={getHeader} />} />
                <Route path="postMngList" element={<PostMngList />} />
                <Route path="mngDept" element={<MngDept />} />
                <Route path="mngUser" element={<MngUser />} />
                <Route path="mngPriv" element={<MngPriv getHeader={getHeader} />} />
                <Route path="mngCommCode" element={<MngCommCode />} />
                <Route path="mngDetailCode" element={<MngDetailCode />} />
                <Route path="mngMenu" element={<MngMenu getHeader={getHeader} />} />
                <Route path="mngCategory" element={<MngCategory />} />
                <Route path="mngApprDoc" element={<MngApprDoc />} />
                <Route path="mngDelayApprDoc" element={<MngDelayApprDoc />} />
                <Route path="holidayState" element={<HolidayState />} />
                <Route path="holidaySearch" element={<HolidaySearch />} />
                <Route path="daysSettings" element={<DaysSettings />} />
                <Route path="holidaySettings" element={<HolidaySettings />} />
            </Routes>
        </div>
    );
}
export default Admin;
