import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    Form,
    Button,
    Row,
    Col,
    Container
} from "react-bootstrap";
import Loading from "../../module/Loading";
import { toast } from 'react-toastify';

function ViewProxySignatureStatus() {

    const [loading, setLoading] = useState(false);

    const [useDelegate, setUseDelegate] = useState<string>("disable");

    const [selectedFile, setSelectedFile] = useState<File | null>(null); // File 타입 또는 null로 초기화
    const [preview, setPreview] = useState<string | ArrayBuffer | null>(null); // string, ArrayBuffer 또는 null로 초기화

    const handleUseDelegateChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setUseDelegate(event.target.value);
    };


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // event.target.files는 FileList | null 타입입니다.
        if (file && file.type.startsWith('image/')) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result); // reader.result는 string 또는 ArrayBuffer 타입입니다.
            };
            reader.readAsDataURL(file);
        } else {
            setSelectedFile(null);
            setPreview(null);
        }
    };

    useEffect(() => {
        const getData_main = async () => {
            try {
                setLoading(true);
                const response = await axios.post(
                    "/api/cmn/setting/viewProxyUserInfo.menu",
                    {
                        user_id: sessionStorage.getItem("user_id")
                    },
                    { headers: { "Content-Type": "application/json" } }
                );
                const rtn = response.data;

                const myProxyUserInfo = rtn.myProxyUserInfo;

                myProxyUserInfo.appr_signature_use_yn === 'Y' ? setUseDelegate('enable') : setUseDelegate('disable');
                myProxyUserInfo.appr_signatur_path !== null ? setPreview(myProxyUserInfo.appr_signatur_path) : setPreview(null);

            } catch (error) {
                alert(
                    "서버 오류로 인한 " +
                    " 추출 실패, 관리자에게 문의 부탁 드립니다."
                );
            } finally {
                setLoading(false);
            }
        };

        getData_main();
    }, []);

    async function proxySave() {
        const appr_signature_use_yn = useDelegate === 'enable' ? 'Y' : 'N';
        const userId = sessionStorage.getItem("user_id") || '';

        const formData = new FormData();
        // 무조건 Y 값으로 고정
        const btn = 'Y';
        // 결제 서명을 하고, 파일을 설정 했을때 
        if (appr_signature_use_yn === 'Y') {
            const btn = 'Y';

            if (selectedFile) {
                formData.append('user_id', userId);
                formData.append('appr_signature', selectedFile);
                formData.append('btn', btn);
                formData.append('appr_signature_use_yn', appr_signature_use_yn);
            } else {
                alert('결재 서명으로 사용할 이미지 파일을 선택하여 주세요.');
                return;
            }
        } else {
            // 결재 서명 미사용
            formData.append('user_id', userId);
            formData.append('btn', btn);
            formData.append('appr_signature_use_yn', appr_signature_use_yn);
        }



        try {
            setLoading(true);
            const saveProxyRes = await axios.post(
                "/api/cmn/setting/signatureSaveAndUpdate.ajax",
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
            <Loading />
            const rtn2 = saveProxyRes.data;
            if (rtn2.success === "fail") {
                throw new Error('파일등록 실패!');
            }
            toast.success("저장이 완료되었습니다.", {
                position: 'top-center',
                autoClose: 1000
            });
        } catch (error) {
            alert(error);
        } finally {

            setLoading(false);
        }
    };

    return (
        <Container>
            {loading && (
                <Loading />
            )}

            {!loading && (
                <Form>

                    <h3 style={{ margin: "15px 0", fontWeight: "bold" }}>
                        결재 서명 등록
                    </h3>
                    <p>
                        결재 서명
                    </p>
                    <hr />
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={8} lg={6}>
                            <Form.Check
                                type="radio"
                                label="사용안함"
                                value="disable"
                                checked={useDelegate === "disable"}
                                onChange={handleUseDelegateChange}
                                inline
                            />
                            <Form.Check
                                type="radio"
                                label="사용함"
                                value="enable"
                                checked={useDelegate === "enable"}
                                onChange={handleUseDelegateChange}
                                inline
                            />
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row} controlId="formFile">

                        <Col xs={12} sm={12} md={8} lg={6}>
                            <br />
                            <Form.Control
                                type="file"
                                onChange={handleFileChange}
                                accept=".gif,.jpg,.png"
                                disabled={useDelegate === "disable"}
                            />
                        </Col>
                        <Form.Label style={{ color: '#888888' }}>
                            * 이미지 크기 76*42의 gif, jpg, pnh 형식
                        </Form.Label>
                    </Form.Group>
                    {preview && (
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={8} lg={6}>
                                {typeof preview === 'string' ? (
                                    <img src={preview} alt="Selected_image" />
                                ) : (
                                    <></> // 혹은 다른 로딩 상태 표시 등을 추가할 수 있습니다.
                                )}
                            </Col>
                        </Form.Group>
                    )}


                    <Button variant="primary" className="mt-3" onClick={proxySave}>
                        저장
                    </Button>
                </Form>
            )}
        </Container>
    )
}

export default ViewProxySignatureStatus;
