import { useEffect, useState } from "react";
import { BsFillArrowUpSquareFill } from "react-icons/bs";

export default function TopButton() {
    const [showButton, setShowButton] = useState(false);

    const scrollToTop = () => {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
  
    }
    useEffect(() => {
      const ShowButtonClick = () => {
        if (window.scrollY > 200) {
          setShowButton(true)
        } else {
          setShowButton(false)
        }
      }
      window.addEventListener("scroll", ShowButtonClick)
      return () => {
        window.removeEventListener("scroll", ShowButtonClick)
      }
    }, [])

    return (
        <div>
        {showButton &&
            <BsFillArrowUpSquareFill onClick={scrollToTop} 
            style={{position: 'fixed', bottom: '2em', right: '1em', opacity: '0.4', width: '2rem', height: '2rem', cursor: 'pointer'}}/>
        }
        </div>
    );
}