import '../../css/exter/Board.css'
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, FormGroup, Table } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import AlertModal from '../../module/AlertModal';

interface ApprProps {
    vac_id: string,
    show: boolean,
    closeFunc: Function
}

function ApprModal({ vac_id, show, closeFunc }: ApprProps) {

    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isAdmin = sessionStorage.getItem('priv_cd') === 'EXTER_APPROVER';
    const [alertTitle] = useState('');
    const [alertBody, setAlertBody] = useState('');
    const [showAlertModal, setShowAlertModal] = useState(false);

    const [vacTitle, setVacTitle] = useState('');
    const [vacContent, setVacContent] = useState('');
    const [vacType, setVacType] = useState('');
    const [vacHalf, setVacHalf] = useState('M');
    const [vacStartDate, setVacStartDate] = useState('');
    const [vacEndDate, setVacEndDate] = useState('');
    const [vacSubWorker, setVacSubWorker] = useState('');
    const [vacStatus, setVacStatus] = useState('');

    function draftChk(nextProc: string) {
        if (!vacTitle.trim()) {
            setAlertBody('문서 제목을 입력해주세요.');
            setShowAlertModal(true);
            document.getElementById('vacTitle')?.focus();
        } else if (!vacContent.trim()) {
            setAlertBody('사유를 입력해주세요.');
            setShowAlertModal(true);
            document.getElementById('vacContent')?.focus();
        } else if (vacType === 'H' && (!vacHalf || vacHalf === 'X')) {
            setAlertBody('반차 사용 시간을 선택해주세요.');
            setShowAlertModal(true);
            document.getElementById('vacContent')?.focus();
        } else if (!vacStartDate) {
            setAlertBody('휴가 시작일을 선택해주세요.');
            setShowAlertModal(true);
            document.getElementById('vacStartDate')?.focus();
        } else if (vacType === 'V' && !vacEndDate) {
            setAlertBody('휴가 종료일을 선택해주세요.');
            setShowAlertModal(true);
            document.getElementById('vacEndDate')?.focus();
        } else if (!vacSubWorker.trim()) {
            setAlertBody('대체 근무자를 입력해주세요.');
            setShowAlertModal(true);
            document.getElementById('vacSubWorker')?.focus();
        } else {
            if (nextProc === 'R')
                draft();
            else if (nextProc === 'M')
                modify();
        }
    }

    function draft() {
        const params = {
            vac_title: vacTitle,
            vac_content: vacContent,
            vac_type: vacType,
            vac_half: (vacType === 'H' ? vacHalf : 'X'),
            vac_start_date: vacStartDate,
            vac_end_date: (vacType === 'V' ? vacEndDate : vacStartDate),
            vac_drafter: sessionStorage.getItem('user_id'),
            vac_sub_worker: vacSubWorker,
        }

        axios.post('/api/exter/regVacation', params, { headers: { "Content-Type": "application/json" } }).then(() => {
            closeFunc();
        });
    }

    function mod() {
        document.querySelectorAll<HTMLElement>('.inp-container *:not([id="vacId"]').forEach(el => {
            el.removeAttribute('readOnly');
            el.removeAttribute('disabled');
        });

        document.getElementById('mod')?.classList.add('hidden');
        if (!isDesktop)
            document.getElementById('menu')?.classList.add('hidden');
        document.getElementById('update')?.classList.remove('hidden');
        document.getElementById('cancel')?.classList.remove('hidden');
    }

    function modify() {
        const params = {
            vac_id: vac_id,
            vac_title: vacTitle,
            vac_content: vacContent,
            vac_type: vacType,
            vac_half: (vacType === 'H' ? vacHalf : 'X'),
            vac_start_date: vacStartDate,
            vac_end_date: (vacType === 'V' ? vacEndDate : vacStartDate),
            vac_sub_worker: vacSubWorker,
        }

        axios.post('/api/exter/modVacation', params, { headers: { "Content-Type": "application/json" } }).then(() => {
            closeFunc();
        });
    }

    function modCancel() {
        axios.post('/api/exter/getDocData', vac_id, { headers: { "Content-Type": 'text/html' } })
            .then(response => {
                const data = response.data;
                setVacTitle(data.vac_title);
                setVacContent(data.vac_content);
                setVacType(data.vac_type_code);
                setVacHalf(data.vac_half);
                setVacStartDate(data.vac_start_date);
                setVacEndDate(data.vac_end_date);
                setVacSubWorker(data.vac_sub_worker);
                setVacStatus(data.vac_status_code);

                document.querySelectorAll<HTMLElement>('.inp-container *:not([id="vacId"])').forEach(el => {
                    if (el.matches('input[type="textBox"], input[type="date"], textarea'))
                        el.setAttribute('readonly', 'true');
                    else
                        el.setAttribute('disabled', 'true');
                });

                document.getElementById('mod')?.classList.remove('hidden');
                if (!isDesktop)
                    document.getElementById('menu')?.classList.remove('hidden');
                document.getElementById('update')?.classList.add('hidden');
                document.getElementById('cancel')?.classList.add('hidden');
            });
    }

    function appr(vac_status: string) {
        const params = {
            vac_id: vac_id,
            vac_status: vac_status
        }

        axios.post('/api/exter/apprVacation', params, { headers: { "Content-Type": "application/json" } }).then(() => {
            closeFunc();
        });
    }

    function copy() {
        document.querySelectorAll<HTMLElement>('.inp-container *:not([id="vacId"]').forEach(el => {
            el.removeAttribute('readOnly');
            el.removeAttribute('disabled');
        });

        (document.getElementById('vacId') as HTMLInputElement).value = '@a@Y-@n';

        document.getElementById('copy')?.classList.add('hidden');
        document.getElementById('draft')?.classList.remove('hidden');
    }

    useEffect(() => {
        if (vac_id) {
            axios.post('/api/exter/getDocData', vac_id, { headers: { "Content-Type": 'text/html' } })
                .then(response => {
                    const data = response.data;
                    setVacTitle(data.vac_title);
                    setVacContent(data.vac_content);
                    setVacType(data.vac_type_code);
                    setVacHalf(data.vac_half);
                    setVacStartDate(data.vac_start_date);
                    setVacEndDate(data.vac_end_date);
                    setVacSubWorker(data.vac_sub_worker);
                    setVacStatus(data.vac_status_code);
                });
        } else {
            setVacTitle('');
            setVacContent('');
            setVacType('V');
            setVacHalf('');
            setVacStartDate('');
            setVacEndDate('');
            setVacSubWorker('');
            setVacStatus('');
            (document.getElementById('vacId') as HTMLInputElement).value = '@a@Y-@n';
        }
    }, [vac_id]);

    return (
        <>
            <Form>
                <Table>
                    <tbody>
                        <tr>
                            <td className="inp-title">문서번호</td>
                            <td className="inp-container">
                                <Form.Control type="textBox" id="vacId" defaultValue={!!vac_id ? vac_id : '@a@Y-@n'} readOnly />
                            </td>
                        </tr>
                        <tr>
                            <td className="inp-title">제목</td>
                            <td className="inp-container">
                                <Form.Control id="vacTitle" type="textBox" value={vacTitle} onChange={e => setVacTitle(e.target.value)} readOnly={!!vac_id} />
                            </td>
                        </tr>
                        <tr>
                            <td className="inp-title">사유</td>
                            <td className="inp-container">
                                <Form.Control as="textarea" rows={5} id="vacContent" value={vacContent} onChange={e => setVacContent(e.target.value)} readOnly={!!vac_id} />
                            </td>
                        </tr>
                        <tr>
                            <td className="inp-title">문서종류</td>
                            <td className="inp-container">
                                <FormGroup>
                                    <Form.Select id={'vacType'} value={vacType} onChange={e => setVacType(e.target.value)} disabled={!!vac_id}>
                                        <option key="V" value="V">휴가</option>
                                        <option key="H" value="H">반차</option>
                                    </Form.Select>
                                    <Form.Check id={'am'} type="radio" className={vacType === 'V' || !vacType ? 'hidden' : ''}
                                        style={vacType === 'H' ? { display: "inline-block", margin: "1% 5% 0 0" } : {}}
                                        label="오전 (09:00~13:00)" value="M" name="VAC_HALF" checked={vacHalf === "M"}
                                        onChange={e => setVacHalf(e.target.value)}
                                        disabled={!!vac_id} />
                                    <Form.Check id={'pm'} type="radio" className={vacType === 'V' || !vacType ? 'hidden' : ''}
                                        style={vacType === 'H' ? { display: "inline-block", margin: "1% 5% 0 0" } : {}}
                                        label="오후 (13:00~18:00)" value="A" name="VAC_HALF" checked={vacHalf === "A"}
                                        onChange={e => setVacHalf(e.target.value)}
                                        disabled={!!vac_id} />
                                </FormGroup>
                            </td>
                        </tr>
                        <tr>
                            <td className="inp-title">기간</td>
                            <td className="inp-container">
                                <FormGroup>
                                    <Form.Control id="vacStartDate" className={isDesktop && (vacType === 'V' || !vacType) ? 'half-width' : ''} type="date" value={vacStartDate} onChange={e => setVacStartDate(e.target.value)} readOnly={!!vac_id} />
                                    <span className={(vacType === 'H' || vacType ? 'hidden ' : '')} style={vacType === 'V' || !vacType ? { display: 'inline-block', width: '10%', textAlign: 'center' } : {}}> ~ </span>
                                    <Form.Control id="vacEndDate" className={(vacType === 'H' || !vacType ? 'hidden ' : '') + (isDesktop ? 'half-width' : '')} style={vacType === 'V' || !vacType ? { display: 'inline-block' } : {}} type="date" value={vacEndDate} onChange={e => setVacEndDate(e.target.value)} readOnly={!!vac_id} />
                                </FormGroup>
                            </td>
                        </tr>
                        <tr>
                            <td className="inp-title">대체근무자</td>
                            <td className="inp-container">
                                <Form.Control id="vacSubWorker" type="textBox" value={vacSubWorker} onChange={e => setVacSubWorker(e.target.value)} readOnly={!!vac_id} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                상기와 같은 사유로 인하여 정기휴가계를 제출하오니 재가 바랍니다.
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className='button-wrap'>
                    {!vac_id ? (
                        <>
                            <Button id="draft" onClick={() => draftChk('R')}>기안</Button>
                        </>
                    ) : vacStatus === 'W' ? isAdmin ? (
                        <>
                            <Button id="appr" onClick={() => appr('A')}>승인</Button>
                            <Button id="rej" variant='danger' onClick={() => appr('R')}>반려</Button>
                        </>
                    ) : (
                        <>
                            <Button id="mod" onClick={() => mod()}>수정</Button>
                            <Button className="hidden" id="update" onClick={() => draftChk('M')}>수정 완료</Button>
                            <Button className="hidden" id="cancel" variant="danger" onClick={() => modCancel()}>취소</Button>
                        </>
                    ) : vacStatus === 'R' ? isAdmin ? (<></>) : (
                        <>
                            <Button id="mod" onClick={() => mod()}>수정</Button>
                            <Button className="hidden" id="update" onClick={() => draftChk('M')}>재기안</Button>
                            <Button className="hidden" id="cancel" variant="danger" onClick={() => modCancel()}>취소</Button>
                        </>
                    ) : !isAdmin && (
                        <>
                            <Button id="copy" onClick={() => copy()}>복사</Button>
                            <Button className="hidden" id="draft" onClick={() => draftChk('R')}>기안</Button>
                        </>
                    )
                    }
                    {!isDesktop && (
                        <Button id="menu" variant="secondary" onClick={() => closeFunc()}>목록으로</Button>
                    )}
                </div>
            </Form>
            <AlertModal title={alertTitle} body={alertBody} show={showAlertModal} setShow={setShowAlertModal} />
        </>
    );

}

export default ApprModal;