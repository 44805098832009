import { useEffect, useState } from 'react';
import DataTable from '../../module/DataTable';
import axios from 'axios';
import { Button, Card, CardGroup, Col, Form, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { BsInfoCircleFill } from 'react-icons/bs';
import ApprInfoArea from '../appr/signbox/ApprInfoArea';
import "../../css/Holiday.css";
import Loading from '../../module/Loading';

function HolidayHist() {

    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });

    const [detailHolidayHistList, setDetailHolidayHistList] = useState<HolidayHistVO[]>([]);

    const [loading, setLoading] = useState(true);

    //data table 정렬용 변수
    const [sort] = useState(['', '']);

    const today = new Date();
    const [selectedPeriod, setSelectedPeriod] = useState<string>('');
    const [holidayRange, setHolidayRange] = useState([]);
    const [userState, setUserState] = useState<UserVO>();
    const [usedHoliday, setUsedHoliday] = useState<VacationUseStatesVO[]>([]);

    // 결재문서 상세보기
    const [showApprDetail, setShowApprDetail] = useState(false);
    const [apprDetail, setApprDetail] = useState<{ sign_id: string, frm_id: string, frm_name: string, mode: string, vacation_yn: string, cooperation_yn: string } | null>(null);

    // 리스트 데이터 가져오기
    const selectDataList = async () => {
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/admin/selectPersonalHolidayHist',
            {
                start_date: selectedPeriod.split(' ~ ')[0], end_date: selectedPeriod.split(' ~ ')[1],
                user_id: sessionStorage.getItem("user_id"),
                sortStr: sortStr,
                sortDir: sortDir,
            },
            { headers: { "Content-Type": 'application/json' } }
        )
        const addData = response.data.hist.map((item: HolidayHistVO) => ({
            ...item,
            vacation_date: item.harf_vacation_tp != null ? `${item.start_date} ${item.harf_vacation_tp}` : parseInt(item.terms_days) > 1 ? `${item.start_date} ~ ${item.end_date}` : `${item.start_date}`
        }));
        setUserState(response.data.info);
        setUsedHoliday(response.data.used);
        setDetailHolidayHistList(addData)
        setLoading(false);
    }

    useEffect(() => {
        const loadPosts = async () => {
            selectDataList();
        };
        if (selectedPeriod !== '') loadPosts();
    }, [selectedPeriod, sort]);

    // 휴가 기간 설정
    useEffect(() => {
        const firstStep = async () => {
            const response = await axios.post('/api/admin/selectPersonalHolidayRange', null, { params: { "user_id": sessionStorage.getItem("user_id") } });
            setHolidayRange(response.data);
            for (const item of response.data) {
                const startDate = new Date(item.split(' ~ ')[0]);
                const endDate = new Date(item.split(' ~ ')[1]);

                if (startDate <= today) {
                    setSelectedPeriod(startDate.getFullYear() + '-' + (startDate.getMonth() + 1).toString().padStart(2, '0') + '-' + startDate.getDate().toString().padStart(2, '0') + ' ~ ' + endDate.getFullYear() + '-' + (endDate.getMonth() + 1).toString().padStart(2, '0') + '-' + endDate.getDate().toString().padStart(2, '0'));
                    break;
                }
            }
        };
        firstStep();
    }, [])

    // 결재문서 클릭
    const handleApprRowClick = (vo: any) => {
        setApprDetail({
            sign_id: vo.sign_id,
            frm_id: vo.frm_id,
            frm_name: vo.holiday_type,
            mode: 'sView',
            vacation_yn: 'Y',
            cooperation_yn: 'N',
        });
    };
    // 결재문서 값이 바뀌면 상세보기 열기
    useEffect(() => {
        if (apprDetail) {
            setShowApprDetail(true);
        }
    }, [apprDetail]);
    if (loading) return <Loading />
    return (
        <div className="content">
            <h2>내 휴가기록</h2>
            <Row style={{ paddingBottom: '5px' }}>
                <Col sm="auto">
                    <Form.Select value={selectedPeriod} id="selectYear" onChange={(e) => { setSelectedPeriod(e.currentTarget.value); }}>
                        {holidayRange
                            ?.filter((item: string) => {
                                const startDate = new Date(item.split(' ~ ')[0]);
                                return startDate <= today; // 옵션범위(최대 시작 기간은 오늘)
                            })
                            .map((item) =>
                                <option key={item} value={item}>{item}</option>
                            )}
                    </Form.Select>
                </Col>
            </Row>
            <h5>내 휴가 정보</h5>
            {isDesktop ?
                <Table>
                    <thead>
                        <tr>
                            <td style={{ backgroundColor: '#f4f4f4' }}>입사일</td>
                            <td >{userState?.hire_dt}</td>
                            <td style={{ backgroundColor: '#f4f4f4' }}>휴가기간</td>
                            <td >{userState?.start_date} ~ {userState?.end_date}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ backgroundColor: '#f4f4f4' }}>휴가 지급일수
                                <OverlayTrigger placement='auto' delay={100} overlay={
                                    <Tooltip className='holidayTooltip'>
                                        ◽ 연차 발생 기준<br />
                                        ◽ 1년차 (입사일 ~ 만 1년) : 11일(1개월 개근시 1일씩을 선입력)<br />
                                        ◽ 2년차 (만 1년 ~ 만 2년) : 15일<br />
                                        ◽ 3년차 (만 2년 ~ 당해 연도 말일) : 남은 개월 수 * 1.3 (반올림)<br />
                                        ◽ 3년차↑(1월 1일 ~ 12월 31일) : 기본 15일, 만 3년 이상부터 2년마다 1일씩 가산(최대 25일)
                                    </Tooltip>
                                }>
                                    <span><BsInfoCircleFill className='mx-1 mb-1' color='gray' /></span>
                                </OverlayTrigger>
                            </td>
                            <td className='w-25'>{userState?.total_holiday}일</td>
                            <td style={{ backgroundColor: '#f4f4f4', width: '30%' }}>사용일 / 잔여일</td>
                            <td>{userState?.used_holiday}일 / {userState?.rest_holiday}일</td>
                        </tr>
                    </tbody>
                </Table>
                : <>
                    <Row>
                        <Form.Group as={Col} className="mb-3" controlId="hire_dt">
                            <Form.Label>입사일</Form.Label>
                            <Form.Control type="text" name='hire_dt' value={userState?.hire_dt ? userState?.hire_dt : ''} readOnly />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="holidayCount">
                            <Form.Label>휴가 지급일수
                                <OverlayTrigger placement='auto' delay={100} overlay={
                                    <Tooltip className='holidayTooltip'>
                                        ◽ 연차 발생 기준<br />
                                        ◽ 1년차 (입사일 ~ 만 1년) : 11일(1개월 개근시 1일씩을 선입력)<br />
                                        ◽ 2년차 (만 1년 ~ 만 2년) : 15일<br />
                                        ◽ 3년차 (만 2년 ~ 당해 연도 말일) : 남은 개월 수 * 1.3 (반올림)<br />
                                        ◽ 3년차↑(1월 1일 ~ 12월 31일) : 기본 15일, 만 3년 이상부터 2년마다 1일씩 가산(최대 25일)
                                    </Tooltip>
                                }>
                                    <span><BsInfoCircleFill className='mx-1 mb-1' color='gray' /></span>
                                </OverlayTrigger>
                            </Form.Label>
                            <Form.Control type="text" name='holidayCount' value={userState?.total_holiday + '일'} readOnly />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className="mb-3" controlId="holidayUsed">
                            <Form.Label>사용일</Form.Label>
                            <Form.Control type="text" name='holidayUsed' value={userState?.used_holiday + '일'} readOnly />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" controlId="holidayRest">
                            <Form.Label>잔여일</Form.Label>
                            <Form.Control type="text" name='holidayRest' value={userState?.rest_holiday + '일'} readOnly />
                        </Form.Group>
                    </Row>
                </>
            }

            <h5>총 휴가 사용일</h5>
            {isDesktop ?
                <CardGroup>
                    {usedHoliday.map((item) => (
                        <Card key={item.FRM_ID} style={{ textAlign: 'center' }}>
                            <Card.Header className='h-100'>{item.FRM_NAME}</Card.Header>
                            <Card.Body>{item.TERMS_DAYS}일</Card.Body>
                        </Card>
                    ))}
                </CardGroup>
                :
                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
                    <CardGroup style={{ display: 'inline-block' }}>
                        {usedHoliday.map((item) => (
                            <Card key={item.FRM_ID} style={{ textAlign: 'center', display: 'inline-block', width: 'max-content' }}>
                                <Card.Header>{item.FRM_NAME}</Card.Header>
                                <Card.Body>{item.TERMS_DAYS}일</Card.Body>
                            </Card>
                        ))}
                    </CardGroup>
                </div>
            }
            <br />
            <h5>휴가 사용 현황</h5>
            {isDesktop ? (
                <DataTable loading={loading} columns={detail_columns} data={detailHolidayHistList} sort={[]} setSortDir={() => { }} onRowClick={(vo) => { handleApprRowClick(vo) }} />
            ) : (
                <DataTable loading={loading} columns={simple_columns} data={detailHolidayHistList} sort={[]} setSortDir={() => { }} onRowClick={(vo) => { handleApprRowClick(vo) }} />
            )}
            <Modal show={showApprDetail} onHide={() => { setShowApprDetail(false); }} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {apprDetail?.frm_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ApprInfoArea
                        boxTpSignUserListCheck={''}
                        recentApprListCheck={[]}
                        mode={'view'}
                        currUserInfo={{
                            USER_POSIT_NM: "",
                            DRFT_DT: "",
                            USER_NM: "",
                            USER_POSIT_CD: "",
                            USER_DEPT_NM: "",
                            APPR_SIGNATURE_USE_YN: "",
                            USER_ID: "",
                            USER_DEPT_CD: "",
                            APPR_SIGNATUR_PATH: ""
                        }}
                        signForm={{
                            sign_line_type: "",
                            retention: 0,
                            cooperation_yn: "",
                            receive_yn: "",
                            fixReceptionList: [],
                            fix_dept_nm: "",
                            fix_dept_cd: "",
                            deptList: [],
                            dept_auth_cd: "",
                            dept_auth_nm: "",
                            formCompList: [],
                            modify_yn: "",
                            frm_ctg_name: "",
                            sec_level_cd: "",
                            output_file: "",
                            use_yn: "",
                            frm_num: "",
                            revision_num: 0,
                            doc_tp_cd: ""
                        }}
                        subWorkerList={[]}
                        actionStatus={JSON.stringify(apprDetail)}
                        varStatus={false}
                        status={false}
                        lastSeq={0}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowApprDetail(false); }}>닫기</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

type UserVO = {
    hire_dt: string;
    total_holiday: string;
    rest_holiday: string;
    used_holiday: string;
    user_nm: string;
    [key: string]: any;
}

type VacationUseStatesVO = {
    FRM_ID: string; // 휴가계아이디
    FRM_NAME: string; // 휴가계명
    TERMS_DAYS: number; // 휴가사용일수
    [key: string]: any;
}

type HolidayHistVO = {
    reg_dt: string;
    holiday_type: string;
    dept_nm: string;
    position_nm: string;
    user_nm: string;
    start_date: string;
    end_date: string;
    terms_days: string;
    harf_vacation_tp: string;
    detail_code_nm: string;
    sign_id: string;
    [key: string]: any;
}

const detail_columns = [
    {
        Header: 'NO',
        accessor: 'no'
    },
    {
        Header: '신청일',
        accessor: 'reg_dt'
    },
    {
        Header: '신청종류',
        accessor: 'holiday_type'
    },
    {
        Header: '부서',
        accessor: 'dept_nm'
    },
    {
        Header: '직급',
        accessor: 'position_nm'
    },
    {
        Header: '이름',
        accessor: 'user_nm'
    },
    {
        Header: '사용기간',
        accessor: 'vacation_date'
    },
    {
        Header: '사용일수',
        accessor: 'terms_days'
    },
    {
        Header: '승인여부',
        accessor: 'detail_code_nm'
    },
];

const simple_columns = [
    {
        Header: '신청종류',
        accessor: 'holiday_type'
    },
    {
        Header: '사용기간',
        accessor: 'vacation_date'
    },
    {
        Header: '승인여부',
        accessor: 'detail_code_nm'
    },
];

export default HolidayHist;