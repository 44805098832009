
import { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMediaQuery } from 'react-responsive';
import { useLocation } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../module/Loading';
import Paginate from '../../../module/Paginate';
import ApprInfoArea from "./ApprInfoArea";
import { Button, Col, Form, Modal } from "react-bootstrap";
import SearchBarV2 from "../../../module/SearchBarV2";
import DataTableV2 from "../../../module/DataTableV2";



function DeptApprBoxList() {
    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const location = useLocation();
    const location_menu_nm = location.state?.menu_nm;
    const location_menu_var = location.state?.menu_var;
    let menu_nm = JSON.stringify(location_menu_nm);
    if (menu_nm) {
        menu_nm = JSON.stringify(location_menu_nm).replaceAll("\"", "");
    }
    //큰 화면용 list를 위한 변수

    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    //작은 화면용 list를 위한 변수
    const [simpleDept, setSimpleDept] = useState<any[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);

    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    const [formList, setFormList] = useState([{
        FRM_ID: '',
        FRM_CTG_ID: '',
        FRM_NAME: ''
    }]);
    //부서 리스트 검색 함수
    const selectDeptList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const searchStr = searchMap.get("searchStr");
        const searchCol = searchMap.get("searchCol");
        const sortStr = sort[0];
        const sortDir = sort[1];
        const session_userId: string = sessionStorage.getItem("user_id") || "";
        const session_SearchViewCnt: string = sessionStorage.getItem("search_view_cnt") || "";
        const response = await axios.post(

            '/api/appr/deptApprBox/deptApprBoxList.menu',
            {
                "user_id": session_userId,
                "sign_box_cd": location_menu_var,
                "search_view_cnt": session_SearchViewCnt,
                "lang": "ko",
                "currPage": currPage,
                "rowSize": rowSize,
                "searchStr": searchStr,
                "searchCol": searchCol,
                "sortStr": sortStr,
                "sortDir": sortDir,
                "form_type": selectedFrmId
            },
            { headers: { "Content-Type": 'application/json' } }
        );
        const data = await response.data;
        if (isDetail) {
            setSignDocList(data.signDocList);
            setPageCount(data.totalPage);
            setRowCount(data.rowCount);
            setFormList(data.formList);
        } else {
            if (simplePage === 0) {
                setSimpleDept(data.signDocList);
            } else {
                setSimpleDept(prevPosts => [...prevPosts, ...data.signDocList]);
            }
            setHasMore(!data.last);
        }
        setLoading(false);

    };


    function search() {
        if (detailPage === 0) {
            selectDeptList(0, rowSize, true);
        } else {
            setDetailPage(0);
        }
        if (simplePage === 0) {
            selectDeptList(0, 25, false);
        } else {
            setSimplePage(0);
        }
    };

    //searchMap에는 검색어와 검색컬럼이 SearchBar에서 세팅된다.
    //검색어는 SearchBar에 searchstr로 지정되어있으므로 바꿀수 없고,
    //select box 선택시 검색컬럼 지정은 하위 selectTypes의 key 에 매핑(해당페이지는 searchCol)
    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map<string, string>([['searchCol', 'sign_doc_title']]));
    const [selectedFrmId, setSelectedFrmId] = useState("");  // selectedFrmId 상태 추가
    //버튼은 여러개 있는 페이지가 있어 Map()[]으로 구성 
    //singBoxList 컬럼
    const [signDocList, setSignDocList] = useState<any[]>([]);
    let detail_columns;
    const data_columns = signDocList;
    const [docTitle, setDocTitle] = useState('');
    let signBoxCd: string;

    if (menu_nm === "결재대기함") {
        detail_columns = [
            {
                Header: '문서종류',
                accessor: 'frm_nm'
            },
            {
                Header: '문서제목',
                accessor: 'sign_doc_title'
            },
            {
                Header: '원기안자/발신부서',
                accessor: 'ori_drft_user_nm'
            },
            {
                Header: '기안자',
                accessor: 'drft_user_nm'
            },
            {
                Header: '결재대기자',
                accessor: 'sign_user_nm'
            },
            {
                Header: '기안일',
                accessor: 'drft_dt'
            },
        ];

    } else if (menu_nm === "결재진행함") {
        detail_columns = [
            {
                Header: '문서종류',
                accessor: 'frm_nm'
            },
            {
                Header: '문서제목',
                accessor: 'sign_doc_title'
            },
            {
                Header: '기안자',
                accessor: 'drft_user_nm'
            },
            {
                Header: '결재대기자',
                accessor: 'sign_user_nm'
            },
            {
                Header: '기안일',
                accessor: 'drft_dt'
            },
            {
                Header: '결재상태',
                accessor: 'doc_sts_nm'
            },
        ];
    } else if (menu_nm === "반송함") {
        detail_columns = [
            {
                Header: '문서종류',
                accessor: 'frm_nm'
            },
            {
                Header: '문서제목',
                accessor: 'sign_doc_title'
            },
            {
                Header: '기안자',
                accessor: 'drft_user_nm'
            },
            {
                Header: '반려자',
                accessor: 'reject_user_nm'
            },
            {
                Header: '반려일',
                accessor: 'reject_dt'
            },

        ];

    } else if (menu_nm === "결재완료함") {
        detail_columns = [
            {
                Header: '문서번호',
                accessor: 'docno'
            },
            {
                Header: '문서종류',
                accessor: 'frm_nm'
            },
            {
                Header: '문서제목',
                accessor: 'sign_doc_title'
            },
            {
                Header: '기안자',
                accessor: 'drft_user_nm'
            },
            {
                Header: '최종결재자',
                accessor: 'final_sign_user_nm'
            },
            {
                Header: '기안일',
                accessor: 'drft_dt'
            },
            {
                Header: '최종결재일',
                accessor: 'final_appr_dt'
            },
        ];
    } else if (menu_nm === "임시저장함") {
        detail_columns = [
            {
                Header: '문서종류',
                accessor: 'frm_nm'
            },
            {
                Header: '문서제목',
                accessor: 'sign_doc_title'
            },
            {
                Header: '작성자',
                accessor: 'drft_user_nm'
            },
            {
                Header: '작성일',
                accessor: 'edit_dt'
            }
        ];
    } else if (menu_nm === "참조확인함") {
        detail_columns = [
            {
                Header: '문서번호',
                accessor: 'docno'
            },
            {
                Header: '문서종류',
                accessor: 'frm_nm'
            },
            {
                Header: '문서제목',
                accessor: 'sign_doc_title'
            },
            {
                Header: '발신부서',
                accessor: 'drftper_dept_nm'
            },
            {
                Header: '발신자',
                accessor: 'drft_user_nm'
            },
            {
                Header: '최종결재일',
                accessor: 'final_appr_dt'
            },
            {
                Header: '참조확인일',
                accessor: 'read_dt'
            }
        ];
    } else if (menu_nm === '참조확인함') {
        detail_columns = [
            {
                Header: '문서번호',
                accessor: 'docno'
            },
            {
                Header: '문서종류',
                accessor: 'frm_nm'
            },
            {
                Header: '문서제목',
                accessor: 'sign_doc_title'
            },
            {
                Header: '발신부서',
                accessor: 'drftper_dept_nm'
            },
            {
                Header: '발신자',
                accessor: 'drft_user_nm'
            },
            {
                Header: '최종결재일',
                accessor: 'final_appr_dt'
            },
            {
                Header: '참조확인일',
                accessor: 'read_dt'
            }
        ];
    } else if (menu_nm === '도착문서함') {
        detail_columns = [

            {
                Header: '문서종류',
                accessor: 'frm_nm'
            },
            {
                Header: '문서제목',
                accessor: 'sign_doc_title'
            },
            {
                Header: '발신부서',
                accessor: 'drftper_dept_nm'
            },
            {
                Header: '발신자',
                accessor: 'drft_user_nm'
            },
            {
                Header: '도착일',
                accessor: 'arriv_dt'
            },
        ];
    } else if (menu_nm === '접수문서함') {
        detail_columns = [

            {
                Header: '문서종류',
                accessor: 'frm_nm'
            },
            {
                Header: '문서제목',
                accessor: 'sign_doc_title'
            },
            {
                Header: '발신자',
                accessor: 'drft_user_nm'
            },
            {
                Header: '접수자',
                accessor: 'recv_user_nm'
            },
            {
                Header: '도착일',
                accessor: 'acc_dt'
            },
        ];
    } else {
        detail_columns = [

            {
                Header: '문서종류',
                accessor: 'frm_nm'
            },
            {
                Header: '문서제목',
                accessor: 'sign_doc_title'
            },
            {
                Header: '기안자',
                accessor: 'drft_user_nm'
            },
            {
                Header: '도착일',
                accessor: 'arriv_dt'
            },
            {
                Header: '접수상태',
                accessor: 'recv_sts_nm'
            },
        ];
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState<string>('');

    const handleModal = (type: string, board?: string) => {
        setDocTitle(type);
        switch (menu_nm) {
            case "결재대기함": signBoxCd = "B04";
                break;
            case "결재진행함": signBoxCd = "B05";
                break;
            case "결재완료함": signBoxCd = "B06";
                break;
            case "참조확인함": signBoxCd = "B07";
                break;
            case "반송함": signBoxCd = "B08";
                break;
            case "임시저장함": signBoxCd = "B09";
                break;
            case "도착문서함": signBoxCd = "B01";
                break;
            case "접수문서함": signBoxCd = "B02";
                break;
            case "반송배포함": signBoxCd = "B03";
                break;
            case "품의함": signBoxCd = "B05";
                break;
            default: signBoxCd = "B00";

        }
        const param = new Map();
        signDocList.forEach((item) => {
            // SIGN_ID가 일치할 경우 param 셋팅 후 AppDetail로 전달
            if (item.sign_id === board) {
                if (signBoxCd === "B08" && sessionStorage.getItem("user_nm") === item.drft_user_nm) {
                    mode = "reDraft";
                } else if (signBoxCd === "B06") {
                    mode = "sView";
                }

                param.set("sign_id", item.sign_id);
                param.set("frm_id", item.frm_id);
                param.set("mode", mode);
                param.set("vacation_yn", item.vacation_form_yn);
                param.set("cooperation_yn", item.cooperation_yn);
            }
        });

        setModalData(JSON.stringify(Object.fromEntries(param)));
        setIsModalOpen(true);
    };
    const closeModal = () => setIsModalOpen(false);

    const renderApprInfoArea = () => (
        <ApprInfoArea
            boxTpSignUserListCheck={''}
            recentApprListCheck={[]}
            mode={'view'}
            currUserInfo={{
                USER_POSIT_NM: "",
                DRFT_DT: "",
                USER_NM: "",
                USER_POSIT_CD: "",
                USER_DEPT_NM: "",
                APPR_SIGNATURE_USE_YN: "",
                USER_ID: "",
                USER_DEPT_CD: "",
                APPR_SIGNATUR_PATH: ""
            }}
            signForm={{
                sign_line_type: "",
                retention: 0,
                cooperation_yn: "",
                receive_yn: "",
                fixReceptionList: [],
                fix_dept_nm: "",
                fix_dept_cd: "",
                deptList: [],
                dept_auth_cd: "",
                dept_auth_nm: "",
                formCompList: [],
                modify_yn: "",
                frm_ctg_name: "",
                sec_level_cd: "",
                output_file: "",
                use_yn: "",
                frm_num: "",
                revision_num: 0,
                doc_tp_cd: ""
            }}
            subWorkerList={[]}
            actionStatus={modalData}
            varStatus={false}
            status={false}
            lastSeq={0}
        />
    );
    let mode = "View";
    //메뉴 타이틀이 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {

            selectDeptList(detailPage, rowSize, true);
            setSimplePage(0);
        };
        loadPosts();
    }, [menu_nm]);

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDeptList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDeptList(simplePage, 25, false);

        };
        loadPosts();
    }, [simplePage, menu_nm]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            search();
        };
        loadPosts();
    }, [sort]);

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    return (

        <div className='content'>
            <h2>{menu_nm}</h2>
            <SearchBarV2
                setRowSize={setRowSize}
                rowCount={rowCount}
                searchMap={searchMap}
                setSearchMap={setSearchMap}
                search={search}
                leftChildren={
                    <>
                        <Col sm="auto">
                            <Form.Select
                                value={selectedFrmId} // 선택된 FRM_ID를 value로 설정
                                onChange={(e) => {
                                    setSelectedFrmId(e.target.value);
                                }}>
                                <option value="">문서종류</option>
                                {formList.map((item) => (
                                    <option key={item.FRM_ID} value={item.FRM_ID}>
                                        {item.FRM_NAME}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select name='searchCol' defaultValue={searchMap.get('searchCol')} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option value='sign_doc_title'>문서제목</option>
                                <option value='drft_user_nm'>기안자</option>
                            </Form.Select>
                        </Col>

                    </>
                }
                rightChildren={
                    <>
                    </>
                } />
            {isDesktop ? (
                <>

                    <DataTableV2 loading={loading} columns={detail_columns} data={data_columns} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => handleModal(vo.frm_nm, vo.sign_id)} />
                    <Modal show={isModalOpen} onHide={closeModal} size="xl" backdrop="static" centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {/* Title can go here */}
                                {docTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {modalData ? renderApprInfoArea() : 'Loading...'}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>닫기</Button>
                        </Modal.Footer>
                    </Modal>
                    {pageCount > 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleDept.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={detail_columns} data={simpleDept} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => handleModal(vo.frm_nm, vo.sign_id)} />
                    <Modal show={isModalOpen} onHide={closeModal} size="xl" backdrop="static" centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {/* Title can go here */}
                                {docTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {modalData ? renderApprInfoArea() : 'Loading...'}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>닫기</Button>
                        </Modal.Footer>
                    </Modal>
                </InfiniteScroll>
            )}

        </div>
    );

}

export default DeptApprBoxList;
