import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CellProps } from 'react-table';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import DataTableV2 from '../../module/DataTableV2';
import Paginate from '../../module/Paginate';
import Loading from '../../module/Loading';
import SearchBarV2 from '../../module/SearchBarV2';
import AlertModal from '../../module/AlertModal';

function MngDept() {
    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });

    //큰 화면용 list를 위한 변수
    const [detailDept, setDetailDept] = useState<DeptVO[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    //작은 화면용 list를 위한 변수
    const [simpleDept, setSimpleDept] = useState<DeptVO[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);

    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    //Modal Popup 변수
    const [showModal, setShowModal] = useState(false);
    const [isMod, setIsMod] = useState(false);
    const [selectDept, setSelectDept] = useState<DeptVO>({
        dept_cd: '',
        dept_level: 1,
        dept_nm: '',
        dept_upper_code: '',
        dept_upper_code_name: '',
        mngr_id: '',
        mngr_nm: '',
        ordr_code: 1,
        use_yn: 'Y',
        use_str: '사용'
    });

    //AlertModal 변수
    const [showAlert, setShowAlert] = useState(false);

    //문서 삭제함수 변수
    const [deleteCd, setDeleteCd] = useState('');

    //큰화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
    //DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
    const detail_columns = [
        {
            Header: '순서',
            accessor: 'no'
        },
        {
            Header: '상위부서코드',
            accessor: 'dept_upper_code'
        },
        {
            Header: '상위부서명',
            accessor: 'dept_upper_code_name'
        },
        {
            Header: '부서코드',
            accessor: 'dept_cd'
        },
        {
            Header: '부서명',
            accessor: 'dept_nm'
        },
        {
            Header: '매니저',
            accessor: 'mngr_nm'
        },
        {
            Header: '사용여부',
            accessor: 'use_str'
        },
        {
            Header: '삭제',
            accessor: 'option1',
            Cell: ({ row }: CellProps<DeptVO>) => (
                <Button size="sm" onClick={(e) => deleteFunc(row.original)}>
                    삭제
                </Button>
            ),
        }
    ];

    //작은화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
    //DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
    const simple_columns = [
        {
            Header: '부서명',
            accessor: 'dept_nm'
        },
        {
            Header: '매니저',
            accessor: 'mngr_nm'
        },
        {
            Header: '사용여부',
            accessor: 'use_str'
        },
        {
            Header: '삭제',
            accessor: 'option1',
            Cell: ({ row }: CellProps<DeptVO>) => (
                <Button size="sm" onClick={(e) => deleteFunc(row.original)}>
                    삭제
                </Button>
            ),
        }
    ];

    //부서 리스트 검색 함수
    const selectDeptList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const searchStr = searchMap.get("searchStr");
        const searchCol = searchMap.get("searchCol");
        
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/admin/selectDeptList',
            {
                "currPage": currPage,
                "rowSize": rowSize,
                "searchStr": searchStr,
                "searchCol": searchCol,
                "useYn": useYn,
                "sortStr": sortStr,
                "sortDir": sortDir
            },
            { headers: { "Content-Type": 'application/json' } }
        );
        const data = await response.data;
        if (isDetail) {
            setDetailDept(data.content);
            setPageCount(data.totalPages);
            setRowCount(data.rowCount);
        } else {
            if (simplePage === 0) {
                setSimpleDept(data.content);
            } else {
                setSimpleDept(prevPosts => [...prevPosts, ...data.content]);
            }
            setHasMore(!data.last);
        }
        setLoading(false);
    };

    function popReg() {
        setSelectDept({
            dept_cd: '',
            dept_level: 1,
            dept_nm: '',
            dept_upper_code: '',
            dept_upper_code_name: '',
            mngr_id: '',
            mngr_nm: '',
            ordr_code: 1,
            use_yn: 'Y',
            use_str: '사용'
        });
        setIsMod(false);
        setShowModal(true);
    };

    function search() {
        if (detailPage === 0) {
            selectDeptList(0, rowSize, true);
        } else {
            setDetailPage(0);
        }
        if (simplePage === 0) {
            selectDeptList(0, 25, false);
        } else {
            setSimplePage(0);
        }
    };

    //searchMap에는 검색어와 검색컬럼이 SearchBar에서 세팅된다.
    //검색어는 SearchBar에 searchstr로 지정되어있으므로 바꿀수 없고,
    //select box 선택시 검색컬럼 지정은 하위 selectTypes의 key 에 매핑(해당페이지는 searchCol)
    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map<string, string>([['searchCol', 'dept_upper_code']]));
    const [useYn, setUseYn] = useState<string>('');

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDeptList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDeptList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            search();
        };
        loadPosts();
    }, [sort]);

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    const deleteFunc = async (vo: DeptVO) => {
        setDeleteCd(vo.dept_cd);
        setShowAlert(true);
    }

    async function confirm() {
        const response = await axios.post("/api/admin/deleteDept", null, { params: { "dept_cd": deleteCd } });
        const data = response.data;
        if (data === 1) {
            toast("삭제가 완료되었습니다.", { position: 'top-center', autoClose: 2000 });
            setShowAlert(false);
            search();
        } else {
            toast("삭제 실패", { position: 'top-center', autoClose: 2000 });
        }
    }

    const detailModalPopup = (vo: DeptVO) => {
        setSelectDept({ ...vo, before_ordr_code: vo.ordr_code });
        setIsMod(true);
        setShowModal(true);
    }

    return (
        <div className='content'>
            <h2>부서관리</h2>
            <h5>부서의 추가 등록 및 관리를 할 수 있습니다.</h5>
            <SearchBarV2
                setRowSize={setRowSize}
                rowCount={rowCount}
                searchMap={searchMap}
                setSearchMap={setSearchMap}
                search={search}
                leftChildren={
                    <>
                        <Col sm="auto">
                            <Form.Select name='searchCol' defaultValue={searchMap.get('searchCol')} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option value='dept_upper_code'>상위부서코드</option>
                                <option value='dept_upper_code_name'>상위부서명</option>
                                <option value='dept_cd'>부서코드</option>
                                <option value='dept_nm'>부서명</option>
                                <option value='mngr_nm'>매니저</option>
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select name='useYn' value={useYn} onChange={(e) => { setUseYn(e.target.value); }}>
                                <option value=''>전체</option>
                                <option value='Y'>사용</option>
                                <option value='N'>미사용</option>
                            </Form.Select>
                        </Col>
                    </>
                }
                rightChildren={
                    <>
                        <Col xs="auto">
                            <Button onClick={() => popReg()}>등록</Button>
                        </Col>
                    </>
                } />

            {isDesktop ? (
                <>
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailDept} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => detailModalPopup(vo)} />
                    {pageCount > 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleDept.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={simple_columns} data={simpleDept} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => detailModalPopup(vo)} />
                </InfiniteScroll>
            )}
            <PopupModal show={showModal} setShow={setShowModal} isMod={isMod} selectDept={selectDept} setSelectDept={setSelectDept} search={search} />
            <AlertModal title='정말 삭제하시겠습니까?' show={showAlert} setShow={setShowAlert} agree='삭제' func={() => confirm()} />
        </div>
    );
}

const PopupModal = ({ show, setShow, isMod, selectDept, setSelectDept, search }: { show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, isMod: boolean, selectDept: DeptVO, setSelectDept: React.Dispatch<React.SetStateAction<DeptVO>>, search: () => void; }): React.ReactElement => {

    const [deptCdList, setDeptCdList] = useState<DeptVO[]>();
    const [mngUserList, setMngUserList] = useState<UserVO[]>();
    const [dupCheck, setDupCheck] = useState(false);

    const selectDetailDept = async () => {
        const response = await axios.post("/api/admin/selectDeptCdList");
        const data = response.data;
        setDeptCdList(data.deptCdList);
        setMngUserList(data.mngUserList);
    };

    async function deptPost() {
        if (selectDept.dept_upper_code === '') {
            toast("상위부서를 선택해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        if (!isMod && !dupCheck) {
            toast("부서코드 중복확인을 해주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        if (selectDept.dept_nm === '') {
            toast("부서명을 입력해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        if (selectDept.mngr_id === '') {
            toast("매니저를 선택해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        const url = isMod ? '/api/admin/updateDept' : '/api/admin/insertDept';
        const response = await axios.post(url, selectDept, { headers: { "Content-Type": 'application/json' }, params: { "userId": sessionStorage.getItem("user_id") } });
        const data = response.data;
        if (data === 1) {
            toast(isMod ? "수정이 완료되었습니다." : "등록이 완료되었습니다.", { position: 'top-center', autoClose: 2000 });
            setShow(false);
            search();
        } else {
            toast(isMod ? "수정 실패" : "등록 실패", { position: 'top-center', autoClose: 2000 });
        }

    };

    async function getDeptSub(e: React.ChangeEvent<any>) {
        const dept_upper_code = e.target.value;
        if (dept_upper_code !== 'none') {
            const dept_upper_code_name = deptCdList!.find(option => option.dept_cd === dept_upper_code)!.dept_nm
            const response = await axios.post('/api/admin/getDeptSub', null, { params: { "dept_upper_code": dept_upper_code } });
            const data = await response.data;
            setSelectDept({ ...selectDept, dept_level: data.subDeptData.dept_level, ordr_code: data.subDeptData.ordr_code, dept_upper_code: dept_upper_code, dept_upper_code_name: dept_upper_code_name });
        }
    };

    async function deptDupCheck() {
        if (selectDept.dept_cd === "") {
            toast("부서코드가 공백입니다.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        const response = await axios.post('/api/admin/deptDupCheck', null, { params: { "dept_cd": selectDept.dept_cd } });
        const data = response.data;
        if (data === 0) {
            setDupCheck(true);
        } else {
            toast("중복된 부서코드 입니다.", { position: 'top-center', autoClose: 2000 });
        }
    };

    function changeDeptMap(e: React.ChangeEvent<any>) {
        setSelectDept({ ...selectDept, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const loadPosts = async () => {
            selectDetailDept();
        };
        loadPosts();
    }, []);

    useEffect(() => {
        setDupCheck(false);
    }, [show]);

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{isMod ? '부서관리 - 상세조회' : '부서관리 - 등록'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col xs='4'>
                            상위부서
                        </Col>
                        <Col>
                            <Form.Select name='dept_upper_code' value={selectDept.dept_upper_code} onChange={(e) => { getDeptSub(e); }}>
                                <option value="none">상위부서선택</option>
                                {deptCdList?.map((item: DeptVO) =>
                                    <option key={item.dept_cd} value={item.dept_cd}>{item.dept_nm}</option>
                                )}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            부서코드
                        </Col>
                        {isMod ?
                            <Col>
                                <Form.Control type="textBox" name="dept_cd" value={selectDept.dept_cd} disabled />
                            </Col>
                            :
                            <>
                                <Col>
                                    <Form.Control type="textBox" name="dept_cd" value={selectDept.dept_cd} onChange={(e) => { setDupCheck(false); changeDeptMap(e); }} />
                                </Col>
                                <Col xs="auto">
                                    <Button size="sm" variant={dupCheck ? 'success' : 'danger'} onClick={() => deptDupCheck()}>  {dupCheck ? "확인완료" : "중복확인"}</Button>
                                </Col>
                            </>
                        }
                    </Row>
                    <Row>
                        <Col xs='4'>
                            부서명
                        </Col>
                        <Col>
                            <Form.Control type="textBox" name="dept_nm" value={selectDept.dept_nm} onChange={(e) => changeDeptMap(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            부서레벨
                        </Col>
                        <Col>
                            <Form.Control type="number" name="dept_level" value={selectDept.dept_level} disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            매니저
                        </Col>
                        <Col>
                            <Form.Select name='mngr_id' value={selectDept.mngr_id} onChange={(e) => setSelectDept({ ...selectDept, mngr_id: e.target.value, mngr_nm: e.target.value !== '' ? mngUserList!.find(option => option.user_id === e.target.value)!.user_nm : '' })}>
                                <option value=''>매니저선택</option>
                                {mngUserList?.map((item: UserVO) =>
                                    <option key={item.user_id} value={item.user_id} >{item.user_nm}</option>
                                )}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            사용여부
                        </Col>
                        <Col>
                            <Form.Select name='use_yn' value={selectDept.use_yn} onChange={(e) => changeDeptMap(e)}>
                                <option value='Y'>사용</option>
                                <option value='N'>미사용</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            배치순서
                        </Col>
                        <Col>
                            <Form.Control type="number" name="ordr_code" value={selectDept.ordr_code} onChange={(e) => changeDeptMap(e)} />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    닫기
                </Button>
                <Button variant="primary" onClick={() => deptPost()}>
                    {isMod ? '수정' : '등록'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

//DataTable에서 사용하는 데이터의 형식 (최하단의 [key: string]: any; 는 공통로직에서 key값에 map으로 접근하기 위해 필수)
type DeptVO = {
    no?: number;
    dept_cd: string;
    dept_upper_code: string;
    dept_upper_code_name: string;
    dept_nm: string;
    mngr_id: string;
    mngr_nm: string;
    use_yn: string;
    use_str: string;
    dept_level: number;
    ordr_code: number;
    before_ordr_code?: number;
    [key: string]: any;
}

type UserVO = {
    user_id: string;
    user_nm: string;
    [key: string]: any;
}

export default MngDept;
