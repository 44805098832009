import { useState } from "react";
import { MdExpandMore, MdKeyboardArrowRight } from "react-icons/md";
import { FcBusinessman, FcBusinesswoman } from "react-icons/fc";

export interface Node {
    [key: string]: any;
    children?: Node[];
}

interface TreeNodeProps {
    node: Node;
    idField: string;
    nameField: string;
    onNodeClick: (node: Node) => void;
    parentIcons?: React.ReactElement[];
    iconField?: string;
    childrenIcons?: { [key: string]: React.ReactElement };
}
interface TreeComponentProps {
    nodes: Node[];
    idField: string;
    nameField: string;
    onNodeClick: (node: Node) => void;
    parentIcons?: React.ReactElement[];
    iconField?: string;
    childrenIcons?: { [key: string]: React.ReactElement };
}

export const TreeNode: React.FC<TreeNodeProps> = ({ node, idField, nameField, onNodeClick, parentIcons, iconField, childrenIcons }) => {
    const [expanded, setExpanded] = useState(false);

    const hasChildren = node.children && node.children.length > 0;

    return (
        <div style={{ margin: 5 }}>
            {hasChildren ?
                <div id={node.dept_cd} onClick={() => setExpanded(!expanded)}>
                    {parentIcons ? expanded ? parentIcons[0] : parentIcons[1] : expanded ? <MdKeyboardArrowRight /> : <MdExpandMore />}
                    {node.dept_nm}
                </div>
                : (node.user_nm === null) ? <></> :
                    <div id={node.user_id} onClick={() => onNodeClick(node)}>
                        {childrenIcons && iconField && node[iconField!] ? childrenIcons[node[iconField!]] : <span style={{ paddingRight: 16 }}></span>}
                        {node.gender === "M" ? <FcBusinessman style={{ paddingBottom: "1.5px" }} /> : <FcBusinesswoman style={{ paddingBottom: "1.5px" }} />}
                        {node.user_nm}

                    </div>
            }
            {expanded && hasChildren && (
                <div>
                    {node.children!.map((children) => (
                        <TreeNode key={`child_key_${children.user_id ?? children.dept_cd}`} node={children} idField={idField} nameField={nameField} onNodeClick={onNodeClick} parentIcons={parentIcons} iconField={iconField} childrenIcons={childrenIcons} />
                    ))}
                </div>
            )}
        </div>
    );
};

export const Tree: React.FC<TreeComponentProps> = ({ nodes, idField, nameField, onNodeClick, parentIcons, iconField, childrenIcons }) => {
    return (
        <div>
            {nodes.map((node) => (
                <TreeNode key={node.user_id} node={node} idField={idField} nameField={nameField} onNodeClick={onNodeClick} parentIcons={parentIcons} iconField={iconField} childrenIcons={childrenIcons} />
            ))}
        </div>
    );
};
