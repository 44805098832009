import { useEffect, useState } from 'react';
import { Form, Row, Card, Col, Button } from 'react-bootstrap';
import '../../css/myinfo/Myinfo.css';
import axios from 'axios';
import PopupShow from './MyInfoPopup';

import FileUpload from './FileUpload';
import { BsFillPersonFill } from 'react-icons/bs';

function MyProfile() {

    const [myInfoData, setMyInfoData] = useState<{ [key: string]: any; }>([]);

    const [showModal, setShowModal] = useState(false);
    const [category, setCategory] = useState(0);

    function popupClick(cate: number) {
        setCategory(cate);
        setShowModal(true);
    }

    useEffect(() => {
        const getMyInfo = () => {
            try {
                axios.post(
                    '/api/myinfo/getMyInfo',
                    sessionStorage.getItem("user_id"),
                    { headers: { "Content-Type": "application/text" } }
                ).then(response => {
                    setMyInfoData({
                        user_id: response.data.user_id,
                        user_nm: response.data.user_nm,
                        office_phone: response.data.office_phone,
                        mobl_phone: response.data.mobl_phone,
                        fax: response.data.fax,
                        email: response.data.email,
                        gender: response.data.gender,
                        birth_dt: response.data.birth_dt,
                        lunar_yn: response.data.lunar_yn,
                        hire_dt: response.data.hire_dt,
                        zipcode_tp: response.data.zipcode_tp,
                        zipcode: response.data.zipcode,
                        address_tp: response.data.address_tp,
                        address: response.data.address,
                        dept_nm: response.data.dept_nm,
                        position_nm: response.data.position_nm,
                        job_title: response.data.job_title,
                        photo_path: response.data.photo_path
                    });
                });
            } catch (error) {
                alert("정보 불러오기에 실패하였습니다. 관리자에게 문의 부탁드립니다.");
            }
        };

        getMyInfo();
    }, []);

    return (
        <div className='content myInfo'>
            <h2>내 정보</h2>

            <div className='d-flex justify-content-end gap-1'>
                <Button onClick={() => popupClick(0)}>비밀번호 변경</Button>
                <Button onClick={() => popupClick(1)}>내 정보 수정</Button>
            </div>

            <Card className='mt-3 p-3 cursorDefault'>
                <Row>
                    <Col md={4}>
                        <div className='m-auto my-1' style={
                            myInfoData.photo_path ?
                                { borderRadius: '50px', width: '100px', height: '100px', backgroundImage: `url(${myInfoData.photo_path})`, backgroundSize: 'cover', border: '1px solid lightgray' } :
                                { backgroundColor: 'lightgray', borderRadius: '50px', width: '100px', height: '100px' }}>
                            <div style={{ position: 'relative', top: '4rem' }}><FileUpload infoData={myInfoData} setInfoData={setMyInfoData} category={1} /></div>
                            {!myInfoData.photo_path &&
                                <BsFillPersonFill size={50} color='white' style={{ marginTop: '-20px', marginLeft: '25px' }} />
                            }
                        </div>
                    </Col>
                    <Col>
                        <Card.Body className='text-center text-md-start'>
                            <Card.Text as={'h5'} className='mb-3'>
                                {myInfoData.user_nm ? myInfoData.user_nm : ''}
                                <span className='fs-6 text-body-secondary'>
                                    &nbsp;&nbsp;{myInfoData.position_nm ? myInfoData.position_nm : ''}/{myInfoData.dept_nm ? myInfoData.dept_nm : ''}
                                </span>
                            </Card.Text>
                            {myInfoData.email ? <Card.Text> {myInfoData.email} </Card.Text> : <></>}
                            {myInfoData.mobl_phone ? <Card.Text> {myInfoData.mobl_phone} </Card.Text> : <></>}
                        </Card.Body>
                    </Col>
                </Row>
            </Card>

            <Form className="myInfoDetail cursorDefault">
                <Row>
                    <Form.Group as={Col} className="mb-3" controlId="user_id">
                        <Form.Label>아이디</Form.Label>
                        <Form.Control type="text" value={myInfoData.user_id ? myInfoData.user_id : ''} readOnly />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3" controlId="user_nm">
                        <Form.Label>이름</Form.Label>
                        <Form.Control type="text" name='user_nm' value={myInfoData.user_nm ? myInfoData.user_nm : ''} readOnly />
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="dept_nm">
                    <Form.Label>부서명</Form.Label>
                    <Form.Control type="text" value={myInfoData.dept_nm ? myInfoData.dept_nm : ''} readOnly />
                </Form.Group>
                <Row>
                    <Form.Group as={Col} className="mb-3" controlId="position_nm">
                        <Form.Label>직급</Form.Label>
                        <Form.Control type="text" value={myInfoData.position_nm ? myInfoData.position_nm : ''} readOnly />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3" controlId="job_title">
                        <Form.Label>직책</Form.Label>
                        <Form.Control type="text" value={myInfoData.job_title ? myInfoData.job_title : ''} readOnly />
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="office_phone">
                    <Form.Label>사무실번호</Form.Label>
                    <Form.Control type="text" name='office_phone' value={myInfoData.office_phone ? myInfoData.office_phone : ''} readOnly />
                </Form.Group>
                <Form.Group className="mb-3" controlId="mobl_phone">
                    <Form.Label>휴대폰번호</Form.Label>
                    <Form.Control type="text" name='mobl_phone' value={myInfoData.mobl_phone ? myInfoData.mobl_phone : ''} readOnly />
                </Form.Group>
                <Form.Group className="mb-3" controlId="fax">
                    <Form.Label>팩스</Form.Label>
                    <Form.Control type="text" name='fax' value={myInfoData.fax ? myInfoData.fax : ''} readOnly />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>이메일</Form.Label>
                    <Form.Control type="text" name='email' value={myInfoData.email ? myInfoData.email : ''} readOnly autoComplete='false' />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label htmlFor='genderM'>성별</Form.Label>
                    <br />
                    <Form.Check
                        inline
                        label='남자'
                        type='radio'
                        id='genderM'
                        className='ps-0'
                        checked={myInfoData.gender === 'M'} readOnly
                    />
                    <Form.Check
                        inline
                        label='여자'
                        type='radio'
                        id='genderF'
                        checked={myInfoData.gender === 'F'} readOnly
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label htmlFor='date_birth'>생년월일</Form.Label>
                    <br />
                    <Form.Check
                        inline
                        label='양력'
                        type='radio'
                        id='lunarS'
                        className='ps-0'
                        checked={myInfoData.lunar_yn === 'S'} readOnly
                    />
                    <Form.Check
                        inline
                        label='음력'
                        type='radio'
                        id='lunarL'
                        checked={myInfoData.lunar_yn === 'L'} readOnly
                    />
                    <Form.Control id="date_birth" defaultValue={myInfoData.birth_dt ? myInfoData.birth_dt : ''} readOnly />
                </Form.Group>
                <Form.Group className="mb-3" controlId='date_hire'>
                    <Form.Label>입사일자</Form.Label>
                    <Form.Control type='date' value={myInfoData.hire_dt ? myInfoData.hire_dt : ''} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label htmlFor='code'>우편번호</Form.Label>
                    <br />
                    <Form.Check
                        inline
                        label='신'
                        type='radio'
                        id='zipcode_tp0'
                        className='ps-0'
                        checked={myInfoData.zipcode_tp === '0'} readOnly
                    />
                    <Form.Check
                        inline
                        label='구'
                        type='radio'
                        id='zipcode_tp1'
                        checked={myInfoData.zipcode_tp === '1'} readOnly
                    />
                    <Form.Control id="code" type='text' value={myInfoData.zipcode ? myInfoData.zipcode : ''} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label htmlFor='adressText'>주소</Form.Label>
                    <br />
                    <Form.Check
                        inline
                        label='지번'
                        type='radio'
                        id='address_tp0'
                        className='ps-0'
                        checked={myInfoData.address_tp === '0'} readOnly
                    />
                    <Form.Check
                        inline
                        label='도로명'
                        type='radio'
                        id='address_tp1'
                        checked={myInfoData.address_tp === '1'} readOnly
                    />
                    <Form.Control id="adressText" type='text' value={myInfoData.address ? myInfoData.address : ''} readOnly />
                </Form.Group>
            </Form>
            <PopupShow show={showModal} setShow={setShowModal} category={category} infoData={myInfoData} setInfoData={setMyInfoData} setCategory={setCategory} />
        </div>
    );
}

export default MyProfile;
