import axios from 'axios';
import { useEffect, useState } from 'react';
import { Carousel, Col, Image, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import noImage from "../images/icon_noimages.gif"
import { useNavigate } from 'react-router-dom';
import Loading from '../module/Loading';
import BoardDetail from './board/BoardDetail';
import CardComponent from './CardComponent';

function Main() {

  const latHeight = useMediaQuery({ maxHeight: 600 });
  const isDesktop = !/iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showPost, setShowPost] = useState<boolean>(false);

  const [waitList, setWaitList] = useState<any[]>([]); // 결재대기함
  const [procList, setProcList] = useState<any[]>([]); // 결재진행함
  const [readList, setReadList] = useState<any[]>([]); // 참조확인함
  const [compList, setCompList] = useState<any[]>([]); // 결재완료함

  const [selectBbs, setSelectBbs] = useState<any>();
  const [selectNtt, setSelectNtt] = useState<any>();
  const [notiList, setNotiList] = useState<any[]>([]); // 전사공지사항
  const [eventList, setEventList] = useState<any[]>([]); // 경조사
  const [propList, setPropList] = useState<any[]>([]); // 사내제안
  const [referList, setReferList] = useState<any[]>([]); // 자료실

  const [newsList, setNewsList] = useState<BoardArticleVO[]>([]); // 캐러셀
  const [isPause, setPause] = useState(false);
  const [defaultActiveIndex, setDefaultActiveIndex] = useState<number | undefined>(0);

  const apprMap = [
    { key: "apprWait", value: "결재대기함", move_id: "GW0102", var: 'sign_box_cd=B04&returnPage=/appr/signbox/apprWaitBox' },
    { key: "apprProc", value: "결재진행함", move_id: "GW0103", var: 'sign_box_cd=B05&returnPage=/appr/signbox/apprProcBox' },
    { key: "apprComp", value: "결재완료함", move_id: "GW0106", var: 'sign_box_cd=B06&returnPage=/appr/signbox/apprCompBox' },
    { key: "apprRead", value: "참조확인함", move_id: "GW0500", var: 'sign_box_cd=B07&returnPage=/appr/signbox/apprReadBox' },
  ];

  useEffect(() => {
    if (typeof Notification !== 'undefined') {
      Notification.requestPermission();
    }
    const fetchData = async () => {
      try {
        setNotiList(await selectBoardList(0));
        setEventList(await selectBoardList(1));
        setPropList(await selectBoardList(2));
        setReferList(await selectBoardList(3));
        setNewsList(await selectBoardList(4));
        selectApprList();
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    if (loading)
      fetchData();
  }, [loading]);

  const boardMap = [
    { board_url: '/board/notice', board_var: 3, board_nm: '전사공지사항' },
    { board_url: '/board/event', board_var: 4, board_nm: '경조사' },
    { board_url: '/board/proposal', board_var: 7, board_nm: '사내제안' },
    { board_url: '/board/reference', board_var: 5, board_nm: '자료실' },
    { board_url: '/board/news', board_var: 2, board_nm: '새소식' },
  ];

  // 게시글 조회
  const selectBoardList = async (index: number) => {
    const param = {
      pathname: boardMap[index].board_url,
      bbs_id: boardMap[index].board_var,
      searchKey: 'BSH001',
      searchMonth: "",
      searchStr: "",
      searchYear: "",
      sortDir: "",
      sortStr: "",
      currPage: 0,
      rowSize: 5,
      user_id: sessionStorage.getItem('user_id'),
    }
    const response = await axios.post(
      '/api/board/getArticleList',
      param,
      { headers: { "Content-Type": 'application/json' } }
    );
    return response.data.content;
  }
  // 결재문서 조회
  const selectApprList = async () => {
    const response = await axios.post(
      '/api/appr/signBox/signMain.menu',
      {
        user_id: sessionStorage.getItem("user_id"),
        lang: "ko",
      },
      { headers: { "Content-Type": "application/json" } }
    );
    setWaitList(response.data.waitList);
    setProcList(response.data.procList);
    setReadList(response.data.readList);
    setCompList(response.data.compList);
  }

  // 메뉴 이동
  function goPage(title: any, boardYn?: any) {
    if (boardYn !== 'y') {
      let menu = { key: '', move_id: '', var: '', value: '' }
      apprMap.map((item) => title === item.value ? menu = item : '');
      const url = '/appr/signbox/' + menu.key + 'Box'
      navigate(url, { state: { menu_cd: menu.move_id, menu_var: menu.var, menu_nm: menu.value } });
    } else {
      let url = {};
      boardMap.map((item) => title === item.board_nm ? url = item.board_url : '')
      navigate(url);
    }
  }

  function moveActive(compo: HTMLElement | null) {
    if (!compo) return;
    const div = compo.id.includes('carouselTitle_') ? compo : compo.parentNode as HTMLElement;
    const idx = Number(div.id.replace('carouselTitle_', ''));
    document.querySelectorAll<HTMLElement>('.carousel-title').forEach(el => {
      el.classList.toggle('active', el === div);
    });
    setDefaultActiveIndex(idx);
  }

  return (
    <div className='content-container'>
      <div className='content'>
        {loading ? (
          <Loading />
        ) : (
          <>
            {isDesktop ?
              <>
                <Row className="g-2 mb-3">
                  <Col xs={9}>
                    <Carousel fade
                      indicators={false}
                      controls={false}
                      interval={isPause ? null : 6000}
                      activeIndex={defaultActiveIndex}
                      defaultActiveIndex={defaultActiveIndex}
                      onSelect={e => moveActive(document.querySelector('#carouselTitle_' + e))}>
                      {newsList.map(vo => {
                        return <Carousel.Item key={vo.ntt_id} id={'carouselItem_' + newsList.indexOf(vo)}>
                          <Image className="carousel-thumb" src={"/api/board/thumb/0" + vo.bbs_id + "/" + vo.ntt_id}
                            width='100%' onError={e => (e.target as HTMLImageElement).src = noImage} />
                        </Carousel.Item>
                      })}
                    </Carousel>
                  </Col>
                  <Col xs={3}>
                    {newsList.map(vo => (
                      <div
                        id={'carouselTitle_' + newsList.indexOf(vo)}
                        className={'carousel-title' + (newsList.indexOf(vo) === 0 ? ' active' : '')}
                        key={vo.ntt_id}
                        style={{ height: 'calc(30vh/' + newsList.length + ')', lineHeight: 'calc(30vh/' + newsList.length + ')' }}
                        onClick={() => {
                          setSelectBbs(vo.bbs_id);
                          setSelectNtt(vo.ntt_id);
                          setShowPost(true);
                        }}
                        onMouseOver={e => {
                          setPause(true);
                          moveActive(e.target as HTMLElement);
                        }}
                        onMouseOut={() => {
                          setPause(false);
                        }}>
                        <span>{vo.ntt_sj}</span>
                      </div>
                    ))}
                  </Col>
                </Row>
                <Row xs={1} md={4} className="g-2 mb-3">
                  <CardComponent title={'전사공지사항'} list={notiList} board={'y'} goPage={goPage} />
                  <CardComponent title={'경조사'} list={eventList} board={'y'} goPage={goPage} />
                  <CardComponent title={'사내제안'} list={propList} board={'y'} goPage={goPage} />
                  <CardComponent title={'자료실'} list={referList} board={'y'} goPage={goPage} />
                </Row>
                <Row xs={1} md={4} className="g-2">
                  <CardComponent title={'결재대기함'} list={waitList} goPage={goPage} />
                  <CardComponent title={'결재진행함'} list={procList} goPage={goPage} />
                  <CardComponent title={'결재완료함'} list={compList} goPage={goPage} />
                  <CardComponent title={'참조확인함'} list={readList} goPage={goPage} />
                </Row>
              </>
              :
              <>
                <Row className="g-2 mb-3">
                  <Col xs={11}>
                    <Carousel fade
                      indicators={false}
                      controls={false}
                      interval={6000}
                      activeIndex={defaultActiveIndex}
                      defaultActiveIndex={defaultActiveIndex}
                      onSelect={e => moveActive(document.querySelector('#carouselTitle_' + e))}>
                      {newsList.map(vo => {
                        return <Carousel.Item key={vo.ntt_id} id={'carouselItem_' + newsList.indexOf(vo)}>
                          <Image className="carousel-thumb" src={"/api/board/thumb/0" + vo.bbs_id + "/" + vo.ntt_id}
                            width='100%' onError={e => (e.target as HTMLImageElement).src = noImage} />
                          <Carousel.Caption>
                            <h3
                              onClick={() => {
                                setSelectBbs(vo.bbs_id);
                                setSelectNtt(vo.ntt_id);
                                navigate('/board/' + vo.bbs_id + '/' + vo.ntt_id, {
                                  state: {
                                    ntt_id: vo.ntt_id,
                                    show: showPost,
                                    currPage: 0,
                                    rowSize: 10,
                                    isDetail: false,
                                    searchMap: '',
                                    menu_nm: '새소식',
                                    prevPath: window.location.pathname
                                  }, replace: false
                                });
                              }}>
                              {vo.ntt_sj}
                            </h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                      })}
                    </Carousel>
                  </Col>
                  <Col xs={1} className='carousel-title-wrap'>
                    {newsList.map(vo => (
                      <div
                        id={'carouselTitle_' + newsList.indexOf(vo)}
                        className={'carousel-title' + (newsList.indexOf(vo) === 0 ? ' active' : '')}
                        key={vo.ntt_id}
                        style={{ height: 'calc(' + (latHeight ? '25vw/' : '30vh/') + newsList.length + ')', lineHeight: 'calc(' + (latHeight ? '25vw/' : '30vh/') + newsList.length + ')' }}
                        onClick={e => {
                          moveActive(e.target as HTMLElement);
                        }}>
                      </div>
                    ))}
                  </Col>
                </Row>
                <div className='d-flex mb-3 align-items-stretch' style={{ overflowX: 'auto', whiteSpace: 'nowrap', scrollSnapType: 'x mandatory' }}>
                  <CardComponent title={'전사공지사항'} list={notiList} board={'y'} goPage={goPage} />
                  <CardComponent title={'경조사'} list={eventList} board={'y'} goPage={goPage} />
                  <CardComponent title={'사내제안'} list={propList} board={'y'} goPage={goPage} />
                  <CardComponent title={'자료실'} list={referList} board={'y'} goPage={goPage} />
                </div>
                <div className='d-flex mb-3 align-items-stretch' style={{ overflowX: 'auto', whiteSpace: 'nowrap', scrollSnapType: 'x mandatory' }}>
                  <CardComponent title={'결재대기함'} list={waitList} goPage={goPage} />
                  <CardComponent title={'결재진행함'} list={procList} goPage={goPage} />
                  <CardComponent title={'결재완료함'} list={compList} goPage={goPage} />
                  <CardComponent title={'참조확인함'} list={readList} goPage={goPage} />
                </div>
              </>
            }
          </>
        )}
        {showPost && <BoardDetail bbs_id={selectBbs} ntt_id={selectNtt} show={showPost} setShow={setShowPost} menu_nm='새소식' setEdit={() => {}} />}
      </div>
    </div>
  );
}

type BoardArticleVO = {
  [key: string]: any;
}

export default Main;
