import { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import MyPageModal from "./MyPageModal";

interface InfoProps {
    show: boolean,
    setShow: Function
}

function MyPage({show, setShow}: InfoProps) {

    const location = useLocation();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isPage = location.pathname === '/exter/mypage';

    function closeModal() {
        if (isPage) {
            navigate('/exter/board');
        } else {
            setShow(false);
        };
    }

    function modalWrap(content: any) {
        return (
            <Modal show={show} onHide={() => closeModal()}
                aria-labelledby="contained-modal-title-vcenter" 
                backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        내 정보
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
            </Modal>
        )
    }
    const rtn: ReactElement = modalWrap(<MyPageModal show={show} closeFunc={closeModal} />);
    if (!isDesktop) {
        return (
            <div className="content datatable-content">
                <MyPageModal show={show} closeFunc={closeModal} />
            </div>
        )
    } else {
        return rtn;
    }

}

export default MyPage;