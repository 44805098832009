import { useEffect, useRef, useState } from 'react';
import { Form, Button, Row, Col, FloatingLabel } from 'react-bootstrap';
import '../css/Login.css';
import login from '../module/Func_Login';
import AlertModal from '../module/AlertModal';
import { Navigate, useNavigate } from 'react-router-dom';
import SignUpModal from './exter/SignUpModal';

function Login() {

  const navigate = useNavigate();
  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState('');
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertBody, setAlertBody] = useState("");

  const [exterLogin, setExterLogin] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const idRef = useRef<HTMLInputElement>(null);
  const pwRef = useRef<HTMLInputElement>(null);
  
  const handleLogin = async (id: String, pw: String, exterFlag: boolean ) => {
    login(id, pw, exterFlag).then(rtn => {
      if (rtn === 0 && !exterFlag) {
        navigate("/main", { replace: true });
      } else if (rtn === 0 && exterFlag) {
        navigate("/exter/board", { replace: true });
      } else if (rtn === 2) {
        setAlertTitle('로그인 실패');
        setAlertBody('아이디 패스워드 확인 부탁 드립니다.');
        setShowModal(true);
      } else {
        setAlertTitle('로그인 실패');
        setAlertTitle('서버 오류로 인한 로그인 실패, 관리자에게 문의 부탁 드립니다.');
        setShowModal(true);
      }
    });
  };

  const appLaunch = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        setDeferredPrompt(null);
      });
    } else if (/iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase()) && !window.matchMedia('(display-mode: standalone)').matches) {
      setAlertTitle('IOS PWA 설치 가이드.');
      setAlertTitle('iOS에서 앱을 설치하려면 브라우저 메뉴에서 "홈 화면에 추가"를 선택하세요.');
      setShowModal(true);
    }
  };

  useEffect(() => {
    
    const handleBeforeInstallPrompt = (event: any) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };

  }, []);

  return localStorage.getItem('login_token') ? (<Navigate to='/' replace={true} />) : (
    <div className="login-container">
      <div className="login-wrapper">
        <Row className="justify-content-sm-center">
          <Col xs={12}>
            <div className="image-container mt-3">
              <img src={`${process.env.PUBLIC_URL}/images/widetns_logo.gif`} alt='Logo' className="responsive-image" />
            </div>
            <div className='textBox'>
              Welcome to the Approval System.
              <br />
              Please log in to continue.
            </div>
            <Form>
              <FloatingLabel controlId="floatingInput" label="USER ID" className="mb-3" >
                <Form.Control type="textBox" ref={idRef} onChange={(e) => setUserid(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') {  e.preventDefault(); pwRef.current?.focus(); } }}/>
              </FloatingLabel>
              <FloatingLabel controlId="floatingPassword" label="Password">
                <Form.Control type="password" ref={pwRef} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') {  e.preventDefault(); handleLogin(userid, password, exterLogin); } }}/>
              </FloatingLabel>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check id="exterLogin" type="switch" label="외부 인력" onChange={(e) => setExterLogin(e.target.checked)} />
              </Form.Group>
            </Form>

            <div className="d-grid gap-2">
              <Button variant='primary' size="lg" onClick={() => handleLogin(userid, password, exterLogin)}>Login</Button>
              {exterLogin && (<Button variant='warning' size="lg" onClick={() => setShowTerms(true)}>신규 ID 생성</Button>)}
              {(!window.matchMedia('(display-mode: standalone)').matches || deferredPrompt) && <Button variant='secondary' size="lg" onClick={() => appLaunch()}>앱 설치</Button>}
              <a href="http://www.widetns.com" target="_blank" rel="noopener noreferrer">← Go to WideTNS Home</a>
            </div>
          </Col>
        </Row>
      </div>
      {exterLogin && (<SignUpModal show={showTerms} onHide={() => setShowTerms(false)} />)}
      <AlertModal title={alertTitle} body={alertBody} show={showModal} setShow={setShowModal} />
    </div>
  );
}

export default Login;
