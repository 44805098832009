import { AiOutlineLoading3Quarters } from "react-icons/ai";
import BarLoader from "react-spinners/BarLoader"

export default function Loading() {
    return (
        <div className="spinner_container">
            {/* <AiOutlineLoading3Quarters className="spinner" color="green" /> */}
            <BarLoader color="#fb0404" height={8} width={500} cssOverride={{marginTop: '5rem'}} />
        </div>
    );
}