import axios from "axios";
import '../../css/myinfo/Myinfo.css';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { BsFillCameraFill } from "react-icons/bs";
import FileUploadChoose from "./FileUploadChoose";

const FileUpload = ({infoData, setInfoData, category}: { infoData: {[key: string]: any;}, setInfoData: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>, category: number}): React.ReactElement => {
    const [showModal, setShowModal] = useState(false);
    
    //모바일 여부
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const userAgent = navigator.userAgent;
        const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        setIsMobile(mobileRegex.test(userAgent));
    }, []);

    const handleFileRegist = (e:any) => {
        if(fn_ext_check(e.target.value)) {
            let file = e.target.files[0] as File;
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const result = reader.result;
                    if (typeof result === 'string') {
                        setInfoData({
                            ...infoData,
                            'photo_path' : result
                        });
                    }
                };
                reader.readAsDataURL(file);
            } else {
                alert("파일을 선택해주세요.");
                return;
            }
        }
    };

    const handleFileChange = (e:any) => {
        if(fn_ext_check(e.target.value)) {
            if(window.confirm("변경하시겠습니까?")) {
                handleUpload(e.target.files[0]);
                e.target.value = null;
                return;
            }
        }
        const file = document.getElementById('imageUpload1') as HTMLInputElement;
        if(file) file.value = '';
    };

    function fn_ext_check(fileName : string){
        let thumbext = fileName;
        thumbext = thumbext.slice(thumbext.indexOf(".") + 1).toLowerCase();
        
        if(thumbext !== "jpg" && thumbext !== "png" &&  thumbext !== "gif" &&  thumbext !== "bmp" && thumbext !== 'jpeg'){
            alert('프로파일 사진은 이미지 파일(jpg, png, gif, bmp)만 등록 가능합니다.');
            return false;
        }
        
        return true;
    }

    const handleUpload = async (file : File) => {
        if (!file) {
            alert("파일을 선택해주세요.");
            return;
        }

        const userId = infoData.user_id;
        if(!userId) {
            alert('시스템에 문제가 발생하였습니다. 관리자에게 문의 바랍니다.');
            return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("user_id", userId);

        try {
            const response = await axios.post('/api/myinfo/updatePicture', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

            if (response.data !== 'failed') {
                alert("파일이 성공적으로 업로드되었습니다!");
                if (file) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const result = reader.result;
                        if (typeof result === 'string') {
                            setInfoData({
                                ...infoData,
                                'photo_path' : result
                            });
                            infoData.photo_path = result;
                        }
                    };
                    reader.readAsDataURL(file);
                    setShowModal(false);
                }
            } else {
                alert("파일 업로드에 실패했습니다.");
            }
        } catch (error) {
            alert("파일 업로드 중 오류가 발생했습니다.");
        }
    };

    return (
        <>
        <Button id='pictureChangeRealBtn' style={{borderRadius: '100px', paddingBottom: '10px'}}
        onClick={() => {if(!isMobile) {
                            document.getElementById('imageUpload1')?.click()
                        } else {
                            setShowModal(true);
                        }}}><BsFillCameraFill size={20}/></Button>
        <div className='pictureChange'>
            <input id='imageUpload1' type="file" capture="environment" onChange={category === 4 ? handleFileRegist : handleFileChange } />
            <input id='imageUpload2' type="file" accept="image/*" onChange={category === 4 ? handleFileRegist : handleFileChange } />
        </div>
        <FileUploadChoose show={showModal} setShow={setShowModal} />
        </>
    );
}

export default FileUpload;