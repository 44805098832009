import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InquiryListPage from "./InquiryListPage";
import AlertModal from "../../module/AlertModal";

interface InquiryProps {
    show: boolean,
    setShow: Function,
    bbs_id: string,
    ntt_id: string,
    state?: object
}

function InquiryList({show, setShow, bbs_id, ntt_id}: InquiryProps) {

    const isDesktop = useMediaQuery({ minWidth: 768 });

    const navigate = useNavigate();
    const location = useLocation();
    const isPage = /\/board\/inquiryList/.test(location.pathname);

    function closeModal() {
        if (isPage) {
            if (location.state && location.state.articlePath) {
                navigate(location.state.articlePath, {state: location.state});
            } else {
                navigate('/board/main');
            }
        } else {
            setShow(false);
        };
    }

    function modalWrap(content: any) {
        return (
            <Modal show={show} onHide={() => closeModal()}
                size="lg" aria-labelledby="contained-modal-title-vcenter" 
                centered id="inquiryModal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        조회 목록
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
            </Modal>
        )
    }
    const rtn: ReactElement = <InquiryListPage bbs_id={bbs_id} ntt_id={ntt_id} closeFunc={closeModal} show={show} />

    if (show && !bbs_id && !ntt_id) {
        return <AlertModal show={true} setShow={() => false} title="" body="잘못된 접근입니다." closeFunc={() => navigate('/', {replace: true})} />;
    } else if (localStorage.getItem('extern_yn') === 'Y') {
        return <AlertModal show={true} setShow={() => false} title="" body="WideT&S 전용 페이지입니다." closeFunc={() => navigate('/', {replace: true})} />;
    } else if (!isDesktop) {
        return (
            <div className="content">
                {rtn}
            </div>
        )
    } else {
        return modalWrap(rtn);
    }
    
}

export default InquiryList;