import { useState, useEffect } from 'react';
import { Form, FloatingLabel, Button, Modal } from 'react-bootstrap';
import axios from "axios";
import PopupShow from './PreviewReceipt';
import FileUploadChoose from './FileUploadChoose';

interface ReceiptProps {
    receipt_id: string,
    user_id: string,
    category: string,
    use_date: string,
    use_history: string,
    use_user: string, 
    use_place: string, 
    price: string,
    proof: string
    note: string
    aid: string
}

const ReceiptForm = ({variable, setShow, listRefreshFn, date} : {variable:ReceiptProps, setShow: React.Dispatch<React.SetStateAction<boolean>>, listRefreshFn: Function, date: string}) => {

    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);

    //모바일 여부
    const [isMobile, setIsMobile] = useState(false);

    const [formData, setFormData] = useState({
        receipt_id: '',
        user_id: sessionStorage.getItem("user_id"),
        category: '프로젝트 관리비',
        use_date: new Date().toISOString().split('T')[0],
        use_history: '',
        use_user: '',
        use_place: '',
        price: '',
        proof: '개인카드',
        note: '',
        imageFile: null as unknown as File,
        imagePreviewUrl: '',
        aid: ''
    });

    useEffect(() => {
        if (variable != null) {
            
            if(variable.note === "영수증 확인") {
                try {
                    setFormData({
                        receipt_id: variable.receipt_id,
                        user_id: sessionStorage.getItem("user_id"),
                        category: variable.category,
                        use_date: variable.use_date,
                        use_history: variable.use_history,
                        use_user: variable.use_user,
                        use_place: variable.use_place,
                        price: variable.price,
                        proof: variable.proof,
                        note: variable.note,
                        imageFile: null as unknown as File,
                        imagePreviewUrl: '',
                        aid: ''
                    });
                    axios.post('/api/myinfo/getReceiptFile',
                        variable.aid,
                        {headers: {"Content-Type": "application/json"}, responseType: 'text'},
                    ).then(response => {
                        const base64Image = response.data;
                        if(base64Image) {
                            setFormData({
                                receipt_id: variable.receipt_id,
                                user_id: sessionStorage.getItem("user_id"),
                                category: variable.category,
                                use_date: variable.use_date,
                                use_history: variable.use_history,
                                use_user: variable.use_user,
                                use_place: variable.use_place,
                                price: variable.price,
                                proof: variable.proof,
                                note: variable.note,
                                imageFile: null as unknown as File,
                                imagePreviewUrl: base64Image,
                                aid: variable.aid
                            });
                        }
                    });
                } catch (error) {
                    alert('정보 불러오기에 실패하였습니다. 관리자에게 문의 부탁드립니다.')
                }
            } else {
                setFormData({
                    receipt_id: variable.receipt_id,
                    user_id: sessionStorage.getItem("user_id"),
                    category: variable.category,
                    use_date: variable.use_date,
                    use_history: variable.use_history,
                    use_user: variable.use_user,
                    use_place: variable.use_place,
                    price: variable.price,
                    proof: variable.proof,
                    note: variable.note,
                    imageFile: null as unknown as File,
                    imagePreviewUrl: '',
                    aid: ''
                });
            }
        }

        const userAgent = navigator.userAgent;
        const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        setIsMobile(mobileRegex.test(userAgent));
    }, [variable]);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageUpload = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const result = reader.result;
                if (typeof result === 'string') {
                    setFormData({
                        ...formData,
                        note: '영수증 확인', // 비고에 영수증 확인을 설정
                        imageFile: file,
                        imagePreviewUrl: result,
                    });
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();

        let use_history_input = document.getElementById('use_history') as HTMLInputElement;
        let use_user_input = document.getElementById('use_user') as HTMLInputElement;
        let use_place_input = document.getElementById('use_place') as HTMLInputElement;
        let price_input = document.getElementById('price') as HTMLInputElement;
        let note_input = document.getElementById('note') as HTMLInputElement;

        const regex = /^[0-9]*$/;
        if(!use_history_input.value?.trim()) {
            alert('사용내역을 입력해주세요.');
            use_history_input.focus();
            return;
        } else if(!use_user_input.value?.trim()) {
            alert('사용내역(당사자)를 입력해주세요.');
            use_user_input.focus();
            return;
        } else if(!use_place_input.value?.trim()) {
            alert('사용처를 입력해주세요.');
            use_place_input.focus();
            return;
        } else if(!price_input.value?.trim()) {
            alert('금액을 입력해주세요.');
            price_input.focus();
            return;
        } else if (!regex.test(price_input.value)) {
            alert('금액은 숫자만 입력해주세요.');
            price_input.focus();
            return;
        } else if(!note_input.value) {
            if(!window.confirm('사진을 첨부하지 않고 등록하시겠습니까?')) {
                note_input.focus();
                return;
            }
        } else {
            if(!window.confirm('등록하시겠습니까?')) {
               return; 
            }
        }

        const fd = new FormData();
        (Object.keys(formData) as (keyof typeof formData)[]).forEach((key) => {
            if (key !== 'imagePreviewUrl' && key !== 'imageFile') {
                fd.append(key, formData[key] as string);
            }
        });
        if (formData.imageFile) {
            fd.append('imageFile', formData.imageFile);
        }

        if(variable == null) {
            try {
                let response = await axios.post('/api/myinfo/registReceipt', fd, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
    
                if (response.data === 'failed') {
                    alert("영수증 등록에 실패했습니다. 관리자에게 문의바랍니다.");
                    return;
                }
                
                if(formData.imageFile) {
                    fd.delete('receipt_id');
                    fd.append('receipt_id', response.data);

                    response = await axios.post('/api/myinfo/registAtt', fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                }
                
                if (response.data !== 'failed') {
                    alert("영수증이 성공적으로 등록되었습니다!");
                    date = formData.use_date.substring(0,7);
                    setFormData({
                        receipt_id: '',
                        user_id: sessionStorage.getItem("user_id"),
                        category: '프로젝트 관리비',
                        use_date: new Date().toISOString().split('T')[0],
                        use_history: '',
                        use_user: '',
                        use_place: '',
                        price: '',
                        proof: '개인카드',
                        note: '',
                        imageFile: null as unknown as File,
                        imagePreviewUrl: '',
                        aid: ''
                    });
                } else {
                    alert("영수증 등록에 실패했습니다. 관리자에게 문의바랍니다.");
                    return;
                }

            } catch (error) {
                alert("영수증 등록 중 오류가 발생했습니다.");
                return;
            }
        } else {
            try {

                if(formData.imagePreviewUrl && !formData.imageFile) {
                    //현상 유지
                    fd.delete('aid');
                }

                let response = await axios.post('/api/myinfo/updateReceipt', fd, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
    
                if (response.data === 'failed') {
                    alert("영수증 수정에 실패했습니다. 관리자에게 문의바랍니다.");
                    return;
                }
                
                if(formData.imageFile) {
                    response = await axios.post('/api/myinfo/registAtt', fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                }
                
                if (response.data !== 'failed') {
                    alert("영수증이 성공적으로 수정되었습니다!");
                } else {
                    alert("영수증 수정에 실패했습니다. 관리자에게 문의바랍니다.");
                    return;
                }
                
            } catch (error) {
                alert("영수증 수정 중 오류가 발생했습니다.");
                return;
            }
        }
        
        listRefreshFn(date);
        setShow(false);
            
    };

    const deleteReceipt = async () => {
        if(!window.confirm('영수증을 삭제하시겠습니까?')) {
            return;
        }
        let response = await axios.post('/api/myinfo/deleteReceipt', formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.data !== 'failed') {
            alert("영수증이 성공적으로 삭제되었습니다!");
            listRefreshFn(date);
        } else {
            alert("영수증 삭제에 실패했습니다. 관리자에게 문의바랍니다.");
            return;
        }

        setShow(false);
    }

    return (
        <>
            <Form className='receipt'>
                <FloatingLabel controlId='category' label="비목" className='mb-3'>
                    <Form.Select name='category' value={formData.category} onChange={handleInputChange}
                    onKeyDown={(e) => {if(e.key === 'Enter') document.getElementById('use_date')?.focus();}}>
                        <option value="프로젝트 관리비">프로젝트 관리비</option>
                        <option value="식대">식대</option>
                        <option value="차량유지비">차량유지비</option>
                        <option value="기타">기타</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel controlId='use_date' label="일자" className='mb-3'>
                    <Form.Control type="date" value={formData.use_date} name='use_date' onChange={handleInputChange} />
                </FloatingLabel>
                <FloatingLabel controlId='use_history' label="사용내역" className='mb-3'>
                    <Form.Control type="textBox" value={formData.use_history} name='use_history' onChange={handleInputChange}
                    onKeyDown={(e) => {if(e.key === 'Enter') document.getElementById('use_user')?.focus();}} autoComplete='true' />
                </FloatingLabel>
                <FloatingLabel controlId='use_user' label="사용내역(당사자)" className='mb-3'>
                    <Form.Control type="textBox" value={formData.use_user} name='use_user' onChange={handleInputChange}
                    onKeyDown={(e) => {if(e.key === 'Enter') document.getElementById('use_place')?.focus();}} autoComplete='true' />
                </FloatingLabel>
                <FloatingLabel controlId='use_place' label="사용처" className='mb-3'>
                    <Form.Control type="textBox" value={formData.use_place} name='use_place' onChange={handleInputChange}
                    onKeyDown={(e) => {if(e.key === 'Enter') document.getElementById('price')?.focus();}} autoComplete='true' />
                </FloatingLabel>
                <FloatingLabel controlId='price' label="금액" className='mb-3'>
                    <Form.Control type="textBox" pattern="[0-9]+" value={formData.price} name='price' onChange={handleInputChange}
                    onKeyDown={(e) => {if(e.key === 'Enter') document.getElementById('proof')?.focus();}} autoComplete='false' />
                </FloatingLabel>
                <FloatingLabel controlId='proof' label="증빙명" className='mb-3'>
                    <Form.Select name='proof' value={formData.proof} onChange={handleInputChange}>
                        <option value="개인카드">개인카드</option>
                        <option value="현금">현금</option>
                        <option value="법인카드">법인카드</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel controlId='note' label="비고" className='mb-3'>
                    {formData.imagePreviewUrl ? (
                        <Form.Control
                            type="textBox"
                            name="note"
                            value={formData.note ? formData.note : ''}
                            readOnly
                            onClick={() => {
                                if (formData.imagePreviewUrl) {
                                    setShowModal(true);
                                }
                            }}
                            autoComplete='off'
                        />
                    ) : (
                        <Form.Control
                            type="textBox"
                            name="note"
                            value={formData.note ? formData.note : ''}
                            onChange={handleInputChange}
                            autoComplete='off'
                        />
                    )}
                </FloatingLabel>
                <input id='imageUpload1' type="file" capture="environment" style={{ display: 'none' }} onChange={handleImageUpload}/>
                <input id='imageUpload2' type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageUpload}/>
                <Modal.Footer>
                    <Button
                        id='imageUploadBtn'
                        type="button"
                        onClick={() => {
                            if(!isMobile) {
                                document.getElementById('imageUpload1')?.click();
                            } else {
                                setShowModal2(true);
                            }
                        }}
                    >사진 업로드
                    </Button>
                    {variable == null ? (
                        <>
                            <Button className='margin-left-5' variant='secondary' onClick={handleFormSubmit}>저장</Button>
                        </>
                        ) : (
                            <>
                            <Button className='margin-left-5' variant='secondary' onClick={handleFormSubmit}>수정</Button>
                            <Button className='margin-left-5' variant='danger' onClick={deleteReceipt}>삭제</Button>
                            <Button variant='secondary' onClick={() => {setShow(false);}}>닫기</Button>
                        </>
                        )
                        
                    }
                </Modal.Footer>
            </Form>
            <PopupShow show={showModal} setShow={setShowModal} formData={formData}/>
            <FileUploadChoose show={showModal2} setShow={setShowModal2} />
        </>
    );

}

export default ReceiptForm;