import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import MemberModal from "./MemberModal";
import { ReactElement } from "react";
import AlertModal from "../../module/AlertModal";

interface MemberProps {
    show: boolean,
    setShow: Function
}

function MemberManagement({show, setShow}: MemberProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isPage = location.pathname === '/exter/members';

    function closeModal() {
        if (isPage) {
            navigate('/exter/board');
        } else {
            setShow(false);
        };
    }

    function modalWrap(content: any) {
        return (
            <Modal show={show} onHide={() => closeModal()}
                size="xl" aria-labelledby="contained-modal-title-vcenter" 
                backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        인력 관리
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
            </Modal>
        )
    }
    const rtn: ReactElement = modalWrap(<MemberModal show={show} closeFunc={closeModal} />);
    if (sessionStorage.getItem('priv_cd') !== 'EXTER_APPROVER') {
        return <AlertModal show={true} setShow={() => false} title="" body="권한이 없습니다." closeFunc={() => navigate('/', {replace: true})} />;
    } else if (!isDesktop) {
        return (
            <div className="content datatable-content">
                <MemberModal show={show} closeFunc={closeModal} />
            </div>
        )
    } else {
        return rtn;
    }
}

export default MemberManagement;