import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CellProps } from 'react-table';
import DataTableV2 from '../../module/DataTableV2';


function HolidaySettings() {
    // 데이터
    const [holidayList, setHolidayList] = useState<Holiday[]>([]);
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState<string>('01');
    const [selectedDay, setSelectedDay] = useState<string>('01');
    const [inputHolidayDesc, setInputHolidayDesc] = useState<string>('');
    const [selectedHolidaySetting, setSelectedHolidaySetting] = useState<Holiday>({
        holiday_date: '',
        holiday_desc: '',
        modify_holiday_date: ''
    });

    // 날짜
    const currentYear = new Date().getFullYear();
    const yearOptions = Array.from({ length: currentYear + 3 - 2014 }, (_, i) => 2015 + i);
    const months: string[] = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
    const monthOptions: string[] = months.map((month) => month.padStart(2, '0'));
    const dates: string[][] = monthOptions.map((month) => {
        return Array.from({ length: new Date(selectedYear, parseInt(month), 0).getDate() }, (_, i) => (i + 1).toString().padStart(2, '0'));
    });
    let dateOptions: string[] = dates[0];
    const [dayOptions, setDayOptions] = useState<string[]>(dates[0]);

    // 모달
    const [show, setShow] = useState(false);
    const [modalType, setModalType] = useState<string>();
    const [loading, setLoading] = useState(true);

    const detail_columns = [
        {
            Header: '휴일 이름',
            accessor: 'holiday_desc'
        },
        {
            Header: '일자',
            accessor: 'month_day'
        },
        {
            Header: '삭제',
            accessor: 'option1',
            Cell: ({ row }: CellProps<Holiday>) => (
                <Button size="sm" onClick={(e) => deleteModal(row.original)}>
                    삭제
                </Button>
            ),
        },
    ];

    // 연도 변경 감지
    const handleYearChange = async (e: React.ChangeEvent<any>) => {
        setSelectedYear(e.target.value);
        const data = await fetchPosts(e.target.value);
        setHolidayList(data);
        setLoading(false);
    };
    // 월 변경 감지
    const handleMonthChange = (month: number) => {
        setSelectedMonth(month.toString().padStart(2, '0')); // month 선택
        let maxDate = dates[month - 1][dates[month - 1].length - 1]; // 선택한 month의 최대 날짜

        dateOptions = Array.from({ length: parseInt(maxDate) }, (_, i) => (i + 1).toString().padStart(2, '0')); // day 배열
        setDayOptions(dateOptions); // 선택한 month의 day 배열로 설정
        if (Number(selectedDay) > dateOptions.length) setSelectedDay('01'); // 선택된 month의 day가 기존에 선택된 day보다 작을 경우 01
    };
    // input 변경
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputHolidayDesc(e.target.value);
    }
    // 일 변경
    const handleDayChange = (e: React.ChangeEvent<any>) => {
        setSelectedDay(e.target.value);
    }

    // 
    useEffect(() => {
        const loadPosts = async () => {
            const data = await fetchPosts(selectedYear);
            setHolidayList(data);
            setLoading(false);
        };
        loadPosts();
        setDayOptions(dates[0])
    }, []);

    // 연도 선택박스 선택 > 데이터 불러오기
    const fetchPosts = async (year: number) => {
        const response = await axios.post('/api/admin/selectHolidaySettings', { holiday_year: year }, { headers: { "Content-Type": "application/json" } });
        const data = await response.data;
        const addData = data.map((item: { holiday_month: string; holiday_day: string; }) => ({
            ...item,
            month_day: `${item.holiday_month}월 ${item.holiday_day}일`
        }));
        return addData;
    };

    // 공휴일 추가
    const insertFunc = async () => {
        const checkDate = holidayList.some(holiday => holiday.holiday_date === selectedYear.toString() + selectedMonth + selectedDay);
        if (inputHolidayDesc === '') {
            toast("공휴일 이름을 입력해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        else if (checkDate) {
            toast("다른 날짜를 입력해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        await axios.post('/api/admin/insertHolidaySetting',
            {
                holiday_date: selectedYear.toString() + selectedMonth + selectedDay,
                holiday_desc: inputHolidayDesc,
                reg_user_id: sessionStorage.getItem("user_id")
            }, { headers: { "Content-Type": "application/json" } });
        const data = await fetchPosts(selectedYear);
        setHolidayList(data);
        setLoading(false);
        setShow(false);
    }

    // 테이블 공휴일 수정 모달
    const updateModal = async (vo: Holiday) => {
        // 선택된 공휴일 데이터 포함 모달 열기
        setSelectedHolidaySetting(vo);
        setInputHolidayDesc(vo.holiday_desc);
        setModalType('update');
        setShow(true);
        setSelectedDay(vo.holiday_date.substring(6, 8));
        handleMonthChange(parseInt(vo.holiday_date.substring(4, 6)));
    }
    // 공휴일 수정
    const updateFunc = async () => {
        const checkDate = holidayList.some(holiday => holiday.holiday_date === selectedYear.toString() + selectedMonth + selectedDay);
        if (inputHolidayDesc === '') {
            toast("공휴일 이름을 입력해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        else if (inputHolidayDesc === selectedHolidaySetting.holiday_desc && checkDate) {
            toast("같은 공휴일이 존재합니다.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        await axios.post('/api/admin/updateHolidaySetting',
            {
                modify_holiday_date: selectedYear.toString() + selectedMonth + selectedDay,
                holiday_date: selectedHolidaySetting.holiday_date,
                holiday_desc: inputHolidayDesc,
                edit_user_id: sessionStorage.getItem("user_id")
            }, { headers: { "Content-Type": "application/json" } })
        const data = await fetchPosts(selectedYear);
        setHolidayList(data);
        setLoading(false);
        setShow(false);
    }
    // 공휴일 삭제 모달
    const deleteModal = async (vo: Holiday) => {
        setSelectedHolidaySetting(vo)
        setModalType('delete');
        setShow(true);
    }
    // 공휴일 삭제
    const deleteFunc = async () => {
        await axios.post('/api/admin/deleteHolidaySetting', { holiday_date: selectedHolidaySetting.holiday_date }, { headers: { "Content-Type": "application/json" } })
        const data = await fetchPosts(selectedYear);
        setHolidayList(data);
        setLoading(false);
        setShow(false);
    }

    let buttonFlag = true;
    const getGoogleCalendar = async () => {
        if (buttonFlag) {
            buttonFlag = false;
            await axios.post('/api/holiday/googleCalendarGet', null, { params: { "year": selectedYear } }).then(async _ => {
                toast("공휴일 설정이 완료 되었습니다.", { position: 'top-center', autoClose: 2000 });
                buttonFlag = true;
                const data = await fetchPosts(selectedYear);
                setHolidayList(data);
                setLoading(false);
                setShow(false);
            });
        } else {
            toast("공휴일 자동 생성 중 입니다.", { position: 'top-center', autoClose: 2000 });
        }
    }

    return (
        <div className='content'>
            <h2>공휴일 설정</h2>
            <div className='d-flex justify-content-start gap-1'>
                <Form.Select className='w-auto' id="selectedYear" value={selectedYear} onChange={(e) => handleYearChange(e)}>
                    {yearOptions?.map((item) =>
                        <option key={item} value={item}>{item}</option>
                    )}
                </Form.Select>
                <Button onClick={(e) => { setShow(true); setModalType('add'); setInputHolidayDesc(''); setSelectedMonth('01'); setSelectedDay('01'); }}>추가</Button>
                <Button onClick={(e) => { getGoogleCalendar() }}>공휴일 자동 지정</Button>
            </div>
            <div style={{ marginTop: '5px' }}>
                <DataTableV2 loading={loading} columns={detail_columns} data={holidayList} sort={[]} setSortDir={() => { }} onRowClick={(vo) => updateModal(vo)} />
            </div>
            {/* 모달 */}
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalType === 'update' ? '공휴일 수정' : modalType === 'add' ? '공휴일 추가' : '공휴일 삭제'}</Modal.Title>
                </Modal.Header>
                {modalType !== 'delete' ?
                    <Modal.Body>
                        <Row>
                            <Col xs={4}><span>공휴일명</span></Col>
                            <Col xs={8}><Form.Control type='text' name='holidayName' value={inputHolidayDesc} onChange={handleInputChange} /></Col>
                        </Row>
                        <Row>
                            <Col xs={4}><span>일자</span></Col>
                            <Col xs="auto">
                                <Form.Select value={selectedMonth} onChange={(e) => handleMonthChange(Number(e.target.value))}>
                                    {monthOptions?.map((item) =>
                                        <option key={item} value={item}>{item}</option>
                                    )}
                                </Form.Select>
                            </Col>
                            <Col xs="auto" style={{ marginLeft: '-19px', marginRight: '-10px' }}><span>월</span></Col>
                            <Col xs="auto">
                                <Form.Select value={selectedDay} onChange={(e) => handleDayChange(e)}>
                                    {dayOptions?.map((item) =>
                                        <option key={item} value={item}>{item}</option>
                                    )}
                                </Form.Select>
                            </Col>
                            <Col xs="auto" style={{ marginLeft: '-19px' }}><span>일</span></Col>
                        </Row>
                    </Modal.Body> : <Modal.Body>삭제하시겠습니까?</Modal.Body>}
                <Modal.Footer>
                    {modalType === 'add' ?
                        <Button onClick={insertFunc} style={{ width: '70px' }}>추가</Button>
                        : modalType === 'update' ?
                            <Button onClick={updateFunc} style={{ width: '70px' }}>수정</Button>
                            :
                            <Button onClick={deleteFunc} style={{ width: '70px' }}>삭제</Button>
                    }
                    <Button variant="secondary" onClick={() => setShow(false)} style={{ width: '70px' }}>취소</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

type Holiday = {
    holiday_desc: string;
    holiday_date: string;
    modify_holiday_date?: string;
    [key: string]: any;
}

export default HolidaySettings;

