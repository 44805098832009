import { Button, Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import DataTableV2 from "../../module/DataTableV2";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Paginate from "../../module/Paginate";
import Loading from "../../module/Loading";
import axios from "axios";
import { MdArrowBack } from "react-icons/md";

interface InquiryProps {
    bbs_id: string,
    ntt_id: string,
    show: boolean,
    closeFunc: Function
}

function InquiryListPage({show, bbs_id, ntt_id, closeFunc}: InquiryProps) {

    const isDesktop = useMediaQuery({ minWidth: 768 });

    //큰 화면용 list를 위한 변수
    const [detailDoc, setDetailDoc] = useState<InquiryVO[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const rowSize = 10;
    const [pageCount, setPageCount] = useState(0);
    
    //작은 화면용 list를 위한 변수
    const [simpleDoc, setSimpleDoc] = useState<InquiryVO[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    
    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    //문서 리스트 검색 함수
    const selectDocList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const sortStr = sort[0];
        const sortDir = sort[1];
        const param = {
            'bbs_id' : bbs_id,
            'ntt_id' : ntt_id,
            "currPage": currPage,
            "rowSize": rowSize,
            "sortStr": sortStr,
            "sortDir": sortDir
        }
        const response = await axios.post(
            '/api/board/getInquiryList',
            param,
            { headers: { "Content-Type": 'application/json' } }
        );
        const data = await response.data;
        if (isDetail) {
            setDetailDoc(data.content);
            setPageCount(data.totalPages);
        } else {
            if (currPage)
                setSimpleDoc(prevPosts => [...prevPosts, ...data.content]);
            else
                setSimpleDoc(data.content);
            setHasMore(!data.last);
        }
        setLoading(false);
    };

    function search() {
        const loadPosts = async () => {
            if (detailPage === 0) {
                selectDocList(0, rowSize, true);
            } else {
                setDetailPage(0);
            }
            setSimpleDoc([]);
            setHasMore(true);
            if (simplePage === 0) {
                selectDocList(0, 25, false);
            } else {
                setSimplePage(0);
            }
        };
        loadPosts();
    }

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDocList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDocList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        search();
    }, [sort]);

    useEffect(() => {
        if (show && bbs_id && ntt_id)
            search();
    }, [show, bbs_id, ntt_id]);
    
    return (
        <>
            {isDesktop ? (
                <>
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailDoc} sort={sort} setSortDir={setSortDir} />
                    {pageCount !== 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (<>
                    <Row>
                        <Col xs={10}>
                            <h4>조회 목록</h4>
                        </Col>
                        <Col xs={2}>
                            <Button id="menu" className="btn-sm" aria-label="Close" onClick={() => closeFunc()}><MdArrowBack /></Button>
                        </Col>
                    </Row>
                    <hr />
                    <InfiniteScroll
                        dataLength={simpleDoc.length}
                        next={loadMore}
                        hasMore={hasMore}
                        loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                    >
                        <DataTableV2 loading={loading} columns={simple_columns} data={simpleDoc} sort={sort} setSortDir={setSortDir} />
                    </InfiniteScroll>
                </>
            )}
        </>
    );
    
}

const detail_columns = [
    {
        Header: '이름',
        accessor: 'register'
    },
    {
        Header: '부서',
        accessor: 'dept_nm'
    },
    {
        Header: '직급',
        accessor: 'position_nm'
    },
    {
        Header: '조회일자',
        accessor: 'rgsde'
    }
];

const simple_columns = [
    {
        Header: '이름',
        accessor: 'register'
    },
    {
        Header: '부서',
        accessor: 'dept_nm'
    },
    {
        Header: '조회일자',
        accessor: 'rgsde'
    }
];

type InquiryVO = {
    [key: string]: any;
}

export default InquiryListPage;