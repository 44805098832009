import axios from "axios";
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase-config";


export default async function login(id: String, pw: String, auto_login: Boolean) {
    let flag = 1;
    await axios.post('/api/exter/login', { "user_id": id, "user_pw": pw }, { headers: { "Content-Type": 'application/json' } })
        .then(async response => {
            // Handle successful login
            const rtn = response.data;
            if (rtn.result === "success") {
                sessionStorage.setItem("user_nm", rtn.user_nm);
                sessionStorage.setItem("user_id", rtn.user_id);
                sessionStorage.setItem("mobile_phone", rtn.mobile_phone);
                sessionStorage.setItem("email", rtn.email);
                sessionStorage.setItem("approver", rtn.approver);
                sessionStorage.setItem("priv_cd", rtn.priv_cd);
                sessionStorage.setItem("extern_yn", rtn.extern_yn);
                //autologin 처리
                if (auto_login) {
                    localStorage.setItem("user_id", rtn.user_id);
                    localStorage.setItem("user_pw", rtn.user_pw);
                    localStorage.setItem("login_token",rtn.login_token);
                }
                const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                //앱으로 실행 + 모바일일때만 token 전송(fcm push 전송을 위해)
                if((window.matchMedia('(display-mode: standalone)').matches) && isMobile){
                    await getToken(messaging, { vapidKey: "BLV84-HykXpnEsjGC-m-Og_zXVUakOPpmzHb18c_Jn-jaUBNMEyGFOO5EH8OXbC0QNBBA1ZZi_5PDzXrHlQP9ME" })
                    .then(async (token) => {
                        await axios.post('/api/insert_fcm_token', { "user_id": id, "fcm_token": token, extern_yn: 'Y'})
                    })
                    .catch(() => {
                        //token은 FCM이 안되는 환경에서 실패할수 있으므로 성공하나 실패하나 메인페이지로 넘어감
                    });
                }
                flag = 0;
            } else if (rtn.result === "failed") {
                flag = 2;
            }
        })
        .catch(error => {
            // Handle login failure
            console.error('Failed login');
        });
    return flag;
};