import '../../css/exter/Board.css'
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import Paginate from '../../module/Paginate';
import Loading from '../../module/Loading';
import ExterAppr from './Appr';
import { useLocation, useNavigate } from 'react-router-dom';
import MemberManagement from './MemberManagement';
import MyPage from './MyPage';
import DataTableV2 from '../../module/DataTableV2';
import SearchBarV2 from '../../module/SearchBarV2';
import { Col, Form } from 'react-bootstrap';

function ExterBoard() {

    const navigate = useNavigate();
    const [vacId, setVacId] = useState('');
    const [showApprModal, setShowApprModal] = useState(false);
    const [showMypageModal, setShowMypageModal] = useState(false);
    const [showMembersModal, setShowMembersModal] = useState(false);

    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });


    //큰 화면용 list를 위한 변수
    const [detailDoc, setDetailDoc] = useState<DetailDoc[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    
    //작은 화면용 list를 위한 변수
    const [simpleDoc, setSimpleDoc] = useState<SimpleDoc[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    
    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);
    
    //searchMap에는 검색어와 검색컬럼이 SearchBar에서 세팅된다.
    //검색어는 SearchBar에 searchstr로 지정되어있으므로 바꿀수 없고,
    //select box 선택시 검색컬럼 지정은 하위 selectTypes의 key 에 매핑(해당페이지는 searchCol)
    const [searchMap, setSearchMap] = useState<Map<string, string>>(() => {
        const map = new Map();
        map.set('searchStr', '');
        map.set('searchKey', 'BSH001');
        map.set('searchYear', '');
        map.set('searchMonth', '');
        return map;
    });

    //문서 리스트 검색 함수
    const selectDocList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const searchStr = searchMap.get("searchStr") ? searchMap.get("searchStr") : (document.querySelector('[name="searchStr"]') as HTMLInputElement).value;
        const searchCol = searchMap.get("searchCol") ? searchMap.get("searchCol") : (document.querySelector('select[name="searchCol"]') as HTMLSelectElement).value;
        const docStatus = searchMap.get("docStatus") ? searchMap.get("docStatus") : (document.querySelector('select[name="docStatus"]') as HTMLSelectElement).value;
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/exter/selectDocList',
            {
                "currPage": currPage,
                "rowSize": rowSize,
                "searchStr": searchStr,
                "searchCol": searchCol,
                "docStatus": docStatus,
                "user_id" : sessionStorage.getItem('user_id'),
                "approverYn": sessionStorage.getItem('priv_cd')?.includes('APPROVER') ? 'Y' : 'N',
                "sortStr": sortStr,
                "sortDir": sortDir
            },
            { headers: { "Content-Type": 'application/json' } }
        );
        const data = await response.data;
        if (isDetail) {
            setDetailDoc(data.content);
            setPageCount(data.totalPages);
            setRowCount(data.rowCount);
        } else {
            if (currPage)
                setSimpleDoc(prevPosts => [...prevPosts, ...data.content]);
            else
                setSimpleDoc(data.content);
            setHasMore(!data.last);
        }
        setLoading(false);
    };

    function search() {
        const loadPosts = async () => {
            if (detailPage === 0) {
                selectDocList(0, rowSize, true);
            } else {
                setDetailPage(0);
            }
            setSimpleDoc([]);
            setHasMore(true);
            if (simplePage === 0) {
                selectDocList(0, 25, false);
            } else {
                setSimplePage(0);
            }
        };
        loadPosts();
    }

    const location = useLocation();

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDocList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        if (location.state) {
            const map = location.state.searchMap ? location.state.searchMap : new Map<string, string>();
            
            if (!map.get('searchStr'))
                map.set('searchStr', '');
            if (!map.get('docStatus'))
                map.set('docStatus', 'all');
            if (!map.get('searchCol'))
                map.set('searchCol', 'vac_title');
            (document.querySelector('[name="searchStr"]') as HTMLInputElement).value = String(map.get('searchStr'));
            (document.querySelector('[name="searchCol"]') as HTMLSelectElement).value = String(map.get('searchCol'));
            (document.querySelector('[name="docStatus"]') as HTMLSelectElement).value = String(map.get('docStatus'));
            setSearchMap(map);
        }
        const loadPosts = async () => {
            selectDocList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        search();
    }, [sort]);

    useEffect(() => {
        if (!!location.state?.show) {
            navigate('/exter/board');
            if (location.state.pageName === 'appr') {
                setVacId('');
                setShowApprModal(location.state.show);
            } else if (location.state.pageName === 'members') {
                setShowMembersModal(location.state.show);
            } else if (location.state.pageName === 'mypage') {
                setShowMypageModal(location.state.show);
            }
        }
    }, [location.state?.show])


    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    return (
        <div className='content datatable-content'>
            <SearchBarV2
                setRowSize={setRowSize}
                rowCount={rowCount}
                searchMap={searchMap}
                setSearchMap={setSearchMap}
                search={search}
                leftChildren={
                    <>
                        <Col sm="auto" key="docStatus" >
                            <Form.Select name="docStatus" defaultValue={searchMap.get("docStatus")} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option key="all" value="all">전체</option>
                                <option key="W" value="W">대기</option>
                                <option key="A" value="A">승인</option>
                                <option key="R" value="R">반려</option>
                            </Form.Select>
                        </Col>
                        <Col sm="auto" key="searchCol" >
                            <Form.Select name="searchCol" defaultValue={searchMap.get("searchCol")} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option key="vac_title" value="vac_title">문서제목</option>
                                <option key="vac_drafter" value="vac_drafter">기안자</option>
                            </Form.Select>
                        </Col>
                    </>
                } />
            {isDesktop ? (
                <>
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailDoc} sort={sort} setSortDir={setSortDir} onRowClick={(detailDoc) => {setVacId(detailDoc.vac_id); setShowApprModal(true);}} />
                    {pageCount !== 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleDoc.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={simple_columns} data={simpleDoc} sort={sort} setSortDir={setSortDir}
                        onRowClick={(simpleDoc) => navigate('/exter/appr', {state: {
                                                                                        vac_id: simpleDoc.vac_id,
                                                                                        show: true,
                                                                                        currPage: simplePage,
                                                                                        rowSize: rowSize,
                                                                                        isDetail: false,
                                                                                        searchMap: searchMap
                                                                                    }, replace: true})}
                         />
                </InfiniteScroll>
            )}
            {isDesktop && (
                <>
                    <ExterAppr 
                        show={showApprModal} 
                        setShow={setShowApprModal}
                        vac_id={vacId} 
                        searchFunc={selectDocList} 
                        currPage={detailPage} 
                        rowSize={rowSize} 
                        isDetail={true} />
                    <MyPage show={showMypageModal} setShow={setShowMypageModal} />
                </>
            )}
            {isDesktop && sessionStorage.getItem('priv_cd') === 'EXTER_APPROVER' && 
                <MemberManagement 
                    show={showMembersModal} 
                    setShow={setShowMembersModal} />
            }
        </div>
    );
}
//큰화면에서 DataTable에서 사용하는 데이터의 형식 (최하단의 [key: string]: any; 는 공통로직에서 key값에 map으로 접근하기 위해 필수)
type DetailDoc = {
    vac_id: string;
    vac_type: string;
    vac_title: string;
    vac_status: string;
    vac_date: string;
    vac_drafter: string;
    vac_approver: string;
    vac_draft_date: string;
    vac_appr_date: string;
    [key: string]: any;
}

//작은화면에서 DataTable에서 사용하는 데이터의 형식 (최하단의 [key: string]: any; 는 공통로직에서 key값에 map으로 접근하기 위해 필수)
type SimpleDoc = {
    vac_id: string;
    vac_title: string;
    vac_status: string;
    vac_draft_date: string;
    [key: string]: any;
}

//큰화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
//DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
const detail_columns = [
    {
        Header: '문서종류',
        accessor: 'vac_type'
    },
    {
        Header: '문서제목',
        accessor: 'vac_title'
    },
    {
        Header: '문서상태',
        accessor: 'vac_status'
    },
    {
        Header: '휴가일',
        accessor: 'vac_date'
    },
    {
        Header: '기안자',
        accessor: 'vac_drafter'
    },
    {
        Header: '결재자',
        accessor: 'vac_approver'
    },
    {
        Header: '기안일',
        accessor: 'vac_draft_date'
    },
    {
        Header: '결재일',
        accessor: 'vac_appr_date'
    }
];

//작은화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
//DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
const simple_columns = [
    {
        Header: '문서제목',
        accessor: 'vac_title'
    },
    {
        Header: '기안자',
        accessor: 'vac_drafter'
    },
    {
        Header: '상태',
        accessor: 'vac_status'
    },
    {
        Header: '기안일',
        accessor: 'vac_draft_date'
    }
];

export default ExterBoard;