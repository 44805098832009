import '../../../css/appr/Board.css'

import { useEffect, useRef, useState } from "react";
import { Button, Form, Table, Row, Col, Nav } from "react-bootstrap";
import axios from 'axios';
import { DeptTree } from '../../cmn/ViewProxyUserInfo';
import { toast, ToastContainer } from 'react-toastify';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { TreeComponent } from '../../../module/TreeViewParentIDSelect';

interface ApprProps {
    vac_id: string,
    show: boolean,
    closeFunc: Function,
    saveFunc: Function,
    mode: boolean,
    recentApprList: recentApprListVO[],
    actionstatus: String,
    signUserList: signUserListVO[],
    recvList: recentRecvListVO[],
    readList: recentReadListVO[],
    frm_id: any,
}
// 기안작성 > 결재진행함 모듈
function ApprLine({ vac_id, show, closeFunc, saveFunc, mode, recentApprList, actionstatus, signUserList, recvList, readList, frm_id }: ApprProps) {
    const location = useLocation();
    const paramsD = new URLSearchParams(location.state?.menu_var);

    const location_id = location.state?.id !== undefined ? location.state?.id : frm_id;
    const [treeData, setTreeData] = useState<DeptTree[]>([]);
    const [checkBackGroundColorId, setCheckBackGroundColorId] = useState("");
    const [line_text, setline_text] = useState("");
    const [paramUser, setParamUser] = useState(new Map<string, string>());
    const [countindex, setCountindex] = useState("");
    const [dept_test, setDept] = useState<DeptVO[]>([]);
    const [recv_list, setRecvList] = useState<recentRecvListVO[]>([]);
    const [read_list, setReadList] = useState<recentReadListVO[]>([]);
    const scrollRef = useRef<HTMLDivElement>(null);
    let rtn = false

    //결재자 추가
    const addDept = (signTpCd: string, signTpDesc: string, userId: string, user: string,
        positNm: string, positCd: string, deptNm: string, deptCd: string, fileUpdeAuthYn: string,
        lineUpdeAuthYn: string, docUpdeAuthYn: string): void => {
        const newDept: DeptVO = {
            signTpCd, signTpDesc, userId, user, positNm, positCd, deptNm,
            deptCd, fileUpdeAuthYn, lineUpdeAuthYn, docUpdeAuthYn
        };
        setDept((prevDept) => [...prevDept, newDept]);
    };
    //수신자 추가
    const addrecvList = (RECV_TP_CD: string,
        RECV_DEPT_CD: string, RECV_DEPT_NM: string,
        RECV_USER_ID: string, RECV_USER_NM: string,
        RECV_USER_POSIT_CD: string, RECV_USER_POSIT_NM: string): void => {
        const newRecv: recentRecvListVO = { RECV_TP_CD, RECV_DEPT_CD, RECV_DEPT_NM, RECV_USER_ID, RECV_USER_NM, RECV_USER_POSIT_CD, RECV_USER_POSIT_NM };
        setRecvList((prevRecv) => [...prevRecv, newRecv]);
    };

    //참조자 추가
    const addreadList = (READ_TP_CD: string,
        READ_USER_ID: string,
        READ_USER_POSIT_CD: string,
        READ_USER_POSIT_NM: string,
        READ_USER_DEPT_CD: string,
        READ_USER_DEPT_NM: string,
        READ_USER_NM: string,
        READ_DEPT_NM: string,
        READ_DEPT_CD: string): void => {
        const newRead: recentReadListVO = { READ_TP_CD, READ_USER_ID, READ_USER_POSIT_CD, READ_USER_POSIT_NM, READ_USER_DEPT_CD, READ_USER_DEPT_NM, READ_USER_NM, READ_DEPT_NM, READ_DEPT_CD };
        setReadList((prevRecv) => [...prevRecv, newRead]);
    };

    useEffect(() => {
        if (Array.isArray(recentApprList) && recentApprList.length > 0) {
            recentApprList?.forEach((element, index) => {

                addDept(recentApprList[index].SIGN_TP_CD ? recentApprList[index].SIGN_TP_CD : recentApprList[index].sign_tp_cd,
                    recentApprList[index].SIGN_TP_NM ? recentApprList[index].SIGN_TP_NM : recentApprList[index].sign_tp_nm,
                    recentApprList[index].SIGN_USER_ID ? recentApprList[index].SIGN_USER_ID : recentApprList[index].sign_user_id,
                    recentApprList[index].SIGN_USER_NM ? recentApprList[index].SIGN_USER_NM : recentApprList[index].sign_user_nm,
                    recentApprList[index].SIGN_USER_POSIT_NM ? recentApprList[index].SIGN_USER_POSIT_NM : recentApprList[index].sign_user_posit_nm,
                    recentApprList[index].SIGN_USER_POSIT_CD ? recentApprList[index].SIGN_USER_POSIT_CD : recentApprList[index].sign_user_posit_cd,
                    recentApprList[index].SIGN_USER_DEPT_NM ? recentApprList[index].SIGN_USER_DEPT_NM : recentApprList[index].sign_user_dept_nm,
                    recentApprList[index].SIGN_USER_DEPT_CD ? recentApprList[index].SIGN_USER_DEPT_CD : recentApprList[index].sign_user_dept_cd,
                    recentApprList[index].FILE_UPDE_AUTH_YN ? recentApprList[index].FILE_UPDE_AUTH_YN : recentApprList[index].file_upde_auth_yn,
                    recentApprList[index].LINE_UPDE_AUTH_YN ? recentApprList[index].LINE_UPDE_AUTH_YN : recentApprList[index].line_upde_auth_yn,
                    recentApprList[index].DOC_UPDE_AUTH_YN ? recentApprList[index].DOC_UPDE_AUTH_YN : recentApprList[index].doc_upde_auth_yn,);
            })

        }

        if (Array.isArray(recvList) && recvList.length > 0) {
            recvList?.forEach((element, index) => {
                addrecvList(recvList[index].RECV_TP_CD, recvList[index].RECV_DEPT_CD,
                    recvList[index].RECV_DEPT_NM, recvList[index].RECV_USER_ID,
                    recvList[index].RECV_USER_NM, recvList[index].RECV_USER_POSIT_CD,
                    recvList[index].RECV_USER_POSIT_NM);
            })

        }
        if (Array.isArray(readList) && readList.length > 0) {
            readList?.forEach((element, index) => {
                addreadList(readList[index].READ_TP_CD, readList[index].READ_USER_ID,
                    readList[index].READ_USER_POSIT_CD, readList[index].READ_USER_POSIT_NM,
                    readList[index].READ_USER_DEPT_CD, readList[index].READ_USER_DEPT_NM,
                    readList[index].READ_USER_NM, readList[index].READ_DEPT_NM, readList[index].READ_DEPT_CD);
            })

        }

        const getData_main = async () => {
            try {
                const response = await axios.post(
                    "/api/cmn/setting/selectDeptTree.axios",
                    {},
                    { headers: { "Content-Type": "application/json" } }
                );
                const rtn = response.data;

                setTreeData(rtn);
            } catch (error) {
                alert("Failed axios : ");
            } finally {

            }
        };

        getData_main();
    }, []);

    const handleButtonClick = (user_id: string, dept_nm: string) => {

        if (checkBackGroundColorId !== user_id && user_id !== null) {

            const myDiv_old = document.getElementById(checkBackGroundColorId);
            const myDiv_new = document.getElementById(user_id);

            if (myDiv_old) {
                myDiv_old.style.backgroundColor = "white";
            }
            if (myDiv_new) {
                myDiv_new.style.backgroundColor = "#f3f3f3";
            }
            setCheckBackGroundColorId(user_id);
        } else {

            const myDiv_old = document.getElementById(checkBackGroundColorId);
            const myDiv_new = document.getElementById(dept_nm);

            if (myDiv_old) {
                myDiv_old.style.backgroundColor = "white";
            }
            if (myDiv_new) {
                myDiv_new.style.backgroundColor = "#f3f3f3";
            }
            setCheckBackGroundColorId(dept_nm);
        }


    };

    const deleteButton = (countindex: any) => {
        if (countindex === null || countindex === '') {
            toast("삭제할 기안자를 선택해주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        const deleteUser = dept_test[countindex].user;
        if (countindex > 0) {
            setDept((prevDept) => prevDept.filter((dept_test) => dept_test.user !== deleteUser));
        } else {
            toast("초기 기안자는 수정 할 수 없습니다.", { position: 'top-center', autoClose: 2000 });
            return;
        }

    };

    const deleteRecvListButton = (countindex: any, text: string) => {
        let deleteRecvList: any;
        if (text === 'Recv') {
            if (recv_list[countindex].RECV_USER_ID === "") {
                deleteRecvList = recv_list[countindex].RECV_DEPT_CD;
                setRecvList((prevDept) => prevDept.filter((recv_list) => recv_list.RECV_DEPT_CD !== deleteRecvList));
            } else {
                deleteRecvList = recv_list[countindex].RECV_USER_ID;
                setRecvList((prevDept) => prevDept.filter((recv_list) => recv_list.RECV_USER_ID !== deleteRecvList));
            }
        } else {
            if (read_list[countindex].READ_USER_ID === "") {
                deleteRecvList = read_list[countindex].READ_USER_DEPT_CD;
                setReadList((prevDept) => prevDept.filter((read_list) => read_list.READ_USER_DEPT_CD !== deleteRecvList));
            } else {
                deleteRecvList = read_list[countindex].READ_USER_ID;
                setReadList((prevDept) => prevDept.filter((read_list) => read_list.READ_USER_ID !== deleteRecvList));
            }
        }


    };

    const apprUpEntry = (index: any) => {

        if (index <= 1) return; // 첫 번째 항목이면 아무것도 하지 않음
        setDept((prevLines) => {
            if (!prevLines) return [];

            const newLines = [...prevLines];
            // 순서 변경
            [newLines[index - 1], newLines[index]] = [newLines[index], newLines[index - 1]];
            return newLines;
        });
        handleClick(index - 1);
    };

    const apprDownEntry = (index: any) => {
        index = Number(index);
        setDept((prevLines) => {
            // prevLines가 undefined이거나 빈 배열인 경우, 아무 작업도 하지 않음
            if (!prevLines || index >= prevLines.length - 1) return prevLines;

            const newLines = [...prevLines];
            // 순서 변경
            if (newLines[index] && newLines[index + 1]) {
                [newLines[index], newLines[index + 1]] = [newLines[index + 1], newLines[index]];
            }
            return newLines;
        });
        handleClick(index + 1); // 다음 항목으로 이동한 후 이벤트 핸들링
    };

    // select 에 따라 결재선 업데이트
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        const newSignTpCd = event.target.value;
        let newSignTpDesc: string;
        if (newSignTpCd === 'AP') newSignTpDesc = '결재';
        else if (newSignTpCd === 'AB') newSignTpDesc = '전결';
        else if (newSignTpCd === 'AA') newSignTpDesc = '후결';
        else if (newSignTpCd === 'CF') newSignTpDesc = '확인';
        else if (newSignTpCd === 'NA') newSignTpDesc = '결재안함';

        setDept(prevDeptTest => {
            const updatedDeptTest = [...prevDeptTest];

            updatedDeptTest[index] = {
                ...updatedDeptTest[index],
                signTpCd: newSignTpCd, // 상태값 업데이트
                signTpDesc: newSignTpDesc,
            };
            return updatedDeptTest;
        });
    };

    //결재자 추가
    function selectButton(signTpCd: any, signTpDesc: any) {

        if (paramUser.get("user_nm") === null && signTpDesc !== '감사') {
            alert('대상을 지정해주세요.');
        } else {
            if (signTpDesc === '감사' && paramUser.get("user_nm") === null) {
                signTpCd = 'DSAD';
            }
            const textUserid: string = paramUser.get("user_id") || "";
            const textUser: string = paramUser.get("user_nm") || "";
            const textPositnm: string = paramUser.get("user_posit_nm") || "";
            const textPositcd: string = paramUser.get("user_posit_cd") || "";
            const texDeptnm: string = paramUser.get("dept_nm") || "";
            const texDeptcd: string = paramUser.get("dept_cd") || "";

            const checkUser = dept_test?.some(item => item && Object.values(item).includes(textUser));
            if (checkUser) {
                toast("중복된 대상이 있습니다. 다시 확인해 주세요.", { position: 'top-center', autoClose: 2000 });

            } else {

                addDept(signTpCd, signTpDesc, textUserid, textUser, textPositnm, textPositcd, texDeptnm, texDeptcd, 'N', 'N', 'N',);
                setRecvList((prevDept) => prevDept.filter((recv_list) => recv_list.RECV_USER_ID !== textUserid));
                setReadList((prevRead) => prevRead.filter((read_list) => read_list.READ_USER_ID !== textUserid));
            }
            if (scrollRef.current) {
                scrollRef.current.scrollTop = scrollRef.current.scrollHeight;  // 맨 아래로 스크롤
            }

            document.getElementById('deleteRecv')?.focus();
        }
    }

    //수신자 추가
    function selectRecvListButton(signTpCd: any, signTpDesc: any) {


        const textUserid: string = paramUser.get("user_id") || "";
        const textUser: string = paramUser.get("user_nm") || "";
        const textPositnm: string = paramUser.get("user_posit_nm") || "";
        const textPositcd: string = paramUser.get("user_posit_cd") || "";
        const texDeptnm: string = paramUser.get("dept_nm") || "";
        const texDeptcd: string = paramUser.get("dept_cd") || "";
        let checkRecv;
        let checkRead;
        if (textUser !== "") {
            checkRecv = dept_test?.some(item => item && Object.values(item).includes(textUser)) ||
                recv_list?.some(item => item && Object.values(item).includes(textUser));

            checkRead = dept_test?.some(item => item && Object.values(item).includes(textUser)) ||
                read_list?.some(item => item && Object.values(item).includes(textUser));
        } else {
            checkRecv = recv_list?.some(item => item && Object.values(item).includes(texDeptnm)) ||
                read_list?.some(item => item && Object.values(item).includes(texDeptnm));
        }

        if (checkRecv || checkRead) {
            toast("중복된 대상이 있습니다. 다시 확인해 주세요.", { position: 'top-center', autoClose: 2000 });

        } else {
            // 수신
            if (signTpCd === 'X') {
                if (textUserid === "") {
                    addrecvList('R2', texDeptcd, texDeptnm, textUserid, textUser, textPositcd, textPositnm);
                    setReadList((prevDept) => prevDept.filter((read_list) => read_list.READ_USER_DEPT_CD !== texDeptcd));
                } else {
                    addrecvList('R1', texDeptcd, texDeptnm, textUserid, textUser, textPositcd, textPositnm);
                    setReadList((prevDept) => prevDept.filter((read_list) => read_list.READ_USER_ID !== textUserid));
                }
                // 
            } else {
                if (textUserid === "") {
                    addreadList('R2', textUserid, textPositcd, textPositnm, texDeptcd, texDeptnm, textUser, texDeptnm, texDeptcd);
                    setRecvList((prevDept) => prevDept.filter((recv_list) => recv_list.RECV_DEPT_CD !== texDeptcd));
                } else {
                    addreadList('R1', textUserid, textPositcd, textPositnm, texDeptcd, texDeptnm, textUser, texDeptnm, texDeptcd);
                    setRecvList((prevDept) => prevDept.filter((recv_list) => recv_list.RECV_USER_ID !== textUserid));
                }
            }
        }


    }

    // 클릭 이벤트
    const handleClick = (index: number) => {
        if (index >= dept_test.length) {
            return;
        }
        if (line_text !== `${index}`) {
            const myDiv_old = document.getElementById(`${index}`);
            const myDiv_new = document.getElementById(line_text);

            if (myDiv_old) {
                myDiv_old.style.backgroundColor = "#f3f3f3";
            }
            if (myDiv_new) {
                myDiv_new.style.backgroundColor = "white";
            }
        } else {

        }
        setCountindex(`${index}`);
        setline_text(`${index}`);
    };

    // 수신/참조자 클릭 이벤트
    const handleRecvClick = (index: number, text: string) => {

        if (line_text !== `${text}_${index}`) {
            const myDiv_old = document.getElementById(`${text}_${index}`);
            const myDiv_new = document.getElementById(line_text);

            if (myDiv_old) {
                myDiv_old.style.backgroundColor = "#f3f3f3";
            }
            if (myDiv_new) {
                myDiv_new.style.backgroundColor = "white";
            }
        } else {

        }
        setCountindex(`${index}`);
        setline_text(`${text}_${index}`);
    };

    function test() {
        rtn = true;


        const abItems: number[] = [];
        let aaCounter = 0;
        let sadCounter = 0;

        dept_test.forEach((element, index) => {
            if (element.signTpCd === "AB" || element.signTpCd === "AB") {
                abItems.push(index);
            } else if (element.signTpCd === "AA" || element.signTpCd === "AA") {
                aaCounter++;
            } else if (element.signTpCd === "SAD" || element.signTpCd === "SAD" || element.signTpCd === "DSAD" || element.signTpCd === "DSAD") {
                sadCounter++;
            }
        });
        if (abItems.length > 1) {
            toast("전결 상태인 결재자가 복수로 존재합니다. 결재라인을 변경해주십시오.", { position: 'top-center', autoClose: 2000 });
            rtn = false;
        } else if (abItems.length + aaCounter > 1) {
            toast("전결과 후결은 동시에 존재할 수 없습니다. 결재라인을 변경해주십시오.", { position: 'top-center', autoClose: 2000 });
            rtn = false;
        } else if (sadCounter > 1) {
            toast("감사는 한번만 지정할 수 있습니다.", { position: 'top-center', autoClose: 2000 });
            rtn = false;
        } else {
            for (let abIndex of abItems) {
                if (abIndex === dept_test.length - 1) {
                    toast("마지막 결재자가 전결인 경우 결재안함이 추가되어야합니다. 결재라인을 변경해주십시오.", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    rtn = false;
                } else if (dept_test[abIndex + 1]["signTpCd"] !== "NA" && dept_test[abIndex + 1]["signTpCd"] !== "NA") {
                    toast("전결 다음에는 결재안함이 지정되어야합니다. 결재라인을 변경해주십시오.", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    rtn = false;
                }
            }
        }
    }

    async function apprChange(): Promise<void> {
        let action = false;
        test();
        if (rtn) {
            if (actionstatus === 'AS' || actionstatus === 'CF' || actionstatus === 'HCF') action = false;
            else if (actionstatus === 'DR' || actionstatus === 'TS' || actionstatus === 'RW' || actionstatus === 'RD' || actionstatus === 'DARW') action = true;

            if (action) {
                //결재자
                const paramMap: Record<string, any> = {
                    user_id: sessionStorage.getItem("user_id"),
                    frm_id: location_id,
                    sign_line_id: 'recent',
                    sign_line_nm: 'recent',
                    recent_yn: 'Y',
                    sign_seq_array: Array.from({ length: dept_test.length }, (_, index) => index).join(', '),
                }
                if (actionstatus === 'DR' || actionstatus === 'RA' || actionstatus === 'RW' || actionstatus === 'DARA' || actionstatus === 'DARW') {
                    paramMap.sign_tp_cd_array = dept_test!.map(map => map.signTpCd).join(', ');
                    paramMap.sign_user_id_array = dept_test!.map(map => map.userId).join(', ');
                    paramMap.sign_user_dept_cd_array = dept_test!.map(map => map.deptCd).join(', ');
                    paramMap.line_upde_auth_yn_array = dept_test!.map(map => map.lineUpdeAuthYn).join(', ');
                    paramMap.doc_upde_auth_yn_array = dept_test!.map(map => map.docUpdeAuthYn).join(', ');
                    paramMap.file_upde_auth_yn_array = dept_test!.map(map => map.fileUpdeAuthYn).join(', ');
                } else {
                    paramMap.sign_tp_cd_array = dept_test!.map(map => map.signTpCd).join(', ');
                    paramMap.sign_user_id_array = dept_test!.map(map => map.userId).join(', ');
                    paramMap.sign_user_dept_cd_array = dept_test!.map(map => map.deptCd).join(', ');
                    paramMap.line_upde_auth_yn_array = dept_test!.map(map => map.lineUpdeAuthYn).join(', ');
                    paramMap.doc_upde_auth_yn_array = dept_test!.map(map => map.docUpdeAuthYn).join(', ');
                    paramMap.file_upde_auth_yn_array = dept_test!.map(map => map.fileUpdeAuthYn).join(', ');
                }
                try {
                    await axios.post('/api/appr/orgChart/insertSignLine.ajax', paramMap);
                } catch (error) {
                    console.error('Error inserting sign line:', error);
                }

                // 참조자
                const paramMap2: Record<string, any> = {
                    user_id: sessionStorage.getItem("user_id"),
                    frm_id: location_id,
                    sign_line_id: 'recent',
                    sign_line_nm: 'recent',
                    recent_yn: 'Y',
                    sign_seq_array: Array.from({ length: read_list.length }, (_, index) => index).join(', '),
                    read_tp_cd_array: read_list!.map(map => map.READ_TP_CD).join(', '),
                    read_user_id_array: read_list!.map(map => map.READ_USER_ID).join(', '),
                    read_dept_cd_array: read_list!.map(map => map.READ_DEPT_CD).join(', '),
                }

                try {
                    await axios.post('/api/appr/orgChart/insertReadLine.ajax', paramMap2);
                } catch (error) {
                    console.error('Error inserting sign line:', error);
                }

                // 수신자
                const paramMap3: Record<string, any> = {
                    user_id: sessionStorage.getItem("user_id"),
                    frm_id: location_id,
                    sign_line_id: 'recent',
                    sign_line_nm: 'recent',
                    recent_yn: 'Y',
                    sign_seq_array: Array.from({ length: recv_list.length }, (_, index) => index).join(', '),
                    recv_tp_cd_array: recv_list!.map(map => map.RECV_TP_CD).join(', '),
                    recv_user_id_array: recv_list!.map(map => map.RECV_USER_ID).join(', '),
                    recv_dept_cd_array: recv_list!.map(map => map.RECV_DEPT_CD).join(', '),
                }
                try {
                    await axios.post('/api/appr/orgChart/insertRecvLine.ajax', paramMap3);
                } catch (error) {
                    console.error('Error inserting sign line:', error);
                }
                saveFunc(location_id);
            }
        }
    }
    const [tab, setTab] = useState<string>('signline'); // 결재선 지정 tab 메뉴 선택

    return (
        <>
            <Nav variant="tabs" defaultActiveKey="signline">
                <Nav.Item>
                    <Nav.Link eventKey="signline" onClick={() => { setTab('signline') }}>결재선</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="reception" onClick={() => { setTab('reception') }}>수신</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="reference" onClick={() => { setTab('reference') }}>참조</Nav.Link>
                </Nav.Item>
            </Nav>

            <Row className='mb-2'>
                <Col>
                    <div className='mt-1' style={{ border: '1px solid lightgray', borderRadius: '10px', padding: '10px', maxHeight: '300px', minHeight: '300px', overflow: 'auto', alignContent: 'normal' }}>
                        <TreeComponent
                            nodes={treeData}
                            idField={"dept_cd"}
                            nameField={"user_nm"}
                            divField={"user_id"}
                            onNodeClick={(node) => {
                                handleButtonClick(node.user_id, node.dept_nm);
                                const newMap = new Map();
                                newMap.set("user_id", node.user_id);
                                newMap.set("user_nm", node.user_nm);
                                newMap.set("user_posit_nm", node.user_posit_nm);
                                newMap.set("user_posit_cd", node.user_posit_cd);
                                newMap.set("dept_nm", node.dept_nm);
                                newMap.set("dept_cd", node.dept_cd);
                                setParamUser(newMap);
                            }}
                            defaultExpend={false}
                        />
                    </div>
                </Col>
                <Col xs='auto'>
                    <div className='d-flex flex-column gap-1'>
                        {tab === 'signline' &&
                            <>
                                <Button variant='light' style={{ border: '1px solid lightgray' }} onClick={() => selectButton('AP', '결재')}>결재</Button>
                                <Button variant='light' style={{ border: '1px solid lightgray' }} onClick={() => selectButton('AB', '전결')}>전결</Button>
                                <Button variant='light' style={{ border: '1px solid lightgray' }} onClick={() => selectButton('AA', '후결')}>후결</Button>
                                <Button variant='light' style={{ border: '1px solid lightgray' }} onClick={() => selectButton('SAD', '감사')}>감사</Button>
                                <Button variant='light' style={{ border: '1px solid lightgray' }} onClick={() => selectButton('CF', '확인')}>확인</Button>
                                <Button variant='light' style={{ border: '1px solid lightgray' }} onClick={() => selectButton('NA', '결재안함')}>결재안함</Button>
                            </>
                        }
                        {tab === 'reception' &&
                            <Button variant='light' style={{ border: '1px solid lightgray' }} onClick={() => selectRecvListButton('X', '수신')}>수신</Button>
                        }
                        {tab === 'reference' &&
                            <Button variant='light' style={{ border: '1px solid lightgray' }} onClick={() => selectRecvListButton('Z', '참조')}>참조</Button>
                        }
                    </div>
                </Col>
            </Row>

            {tab === 'signline' &&
                <>
                    <div className='mb-1 p-0 d-flex'>
                        <p className='mb-0 pt-1'>결재선 지정</p>
                        <span className='d-flex ms-auto gap-1'>
                            <Button size='sm' onClick={() => deleteButton(countindex)}>삭제</Button>
                            <Button size='sm' onClick={() => apprUpEntry(countindex)}>↑</Button>
                            <Button size='sm' onClick={() => apprDownEntry(countindex)}>↓</Button>
                        </span>
                    </div>
                    <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                        <Table style={{ borderTop: '2px solid #fb040498' }}>
                            <tr>
                                <th>결재유형</th>
                                <th>결재자</th>
                                <th>직급</th>
                                <th>부서</th>
                            </tr>
                            {dept_test?.map((dept_test, index) => (
                                <tr id={`${index}`} key={index} onClick={() => handleClick(index)}>
                                    {`${index}` === '0' ? (
                                        <td>{dept_test.signTpDesc}</td>
                                    ) : (
                                        <td className='py-0'>
                                            <Form.Select defaultValue={dept_test.signTpCd || 'NA'} onChange={(e) => handleSelectChange(e, index)} style={{ textAlign: 'center' }}>
                                                <option value="AP">결재</option>
                                                <option value="CF">확인</option>
                                                <option value="AA">후결</option>
                                                <option value="AB">전결</option>
                                                <option value="SAD">감사</option>
                                                <option value="DSAD">감사</option>
                                                <option value="NA">결재안함</option>
                                            </Form.Select>
                                        </td>
                                    )}
                                    <td>{dept_test.user}</td>
                                    <td>{dept_test.positNm}</td>
                                    <td>{dept_test.deptNm}</td>
                                </tr>
                            ))}
                        </Table>
                    </div>
                </>
            }
            {tab === 'reception' &&
                <>
                    <div className='mb-1 p-0 d-flex'>
                        <p className='mb-0 pt-1'>수신처 지정</p>
                        <span className='d-flex ms-auto gap-1'>
                            <Button id='deleteRecv' size='sm' onClick={() => deleteRecvListButton(countindex, 'Recv')}>삭제</Button>
                        </span>
                    </div>
                    <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                        <Table style={{ borderTop: '2px solid #fb040498' }}>
                            {recv_list.length === 0 &&
                                <tr>
                                    <td>수신처를 선택해 주세요.</td>
                                </tr>
                            }
                            {recv_list?.map((recv, index) => (
                                <tr id={`recv_${index}`} key={index} onClick={() => handleRecvClick(index, 'recv')}>
                                    <td>
                                        {recv.RECV_USER_NM === "" || recv.RECV_USER_NM === null || recv.RECV_USER_NM === undefined
                                            ? recv.RECV_DEPT_NM
                                            : `${recv.RECV_USER_NM}/${recv.RECV_USER_POSIT_NM}/${recv.RECV_DEPT_NM}`}
                                    </td>
                                </tr>
                            ))}
                        </Table>
                    </div>
                </>
            }
            {tab === 'reference' &&
                <>
                    <div className='mb-1 p-0 d-flex'>
                        <p className='mb-0 pt-1'>참조자</p>
                        <span className='d-flex ms-auto gap-1'>
                            <Button variant='primary' size='sm' onClick={() => deleteRecvListButton(countindex, 'Read')}>삭제</Button>
                        </span>
                    </div>
                    <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                        <Table style={{ borderTop: '2px solid #fb040498' }}>
                            {read_list.length === 0 &&
                                <tr>
                                    <td>참조자를 선택해 주세요.</td>
                                </tr>
                            }
                            {read_list?.map((read, index) => (
                                <tr id={`read_${index}`} key={index} onClick={() => handleRecvClick(index, 'read')}>
                                    <td>
                                        {read.READ_USER_NM === "" || read.READ_USER_NM === null || read.READ_USER_NM === undefined
                                            ? read.READ_DEPT_NM
                                            : `${read.READ_USER_NM}/${read.READ_USER_POSIT_NM}/${read.READ_USER_DEPT_NM}`}
                                    </td>
                                </tr>
                            ))}
                        </Table>
                    </div>
                </>
            }
            <Button className='mt-1 d-flex ms-auto' onClick={() => apprChange()}>저장</Button>

            <ToastContainer />
        </>

    );

}

type DeptVO = {

    signTpCd: string;
    signTpDesc: string;
    userId: string;
    user: string;
    positNm: string;
    positCd: string;
    deptNm: string;
    deptCd: string;
    fileUpdeAuthYn: string;
    lineUpdeAuthYn: string;
    docUpdeAuthYn: string;
}


type recentApprListVO = {
    SIGN_USER_DEPT_NM: string;
    SIGN_USER_ID: string;
    FILE_UPDE_AUTH_YN: string;
    LINE_UPDE_AUTH_YN: string;
    SIGN_SEQ: number;
    SIGN_USER_POSIT_CD: string;
    DOC_UPDE_AUTH_YN: string;
    SIGN_USER_POSIT_NM: string;
    SIGN_TP_CD: string;
    SIGN_TP_NM: string;
    SIGN_USER_NM: string;
    SIGN_USER_DEPT_CD: string;

    [key: string]: any;
}

type signUserListVO = {
    SIGN_USER_DEPT_NM: string;
    SIGN_USER_ID: string;
    FILE_UPDE_AUTH_YN: string;
    LINE_UPDE_AUTH_YN: string;
    SIGN_SEQ: number;
    SIGN_USER_POSIT_CD: string;
    DOC_UPDE_AUTH_YN: string;
    SIGN_USER_POSIT_NM: string;
    SIGN_TP_CD: string;
    SIGN_TP_NM: string;
    SIGN_USER_NM: string;
    SIGN_USER_DEPT_CD: string;

    [key: string]: any;
}

type recentRecvListVO = {
    RECV_TP_CD: string;
    RECV_DEPT_CD: string;
    RECV_DEPT_NM: string;
    RECV_USER_ID: string;
    RECV_USER_NM: string;
    RECV_USER_POSIT_CD: string;
    RECV_USER_POSIT_NM: string;


    [key: string]: any;
}

type recentReadListVO = {
    READ_TP_CD: string;
    READ_USER_ID: string;
    READ_USER_POSIT_CD: string;
    READ_USER_POSIT_NM: string;
    READ_USER_DEPT_CD: string;
    READ_USER_DEPT_NM: string;
    READ_USER_NM: string;
    READ_DEPT_NM: string;
    READ_DEPT_CD: string;

    [key: string]: any;
}

export default ApprLine;