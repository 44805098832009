import { useState } from "react";
import { Button, Card, Image } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import noImage from "../images/icon_noimages.gif"
import BoardDetail from "./board/BoardDetail";
import { Modal } from "react-bootstrap";
import ApprInfoArea from "./appr/signbox/ApprInfoArea";

interface props {
    title: string
    list: any[]
    board?: string|undefined
    goPage: Function
}
function CardComponent({ title, list, board, goPage }: props) {
    
    const isDesktop = !/iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const navigate = useNavigate();
    const [selectBbs, setSelectBbs] = useState<any>();
    const [selectNtt, setSelectNtt] = useState<any>();
    const [showPost, setShowPost] = useState<boolean>(false);

    const [modalData, setModalData] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => setIsModalOpen(false);
    const clicksetting = (sign_id: string, frm_id: string, vacation_form_yn: string, cooperation_yn: string) => {
      const param = new Map<string, string>();
      if(title === '결재완료함'){
        param.set("mode", 'sView');
      }else{
        param.set("mode", 'View');
      }
      param.set("sign_id", sign_id);
      param.set("frm_id", frm_id);
      
      param.set("vacation_yn", vacation_form_yn);
      param.set("cooperation_yn", cooperation_yn);
      setModalData(JSON.stringify(Object.fromEntries(param)));
      setIsModalOpen(true);
    };
    const renderApprInfoArea = () => (
      <ApprInfoArea 
          boxTpSignUserListCheck={''}
          recentApprListCheck={[]}
          mode={'view'}
          currUserInfo={{
              USER_POSIT_NM: "",
              DRFT_DT: "",
              USER_NM: "",
              USER_POSIT_CD: "",
              USER_DEPT_NM: "",
              APPR_SIGNATURE_USE_YN: "",
              USER_ID: "",
              USER_DEPT_CD: "",
              APPR_SIGNATUR_PATH: ""
          }} 
          signForm={{
              sign_line_type: "",
              retention: 0,
              cooperation_yn: "",
              receive_yn: "",
              fixReceptionList: [],
              fix_dept_nm: "",
              fix_dept_cd: "",
              deptList: [],
              dept_auth_cd: "",
              dept_auth_nm: "",
              formCompList: [],
              modify_yn: "",
              frm_ctg_name: "",
              sec_level_cd: "",
              output_file: "",
              use_yn: "",
              frm_num: "",
              revision_num: 0,
              doc_tp_cd: ""
          }} 
          subWorkerList={[]} 
          actionStatus={modalData} 
          varStatus={false} 
          status={false} 
          lastSeq={0}
      />
  );
    return (<>
    <div className={isDesktop?'col':'me-1'} style={!isDesktop?{minWidth: '260px', display: 'inline-block', scrollSnapAlign: 'start'}:{height: '26vh'}}>
      <Card className='h-100'>
        <Card.Header>
          <Card.Title className='mb-0 d-flex justify-content-between'>
            {title} <FaAngleRight style={{ height: '1.5rem', color: 'gray', cursor: 'pointer' }} 
              onClick={() => {goPage(title, board)}}  />
          </Card.Title>
        </Card.Header>
        <Card.Body style={{ overflowY: 'auto', whiteSpace: 'nowrap', maxHeight: '290px', paddingTop: 0 }}>
          {board !== 'y' ? 
            <ul className='ps-0' style={{ listStyle: 'none' }}>
              {list.map((item: any) => (
                <li key={item.sign_id} onClick={() => clicksetting(item.sign_id, item.frm_id, item.vacation_form_yn, item.cooperation_yn)}
                  style={{ padding: '0.3rem', borderBottom: '1px solid lightgray', cursor: 'pointer' }}>
                  <div className='text-truncate'>
                    <p className='mb-0 text-truncate'>{item.sign_doc_title}</p>
                    <span className='text-body-secondary' style={{ fontSize: '0.8rem' }}>
                      {item.drft_dt} by {item.drft_user_nm}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          : 
            <ul className='ps-0' style={{ listStyle: 'none' }}>
              {list.map((item: any) => (
                <li key={item.ntt_id} onClick={() => {setSelectBbs(item.bbs_id); setSelectNtt(item.ntt_id);
                  isDesktop ? setShowPost(true) : 
                  navigate('/board/'+item.bbs_id+'/'+item.ntt_id , {state: {
                      ntt_id: item.ntt_id,
                      show: showPost,
                      currPage: 0,
                      rowSize: 10,
                      isDetail: false,
                      searchMap: '',
                      menu_nm: title,
                      prevPath: window.location.pathname
                  }, replace: false});}} 
                style={{ padding: '0.3rem', borderBottom: '1px solid lightgray', cursor: 'pointer' }}>
                  <div className='d-flex gap-2 align-items-center'>
                    <Image
                      className='board-thumbnail'
                      src={'/api/board/thumb/' + item.bbs_id + '/' + item.ntt_id}
                      onError={e => (e.target as HTMLImageElement).src = noImage} />
                    <div className='col text-truncate' >
                      <p className='mb-0 text-truncate' >{item.ntt_sj}</p>
                      <span className='text-body-secondary' style={{ fontSize: '0.8rem' }}>
                        {item.rgsde}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          }
        </Card.Body>
      </Card>
    </div>
    <Modal show={isModalOpen} onHide={closeModal} size="xl" backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderApprInfoArea()}
      </Modal.Body>
      <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>닫기</Button>
      </Modal.Footer>
    </Modal>
    {showPost && <BoardDetail bbs_id={selectBbs} ntt_id={selectNtt} show={showPost} setShow={setShowPost} menu_nm={title} setEdit={() => {}} />}
    </>
  )
}

export default CardComponent;