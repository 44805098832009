import { useState } from "react";
import { Form } from "react-bootstrap";
import { MdExpandMore, MdKeyboardArrowRight } from "react-icons/md";

export interface Node {
    [key: string]: any;
    children?: Node[];
}

interface TreeNodeProps {
    node: Node;
    idField: string;
    nameField: string;
    onNodeClick: (node: Node) => void;
    defaultExpend: boolean;
    parentIcons?: React.ReactElement[];
    iconField?: string;
    childrenIcons?: { [key: string]: React.ReactElement };
    checkedItems?: { [key: string]: boolean; };
    setCheckedItems?: React.Dispatch<React.SetStateAction<{ [key: string]: boolean; }>>;
}
interface TreeComponentProps {
    nodes: Node[];
    idField: string;
    nameField: string;
    onNodeClick: (node: Node) => void;
    defaultExpend: boolean;
    parentIcons?: React.ReactElement[];
    iconField?: string;
    childrenIcons?: { [key: string]: React.ReactElement };
    checkedItems?: { [key: string]: boolean; };
    setCheckedItems?: React.Dispatch<React.SetStateAction<{ [key: string]: boolean; }>>;
}

export const TreeNode: React.FC<TreeNodeProps> = ({ node, idField, nameField, onNodeClick, defaultExpend, parentIcons, iconField, childrenIcons, checkedItems, setCheckedItems }) => {
    const [expanded, setExpanded] = useState(defaultExpend);

    const hasChildren = node.children && node.children.length > 0;

    // 개별 체크박스 변경 핸들러
    const handleCheckboxChange = (node: Node) => {
        setCheckedItems!((prevState: { [key: string]: boolean; }) => {
            return { ...prevState, [node[idField]]: !prevState[node[idField]] }
        });
    };

    return (
        <div style={{ margin: 5 }}>
            {hasChildren ?
                <div onClick={() => onNodeClick(node)} style={{ display: "flex", alignItems: "center" }}>
                    {parentIcons ? expanded ? parentIcons[0] : parentIcons[1] : expanded ? <MdKeyboardArrowRight onClick={(e) => { e.stopPropagation(); setExpanded(!expanded) }} /> : <MdExpandMore onClick={(e) => { e.stopPropagation(); setExpanded(!expanded) }} />}
                    {checkedItems && <span><Form.Check type='checkbox' name="checkbox" checked={checkedItems[node[idField]] || false} onChange={() => handleCheckboxChange(node)} onClick={(e) => e.stopPropagation()} /></span>}
                    {/* 추후 수정 필요(dept_nm) */}
                    {node[nameField] ? node[nameField] : node.dept_nm}
                </div>
                :
                <div onClick={() => onNodeClick(node)} style={{ display: "flex", alignItems: "center" }}>
                    {childrenIcons && iconField && node[iconField!] ? childrenIcons[node[iconField!]] : <span style={{ paddingRight: 16 }}></span>}
                    {checkedItems && <span><Form.Check type='checkbox' name="checkbox" checked={checkedItems[node[idField]] || false} onChange={() => handleCheckboxChange(node)} onClick={(e) => e.stopPropagation()} /></span>}
                    {node[nameField]}
                </div>
            }
            {expanded && hasChildren && (
                <div>
                    {node.children!.map((children) => (
                        <TreeNode key={children[idField]} node={children} idField={idField} nameField={nameField} onNodeClick={onNodeClick} defaultExpend={defaultExpend} parentIcons={parentIcons} iconField={iconField} childrenIcons={childrenIcons} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
                    ))}
                </div>
            )}
        </div>
    );
};

export const TreeComponent: React.FC<TreeComponentProps> = ({ nodes, idField, nameField, onNodeClick, defaultExpend, parentIcons, iconField, childrenIcons, checkedItems, setCheckedItems }) => {
    return (
        <div>
            {nodes.map((node) => (
                <TreeNode key={node[idField]} node={node} idField={idField} nameField={nameField} onNodeClick={onNodeClick} defaultExpend={defaultExpend} parentIcons={parentIcons} iconField={iconField} childrenIcons={childrenIcons} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
            ))}
        </div>
    );
};