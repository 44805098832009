import { Navigate, Outlet, useNavigate } from "react-router-dom";
import login from "./Func_Login";
import AlertModal from "./AlertModal";
import axios from "axios";

function Auth() {

  const isExter = /^\/exter/.test(window.location.pathname);
  const isExterLogin = sessionStorage.getItem("extern_yn") === "Y";
  const isLogin = !!sessionStorage.getItem("user_nm") && !isExterLogin;

  const navigate = useNavigate();

  let rtn = <></>;

  
  if (!isLogin && !isExterLogin) {
    if (localStorage.getItem('login_token')) {
      const extern_yn = localStorage.getItem('extern_yn');
      login('', '', extern_yn ? extern_yn === 'Y' : false).then(response => {
        if ((response === 0 && isExter && localStorage.getItem('extern_yn') === 'Y')
          || (response === 0 && !isExter && localStorage.getItem('extern_yn') === 'N')) {
            window.location.href = window.location.pathname;
        } else {
          const mobile_yn = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'Y' : 'N';
          axios.post('/api/logout', {
              user_id: sessionStorage.getItem('user_id'),
              extern_yn: localStorage.getItem('extern_yn'),
              mobile_yn: mobile_yn
          }).then(() => {
              localStorage.clear();
              sessionStorage.clear();
              navigate('/login', { replace: true });
          });
        }
      });
    } else {
      rtn = <Navigate to="/login" replace={true} />;
    }
  } else {
    if ((isExter && localStorage.getItem('extern_yn') === 'Y')
      || (!isExter && localStorage.getItem('extern_yn') === 'N')) {
        rtn = <Outlet />;
    } else {
      rtn = <AlertModal show={true} setShow={() => false} title="" body="권한이 없습니다." closeFunc={() => navigate('/', {replace: true})} />
    }
  }

  rtn = rtn === <></> ? <Auth /> : rtn;

  return rtn;

};

export default Auth;