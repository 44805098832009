import ReceiptForm from './ReceiptForm';
import { Modal } from 'react-bootstrap';

interface ReceiptProps {
    receipt_id: string,
    user_id: string,
    category: string,
    use_date: string,
    use_history: string,
    use_user: string, 
    use_place: string, 
    price: string,
    proof: string
    note: string
    aid: string
}

const ReceiptRegist = ({ show, setShow, listRefreshFn}: { show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, listRefreshFn: Function}): React.ReactElement => {
    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{'영수증 확인'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReceiptForm variable={undefined as unknown as ReceiptProps} setShow={setShow} listRefreshFn={listRefreshFn} date={undefined as unknown as string}/>
            </Modal.Body>
        </Modal>
    );
};

export default ReceiptRegist;