import { Route, Routes } from "react-router-dom";
import ViewProxyUserInfo from "./ViewProxyUserInfo";
import ViewProxyPassword from "./ViewProxyPassword";
import ViewProxyEmailSendYn from "./ViewProxyEmailSendYn";
import ViewProxySignatureStatus from "./ViewProxySignatureStatus";

function Cmn() {
    return (
        <div className="content-container">
            <Routes>
                <Route path="/setting/ViewProxyUserInfo" element={<ViewProxyUserInfo />} />
                <Route path="/setting/ViewProxyPassword" element={<ViewProxyPassword />} />
                <Route path="/setting/ViewProxyEmailSendYn" element={<ViewProxyEmailSendYn />} />
                <Route path="/setting/ViewProxySignatureStatus" element={<ViewProxySignatureStatus />} />
            </Routes>
        </div>
    );
}
export default Cmn;
