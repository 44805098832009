import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertModal from "../module/AlertModal";
import { Button, Container, Image, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import '../css/Header.css';
import { FaCircleUser } from "react-icons/fa6";
import { BsChevronDown } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";

interface HeaderProps {
    header: Menu[];
    toggleHeader: Menu[];
    getHeader: () => void;
}
// Menu 인터페이스 정의
export interface Menu {
    menu_id: number;
    menu_cd: string;
    menu_nm: string;
    menu_lvl: number;
    menu_url: string;
    menu_tp: string;
    menu_var?: string;
    hgr_menu_id: number;
    array_ord: number;
    children?: Menu[];
}

function Header({ header, toggleHeader, getHeader }: HeaderProps) {
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    const renderMenu = (menu: Menu) => {
        const isDropdown = menu.children && menu.children.length > 0;
        return isDropdown ? (
            <NavDropdown title={
                <span>
                    {menu.menu_nm} <BsChevronDown className="menuChevron" style={{ float: 'right', marginTop: '0.555rem' }} size={20 - menu.menu_lvl * 3} />
                </span>
            } className={`menuDepth${menu.menu_lvl}`} id='basic-nav-dropdown' renderMenuOnMount={true}
                drop={menu.menu_lvl > 2 && window.screen.width > 767 ? 'end' : 'down-centered'}
                key={menu.menu_nm}>
                {menu.children!.map((child) => (renderMenu(child)))}
            </NavDropdown>
        ) : (
            <Nav.Link onClick={() => { goPage(menu.menu_url, menu); }} key={menu.menu_nm}>
                {menu.menu_nm}
            </Nav.Link>
        );
    }

    const DesktopHeader: React.FC<{ data: Menu[] }> = ({ data }) => {
        return (
            <Navbar className="bg-body-tertiary" expand='md' sticky="top">
                <Container fluid>
                    <Navbar.Brand style={{ paddingLeft: '0.5rem', cursor: 'pointer' }}>
                        <Image className="d-inline-block align-top" src={`${process.env.PUBLIC_URL}/images/widetns_logo.gif`} alt="Logo" onClick={toMain} width={110} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
                    <Navbar.Offcanvas
                        id='offcanvasNavbar-expand-md'
                        aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                        placement="end">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id='offcanvasNavbarLabel-expand-md'>
                                <Image className="d-inline-block align-top" style={{ cursor: 'pointer' }} src={`${process.env.PUBLIC_URL}/images/widetns_logo.gif`} alt="Logo" onClick={toMain} width={90} />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto fs-5">
                                    {toggleHeader.map((menu) => (
                                        renderMenu(menu)
                                    ))}
                                </Nav>
                                <NavDropdown title={
                                    <FaCircleUser size={30} />
                                } className="me-3" align={{ md: 'end' }} id='profile-dropdown' renderMenuOnMount={true}>
                                    <Nav.Link onClick={() => { !sessionStorage.getItem('extern_yn') ? navigate("/myinfo/myProfile") : navigate('/exter/mypage') }}>
                                        {sessionStorage.getItem("dept_nm") && <>{sessionStorage.getItem("dept_nm")}<br /></>} {sessionStorage.getItem("user_nm")} {sessionStorage.getItem("position_nm")}님
                                    </Nav.Link>
                                    <NavDropdown.Divider />
                                    <Nav.Link onClick={() => setShowModal(true)} >Logout</Nav.Link>
                                </NavDropdown>
                            </Navbar.Collapse>
                        </Offcanvas.Body>
                        <div className="p-2 pt-0" id="profile-below-btn">
                            <hr />
                            <div className="d-flex align-items-stretch">
                                <Button variant="secondary" style={{ display: 'block', width: '100%' }} onClick={() => { !sessionStorage.getItem('extern_yn') ? navigate("/myinfo/myProfile") : navigate('/exter/mypage') }}>
                                    <FaCircleUser size={30} className="pb-1" />
                                    <div style={{ fontSize: '0.8rem' }}>
                                        {sessionStorage.getItem("dept_nm") && <>{sessionStorage.getItem("dept_nm")}<br /></>} {sessionStorage.getItem("user_nm")}{sessionStorage.getItem("position_nm")}님
                                    </div>
                                </Button>
                                <Button className="ms-1 pb-2" onClick={() => setShowModal(true)}><MdOutlineLogout size={23} /></Button>
                            </div>
                        </div>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        );
    };

    function goPage(url: string, state?: any) {
        if (/^\/exter/.test(url) && !/\/board$/.test(url)) {
            navigate(url, { state: { ...state, show: true, pageName: url.replace('/exter/', '') } });
        } else {
            navigate(url, { state: { ...state } });
        }
    }
    function logout() {
        const mobile_yn = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'Y' : 'N';
        axios.post('/api/logout', {
            user_id: sessionStorage.getItem('user_id'),
            extern_yn: localStorage.getItem('extern_yn'),
            mobile_yn: mobile_yn
        }).then(() => {
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login', { replace: true });
        });
    }

    function toMain() {
        let path = '/main';
        if (sessionStorage.getItem('extern_yn'))
            path = '/exter/board'
        navigate(path, { replace: true });
    }

    useEffect(() => {
        if (sessionStorage.getItem('priv_cd')) {
            getHeader();
        }
    }, [sessionStorage.getItem('priv_cd')]);

    return (
        <header>
            <DesktopHeader data={header} />
            <AlertModal title="로그아웃 하시겠습니까?" agree="로그아웃" show={showModal} setShow={setShowModal} func={logout} />
        </header>
    );
};


export default Header;