import { initializeApp } from 'firebase/app';
import { Messaging, getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyC5xCTdJR7IEeT3QUXbZEC0k9S5rgGYGxU",
    authDomain: "widetns-app.firebaseapp.com",
    projectId: "widetns-app",
    storageBucket: "widetns-app.appspot.com",
    messagingSenderId: "755476043848",
    appId: "1:755476043848:web:84e7135fd2ed07ca25607d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
let messaging: Messaging;

if ('serviceWorker' in navigator && 'PushManager' in window) {
    try{
        messaging = getMessaging(app);
    } catch(error){
        console.error(error);
    }
}
export { messaging };