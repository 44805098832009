import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    Form,
    Button,
    Row,
    Col,
    Container
} from "react-bootstrap";
import Loading from "../../module/Loading";
import { toast } from 'react-toastify';


function ViewProxyUserInfo() {

    const [loading, setLoading] = useState(false);

    const [useDelegate, setUseDelegate] = useState<string>("disable");
    const [newPass, setNewPassn] = useState<string | null>(null);
    const [newPassCheck, setNewPassCheck] = useState<string | null>(null);

    const [apprPwType, setApprPwType] = useState<string>("");
    const [apprPwUseYn, setApprPwUseYn] = useState<boolean>(false);



    const handleUseDelegateChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setUseDelegate(event.target.value);
        if (event.target.value === 'disable') {
            setApprPwType('L');
            setNewPassn(null);
            setNewPassCheck(null);
        } else {
            setApprPwType('D');
        }
    };



    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setApprPwUseYn(event.target.checked);

    };


    useEffect(() => {
        const getData_main = async () => {
            try {
                setLoading(true);
                const response = await axios.post(
                    "/api/cmn/setting/viewProxyUserInfo.menu",
                    {
                        user_id: sessionStorage.getItem("user_id")
                    },
                    { headers: { "Content-Type": "application/json" } }
                );
                const rtn = response.data;

                const myProxyUserInfo = rtn.myProxyUserInfo;

                myProxyUserInfo.appr_pw_use_yn === 'N' || null ? setApprPwUseYn(false) : setApprPwUseYn(true);
                if (myProxyUserInfo.appr_pw_type === 'L' || myProxyUserInfo.appr_pw_type === null) {
                    setApprPwType('L');
                    setUseDelegate('disable');
                } else {
                    setApprPwType('D');
                    setUseDelegate('enable');
                }

            } catch (error) {
                alert(
                    "서버 오류로 인한 " +
                    " 추출 실패, 관리자에게 문의 부탁 드립니다."
                );
            } finally {
                setLoading(false);
            }
        };
        getData_main();
    }, []);

    async function proxySave() {

        const pwUseYn = apprPwUseYn ? 'Y' : 'N';


        if (pwUseYn === 'Y') {
            // 사용
            if (apprPwType === 'D') {
                // 결재암호를 사용하고 별도의 비밀번호를 사용한다면 체크
                if (newPass === null || newPassCheck === null) {
                    alert("변경할 비밀번호를 입력하세요.");
                    return;
                } else if (newPass !== newPassCheck) {
                    alert("변경할 비밀번호가 다릅니다.");
                    return;
                }
            }

        } else {
            // 미사용
            // 이때도 값은 보내야함 null 이라도.
            // 그리구 미사용이여도 별도의 비밀번호를 설정 가능하기에 추가
            if (apprPwType === 'D') {
                // 결재암호를 사용하고 별도의 비밀번호를 사용한다면 체크
                if (newPass === null || newPassCheck === null) {
                    alert("변경할 비밀번호를 입력하세요.");
                    return;
                } else if (newPass !== newPassCheck) {
                    alert("변경할 비밀번호가 다릅니다.");
                    return;
                }
            }
        }

        try {
            setLoading(true);
            const saveProxyRes = await axios.post(
                "/api/cmn/setting/apprPassUseYn.ajax",
                {
                    user_id: sessionStorage.getItem("user_id"),
                    "appr_pw_use_yn": pwUseYn,
                    "appr_pw_type": apprPwType,
                    "newPass": newPass
                },
                { headers: { "Content-Type": "application/json" } }
            );
            <Loading />
            const rtn2 = saveProxyRes.data;
            if (rtn2.result === "success") {

            } else if (rtn2.result === "failed") {
                alert(" 데이터 추출 실패");
            }
            toast.success("저장이 완료되었습니다.", {
                position: 'top-center',
                autoClose: 1000
            });
        } catch (error) {
            alert("Failed axios ");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            {loading && (
                <Loading />
            )}

            {!loading && (
                <Form>

                    <h3 style={{ margin: "15px 0", fontWeight: "bold" }}>
                        결재암호 지정
                    </h3>
                    <p>
                        결재암호는 결재권자가 결재문서를 결재하고자 할 때 비밀번호로 사용됩니다.
                        <br />
                        [사용] 으로 체크시 결재 비밀번호를 입력하여야 결재 처리를 할 수 있습니다.
                    </p>
                    <hr />
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={8} lg={6}>
                            <Form.Check
                                type="checkbox"
                                label="사용"
                                value={apprPwUseYn ? 'Y' : 'N'}
                                checked={apprPwUseYn}
                                onChange={handleCheckboxChange}
                                inline
                            />

                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={8} lg={6}>
                            <Form.Check
                                type="radio"
                                label="로그인 비밀번호 사용"
                                value="disable"
                                checked={useDelegate === "disable"}
                                onChange={handleUseDelegateChange}
                                inline
                            />
                            <Form.Check
                                type="radio"
                                label="별도의 비밀번호 사용"
                                value="enable"
                                checked={useDelegate === "enable"}
                                onChange={handleUseDelegateChange}
                                inline
                            />
                        </Col>
                    </Form.Group>

                    <Form.Label column sm={3}>
                        신규 비밀번호
                    </Form.Label>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={8} lg={6}>
                            <Form.Control
                                type="password"
                                value={newPass === null ? '' : newPass}
                                onChange={(e) => setNewPassn(e.target.value)}
                                disabled={useDelegate === "disable"}
                                maxLength={64}
                            // WG_USER_ENV appr_pw 컬럼 길이 64
                            />
                        </Col>
                    </Form.Group>

                    <Form.Label column sm={3}>
                        신규 비밀번호 확인
                    </Form.Label>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={8} lg={6}>
                            <Form.Control
                                type="password"
                                value={newPassCheck === null ? '' : newPassCheck}
                                onChange={(e) => setNewPassCheck(e.target.value)}
                                disabled={useDelegate === "disable"}
                                maxLength={64}
                            // WG_USER_ENV appr_pw 컬럼 길이 64
                            />
                        </Col>
                    </Form.Group>


                    <Button variant="primary" className="mt-3" onClick={proxySave}>
                        저장
                    </Button>
                </Form>
            )}
        </Container>
    );
}

export default ViewProxyUserInfo;
