import { FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";

interface PaginateProps {
    setDetailPage: (page: number) => void;
    detailPage: number;
    pageCount: number;
}

export default function Paginate({ setDetailPage, detailPage, pageCount }: PaginateProps) {

    const handlePageClick = (selectedItem: { selected: number }) => {
        setDetailPage(selectedItem.selected);
    };

    const handleFirstPage = () => {
        setDetailPage(0);
    };

    const handleLastPage = () => {
        setDetailPage(pageCount - 1);
    };

    return (

        <div className="pagination-container">
            <button className="page-link first-page" onClick={handleFirstPage} disabled={detailPage === 0}>
                <FaAngleDoubleLeft style={{marginTop: '-4px'}}/>
            </button>
            <ReactPaginate
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
                previousLabel={<FaAngleLeft style={{marginTop: '-4px'}}/>}
                nextLabel={<FaAngleRight style={{marginTop: '-4px'}}/>}
                breakLabel={"..."}
                forcePage={detailPage}
            />
            <button className="page-link last-page" onClick={handleLastPage} disabled={detailPage === pageCount - 1}>
                <FaAngleDoubleRight style={{marginTop: '-4px'}} />
            </button>
        </div>
    );
}