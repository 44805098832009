import axios from "axios";
import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../module/Loading";
import Paginate from "../../module/Paginate";
import SearchBarV2 from "../../module/SearchBarV2";
import { Button, Col, Form, Image } from "react-bootstrap";
import DataTableV2 from "../../module/DataTableV2";
import { CellProps } from "react-table";
import noImage from "../../images/icon_noimages.gif"
import "../../css/board/Board.css"
import BoardDetail from "./BoardDetail";
import BoardReg from "./BoardReg";

interface BBSProps {
    state: {[key: string] : string}
}

let bProcFlag = false;

function BBS({state}: BBSProps) {

    const location = useLocation();

    const [bbs_id, setBbs_id] = useState('');
    const [menu_nm, setMenu_nm] = useState('');
    const [ntt_id, setNtt_id] = useState('');
    const [showDetail, setShowDetail] = useState(false);
    const [showReg, setShowReg] = useState(false);
    const [isEdit, setEdit] = useState(false);
    
    if (!bbs_id && !bProcFlag) {
        bProcFlag = true;
        if (state && state.bbs_id && state.menu_nm) {
            setBbs_id(state.bbs_id);
            setMenu_nm(state.menu_nm);
        } else {
            axios.post('/api/board/getInfo', 
                location.pathname, 
                {headers : {'Content-Type' : 'text/html'}}).then(response => {
                    setBbs_id(response.data.bbs_id);
                    setMenu_nm(response.data.menu_nm);
                    bProcFlag = false;
                });
        }
    }

    const navigate = useNavigate();
    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });

    //큰 화면용 list를 위한 변수
    const [detailDoc, setDetailDoc] = useState<ArticleVO[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    
    //작은 화면용 list를 위한 변수
    const [simpleDoc, setSimpleDoc] = useState<ArticleVO[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    
    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    const idxList: number[] = [0, 1, 2, 3, 4, 5];
    const thisYear = new Date().getFullYear();

    // data column 세팅을 위한 변수
    const [detail_columns, setDetail_columns] = useState([
        {
            Header: '번호',
            accessor: 'optionNo',
            Cell: ({ row }: CellProps<ArticleVO>) => (
                <div className="board-no-wrapper">
                    {row.original.no}
                </div>
            ),
        },
        {
            Header: '',
            accessor: 'optionTumb',
            Cell: ({ row }: CellProps<ArticleVO>) => (
                <Image
                    className='board-thumbnail'
                    src={'/api/board/thumb/' + row.original.bbs_id + '/' + row.original.ntt_id}
                    onError={e => (e.target as HTMLImageElement).src = noImage} />
            ),
        },
        {
            Header: '제목',
            accessor: 'ntt_sj'
        },
        {
            Header: '작성자',
            accessor: 'ntt_wrter'
        },
        {
            Header: '작성일',
            accessor: 'rgsde'
        },
        {
            Header: '조회수',
            accessor: 'inquiry_cnt'
        },
        {
            Header: '추천',
            accessor: 'ntt_recomend_co'
        },
        {
            Header: '댓글',
            accessor: 'answer_co'
        }
    ]);
    const [simple_columns, setSimple_columns] = useState([
        {
            Header: '',
            accessor: 'optionTumb',
            Cell: ({ row }: CellProps<ArticleVO>) => (
                <Image
                    className='board-thumbnail'
                    src={'/api/board/thumb/' + row.original.bbs_id + '/' + row.original.ntt_id}
                    onError={e => (e.target as HTMLImageElement).src = noImage} />
            ),
        },
        {
            Header: '제목',
            accessor: 'ntt_sj'
        },
        {
            Header: '작성자',
            accessor: 'ntt_wrter'
        }
    ]);
    
    //searchMap에는 검색어와 검색컬럼이 SearchBar에서 세팅된다.
    //검색어는 SearchBar에 searchstr로 지정되어있으므로 바꿀수 없고,
    //select box 선택시 검색컬럼 지정은 하위 selectTypes의 key 에 매핑(해당페이지는 searchCol)
    const [searchMap, setSearchMap] = useState<Map<string, string>>(() => {
        const map = new Map();
        map.set('searchStr', '');
        map.set('searchKey', 'BSH001');
        map.set('searchYear', '');
        map.set('searchMonth', '');
        return map;
    });

    //문서 리스트 검색 함수
    const selectDocList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const searchStr = searchMap.get("searchStr") ? searchMap.get("searchStr") : (document.querySelector('[name="searchStr"]') as HTMLInputElement).value;
        const searchYear = searchMap.get('searchYear') ? searchMap.get("searchYear") : (document.querySelector('[name="searchYear"]') as HTMLSelectElement).value;
        const searchMonth = searchMap.get('searchMonth') ? searchMap.get("searchMonth") : (document.querySelector('[name="searchMonth"]') as HTMLSelectElement).value;
        const searchKey = searchMap.get('searchKey') ? searchMap.get("searchKey") : (document.querySelector('[name="searchKey"]') as HTMLSelectElement).value;
        const sortStr = sort[0];
        const sortDir = sort[1];
        const param = {
            'pathname' : location.pathname,
            'bbs_id' : bbs_id,
            'user_id' : sessionStorage.getItem('user_id'),
            "currPage": currPage,
            "rowSize": rowSize,
            "searchStr": searchStr,
            "searchYear": searchYear,
            "searchMonth": searchMonth,
            "searchKey": searchKey,
            "sortStr": sortStr,
            "sortDir": sortDir,
            "admin_yn": sessionStorage.getItem('priv_cd') === 'WIDE_ADMIN' ? 'Y' : 'N',
        }
        const response = await axios.post(
            '/api/board/getArticleList',
            param,
            { headers: { "Content-Type": 'application/json' } }
        );
        const data = await response.data;
        if (isDetail) {
            setDetailDoc(data.content);
            setPageCount(data.totalPages);
            setRowCount(data.rowCount);
        } else {
            if (currPage)
                setSimpleDoc(prevPosts => [...prevPosts, ...data.content]);
            else
                setSimpleDoc(data.content);
            setHasMore(!data.last);
        }
        setLoading(false);
    };

    function search() {
        const loadPosts = async () => {
            if (detailPage === 0) {
                selectDocList(0, rowSize, true);
            } else {
                setDetailPage(0);
            }
            setSimpleDoc([]);
            setHasMore(true);
            if (simplePage === 0) {
                selectDocList(0, 25, false);
            } else {
                setSimplePage(0);
            }
        };
        loadPosts();
    }

    function showDetailArticle(vo: ArticleVO) {
        if (isDesktop) {
            setNtt_id(vo.ntt_id);
            setShowDetail(true);
        } else {
            const path = '/board/' + bbs_id + '/' + vo.ntt_id;
            navigate(path , {state: {
                ntt_id: vo.ntt_id,
                menu_nm: menu_nm,
                show: showDetail,
                currPage: simplePage,
                rowSize: rowSize,
                isDetail: false,
                searchMap: searchMap,
                prevPath: location.pathname
            }, replace: false});
        }
    }

    function regArticle() {
        if (isDesktop) {
            setShowReg(true);
        } else {
            const path = '/board/reg';
            navigate(path , {state: {
                menu_nm: menu_nm,
                show: showDetail,
                currPage: simplePage,
                rowSize: rowSize,
                isDetail: false,
                searchMap: searchMap,
                prevPath: location.pathname
            }, replace: false});
        }
    }

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDocList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        if (location.state) {
            const map = location.state.searchMap ? location.state.searchMap : new Map<string, string>();
            if (!map.get('searchStr'))
                map.set('searchStr', '');
            if (!map.get('searchYear'))
                map.set('searchYear', '');
            if (!map.get('searchMonth'))
                map.set('searchMonth', '');
            if (!map.get('searchKey'))
                map.set('searchKey', 'BSH001');
            (document.querySelector('[name="searchStr"]') as HTMLInputElement).value = String(map.get('searchStr'));
            (document.querySelector('[name="searchYear"]') as HTMLSelectElement).value = String(map.get('searchYear'));
            (document.querySelector('[name="searchMonth"]') as HTMLSelectElement).value = String(map.get('searchMonth'));
            (document.querySelector('[name="searchKey"]') as HTMLSelectElement).value = String(map.get('searchKey'));
            setSearchMap(map);
        }
        const loadPosts = async () => {
            selectDocList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        search();
    }, [sort]);

    useEffect(() => {
        if (!location.state) {
            setSearchMap(new Map<string, string>());
            (document.querySelector('[name="searchStr"]') as HTMLInputElement).value = '';
            (document.querySelector('[name="searchYear"]') as HTMLSelectElement).value = '';
            (document.querySelector('[name="searchMonth"]') as HTMLSelectElement).value = '';
            (document.querySelector('[name="searchKey"]') as HTMLSelectElement).value = 'BSH001';
        }
        if (location.pathname === '/board/reference') {
            setDetail_columns([
                {
                    Header: '제목',
                    accessor: 'ntt_sj'
                },
                {
                    Header: '작성자',
                    accessor: 'ntt_wrter'
                },
                {
                    Header: '작성일',
                    accessor: 'rgsde'
                },
                {
                    Header: '조회수',
                    accessor: 'inquiry_cnt'
                },
                {
                    Header: '추천',
                    accessor: 'ntt_recomend_co'
                },
                {
                    Header: '댓글',
                    accessor: 'answer_co'
                }
            ]);
            setSimple_columns([
                {
                    Header: '제목',
                    accessor: 'ntt_sj'
                },
                {
                    Header: '작성자',
                    accessor: 'ntt_wrter'
                }
            ]);
        }
        search();

    }, [location.pathname]);

    useEffect(() => {
        if (isEdit) setShowReg(true);
    }, [isEdit]);

    
    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    return (
        <div className='content datatable-content'>
            <h2>{menu_nm}</h2>
            <SearchBarV2
                setRowSize={setRowSize}
                rowCount={rowCount}
                searchMap={searchMap}
                setSearchMap={setSearchMap}
                search={search}
                leftChildren={
                    <>
                        <Col sm="auto" key="searchYear" >
                            <Form.Select name="searchYear" defaultValue={searchMap.get("searchYear")} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                {idxList.map(idx => {
                                    if (idx === 0)
                                        return <option key="0" value="">연도 선택</option>
                                    else
                                        return (<option key={idx} value={thisYear - (5 - idx)}>{thisYear - (5 - idx)}</option>);
                                })}
                            </Form.Select>
                        </Col>
                        <Col sm="auto" key="searchMonth" >
                            <Form.Select name="searchMonth" defaultValue={searchMap.get("searchMonth")} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option key="0" value="">월 선택</option>
                                <option key="01" value="01" >1월</option>
                                <option key="02" value="02" >2월</option>
                                <option key="03" value="03" >3월</option>
                                <option key="04" value="04" >4월</option>
                                <option key="05" value="05" >5월</option>
                                <option key="06" value="06" >6월</option>
                                <option key="07" value="07" >7월</option>
                                <option key="08" value="08" >8월</option>
                                <option key="09" value="09" >9월</option>
                                <option key="10" value="10">10월</option>
                                <option key="11" value="11">11월</option>
                                <option key="12" value="12">12월</option>
                            </Form.Select>
                        </Col>
                        <Col sm="auto" key="searchKey" >
                            <Form.Select name="searchKey" defaultValue={searchMap.get("searchKey")} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option key='BSH001' value='BSH001'>제목</option>
                                <option key='BSH002' value='BSH002'>내용</option>
                                <option key='BSH003' value='BSH003'>제목+내용</option>
                                <option key='BSH004' value='BSH004'>작성자</option>
                            </Form.Select>
                        </Col>
                    </>
                }
                rightChildren={
                    (Number(bbs_id) > 3
                    || sessionStorage.getItem('priv_cd') === 'WIDE_ADMIN') && 
                    <Col xs="auto">
                        <Button onClick={regArticle}>글 작성</Button>
                    </Col>
                } />
            {isDesktop ? (
                <>
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailDoc} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => showDetailArticle(vo)} />
                    {pageCount !== 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}

                    {showDetail && <BoardDetail bbs_id={bbs_id} ntt_id={ntt_id} menu_nm={menu_nm}
                        show={showDetail} setShow={setShowDetail} searchFunc={search}
                        currPage={detailPage} rowSize={rowSize} isDetail={true} searchMap={searchMap} setEdit={setEdit} />}
                    {showReg && <BoardReg bbs_id={bbs_id} ntt_id={isEdit ? ntt_id : null} menu_nm={menu_nm}
                        show={showReg} setShow={setShowReg} searchFunc={search} setEdit={setEdit}
                        currPage={detailPage} rowSize={rowSize} isDetail={true} searchMap={searchMap} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleDoc.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={simple_columns} data={simpleDoc} sort={sort} setSortDir={setSortDir}
                        onRowClick={(vo) => showDetailArticle(vo)}
                            />
                </InfiniteScroll>
            )}
        </div>
    )
    
}

type ArticleVO = {
    ntce_endde: string;
    ntt_sj: string;
    ntt_wrter: string;
    rgsde: string;
    inquiry_cnt: string;
    answer_co: string;
    [key: string]: any;
}

export default BBS;