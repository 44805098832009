import { Button, Form } from 'react-bootstrap';
import { Column, useTable } from 'react-table';
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import Loading from './Loading';
import { useEffect, useState } from 'react';

interface DataTableProps<T extends object> {
    loading: boolean;
    columns: Column<T>[];
    data: T[];
    sort: string[];
    setSortDir: (sort: string[]) => void;
    onRowClick?: (row: T) => void;
    options?: any[];
    keyField?: keyof T;
    checkedItems?: { [key: string]: boolean; };
    setCheckedItems?: React.Dispatch<React.SetStateAction<{ [key: string]: boolean; }>>;
}

const DataTable = <T extends {}>({ loading, columns, data, keyField, sort, setSortDir, onRowClick, options, checkedItems, setCheckedItems }: DataTableProps<T>) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

    function handleSort(id: string) {
        if (id !== 'no') {
            if (sort[0] !== id) {
                setSortDir([id, 'ASC']);
            } else if (sort[1] === "ASC") {
                setSortDir([id, 'DESC']);
            } else {
                setSortDir(['', '']);
            }
        }
    }

    // 전체 선택 체크박스 변경 핸들러
    const handleAllCheckboxChange = () => {
        const newCheckedState = !isAllChecked;
        const newCheckedItems: { [key: string]: boolean; } = {};
        data.forEach(item => {
            newCheckedItems[item[keyField!] as string] = newCheckedState;
        });
        setCheckedItems!(newCheckedItems);
        setIsAllChecked(newCheckedState);
    };

    // 개별 체크박스 변경 핸들러
    const handleCheckboxChange = (key: string) => {
        setCheckedItems!((prevState: { [key: string]: boolean; }) => {
            return { ...prevState, [key]: !prevState[key] }
        });
    };

    // 모든 체크박스 선택 상태를 감지하고 설정
    useEffect(() => {
        if (checkedItems && keyField) {
            const allChecked = data.every(item => checkedItems![item[keyField!] as string]);
            setIsAllChecked(allChecked);
        }
    }, [checkedItems, data, keyField]);


    if (loading) return <Loading />

    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup, headerGroupIndex) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                        {checkedItems && <th className='option'><Form.Check type='checkbox' checked={isAllChecked} onChange={handleAllCheckboxChange} /></th>}
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} key={column.id} onClick={() => handleSort(column.id)}>
                                {column.render('Header')}
                                {sort[0] === column.id && sort[1] === 'ASC' && <MdArrowDropUp />}
                                {sort[0] === column.id && sort[1] === 'DESC' && <MdArrowDropDown />}
                            </th>
                        ))}
                        {options && <th className="option"></th>}
                    </tr>
                ))}
            </thead>
            <tbody className='data-table' {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    const key = keyField ? row.original[keyField] as string : '';
                    return (
                        <tr {...row.getRowProps()} onClick={() => onRowClick && onRowClick(row.original)} key={row.id}>
                            {checkedItems && <td className='option'><Form.Check type='checkbox' checked={checkedItems[key] || false} onChange={() => handleCheckboxChange(key)} /></td>}
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()} key={cell.column.id}>
                                    {cell.render('Cell')}
                                </td>
                            ))}
                            {options &&
                                <td className="option">
                                    {options.map(option => (
                                        <div key={option.text} style={{ display: 'grid' }}>
                                            <Button size="sm" onClick={(e) => { e.stopPropagation(); option.onclick(row.original) }}>{option.text}</Button>
                                        </div>
                                    ))}
                                </td>
                            }
                        </tr>
                    );
                })}
                {rows.length === 0 && <tr><td colSpan={columns.length + 1} style={{ textAlign: 'center' }}>데이터가 없습니다.</td></tr>}
            </tbody>
        </table>
    );
};

export default DataTable;