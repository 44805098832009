import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";
import AlertModal from "../../module/AlertModal";

function SignUpModal(this: any, props:any) {

    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [name, setName] = useState('');
    const [mobilePhone, setMobilePhone] = useState('');
    const [email, setEmail] = useState('');
    const [approver, setApprover] = useState('');

    const [alertTitle] = useState('');
    const [alertBody, setAlertBody] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const [approverList, setApproverList] = useState<string[]|null>(null);

    function agreeTerms(bAgreement:Boolean) {
        const modal = document.querySelector('.modal-dialog');
        const prevBody = document.querySelector("#signUpBody1");
        const prevFoot = document.querySelector("#signUpFooter1");
        const postBody = document.querySelector("#signUpBody2");
        const postFoot = document.querySelector("#signUpFooter2");
        if (bAgreement) {
            if (prevBody && prevFoot && postBody && postFoot && modal) {
                prevBody.classList.add('hidden');
                prevFoot.classList.add('hidden');
                // 모달 사이즈 변경
                modal.classList.add('modal-md');
                modal.classList.remove('modal-xl');

                postBody.classList.remove('hidden');
                postFoot.classList.remove('hidden');
            }
        } else {
            if (prevBody && prevFoot && postBody && postFoot && modal) {
                postBody.classList.add('hidden');
                postFoot.classList.add('hidden');
                // 모달 사이즈 변경
                modal.classList.add('modal-xl');
                modal.classList.remove('modal-md');

                prevBody.classList.remove('hidden');
                prevFoot.classList.remove('hidden');
            }
        }
    }

    function checkPhoneNum(inp: HTMLInputElement) {
        if (!/^[+]{0,1}[0-9]{0,}$/.test(inp.value)) {
            setAlertBody('전화번호는 국가번호 및 숫자만 입력 가능합니다.');
            setShowAlert(true);
            inp.value = mobilePhone;
            return;
        }
        setMobilePhone(inp.value);
    }

    function pwCheck(inp: HTMLInputElement) {
        const lab = document.querySelector('label[for="floatingPasswordCheck"]');
        if (lab) {
            if (inp.value.length === 0) {
                inp.removeAttribute('style');
                lab.textContent = 'Password Check';
            } else if (inp.value !== password) {
                inp.setAttribute('style', 'background: #ffaaaa;');
                lab.textContent = "비밀번호가 일치하지 않습니다.";
            } else {
                inp.setAttribute('style', 'background: #aaffaa;');
                lab.textContent = "비밀번호가 확인되었습니다.";
            }
        }
        setPasswordCheck(inp.value);
    }

    function changePassowrd(pw: string) {
        setPassword(pw);
        const pwChk = document.getElementById('floatingPasswordCheck') as HTMLInputElement;
        pwChk.value = '';
        pwCheck(pwChk);
    }

    async function signUp() {
        if (!userId) {
            setAlertBody('ID를 입력 해 주세요.');
            setShowAlert(true);
            document.getElementById('floatingId')?.focus();
        } else if (!password) {
            setAlertBody('비밀번호를 입력 해 주세요.');
            setShowAlert(true);
            document.getElementById('floatingPassword')?.focus();
        } else if (password !== passwordCheck) {
            setAlertBody('비밀번호가 다릅니다.');
            setShowAlert(true);
            document.getElementById('floatingPasswordCheck')?.focus();
        } else if (!name) {
            setAlertBody('이름을 입력 해 주세요.');
            setShowAlert(true);
            document.getElementById('floatingName')?.focus();
        } else if (!mobilePhone) {
            setAlertBody('휴대폰 번호를 입력 해 주세요.');
            setShowAlert(true);
            document.getElementById('floatingPhone')?.focus();
        } else if (!email) {
            setAlertBody('E-mail 주소를 입력 해 주세요.');
            setShowAlert(true);
            document.getElementById('floatingEmail')?.focus();
        } else {
            await axios.post('/api/exter/checkId', userId, { headers: { "Content-Type": 'text/html' } })
                .then( async response => {
                    if (response.data > 0) {
                        setAlertBody('중복된 ID입니다.\r\n새로운 ID를 입력 해 주세요');
                        setShowAlert(true);
                        document.getElementById('floatingId')?.focus();
                    } else {
                        await axios.post('/api/exter/signUp', {
                            'user_id': userId,
                            'user_pw': password,
                            'user_nm': name,
                            'mobile_phone' : mobilePhone,
                            'email' : email,
                            'approver' : approver,
                        }, { headers: { 'Content-Type': 'application/json' } }).then( async response => {
                            setAlertBody('신규 ID가 생성되었습니다.');
                            setShowAlert(true);
                            props.onHide();
                        });
                    }
                });
        }
    }
    
    useEffect(() => {
        if (props.show) {
            axios.post('/api/exter/getApproverList').then(response => {
                setApproverList(response.data);
            });
        }
    },[props.show]);

    return (
        <>
            <Modal show={props.show} onHide={props.onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" backdrop="static" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                외부인력 신규 ID 생성
                </Modal.Title>
            </Modal.Header>
            <Modal.Body id="signUpBody1">
                <h4>개인정보 수집 및 이용 동의서</h4>
                <p>
                <b>1. 개인정보의 수집 및 이용 목적</b><br />
                &ensp;본인은 WideTNS 에게 제공하는 개인정보가 다음의 목적을 위해 수집 및 이용됨에 동의합니다.<br />
                &ensp;수집된 개인정보를 외부인력 휴가 관리 및 연락처 관리로 활용하며, 목적 외의 용도로는 사용하지 않습니다.<br /><br />

                <b>2. 수집하는 개인정보의 항목</b><br />
                &ensp;WideTNS은 다음의 개인정보 항목을 수집합니다.<br />
                &ensp;성명<br />
                &ensp;전화번호<br />
                &ensp;이메일 주소<br /><br />

                <b>3. 개인정보의 보유 및 이용 기간</b><br />
                &ensp;WideTNS은 개인정보의 수집 및 이용 목적이 달성된 후에도 법령에 따른 개인정보 보유 및 이용 기간을 준수합니다.<br />
                &ensp;개인정보 수집 및 이용 목적이 달성된 후에는 지체 없이 해당 정보를 파기하거나 별도의 처리 방법에 따라 안전하게 처리합니다.<br /><br />

                <b>4. 개인정보의 제공 및 공유</b><br />
                &ensp;WideTNS은 본인의 동의 없이 개인정보를 외부에 제공하지 않습니다. 다만, 법령에 의해 규정된 경우에는 그러하지 않습니다.<br /><br />

                <b>5. 개인정보의 열람, 정정 및 삭제</b><br />
                &ensp;본인은 WideTNS에게 언제든지 제공한 개인정보에 대한 열람, 정정, 삭제를 요청할 수 있습니다. 이를 위해서는 WideTNS으로 문의하여야 합니다.<br /><br />

                <b>6. 동의 철회</b><br />
                &ensp;개인정보 제공에 대한 동의는 본인의 의사에 의해 철회할 수 있습니다. 이 경우, WideTNS은 본인의 개인정보를 파기하는 등 필요한 조치를 취합니다.<br /><br />

                <b>7. 기타</b><br />
                &ensp;이 개인정보 동의서에 명시되지 않은 사항은 관련 법령 및 WideTNS의 개인정보 처리 방침에 따릅니다.<br /><br />

                <b>본인은 본 개인정보 동의서 내용을 숙지하였으며, 동의합니다.</b>
                </p>
            </Modal.Body>
            <Modal.Body id="signUpBody2" className="hidden">
                <Form>
                    <FloatingLabel controlId="floatingId" label="USER ID" className="mb-3" >
                        <Form.Control type="textBox" onChange={(e) => setUserId(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPassword" label="Password" className="mb-3">
                        <Form.Control type="password" onChange={(e) => changePassowrd(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPasswordCheck" label="Password Check" className="mb-3">
                        <Form.Control type="password" onChange={(e) => pwCheck(e.target as HTMLInputElement)} />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingName" label="Name" className="mb-3" >
                        <Form.Control type="textBox" onChange={(e) => setName(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPhone" label="Phone" className="mb-3" >
                        <Form.Control type="textBox" onChange={(e) => checkPhoneNum(e.target as HTMLInputElement)} />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingEmail" label="E-mail" className="mb-3" >
                        <Form.Control type="textBox" onChange={(e) => setEmail(e.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingApprover" label="담당자" className="mb-3" >
                        <Form.Select onChange={(e) => setApprover(e.target.value)}>
                            {approverList && approverList.map(obj => {
                                const id = Object(obj).user_id;
                                const name = Object(obj).user_nm;
                                return (<option key={id} value={id}>{name}</option>);
                            })}
                        </Form.Select>
                    </FloatingLabel>
                </Form>
            </Modal.Body>
            <Modal.Footer id="signUpFooter1">
                <div className="button-wrap">
                    <Button variant="info" onClick={() => agreeTerms(true)}>동의합니다</Button>
                    <Button variant="danger" onClick={props.onHide}>동의하지 않습니다</Button>
                </div>
            </Modal.Footer>
            <Modal.Footer id="signUpFooter2" className="hidden">
                <div className="button-wrap">
                    <Button variant="danger" onClick={() => agreeTerms(false)}>이용 약관 다시 보기</Button>
                    <Button onClick={() => signUp()}>생성</Button>
                </div>
            </Modal.Footer>
            </Modal>
            <AlertModal title={alertTitle} body={alertBody} show={showAlert} setShow={setShowAlert} />
        </>
    );
}

export default SignUpModal;