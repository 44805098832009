import '../../../css/appr/Board.css'
import { useLocation, useNavigate } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, ModalBody, Table } from "react-bootstrap";
import AlertModal from '../../../module/AlertModal';
import axios from 'axios';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { RxCross2 } from 'react-icons/rx';
import { RiFileExcelLine, RiFilePptLine, RiFileWordLine, RiFileHwpLine, RiFileImageLine, RiFile3Line, RiFileTextLine, RiFilePdf2Line, RiFileVideoLine } from 'react-icons/ri';
import ApprLine from './ApprLine';
import { Tree } from '../../cmn/Tree';
import DraftConfirm from './DraftConfirm';
import ApprovalConfirm from './ApprovalConfirm';
import ApprPopComment from './ApprPopComment';
import { useMediaQuery } from 'react-responsive';
interface ApprProps {
    boxTpSignUserListCheck: String
    recentApprListCheck: recentApprListVO[],
    currUserInfo: {
        USER_POSIT_NM: string;
        DRFT_DT: string;
        USER_NM: string;
        USER_POSIT_CD: string;
        USER_DEPT_NM: string;
        APPR_SIGNATURE_USE_YN: string;
        USER_ID: string;
        USER_DEPT_CD: string;
        APPR_SIGNATUR_PATH: string;
    },
    signForm: {
        sign_line_type: string,
        retention: number,
        cooperation_yn: string,
        receive_yn: string,
        fixReceptionList: any[],
        fix_dept_nm: string,
        fix_dept_cd: string,
        deptList: any[],
        dept_auth_cd: string,
        dept_auth_nm: string,
        formCompList: any[],
        modify_yn: string,
        frm_ctg_name: string,
        sec_level_cd: string,
        output_file: string,
        use_yn: string,
        frm_num: string,
        revision_num: number,
        doc_tp_cd: string
    },
    subWorkerList: deptMemListVO[],
    actionStatus: string,
    varStatus: boolean,
    status: boolean,
    lastSeq: number,
    mode: string,
}
export interface DeptTree {
    dept_cd: string;
    dept_nm: string;
    dept_level: string;
    dept_upper_code: string;
    ordr_code: string;
    office_phone: string;
    lvl: string;
    user_id: string;
    user_nm: string;
    position_cd: string;
    job_title: string;
    email: string;
    user_office_phone: string;
    gender: string;
    children?: DeptTree[];
}

function ApprInfoArea({ boxTpSignUserListCheck, recentApprListCheck, currUserInfo, signForm, subWorkerList, actionStatus, varStatus, status, lastSeq, mode }: ApprProps) {
    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const location = useLocation();
    //다이렉트 휴가계 페이지 이동을 위한 변수
    const paramsD = new URLSearchParams(location.state?.menu_var);
    const jsonD = {
        frm_id: paramsD.get('frm_id'),
        text: paramsD.get('text'),
        vacation_yn: 'Y',
        cooperation_yn: 'N',
        mode: 'edit'
    }
    const location_id = location.state?.id !== undefined ? location.state?.id : jsonD.frm_id;
    const location_mode = mode;
    const location_text = location.state?.text !== undefined ? location.state?.text : jsonD.text;
    const pre_param = actionStatus.length > 16 ? JSON.parse(actionStatus) : jsonD;
    const [alertBody, setAlertBody] = useState('');
    const [showAlertModal, setShowAlertModal] = useState(false);


    const [vacStartDate, setVacStartDate] = useState(new Date());
    const [vacEndDate, setVacEndDate] = useState(new Date());
    const [vacTotal, setVacTotal] = useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    //* 한개 페이지로의 개발

    const [recentApprList, setRecentApprList] = useState<recentApprListVO[]>([]);
    const [signUserList, setSignUserList] = useState<signUserListVO[]>([]);

    const [recentReadList, setRecentReadList] = useState<recentReadListVO[]>([]);
    const [recentRecvList, setRecentRecvList] = useState<recentRecvListVO[]>([]);
    const [signDoc, setSignDoc] = useState({
        sign_id: '',
        frm_id: '',
        doc_tp_cd: '',
        recv_flag: '',
        auth_dept_cd: '',
        retention: '',
        sec_level_cd: '',
        urgency_yn: '',
        security_yn: '',
        security_bgnde: '',
        security_endde: '',
        arbitrary_cont1: '',
        arbitrary_cont2: '',
        sign_doc_title: '',
        doc_sts_cd: '',
        drft_dt: Date,
        drft_user_id: '',
        drft_user_nm: '',
        drft_user_posit_cd: '',
        drft_user_posit_nm: '',
        drftper_dept_cd: '',
        drftper_dept_nm: '',
        send_dept_cd: '',
        org_sign_id: '',
        docno: '',
        old_sts_cd: '',
        aid: '',
        did: '',
        revision_id: 0,
        reg_dt: Date,
        reg_user_id: '',
        edit_dt: Date,
        edit_user_id: '',
    });
    const [bodyContent, setBodyContent] = useState<string[][]>([]);

    const addProduct = (productName: string, amount: string, unitPrice: string, sumOfMoney: string, requester: string) => {
        // 새로운 데이터 배열 생성
        setBodyContent(prevBodyContent => {
            const updatedContent = Array.isArray(prevBodyContent) ? prevBodyContent : [];
            return [
                ...updatedContent, // 기존 데이터 유지
                [productName, amount, unitPrice, sumOfMoney, requester], // 새로운 상품 데이터 추가
            ]
        });
    };
    // _attrLineList와 동일한 다차원 배열을 상태로 관리
    const [attrLineList, setAttrLineList] = useState<string[][]>([]);
    const addAttrLine = (newAttrLine: string[]) => {
        setAttrLineList([...attrLineList, newAttrLine]);
    };

    const [cooperationList, setCooperationList] = useState<string[]>([]);
    const [actionStatusset, setActionStatus] = useState('');
    const [docUpdeAuth, setDocUpdeAuth] = useState(false);
    const [lineUpdeAuth, setLineUpdeAuth] = useState(false);
    const [drafter, setDrafter] = useState<string[]>([]);
    const [draftDate, setdDraftDate] = useState('');
    const [docNo, setDocNo] = useState('');
    const drafterString: string[] = [];
    let docNoString;
    let draftDateString;

    const test: any[] = [];

    const [orgSignId, setOrgSignId] = useState(''); // 초기 값 설정
    // 상태 관리
    const [retention, setRetention] = useState('15'); // 초기 값 설정
    const retentionList = [1, 2, 3, 5, 10, 15, 20]; // 보존 연한 목록

    // 이벤트 핸들러 타입 명시적으로 지정
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setRetention(event.target.value);
    };

    // 날짜 선택
    const [dateRange, setDateRange] = useState({
        start: new Date(), // 현재 날짜로 시작
        end: new Date(),   // 현재 날짜로 종료
    });

    // 날짜 범위 변경 핸들러
    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const newStartDate = new Date(event.target.value);
        if (newStartDate >= vacEndDate) {
            setDateRange((prevRange) => ({
                ...prevRange,
                start: newStartDate,
                end: newStartDate
            }));

            dateCalculate(newStartDate, newStartDate);
        } else {
            setDateRange((prevRange) => ({
                ...prevRange,
                start: newStartDate,
            }));

            dateCalculate(newStartDate, vacEndDate);
        }

        setVacStartDate(newStartDate);

    };
    async function dateCalculate(vacStartDate: Date, vacEndDate: Date) {
        if (location_id === '0000000000000002' || pre_param.frm_id === '0000000000000002') {
            setTermsDays("0.5");
        } else {
            const StartDate = new Date(vacStartDate).toISOString().slice(0, 10).replace(/-/g, '');
            const EndDate = new Date(vacEndDate).toISOString().slice(0, 10).replace(/-/g, '');
            holidayRange.forEach(item => {

                const MaxDate = new Date(item.split(' ~ ')[1].substring(0, 10));
                const MinDate = new Date(item.split(' ~ ')[0].substring(0, 10));
                const test1 = new Date(MaxDate).toISOString().slice(0, 10).replace(/-/g, '');
                const test2 = new Date(MinDate).toISOString().slice(0, 10).replace(/-/g, '');

                if (Number(StartDate) <= Number(test1)) {
                    if (Number(StartDate) >= Number(test2)) {
                        setMaxholidayRange(test1.slice(0, 4) + '-' + test1.slice(4, 6) + '-' + test1.slice(6, 8));
                    }
                }
            })
            try {
                const response = await axios.post(
                    "/api/appr/formContents/DateCalculate.ajax",
                    {
                        strSDT: StartDate,
                        strEDT: EndDate,
                    },
                    { headers: { "Content-Type": "application/json" } }
                );
                const rtn = response.data;

                setTermsDays(rtn);
            } catch (error) {
                alert("Failed axios : ");
            }
        }
    }

    const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEndDate = new Date(event.target.value);
        setDateRange((prevRange) => ({
            ...prevRange,
            end: newEndDate,
        }));
        setVacEndDate(newEndDate);
        dateCalculate(vacStartDate, newEndDate);
    };
    const [treeData, setTreeData] = useState<DeptTree[]>([]);

    // 본문내용
    const [vacContent, setVacContent] = useState('');
    // 제목
    const [vacTitle, setVacTitle] = useState('');
    // 제목
    const [vacDestination, setVacDestination] = useState('');
    // 연락처
    const [vacPhoneTell, setVacPhoneTell] = useState('');
    // 파일 선택
    const [fileList, setFileList] = useState<AttchFiles[] | null>(null);
    const [flSize, setFlSize] = useState('0 MB');
    // 반차
    const [vacHalf, setVacHalf] = useState('M');
    //결재선지정
    // attrLineList 상태에 데이터를 추가하는 예시 함수
    const [termsDays, setTermsDays] = useState('');
    const [holidayRange, setHolidayRange] = useState<string[]>([]);
    const [minholidayRange, setMinholidayRange] = useState('');
    const [maxholidayRange, setMaxholidayRange] = useState('');
    useEffect(() => {
        const getData_main = async () => {
            try {
                const response = await axios.post('/api/admin/selectPersonalHolidayRange', null, { params: { "user_id": sessionStorage.getItem("user_id") } });
                setHolidayRange(response.data);
                setMinholidayRange(response.data[response.data.length - 1]);
            } catch (error) {
                alert("Failed axios : ");
            }
            if (location_id === "0000000000000002") {
                setTermsDays("0.5");
            } else {
                setTermsDays("1");
            }
            try {
                if (location_mode === 'edit') {
                    addProduct('', '', '', '', '');
                    getApprForm(location_id);
                } else {
                    authCheck();
                }
            } catch (error) {
                alert("Failed axios : ");
            } finally {
                try {
                    const response2 = await axios.post(
                        "/api/cmn/setting/selectDeptTree.axios",
                        {},
                        { headers: { "Content-Type": "application/json" } }
                    );
                    const rtn2 = response2.data;
                    setTreeData(rtn2);
                } catch (error) {
                    alert("Failed axios : ");
                }
            }

        }
        getData_main();
    }, []);
    const [delegate, setDelegate] = useState<string>("");
    function selectButton() {

        setDelegate(paramUser.get("user_nm") +
            "/" +
            paramUser.get("user_posit_nm") +
            "/" +
            paramUser.get("dept_nm"))
        handleClose();
    }

    function removeAttch(key?: string) {
        const dt = new DataTransfer();
        if (key) {
            let nKey: number;
            if (/^[0-9]+$/.test(key)) nKey = Number(key);
            else return;
            const files = (document.getElementById('attchFilesInp') as HTMLInputElement).files;
            const arr = Array.from(files || []);
            arr.splice(nKey, 1);
            arr?.forEach(file => dt.items.add(file));
            (document.getElementById('attchFilesInp') as HTMLInputElement).files = dt.files;
        }
        const newFiles = dt.files;
        const newArr: object[] = [];
        let size = 0;
        if (newFiles) {
            for (let i = 0; i < newFiles.length; i++) {
                size += newFiles[i].size;
                newArr.push({ [i]: newFiles[i].name });
            }
        }
        setFlSize(calcSize(size));
        setFileList(newArr);
    }

    function calcSize(byteSize: number) {
        const mbSize = Math.round(byteSize * 10 / Math.pow(2, 20)) / 10.0;
        const kbSize = Math.round(byteSize * 10 / Math.pow(2, 10)) / 10.0;
        if (byteSize === 0)
            return '0 MB';
        else if (kbSize === 0)
            return byteSize + ' Bytes';
        else if (mbSize === 0)
            return (kbSize > 10 ? Math.round(kbSize) : kbSize) + ' KB';
        else
            return (mbSize > 10 ? Math.round(mbSize) : mbSize) + ' MB';
    }


    // 연락처 폼 변환
    const formatPhoneNumber = (value: string) => {
        // Remove non-digit characters
        const cleaned = ('' + value).replace(/\D/g, '');

        if (cleaned.length <= 3) {
            return cleaned; // Return as is for 1-3 digits
        } else if (cleaned.length <= 7) {
            return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`; // Format as xxx-x
        } else {
            return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 7)}-${cleaned.slice(7)}`; // Format as xxx-xxxx-x
        }
    };
    const handlePhoneChange = (e: { target: { value: any; }; }) => {
        const rawValue = e.target.value;
        const formattedValue = formatPhoneNumber(rawValue);
        setVacPhoneTell(formattedValue);
    };


    function setIcon(ext: string) {
        let res: ReactNode;
        if (!ext) res = iconMap.etc;
        else {
            switch (ext.toLowerCase().trim()) {
                case 'xls': case 'xlsx':
                    res = iconMap.excel;
                    break;
                case 'doc': case 'docx':
                    res = iconMap.word;
                    break;
                case 'ppt': case 'pptx':
                    res = iconMap.ppt;
                    break;
                case 'hwp': case 'hwpx':
                    res = iconMap.hwp;
                    break;
                case 'pdf':
                    res = iconMap.pdf;
                    break;
                case 'jpg': case 'jpeg': case 'png':
                case 'gif': case 'bmp': case 'svg':
                    res = iconMap.img;
                    break;
                case 'mp4': case 'mov': case 'wmv':
                case 'avi': case 'flv': case 'f4v':
                case 'swf': case 'mkv':
                    res = iconMap.video;
                    break;
                case 'txt': case 'md':
                    res = iconMap.txt;
                    break;
                default:
                    res = iconMap.etc;
                    break;
            }
        }
        return res;
    }

    async function getApprForm(location_id: String) {
        await axios.post(
            "/api/appr/signForm/writeSignForm.do",
            {
                user_id: sessionStorage.getItem("user_id"),
                frm_id: location_id,
                lang: "ko",
            },
            { headers: { "Content-Type": "application/json" } }
        ).then(async response => {
            const rtn = response.data;
            const newSubWorkerList: string[] = [];
            const deptMem: [] = rtn.deptMem;
            let recentApprList: any[] = rtn.recentApprList;

            setRecentReadList(rtn.recentReadList);
            deptMem?.forEach((element, index) => {
                if (element["USER_ID"] !== sessionStorage.getItem('user_id')) {
                    const subWorkerListMap = `${element["USER_NM"]}/${element["USER_POSIT_NM"]}/${element["USER_DEPT_NM"]}`;
                    newSubWorkerList.push(subWorkerListMap); // 배열에 추가
                }
            })
            if (recentApprList === null) {
                recentApprList = [];
                recentApprList.push({
                    SIGN_USER_ID: String(sessionStorage.getItem("user_id")),
                    SIGN_USER_DEPT_NM: String(sessionStorage.getItem("dept_nm")),
                    FILE_UPDE_AUTH_YN: 'Y',
                    LINE_UPDE_AUTH_YN: 'Y',
                    SIGN_SEQ: 0,
                    SIGN_USER_POSIT_CD: String(sessionStorage.getItem("position_cd")),
                    DOC_UPDE_AUTH_YN: 'Y',
                    SIGN_USER_POSIT_NM: String(sessionStorage.getItem("position_nm")),
                    SIGN_TP_CD: 'DR',
                    SIGN_TP_NM: '기안',
                    SIGN_USER_NM: String(sessionStorage.getItem("user_nm")),
                    SIGN_USER_DEPT_CD: String(sessionStorage.getItem("dept_cd"))
                })
            }

            recentApprList?.forEach((element, index) => {
                const signTpCd = element["SIGN_TP_CD"];
                if (signTpCd !== "SA" && signTpCd !== "PA" && signTpCd !== "PS"
                    && signTpCd !== "PP" && signTpCd !== "SAD" && signTpCd !== "PAD"
                    && signTpCd !== "PSAD" && signTpCd !== "PPAD" && signTpCd !== "DSAD"
                    && signTpCd !== "DPAD" && signTpCd !== "DSA" && signTpCd !== "DPA"
                    && signTpCd !== "NA" && signTpCd !== "CF" && signTpCd !== "PCF") {
                    test.push([element["SIGN_USER_POSIT_NM"],
                    index === 0 ? element["SIGN_TP_NM"] : "",
                    element["SIGN_USER_NM"],
                    index === 0 ? rtn?.currUserInfo?.DRFT_DT.substring(0, 10) : "",]);
                } else if (signTpCd === "SA" || signTpCd === "PS" || signTpCd === "PP"
                    || signTpCd === "PA" || signTpCd === "DSA" || signTpCd === "DPA") {
                    const cooperationString = element["SIGN_USER_NM"] !== null ? `${element["SIGN_USER_NM"]}/${element["SIGN_USER_POSIT_NM"]}/${element["SIGN_USER_DEPT_NM"]} ` : element["SIGN_USER_DEPT_NM"];

                    setCooperationList((prevList) => [...prevList, cooperationString]);
                }
            })

            setAttrLineList(test);
            setSignUserList(recentApprList);
            setRecentApprList(recentApprList);
            setRecentReadList(rtn.recentReadList ? rtn.recentReadList : null);
            setRecentRecvList(rtn.recentRecvList ? rtn.recentRecvList : null);
            setActionStatus(rtn.actionStatus);
            actionStatus = rtn.actionStatus;
            drafterString.push(`${rtn.currUserInfo?.USER_NM}/${rtn.currUserInfo?.USER_POSIT_NM}/${rtn.currUserInfo?.USER_DEPT_NM}`);
            setDrafter(drafterString);
            docNoString = '@a@Y-@n';
            draftDateString = rtn.currUserInfo?.DRFT_DT.substring(0, 10);
            setdDraftDate(draftDateString);
            setDocNo(docNoString);
        });

        if (actionStatus === "DR" || actionStatus === "TS" || actionStatus === "RW") {
            setLineUpdeAuth(true);
            setDocUpdeAuth(true);
        }
    }
    let actionStatusCheck: any;
    async function authCheck() {
        await axios.post(
            "/api/appr/signForm/viewSignForm.do",
            {
                sign_id: pre_param.sign_id,
                mode: pre_param.mode,
                relDocViewYn: "N",
                user_id: sessionStorage.getItem("user_id"),
                dept_cd: sessionStorage.getItem("dept_cd"),
                position_cd: sessionStorage.getItem("position_cd"),
                lang: "ko",
                priv_cd: sessionStorage.getItem("priv_cd"),
            },
            { headers: { "Content-Type": "application/json" } }
        ).then(async response => {
            const rtn = response.data;

            actionStatusCheck = rtn.actionStatus;
            setActionStatus(rtn.actionStatus);
            if (pre_param.mode === 'sView') {
                setActionStatus('CP');
            }
            setDocUpdeAuth(rtn.doc_upde_auth_yn === "Y" ? true : false);
            setLineUpdeAuth(rtn.line_upde_auth_yn === "Y" ? true : false);

            if (rtn.type === 'free') {
                _getApprDetail(); // 보안결재 아님
            } else if (rtn.type === 'priv') {
                toast('문서열람 권한이 없습니다.', { position: 'top-center', autoClose: 2000 });
                return;
            } else if (rtn.type === 'pass') {
                toast('개발 중 입니다.', { position: 'top-center', autoClose: 2000 });
                navigate('/appr/easMain');
            }
        });
    }

    async function _getApprDetail() {
        const response = await axios.post(
            "/api/appr/signForm/securityAppr.do",
            {
                mode: mode,
                sign_id: pre_param.sign_id,
                actionStatus: actionStatusCheck,
                user_id: sessionStorage.getItem("user_id"),
                lang: "ko",
                priv_cd: sessionStorage.getItem("priv_cd"),
            },
            { headers: { "Content-Type": "application/json" } }
        );
        const rtn = response.data;
        setSignDoc(rtn.signDoc);

        let signuser_list: signUserListVO[] = [];
        signuser_list = rtn.signUserList;
        const actionStatusResult = rtn.actionStatus;

        setActionStatus(rtn.actionStatus);
        subWorkerList?.forEach((element, index) => {
            if (element["USER_ID"] !== (signDoc.drft_user_id ? signDoc.drft_user_id : "")) {
                const subWorkerMap = element["USER_NM"] + "/" + element["USER_POSIT_NM"] + "/" + element["USER_DEPT_NM"];
                //addAttrLine이 아닌 다른것으로 교체 필요
                addAttrLine([subWorkerMap]);
            }
        })

        if (signuser_list?.length === 0) {
            signUserList.push({
                SIGN_USER_ID: String(sessionStorage.getItem("user_id")),
                SIGN_USER_DEPT_NM: String(sessionStorage.getItem("dept_nm")),
                FILE_UPDE_AUTH_YN: 'Y',
                LINE_UPDE_AUTH_YN: 'Y',
                SIGN_SEQ: 0,
                SIGN_USER_POSIT_CD: String(sessionStorage.getItem("position_cd")),
                DOC_UPDE_AUTH_YN: 'Y',
                SIGN_USER_POSIT_NM: String(sessionStorage.getItem("position_nm")),
                SIGN_TP_CD: 'DR',
                SIGN_TP_NM: '기안',
                SIGN_USER_NM: String(sessionStorage.getItem("user_nm")),
                SIGN_USER_DEPT_CD: String(sessionStorage.getItem("dept_cd"))
            })
        } else {
            signuser_list?.forEach((element, index) => {
                if (actionStatusResult === 'RA' || actionStatusResult === 'RW' || actionStatusResult === 'DARA' || actionStatusResult === 'DARW') {
                    const signTpCd = signuser_list[index].SIGN_TP_CD;

                    if (signTpCd !== "SA" && signTpCd !== "PA" && signTpCd !== "PS"
                        && signTpCd !== "PP" && signTpCd !== "SAD" && signTpCd !== "PAD"
                        && signTpCd !== "PSAD" && signTpCd !== "PPAD" && signTpCd !== "DSAD"
                        && signTpCd !== "DPAD" && signTpCd !== "DSA" && signTpCd !== "DPA"
                        && signTpCd !== "NA" && signTpCd !== "CF" && signTpCd !== "PCF") {
                        test.push([
                            element.SIGN_USER_POSIT_NM,
                            element.SIGN_DT !== null ? element.SIGN_TP_NM : "",
                            element.SIGN_USER_NM,
                            element.SIGN_DT !== null ? element.SIGN_DT.substring(0, 10) : "",
                            element.comment_yn,
                        ]);
                    } else if (signTpCd === "SA" || signTpCd === "PS" || signTpCd === "PP"
                        || signTpCd === "PA" || signTpCd === "DSA" || signTpCd === "DPA") {
                        const nm = element.sign_user_nm ? `${element.sign_user_nm}/${element.sign_user_posit_nm}/${element.sign_user_dept_nm}` : element.sign_user_dept_nm;
                        const dt = element.sign_sts_cd === "C1" ? `(${element.sign_dt.substring(0, 10)}, 합의)` : "";
                        const cooperationString = nm + dt;
                        setCooperationList((prevList) => [...prevList, cooperationString]);
                    } else {
                        const signTpCd = element.sign_tp_cd;

                        if (signTpCd !== "SA" && signTpCd !== "PA" && signTpCd !== "PS"
                            && signTpCd !== "PP" && signTpCd !== "SAD" && signTpCd !== "PAD"
                            && signTpCd !== "PSAD" && signTpCd !== "PPAD" && signTpCd !== "DSAD"
                            && signTpCd !== "DPAD" && signTpCd !== "DSA" && signTpCd !== "DPA"
                            && signTpCd !== "NA" && signTpCd !== "CF" && signTpCd !== "PCF") {

                            test.push([
                                element.sign_user_posit_nm,
                                element.sign_dt !== null ? element.sign_tp_cd : "",
                                element.sign_user_nm,
                                element.sign_dt !== null ? element.sign_dt.substring(0, 10) : "",
                                element.comment_yn,
                            ]);
                        } else if (signTpCd === "SA" || signTpCd === "PS" || signTpCd === "PP"
                            || signTpCd === "PA" || signTpCd === "DSA" || signTpCd === "DPA") {
                            const nm = element.sign_user_nm !== null ? `${element.sign_user_nm}/${element.sign_user_posit_nm}/${element.sign_user_dept_nm}` : element.sign_user_dept_nm;
                            const dt = element.sign_sts_cd === "C1" ? `(${element.sign_dt.substring(0, 10)}, 합의)` : "";
                            const cooperationString = nm + dt;
                            setCooperationList((prevList) => [...prevList, cooperationString]);
                        }
                    }
                } else {

                    const signTpCd = element.sign_tp_cd;

                    if (signTpCd !== "SA" && signTpCd !== "PA" && signTpCd !== "PS"
                        && signTpCd !== "PP" && signTpCd !== "SAD" && signTpCd !== "PAD"
                        && signTpCd !== "PSAD" && signTpCd !== "PPAD" && signTpCd !== "DSAD"
                        && signTpCd !== "DPAD" && signTpCd !== "DSA" && signTpCd !== "DPA"
                        && signTpCd !== "NA" && signTpCd !== "CF" && signTpCd !== "PCF") {

                        test.push([
                            element.sign_user_posit_nm,
                            element.sign_dt !== null ? element.sign_tp_nm : "",
                            element.sign_user_nm,
                            element.sign_dt !== null ? element.sign_dt.substring(0, 10) : "",
                            element.comment_yn,
                        ]);
                    } else if (signTpCd === "SA" || signTpCd === "PS" || signTpCd === "PP"
                        || signTpCd === "PA" || signTpCd === "DSA" || signTpCd === "DPA") {
                        const nm = element.sign_user_nm !== null ? `${element.sign_user_nm}/${element.sign_user_posit_nm}/${element.sign_user_dept_nm}` : element.sign_user_dept_nm;
                        const dt = element.sign_sts_cd === "C1" ? `(${element.sign_dt.substring(0, 10)}, 합의)` : "";
                        const cooperationString = nm + dt;

                        setCooperationList((prevList) => [...prevList, cooperationString]);
                    }
                }

            })
            setSignUserList(rtn.signUserList);
            setRecentApprList(rtn.signUserList);
            setRecentReadList(rtn.signReadList ? rtn.signReadList : null);
            setRecentRecvList(rtn.signRecvList ? rtn.signRecvList : null);
            setFileList(rtn.fileList ? rtn.fileList : null)
            drafterString.push(`${rtn.signDoc.drft_user_nm}/${rtn.signDoc.drft_user_posit_nm}/${rtn.signDoc.drftper_dept_nm}`);
            setDrafter(drafterString);
            draftDateString = rtn?.signDoc.drft_dt.substring(0, 10);
            setdDraftDate(draftDateString);

            setDocNo(rtn.signDoc.docno);
            setRetention(rtn.signDoc.retention);
            setOrgSignId(rtn.signDoc.org_sign_id);
            setAttrLineList(test);
            let bodyContents = rtn.signDocDtl.bodyContents;
            if (pre_param.vacation_yn === 'Y') {

            }
            try {
                bodyContents = JSON.parse(bodyContents);
            } catch (error) {
                const cleanedBodyContents = bodyContents.replace(/&nbsp;/g, " ").replace(/<([^>]+)>/g, "");
                bodyContents = { reason: cleanedBodyContents };
            }

            setBodyContent(bodyContents);
            let count = 0;
            while (bodyContents.hasOwnProperty('product_name_' + (count))) {
                count++;
            }
            for (let i = 0; i < count; i++) {
                let productName = bodyContents['product_name_' + i];
                let amount = bodyContents['amount_' + i];
                let unitPrice = bodyContents['unit_price_' + i];
                let sumOfMoney = bodyContents['sum_of_money_' + i];
                let requester = bodyContents['requester_' + i];
                addProduct(productName, amount, unitPrice, sumOfMoney, requester);
            }
            setVacTotal(bodyContents.total);

            setVacContent(bodyContents.reason ? bodyContents.reason : bodyContents);
            setVacDestination(bodyContents.destination)
            setVacPhoneTell(bodyContents.tel)
            setDelegate(bodyContents.etc);
            if (location_id === '0000000000000002' || pre_param.frm_id === '0000000000000002') {

                if (bodyContents.halfDay === 'morning') {
                    setVacHalf('M');
                } else {
                    setVacHalf('A');
                }

            }
            const s_date = new Date(
                bodyContents.s_year + "-" +
                String(bodyContents.s_month).padStart(2, '0') + "-" +
                String(bodyContents.s_day).padStart(2, '0'));
            const e_date = new Date(
                bodyContents.e_year + "-" +
                String(bodyContents.e_month).padStart(2, '0') + "-" +
                String(bodyContents.e_day).padStart(2, '0'));
            setDateRange((prevRange) => ({
                ...prevRange,
                start: s_date,
                end: e_date,
            }));
            setTermsDays(bodyContents.terms_days);
            setVacTitle(rtn.signDoc.sign_doc_title);
        }



    }

    function selectTreeView() {
        setShow(true);
    }

    const navigate = useNavigate();

    function closeModal() {
        setShowModal(false);
    }

    const parentFunction = (location_id: any) => {
        setShowModal(false);
        getApprForm(location_id);
    }
    const [showModal, setShowModal] = useState(false); // Modal visibility state
    const [content, setContent] = useState<React.ReactElement | null>(null); // State to manage modal content
    let boolmode = false;
    // Function to handle showing the modal
    const handleButtonClick = () => {
        if (actionStatusset === "DR" || actionStatusset === "TS" || actionStatusset === "RW" || actionStatusset === "RD" || actionStatusset === "DARW") {
            boolmode = true;
            const rtn: React.ReactElement = (
                <ApprLine
                    vac_id=''
                    show={true}
                    closeFunc={closeModal}
                    saveFunc={parentFunction}
                    mode={boolmode}
                    recentApprList={recentApprList}
                    actionstatus={actionStatusset}
                    signUserList={signUserList}
                    recvList={recentRecvList}
                    readList={recentReadList}
                    frm_id={pre_param.frm_id}
                />
            );
            setContent(rtn); // Set the content for the modal
        }
        if (actionStatusset === "AS" || actionStatusset === "CF" || actionStatusset === "HCF") {
            boolmode = false;
            const rtn: React.ReactElement = (
                <ApprLine
                    vac_id=''
                    show={true}
                    closeFunc={closeModal}
                    saveFunc={parentFunction}
                    mode={boolmode}
                    recentApprList={recentApprList}
                    actionstatus={actionStatusset}
                    signUserList={signUserList}
                    recvList={recentRecvList}
                    readList={recentReadList}
                    frm_id={pre_param.frm_id}
                />
            );
            setContent(rtn); // Set the content for the modal
        }



        setShowModal(true); // Show the modal
    };

    const [checkBackGroundColorId, setCheckBackGroundColorId] = useState("");
    const [paramUser, setParamUser] = useState(new Map<string, string>());
    const handleVacWorkerClick = (user_id: string) => {
        if (checkBackGroundColorId !== user_id) {
            const myDiv_old = document.getElementById(checkBackGroundColorId);
            const myDiv_new = document.getElementById(user_id);

            if (myDiv_old) {
                myDiv_old.style.backgroundColor = "white";
            }
            if (myDiv_new) {
                myDiv_new.style.backgroundColor = "lightblue";
            }
        } else {

        }
        setCheckBackGroundColorId(user_id);

    };

    const [apprCheck, setApprCheck] = useState(false);
    const handleSubmitClose = () => setApprCheck(false);
    const newMap = new Map();
    const submitDraft = async (temp: Boolean) => {

        let multi;
        if (actionStatusset === 'RW' || actionStatusset === 'DARW') {
            multi = true;
        } else {
            multi = false;
        }
        signSetting(temp, false);
        // 기안자 의견


        if (!temp) {
            const comment_Element = document.getElementById('comment') as HTMLTextAreaElement;
            const comment_Value = comment_Element?.value;

            // 긴급 결재문서 YN
            const chkUrgencyYn_Element = document.getElementById('chkUrgencyYn') as HTMLInputElement;
            const chkUrgencyYn_Value = chkUrgencyYn_Element.checked;

            // 보안 등급
            const selected_security_level_cd_Element = document.getElementById("selected_security_level_cd_Value") as HTMLInputElement;
            const selected_security_level_cd_Value = selected_security_level_cd_Element?.value;

            // 보안 결재 YN
            const chkSecurityAppr_Element = document.getElementById('chkSecurityAppr') as HTMLInputElement;
            const chkSecurityAppr_Value = chkSecurityAppr_Element.checked;

            newMap.set("urgency_yn", chkUrgencyYn_Value === true ? "Y" : "N");
            newMap.set("sec_level_cd", selected_security_level_cd_Value);
            newMap.set("security_yn", chkSecurityAppr_Value === true ? "Y" : "N");

            if (chkSecurityAppr_Value === true) {
                // 보안 결재 시작 날짜
                const security_bgnde_Element = document.getElementById('security_bgnde') as HTMLInputElement;
                const security_bgnde_Value = security_bgnde_Element.value;
                // 보안 결재 끝 날짜
                const security_endde_Element = document.getElementById('security_endde') as HTMLInputElement;
                const security_endde_Value = security_endde_Element.value;

                newMap.set("security_bgnde", security_bgnde_Value);
                newMap.set("security_endde", security_endde_Value);
            } else {
                newMap.set("security_bgnde", "");
                newMap.set("security_endde", "");
            }

            newMap.set("comment", comment_Value ? comment_Value : "");
        }
        let rtn;
        if (multi) {
            const response = await axios.post(
                "/api/appr/signProc/mDraftSignForm.ajax",
                Object.fromEntries(newMap), // Map을 객체로 변환,
                { headers: { "Content-Type": "application/json" } }
            );
            rtn = response.data;
        } else {
            const response = await axios.post(
                "/api/appr/signProc/draftSignForm.ajax",
                Object.fromEntries(newMap), // Map을 객체로 변환,
                { headers: { "Content-Type": "application/json" } }
            );
            rtn = response.data;
        }

        if (pre_param.vacation_yn === "Y") {
            const vacationMap = new Map();
            vacationMap.set("sign_id", rtn.sign_id);
            vacationMap.set("user_id", sessionStorage.getItem("user_id"));
            vacationMap.set("tempSaveYn", newMap.get("tempSaveYn"));

            if (pre_param.frm_id === "0000000000000002") {
                vacationMap.set("s_year", dateRange.start.getFullYear());
                vacationMap.set("s_month", dateRange.start.getMonth());
                vacationMap.set("s_day", dateRange.start.getDay());
                vacationMap.set("e_year", dateRange.end.getFullYear());
                vacationMap.set("e_month", dateRange.end.getMonth());
                vacationMap.set("e_day", dateRange.end.getDay());
                vacationMap.set("harf_vacation_tp", vacHalf === "M" ? "morning" : "afternoon");
            } else {
                vacationMap.set("s_year", dateRange.start.getFullYear());
                vacationMap.set("s_month", dateRange.start.getMonth());
                vacationMap.set("s_day", dateRange.start.getDay());
                vacationMap.set("e_year", dateRange.end.getFullYear());
                vacationMap.set("e_month", dateRange.end.getMonth());
                vacationMap.set("e_day", dateRange.end.getDay());
            }
            vacationMap.set("reason", vacContent);
            //경조사, 교육/훈련, 출산 휴가계는 차감X(minus_yn="N")
            vacationMap.set("minus_yn", pre_param.frm_id === "0000000000000003" || pre_param.frm_id === "0000000000000004" || pre_param.frm_id === "0000000000000005" ? "N" : "Y");
            vacationMap.set("terms_days", termsDays);

            if (rtn.last_yn !== null && rtn.last_yn === "Y") {
                await axios.post(
                    "/api/appr/formContents/ins_uptVacation.ajax",
                    Object.fromEntries(vacationMap), // Map을 객체로 변환,
                    { headers: { "Content-Type": "application/json" } }
                );

            } else {
                await axios.post(
                    "/api/appr/formContents/insertVacation.ajax",
                    Object.fromEntries(vacationMap), // Map을 객체로 변환,
                    { headers: { "Content-Type": "application/json" } }
                );

            }
        }

        const formData = new FormData();
        formData.append("user_id", sessionStorage.getItem("user_id") as string);
        formData.append("lang", "ko");
        formData.append("sign_id", rtn.sign_id);
        if (rtn.prev_aid !== null && rtn.prev_aid !== "") {
            formData.append("prev_aid", rtn.prev_aid);
        }
        formData.append("actionStatus", actionStatusset);
        formData.append("reDraft", reDraft);

        formData.append("file_seq_array", fileList?.map(map => map.seq).join(', ') || '');

        const attchFiles = (document.getElementById('attchFilesInp') as HTMLInputElement);
        if (attchFiles.files) {
            for (let i = 0; i < attchFiles.files.length; i++) {
                formData.append('uploadFiles[' + i + ']', attchFiles.files[i]);
            }
        }
        axios.post('/api/appr/signProc/generalUpload.ajax', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
            navigate('/appr/easMain');
        });
        setApprCheck(false);
    }
    let reDraft = "N";
    let reDraftMode = "";
    let halfDay = new Date();
    const signSetting = (temp: Boolean, coorection: Boolean) => {

        if (actionStatusset === 'RD') {
            reDraft = "Y";
            reDraftMode = "update";
            setActionStatus('DR');
        }
        newMap.set("sign_id", pre_param.sign_id ? pre_param.sign_id : "");
        newMap.set("aid", signDoc ? signDoc.aid : "");
        newMap.set("opn_tp_cd", coorection ? "RC" : "RA");

        if (temp) {
            newMap.set("tempSaveYn", "Y");
        } else if (actionStatusset === "DR") {
            newMap.set("tempSaveYn", "N");
        } else {
            newMap.set("tempSaveYn", "T");
        }

        newMap.set("reDraft", reDraft);
        newMap.set("reDraftMode", reDraftMode ? reDraftMode : "");
        newMap.set("actionStatus", actionStatusset);
        newMap.set("user_id", sessionStorage.getItem("user_id"));
        newMap.set("dept_cd", sessionStorage.getItem("dept_cd"));
        newMap.set("lang", "ko");
        newMap.set("curDonePos", 0);

        //WG_SIGN_DOC param
        newMap.set("frm_id", pre_param.frm_id);
        newMap.set("retention", retention);
        newMap.set("sign_doc_title", signDoc.sign_doc_title ? signDoc.sign_doc_title : vacTitle);
        newMap.set("drft_user_id", sessionStorage.getItem("user_id"));
        newMap.set("drft_user_nm", sessionStorage.getItem("user_nm"));
        newMap.set("drft_user_posit_cd", sessionStorage.getItem("position_cd"));
        newMap.set("drft_user_posit_nm", sessionStorage.getItem("position_nm"));
        newMap.set("drftper_dept_cd", sessionStorage.getItem("dept_cd"));
        newMap.set("drftper_dept_nm", sessionStorage.getItem("dept_nm"));
        newMap.set("docno", docNo);
        newMap.set("reg_user_id", sessionStorage.getItem("user_id"));
        newMap.set("edit_user_id", sessionStorage.getItem("user_id"));
        newMap.set("revision_id", 1);

        //WG_SIGN_DOC_DTL param
        let bodyContents_c;
        if (pre_param.vacation_yn === "Y") {
            if (pre_param.frm_id === "0000000000000002") {
                halfDay = new Date(dateRange.end.toISOString().slice(0, 10));

                bodyContents_c = {
                    terms_days: termsDays,
                    destination: vacDestination,
                    tel: vacPhoneTell,
                    etc: delegate,
                    reason: vacContent,
                    s_year: halfDay.getFullYear(),
                    s_month: halfDay.getMonth() + 1,
                    s_day: halfDay.getDate(),
                    e_year: halfDay.getFullYear(),
                    e_month: halfDay.getMonth() + 1,
                    e_day: halfDay.getDate(),
                    halfDay: (vacHalf === "M" ? "morning" : "afternoon"),
                };
            } else {
                bodyContents_c = {
                    terms_days: termsDays,
                    destination: vacDestination,
                    tel: vacPhoneTell,
                    etc: delegate,
                    reason: vacContent,
                    s_year: dateRange.start.getFullYear(),
                    s_month: dateRange.start.getMonth() + 1,
                    s_day: dateRange.start.getDate(),
                    e_year: dateRange.end.getFullYear(),
                    e_month: dateRange.end.getMonth() + 1,
                    e_day: dateRange.end.getDate()
                };
            }
        } else if (pre_param.frm_id === "0000000000000006") {
            let bodyContentList = '{';

            bodyContent?.forEach((item, index) => {
                const bodyContentsStr = `"product_name_${index}": "${item[0]}", "amount_${index}": "${item[1]}", "unit_price_${index}": "${item[2]}", "sum_of_money_${index}": "${item[3]}", "requester_${index}": "${item[4]}"`;
                bodyContentList += bodyContentsStr + (index < bodyContent.length - 1 ? ", " : "");
            })

            const additionalContent = `, "reason": "${vacContent}", "total": "${vacTotal}"}`;

            bodyContentList += additionalContent;
            bodyContents_c = bodyContentList;

        } else {
            bodyContents_c = vacContent;
        }
        if (pre_param.frm_id === "0000000000000006") {

            newMap.set("bodyContents", bodyContents_c);
        } else {
            newMap.set("bodyContents", JSON.stringify(bodyContents_c, null, 0));
        }

        newMap.set("sign_seq_array", Array.from({ length: signUserList.length }, (_, index) => index).join(', '));
        const proxyYnArray = new Array(signUserList.length).fill("N").join(", ");
        newMap.set("proxy_yn_array", proxyYnArray);
        newMap.set("sign_dt_array", "");
        if (location_mode === 'edit') {
            //list param
            newMap.set("sign_user_id_array", signUserList!.map(map => map.SIGN_USER_ID).join(', '));
            newMap.set("sign_tp_cd_array", signUserList!.map(map => map.SIGN_TP_CD).join(', '));
            newMap.set("line_upde_auth_yn_array", signUserList!.map(map => map.LINE_UPDE_AUTH_YN).join(', '));
            newMap.set("doc_upde_auth_yn_array", signUserList!.map(map => map.DOC_UPDE_AUTH_YN).join(', '));
            newMap.set("file_upde_auth_yn_array", signUserList!.map(map => map.FILE_UPDE_AUTH_YN).join(', '));
            newMap.set("sign_user_nm_array", signUserList!.map(map => map.SIGN_USER_NM).join(', '));
            newMap.set("sign_user_posit_cd_array", signUserList!.map(map => map.SIGN_USER_POSIT_CD).join(', '));
            newMap.set("sign_user_posit_nm_array", signUserList!.map(map => map.SIGN_USER_POSIT_NM).join(', '));
            newMap.set("sign_user_dept_cd_array", signUserList!.map(map => map.SIGN_USER_DEPT_CD).join(', '));
            newMap.set("sign_user_dept_nm_array", signUserList!.map(map => map.SIGN_USER_DEPT_NM).join(', '));
            newMap.set("sign_sts_cd_array", signUserList!.map(map => map.SIGN_STS_CD).join(', '))
        } else {
            newMap.set("sign_user_id_array", signUserList!.map(map => map.sign_user_id).join(', '));
            newMap.set("sign_tp_cd_array", signUserList!.map(map => map.sign_tp_cd).join(', '));
            newMap.set("line_upde_auth_yn_array", signUserList!.map(map => map.line_upde_auth_yn).join(', '));
            newMap.set("doc_upde_auth_yn_array", signUserList!.map(map => map.doc_upde_auth_yn).join(', '));
            newMap.set("file_upde_auth_yn_array", signUserList!.map(map => map.file_upde_auth_yn).join(', '));
            newMap.set("sign_user_nm_array", signUserList!.map(map => map.sign_user_nm).join(', '));
            newMap.set("sign_user_posit_cd_array", signUserList!.map(map => map.sign_user_posit_cd).join(', '));
            newMap.set("sign_user_posit_nm_array", signUserList!.map(map => map.sign_user_posit_nm).join(', '));
            newMap.set("sign_user_dept_cd_array", signUserList!.map(map => map.sign_user_dept_cd).join(', '));
            newMap.set("sign_user_dept_nm_array", signUserList!.map(map => map.sign_user_dept_nm).join(', '));
            newMap.set("sign_sts_cd_array", signUserList!.map(map => map.sign_sts_cd).join(', '))
        }


        newMap.set("read_user_id_array", recentReadList!.map(map => map.READ_USER_ID).join(', '));
        newMap.set("read_tp_cd_array", recentReadList!.map(map => map.READ_TP_CD).join(', '));
        newMap.set("read_user_nm_array", recentReadList!.map(map => map.READ_USER_NM).join(', '));
        newMap.set("read_user_posit_cd_array", recentReadList!.map(map => map.READ_USER_POSIT_CD).join(', '));
        newMap.set("read_user_posit_nm_array", recentReadList!.map(map => map.READ_USER_POSIT_NM).join(', '));
        newMap.set("read_user_dept_cd_array", recentReadList!.map(map => map.READ_USER_DEPT_CD).join(', '));
        newMap.set("read_user_dept_nm_array", recentReadList!.map(map => map.READ_USER_DEPT_NM).join(', '));
        newMap.set("read_dept_cd_array", recentReadList!.map(map => map.READ_DEPT_CD).join(', '));
        newMap.set("read_dept_nm_array", recentReadList!.map(map => map.READ_DEPT_NM).join(', '));

        newMap.set("recv_user_id_array", recentRecvList!.map(map => map.RECV_USER_ID).join(', '));
        newMap.set("recv_tp_cd_array", recentRecvList!.map(map => map.RECV_TP_CD).join(', '));
        newMap.set("recv_user_nm_array", recentRecvList!.map(map => map.RECV_USER_NM).join(', '));
        newMap.set("recv_user_posit_cd_array", recentRecvList!.map(map => map.RECV_USER_POSIT_CD).join(', '));
        newMap.set("recv_user_posit_nm_array", recentRecvList!.map(map => map.RECV_USER_POSIT_NM).join(', '));
        newMap.set("recv_dept_cd_array", recentRecvList!.map(map => map.RECV_DEPT_CD).join(', '));
        newMap.set("recv_dept_nm_array", recentRecvList!.map(map => map.RECV_DEPT_NM).join(', '));

        return newMap;
    };

    const handleSubmit = async (onclick: any) => {
        if (!vacTitle.trim()) {
            toast('문서 제목을 입력해주세요.', { position: 'top-center', autoClose: 2000 });
            document.getElementById('vacTitle')?.focus();
            return;

        } else if (!vacContent.trim()) {
            toast('사유를 입력해주세요.', { position: 'top-center', autoClose: 2000 });
            document.getElementById('vacContent')?.focus();
            return;

        } else if (pre_param.vacation_yn === 'Y' && !vacStartDate) {
            toast('휴가 시작일을 선택해주세요.', { position: 'top-center', autoClose: 2000 });
            document.getElementById('vacStartDate')?.focus();
            return;
        } else if (pre_param.vacation_yn === 'Y' && !vacEndDate) {
            toast('휴가 종료일을 선택해주세요.', { position: 'top-center', autoClose: 2000 });
            document.getElementById('vacEndDate')?.focus();
            return;
        } else if (pre_param.vacation_yn === 'Y' && !delegate.trim()) {
            toast('대체 근무자를 입력해주세요.', { position: 'top-center', autoClose: 2000 });
            return;
        }
        if (onclick === 'onDraftSave') {
            submitDraft(true);
        } else {
            if (pre_param.vacation_yn === "Y") {
                const vacationMap = new Map();
                vacationMap.set('user_id', sessionStorage.getItem('user_id'));
                vacationMap.set('termsDays', termsDays);
                const response = await axios.post(
                    "/api/appr/formContents/getRemainVacation.ajax",
                    Object.fromEntries(vacationMap), // Map을 객체로 변환,
                    { headers: { "Content-Type": "application/json" } }
                );
                const result = response.data;
                const remainHoliday = Number(result.remainHoliday);
                if (remainHoliday < 0) {
                    toast('남은 휴가일 수가 부족합니다.', { position: 'top-center', autoClose: 2000 });
                    return;
                }
            }
            const rtn: React.ReactElement = (
                <DraftConfirm
                    draftType={onclick}
                    conFirmFunc={submitDraft}
                    closeFunc={handleSubmitClose}
                />
            );
            setContent(rtn); // Set the content for the modal
            setApprCheck(true);
        }
    }
    const _popComment = async () => {

        const vacationMap = new Map();
        vacationMap.set('sign_id', pre_param.sign_id);
        const response = await axios.post(
            "/api/appr/signForm/viewCmtList.do",
            Object.fromEntries(vacationMap), // Map을 객체로 변환,
            { headers: { "Content-Type": "application/json" } }
        );
        const result = response.data;
        const rtn: React.ReactElement = (
            <ApprPopComment
                resultComment={result.approvalCmtList}
                closeFunc={handleSubmitClose}

            />
        );
        setContent(rtn); // Set the content for the modal
        setApprCheck(true);
    }
    //사유 or 의견 팝업
    const popReason = (onclick: any) => {

        const rtn: React.ReactElement = (
            <ApprovalConfirm
                processType={onclick}
                actionStatus={actionStatusset}
                sign_id={pre_param.sign_id}
                conFirmFunc={submitpopReason}
                closeFunc={handleSubmitClose}

            />
        );
        setContent(rtn); // Set the content for the modal
        setApprCheck(true);
    }

    const submitpopReason = async (checkedRadio: any) => {

        const text = document.getElementById('apprComment') as HTMLTextAreaElement;

        // checkedRadio === type
        if (checkedRadio === 'correction') {

            signSetting(false, true);
            newMap.set("comment", text.value);
            const response = await axios.post(
                "/api/appr/signProc/docCorrection.ajax",
                Object.fromEntries(newMap), // Map을 객체로 변환,
                { headers: { "Content-Type": "application/json" } }
            );
            const rtn = response.data;
            if (pre_param.vacation_yn === 'Y') {
                const vacationMap = new Map();
                vacationMap.set('sign_id', rtn.sign_id);
                vacationMap.set('user_id', sessionStorage.getItem('user_id'));
                if (pre_param.frm_id === "0000000000000002") {
                    vacationMap.set("s_year", dateRange.start.getFullYear());
                    vacationMap.set("s_month", dateRange.start.getMonth());
                    vacationMap.set("s_day", dateRange.start.getDay());
                    vacationMap.set("e_year", dateRange.end.getFullYear());
                    vacationMap.set("e_month", dateRange.end.getMonth());
                    vacationMap.set("e_day", dateRange.end.getDay());
                    vacationMap.set("harf_vacation_tp", vacHalf === "M" ? "morning" : "afternoon");
                } else {
                    vacationMap.set("s_year", dateRange.start.getFullYear());
                    vacationMap.set("s_month", dateRange.start.getMonth());
                    vacationMap.set("s_day", dateRange.start.getDay());
                    vacationMap.set("e_year", dateRange.end.getFullYear());
                    vacationMap.set("e_month", dateRange.end.getMonth());
                    vacationMap.set("e_day", dateRange.end.getDay());
                }
                vacationMap.set("reason", vacContent);
                //경조사, 교육/훈련, 출산 휴가계는 차감X(minus_yn="N")
                vacationMap.set("minus_yn", (pre_param.frm_id === '0000000000000003' || pre_param.frm_id === '0000000000000004' || pre_param.frm_id === '0000000000000005') ? "N" : "Y");
                vacationMap.set("terms_days", termsDays);
                await axios.post(
                    "/api/appr/formContents/correctVacation.ajax",
                    Object.fromEntries(vacationMap), // Map을 객체로 변환,
                    { headers: { "Content-Type": "application/json" } }
                );
            }
            const formData = new FormData();
            formData.append("user_id", sessionStorage.getItem("user_id") as string);
            formData.append("lang", "ko");
            formData.append("sign_id", rtn.sign_id);
            if (rtn.prev_aid !== null && rtn.prev_aid !== "") {
                formData.append("prev_aid", rtn.prev_aid);
            }
            formData.append("actionStatus", actionStatusset);
            formData.append("reDraft", reDraft);
            formData.append("file_seq_array", fileList!.map(map => map.seq).join(', '));
            const attchFiles = (document.getElementById('attchFilesInp') as HTMLInputElement);

            if (attchFiles.files) {
                for (let i = 0; i < attchFiles.files.length; i++) {
                    formData.append('uploadFiles[' + i + ']', attchFiles.files[i]);
                }
            }
            axios.post('/api/appr/signProc/generalUpload.ajax', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                setShowAlertModal(true);
            });

        } else if (checkedRadio === "appr" || checkedRadio === "after_appr" || checkedRadio === "SA_appr" || checkedRadio === "PA_appr" || checkedRadio === "PA_reject") {

            const map = new Map();
            map.set("sign_id", pre_param.sign_id);
            map.set("actionStatus", actionStatusset);
            map.set("user_id", sessionStorage.getItem("user_id"));
            map.set("lang", "ko");
            map.set("chg_next_user_yn", "N");
            map.set("processType", checkedRadio);
            map.set("apprType", checkedRadio);
            map.set("opn_tp_cd", "RA");
            map.set("comment", text.value);

            const response = await axios.post(
                "/api/appr/signProc/apprSignForm.ajax",
                Object.fromEntries(map), // Map을 객체로 변환,
                { headers: { "Content-Type": "application/json" } }
            );
            const rtn = response.data;

            if (pre_param.vacation_yn === 'Y' && rtn.lastYn === 'Y') {
                const vacationMap = new Map();
                vacationMap.set('sign_id', pre_param.sign_id);
                vacationMap.set('user_id', sessionStorage.getItem('user_id'));
                vacationMap.set("s_year", dateRange.start.getFullYear());
                vacationMap.set("s_month", dateRange.start.getMonth());
                vacationMap.set("s_day", dateRange.start.getDay());
                vacationMap.set("e_year", dateRange.end.getFullYear());
                vacationMap.set("e_month", dateRange.end.getMonth());
                vacationMap.set("e_day", dateRange.end.getDay());
                if (pre_param.frm_id === '0000000000000002') vacationMap.set("harf_vacation_tp", vacHalf === "M" ? "morning" : "afternoon");
                vacationMap.set("reason", vacContent);
                //경조사, 교육/훈련, 출산 휴가계는 차감X(minus_yn="N")
                vacationMap.set("minus_yn", (pre_param.frm_id === '0000000000000003' || pre_param.frm_id === '0000000000000004' || pre_param.frm_id === '0000000000000005') ? "N" : "Y");
                vacationMap.set("terms_days", termsDays);

                await axios.post(
                    "/api/appr/formContents/updateVacation.ajax",
                    Object.fromEntries(vacationMap), // Map을 객체로 변환,
                    { headers: { "Content-Type": "application/json" } }
                );

            }
        } else if (checkedRadio === 'reject') {
            const map = new Map();
            map.set("sign_id", pre_param.sign_id);
            map.set("user_id", sessionStorage.getItem("user_id"));
            map.set("lang", "ko");
            map.set("apprType", checkedRadio);
            map.set("opn_tp_cd", "RA");
            map.set("comment", text.value);
            map.set("termsDays", termsDays);
            map.set("year", isNaN(dateRange.start.getFullYear()) ? 0 : dateRange.start.getFullYear());
            await axios.post(
                "/api/appr/signProc/rejectSignForm.ajax",
                Object.fromEntries(map), // Map을 객체로 변환,
                { headers: { "Content-Type": "application/json" } }
            );
        } else if (checkedRadio === 'prevReject') {
            const map = new Map();
            map.set("sign_id", pre_param.sign_id);
            map.set("user_id", sessionStorage.getItem("user_id"));
            map.set("lang", "ko");
            map.set("apprType", checkedRadio);
            map.set("opn_tp_cd", "RA");
            map.set("comment", text.value);

            await axios.post(
                "/api/appr/signProc/prevStepReject.ajax",
                Object.fromEntries(map), // Map을 객체로 변환,
                { headers: { "Content-Type": "application/json" } }
            );
        }
        setApprCheck(false);
        navigate('/appr/easMain');
    }

    const cancel = async (dest: any) => {

        let text;
        if (dest === 'B04') {
            text = '승인 취소';
        } else {
            text = '회수'
        }
        if (window.confirm(text + ' 하시겠습니까?')) {
            const map = new Map();
            map.set("sign_id", pre_param.sign_id);
            map.set("user_id", sessionStorage.getItem("user_id"));
            map.set("lang", "ko");
            map.set("termsDays", termsDays === undefined ? 0 : termsDays);
            map.set("year", isNaN(dateRange.start.getFullYear()) ? 0 : dateRange.start.getFullYear());
            await axios.post(
                "/api/appr/signProc/cancelApproval.ajax",
                Object.fromEntries(map), // Map을 객체로 변환,
                { headers: { "Content-Type": "application/json" } }
            );
            navigate('/appr/easMain');
        }

    }
    const formatDateString = (dateString: string) => {
        if (dateString.length === 8) {
            // 'YYYYMMDD' -> 'YYYY-MM-DD'로 변환
            return `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`;
        }
        return dateString; // 기본적으로 날짜 형식이 아닐 경우 그대로 반환
    };

    const download = (fileList: AttchFiles) => {
        const params = {
            file_path: fileList.file_path,
        }
        axios.post('/api/file/download', params, { headers: { 'Content-Type': 'application/json' }, responseType: 'blob' }).then(response => {
            const rtn = response.data;
            rtn.text().then((res: string) => {
                if (res === '1') {
                    setAlertBody('오류가 발생했습니다. 관리자에게 문의 바랍니다.');
                } else if (res === '2') {
                    setAlertBody('파일을 찾을 수 없습니다.');
                    alert("파일을 찾을수없습니다.");
                } else {
                    const el = document.createElement('a');
                    el.download = fileList.file_nm;
                    el.href = window.URL.createObjectURL(new Blob([rtn]));
                    el.click();
                }
            });
        });
    }
    return (

        <div className='content m-1'>
            <div className='d-flex gap-1 position-sticky top-0 p-1 bg-white z-3' style={!isDesktop ? { overflowX: 'auto', whiteSpace: 'nowrap' } : {}}>
                <div className='ms-auto'></div>
                {(actionStatusset === "DR" || actionStatusset === "TS" || actionStatusset === "RW") &&
                    <Button onClick={handleButtonClick}>결재선지정</Button>
                }
                {(actionStatusset === 'DR' || actionStatusset === 'TS' || actionStatusset === 'RW' || actionStatusset === 'RD' || actionStatusset === 'DARW') && (
                    <>
                        {(actionStatusset !== 'DARW' && actionStatusset !== 'RW' && actionStatusset !== 'RD') && (
                            <Button variant='secondary' onClick={() => handleSubmit("onDraftSave")}>임시저장</Button>
                        )}
                        <Button onClick={() => handleSubmit("DR")}>기안</Button>
                    </>
                )}
                {(actionStatusset === 'AS' || actionStatusset === 'CF' || actionStatusset === 'HCF') && (
                    <>
                        {lineUpdeAuth && (
                            <Button onClick={handleButtonClick}>결재선수정</Button>
                        )}
                        {docUpdeAuth && (
                            <Button onClick={() => popReason('correction')}>문서 수정</Button>
                        )}
                    </>
                )}

                {(actionStatusset === "AS" || actionStatusset === "CF" || actionStatusset === "HCF" || actionStatusset === "AA"
                    || actionStatusset === "CP" || actionStatusset === "DH" || actionStatusset === "RJ" || actionStatusset === "PC"
                    || actionStatusset === "AC" || actionStatusset === "WD" || actionStatusset === "RT" || actionStatusset === "RF"
                    || actionStatusset === "RC" || actionStatusset === "RA" || actionStatusset === "RW" || actionStatusset === "DARA"
                    || actionStatusset === "DARW" || actionStatusset === "RD" || actionStatusset === "TS") && (
                        <>
                            {(actionStatusset === 'RT') && (
                                <Button onClick={() => popReason('_readConfirm')}>참조 확인</Button>
                            )}
                            {(actionStatusset !== 'TS') && (
                                <Button onClick={() => _popComment()}>의견 보기</Button>
                            )}
                            {(orgSignId !== null && orgSignId !== "") && (
                                <Button onClick={() => popReason('correction')}>
                                    원기안문서
                                </Button>
                            )}
                            {(actionStatusset === 'AC') && (
                                <Button onClick={() => cancel('B04')}>
                                    승인 취소
                                </Button>
                            )}
                            {(actionStatusset === 'WD') && (
                                <Button onClick={() => cancel('B09')}>
                                    회수
                                </Button>
                            )}
                        </>
                    )}

                {(actionStatusset === "AS" || actionStatusset === "CF" || actionStatusset === "HCF" || actionStatusset === "AA"
                    || actionStatusset === "SA" || actionStatusset === "HSA" || actionStatusset === "PA" || actionStatusset === "HPA"
                    || actionStatusset === "SAD" || actionStatusset === "HSAD" || actionStatusset === "PAD" || actionStatusset === "HPAD") && (
                        <>
                            {(actionStatusset !== 'AA') && (
                                <Button onClick={() => popReason((actionStatusset === "PA" || actionStatusset === "HPA" || actionStatusset === "PAD" || actionStatusset === "HPAD") ? "PA_reject" : "reject")}>
                                    반 려
                                </Button>
                            )}

                            {(actionStatusset === 'AS' || actionStatusset === 'CF' || actionStatusset === 'HCF') && (
                                <Button onClick={() => popReason('prevReject')}>
                                    전단계 반려
                                </Button>
                            )}

                            {(actionStatusset === 'AS' || actionStatusset === 'CF' || actionStatusset === 'HCF' || actionStatusset === 'AA') && (
                                <Button onClick={() => popReason(actionStatusset === "AA" ? "after_appr" : "appr")}>
                                    결 재
                                </Button>
                            )}

                            {(actionStatusset === 'SAD' || actionStatusset === 'HSAD' || actionStatusset === 'PAD' || actionStatusset === 'HPAD') && (
                                <Button onClick={() => popReason((actionStatusset === "PAD" || actionStatusset === "HPAD") ? "PA_appr" : "SA_appr")}>
                                    감 사
                                </Button>
                            )}

                            {(actionStatusset === 'SA' || actionStatusset === 'HSA' || actionStatusset === 'PA' || actionStatusset === 'HPA') && (
                                <Button onClick={() => popReason((actionStatusset === "PA" || actionStatusset === "HPA") ? "PA_appr" : "SA_appr")}>
                                    합 의
                                </Button>
                            )}
                        </>
                    )}

                {(actionStatusset === "RC" || actionStatusset === "RA" || actionStatusset === "RW" || actionStatusset === "DARA" || actionStatusset === "DARW") && (
                    <>
                        {(actionStatusset === 'RC' || actionStatusset === 'RA' || actionStatusset === 'DARA') && (
                            <Button onClick={() => popReason('_documentReceipt')}>접 수</Button>
                        )}
                        <Button onClick={() => popReason('_receptionReject')}>반 송</Button>
                        {(actionStatusset === 'RC' || actionStatusset === 'RA' || actionStatusset === 'RW') && (
                            <Button onClick={() => popReason('popDeptSelect')}>배 포</Button>
                        )}
                    </>
                )}
            </div>
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                <Table className='my-0 caption-top'>
                    <caption>결재진행열람</caption>
                    <thead>
                        <tr>
                            {attrLineList?.map((e, index) => (
                                <th key={index}>
                                    {e[0]} {/* e.elementAt(0)에 해당 */}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {attrLineList?.map((e, index) => (
                                <td key={index}>
                                    <p className="fw-semibold">
                                        {e[1]} {/* e.elementAt(1)에 해당 */}
                                    </p>
                                    {e[4] === 'Y' ?
                                        <p role='button' onClick={() => _popComment()}>{e[2]}<span className='text-secondary'>(의견)</span></p>
                                        :
                                        <p>
                                            {e[2]} {/* e.elementAt(2)에 해당 이름 */}
                                        </p>
                                    }
                                    <p>
                                        {e[3]} {/* e.elementAt(3)에 해당 날짜 */}
                                    </p>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </Table>
            </div>
            <Table className='table-normal-border-top mt-0 mb-1'>
                <tbody>
                    {pre_param.cooperation_yn === "Y" && (
                        <tr>
                            <th>합의</th>
                            <td colSpan={3}>{cooperationList?.join(', ')}</td>
                        </tr>
                    )}
                    <tr>
                        <th>수신</th>
                        <td colSpan={3}>
                            {recentRecvList?.map((value) => (
                                value.RECV_TP_CD === 'R1'
                                    ? `${value.RECV_USER_NM}`
                                    : value.RECV_DEPT_NM
                            )).join(', ')}
                        </td>
                    </tr>
                    <tr>
                        <th>참조</th>
                        <td colSpan={3}>
                            {recentReadList?.map((value) => (
                                value.READ_TP_CD === 'R1'
                                    ? `${value.READ_USER_NM}/${value.READ_USER_POSIT_NM}/${value.READ_USER_DEPT_NM}`
                                    : value.READ_DEPT_NM
                            )).join(', ')}
                        </td>
                    </tr>
                    {isDesktop ?
                        <tr>
                            <th className='w-25'>기안자</th>
                            <td>{drafter}</td>
                            <th className='w-25'>기안일</th>
                            <td>{draftDate}</td>
                        </tr>
                        :
                        <>
                            <tr>
                                <th>기안자</th>
                                <td>{drafter}</td>
                            </tr>
                            <tr>
                                <th>기안일</th>
                                <td>{draftDate}</td>
                            </tr>
                        </>
                    }
                </tbody>
            </Table>
            <div>
                <p className='mb-1 text-body-secondary'>첨부파일</p>
                {(location_mode === 'edit' || location_mode === 'view') && (
                    <div hidden={actionStatusset !== 'DR' && actionStatusset !== 'RD'} >
                        {/* 파일 input */}
                        <Form.Control id='attchFilesInp' type='file' multiple onChange={e => {
                            const files = (e.target as HTMLInputElement).files ?
                                (e.target as HTMLInputElement).files : [];

                            const arr: object[] = [];
                            let size = 0;
                            if (files) {
                                if (files.length > 5) {
                                    toast('파일은 최대 5개까지 업로드 가능합니다.', { position: 'top-center', autoClose: 2000 });
                                    e.target.value = '';
                                    removeAttch();
                                    setFlSize('0 MB');
                                    return;
                                }
                                for (let i = 0; i < files.length; i++) {
                                    size += files[i].size;
                                    arr.push({ [i]: files[i].name });
                                }
                                if (size > Math.pow(2, 20) * 50) {
                                    toast('파일 용량이 50MB를 초과했습니다.', { position: 'top-center', autoClose: 2000 });
                                    e.target.value = '';
                                    removeAttch();
                                    setFlSize('0 MB');
                                    return;
                                }
                            }
                            setFlSize(calcSize(size));
                            setFileList(arr);
                        }} />

                        {/* 파일 목록 */}
                        {fileList && fileList.length > 0 &&
                            <div className="" hidden={actionStatusset !== 'DR' && actionStatusset !== 'RD'}>
                                <div className='mt-1'>
                                    {fileList.map(fileComp => {
                                        const key = Object.keys(fileComp)[0];
                                        const val = Object.values(fileComp)[0];
                                        const ext = val.substring(val.lastIndexOf('.') + 1).toLowerCase();
                                        return (
                                            <div key={key} className='d-flex px-2 bg-body-tertiary rounded'>
                                                <div className='me-2'>{setIcon(ext)}</div>
                                                <div>{fileComp.file_nm ? fileComp.file_nm : val}</div>
                                                <RxCross2 role='button' className='mt-1 ms-auto' onClick={() => removeAttch(key)} size={20} />
                                            </div>
                                        )
                                    })}
                                </div>
                                <p className='text-end text-body-secondary'><span>파일 갯수 : {fileList.length} / 5 , 파일 용량: {flSize} / 50 MB</span></p>
                            </div>
                        }
                    </div>
                )}
                {(actionStatusset !== 'DR' && actionStatusset !== 'RD') && fileList && fileList.length > 0 && (
                    <div className='mb-2'>
                        {fileList.map(fileComp => {
                            return (
                                <div key={fileComp.ntt_atchmnfl_sn} role='button' className='d-flex px-2 bg-body-tertiary rounded' title='다운로드' onClick={() => download(fileComp)} >
                                    <div className='mx-2'>{setIcon(fileComp.file_nm.substring(fileComp.file_nm.lastIndexOf('.') + 1).toLowerCase())}</div>
                                    <div>{fileComp.file_nm}</div>
                                </div>
                            );
                        })}
                    </div>
                )}
                {(actionStatusset !== 'DR' && actionStatusset !== 'RD') && fileList?.length === 0 && (
                    <div className='mb-2 text-center px-2 bg-body-tertiary rounded'>첨부파일이 없습니다.</div>
                )}
            </div>
            <p className='mb-1 text-body-secondary'>본문</p>
            <Table className='my-1'>
                <tbody>
                    {isDesktop ?
                        <tr>
                            <th className='w-25'>문서번호</th>
                            <td className='w-25'>{docNo}</td>
                            <th className='w-25'>문서보존연한</th>
                            <td>
                                <Form.Select id="retention" name="retention" value={retention} onChange={handleChange}
                                    disabled={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')} >
                                    {retentionList.map((item) => (
                                        <option key={item} value={item} >
                                            {item}년
                                        </option>
                                    ))}
                                </Form.Select>
                            </td>
                        </tr>
                        :
                        <>
                            <tr>
                                <th>문서번호</th>
                                <td>{docNo}</td>
                            </tr>
                            <tr>
                                <th>문서보존연한</th>
                                <td>
                                    <Form.Select id="retention" name="retention" value={retention} onChange={handleChange}
                                        disabled={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')} >
                                        {retentionList.map((item) => (
                                            <option key={item} value={item} >
                                                {item}년
                                            </option>
                                        ))}
                                    </Form.Select>
                                </td>
                            </tr>
                        </>
                    }
                    <tr>
                        <th>제목</th>
                        <td colSpan={3}>
                            <Form.Control id="vacTitle" type="textBox" value={vacTitle} onChange={e => setVacTitle(e.target.value)} readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')} />
                        </td>
                    </tr>
                </tbody>
            </Table>

            {(pre_param.frm_id === "0000000000000006" || location_id === "0000000000000006") && (
                <>
                    <InnerStyledTable>
                        {bodyContent?.map((item, index) => (
                            <tr key={index} >
                                <td >
                                    <Form.Control readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')} type="text" id={`product_name_${index}`} value={item[0]} onChange={e => {
                                        const newBodyContent = [...bodyContent];
                                        newBodyContent[index] = [e.target.value, item[1], item[2], item[3], item[4]]; // product_name만 업데이트
                                        setBodyContent(newBodyContent); // 품명 갱신
                                    }} />
                                </td>
                                <td >
                                    <Form.Control readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')} type="number" id={`amount_${index}`} value={item[1]} onChange={e => {
                                        const newBodyContent = [...bodyContent];
                                        newBodyContent[index] = [item[0], e.target.value, item[2], item[3], item[4]]; // amount만 업데이트
                                        setBodyContent(newBodyContent); // 수량 갱신
                                    }} />
                                </td>
                                <td>
                                    <Form.Control readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')} type="number" id={`unit_price_${index}`} value={item[2]} onChange={e => {
                                        const newBodyContent = [...bodyContent];
                                        newBodyContent[index] = [item[0], item[1], e.target.value, item[3], item[4]]; // unit_price만 업데이트
                                        setBodyContent(newBodyContent); // 단가 갱신
                                    }} />
                                </td>
                                <td>
                                    <Form.Control readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')} type="number" id={`sum_of_money_${index}`} value={item[3]} onChange={e => {
                                        const newBodyContent = [...bodyContent];
                                        newBodyContent[index] = [item[0], item[1], item[2], e.target.value, item[4]]; // sum_of_money만 업데이트
                                        setBodyContent(newBodyContent); // 금액 갱신
                                    }} />
                                </td>
                                <td >
                                    <Form.Control readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')} type="text" id={`requester_${index}`} value={item[4]} onChange={e => {
                                        const newBodyContent = [...bodyContent];
                                        newBodyContent[index] = [item[0], item[1], item[2], item[3], e.target.value]; // requester만 업데이트
                                        setBodyContent(newBodyContent); // 요청인 갱신
                                    }} />
                                </td>
                            </tr>
                        ))}
                    </InnerStyledTable>
                    {((location_mode === 'edit' || location_mode === 'view') && (actionStatusset === 'DR' || actionStatusset === 'TS' || actionStatusset === 'RW' || actionStatusset === 'RD' || actionStatusset === 'DARW')) && (
                        <Button className='w-100' onClick={() => addProduct('', '', '', '', '')}>추가</Button>
                    )}
                    <Table className='table-normal-border-top my-1'>
                        <tbody>
                            <tr>
                                <th className='w-25'>합계</th>
                                <td>
                                    <Form.Control type="number" readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')} id='xptmxm' value={vacTotal} onChange={e => setVacTotal(e.target.value)} />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </>
            )}
            <Table className='table-normal-border-top mt-0'>
                <tbody>
                    {pre_param.vacation_yn === 'Y' && (
                        <tr>
                            <th>기간</th>
                            <td colSpan={3}>
                                <div className='p-0 d-sm-flex justify-content-sm-between align-items-sm-center'>
                                    <Form.Control
                                        id="vacStartDate"
                                        type="date"
                                        style={{ display: 'inline-block' }}
                                        min={minholidayRange ? minholidayRange.substring(0, 10) : ""}
                                        max={holidayRange[0] ? holidayRange[0].substring(13, 24) : ""}
                                        value={dateRange.start.toISOString().slice(0, 10)}
                                        onChange={handleStartDateChange}
                                        readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')}

                                    />
                                    {!location_text?.includes('반차') || pre_param.frm_id !== '0000000000000002' ? (
                                        <>
                                            <span className='mx-2'>~</span>
                                            <Form.Control
                                                id="vacEndDate"
                                                style={{ display: 'inline-block' }}
                                                min={dateRange.start.toISOString().slice(0, 10)}
                                                type="date"
                                                max={maxholidayRange ? new Date(formatDateString(maxholidayRange)).toISOString().slice(0, 10) : ''}
                                                value={dateRange.end.toISOString().slice(0, 10)}
                                                onChange={handleEndDateChange}
                                                readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')}
                                            />
                                        </>
                                    ) : (
                                        <div className='ps-2'>
                                            <Form.Check type="radio" id='am' inline
                                                label="오전 (09:00~13:00)" value="M" name="VAC_HALF" checked={vacHalf === "M"}
                                                onChange={e => setVacHalf(e.target.value)} readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')}
                                            />
                                            <Form.Check type="radio" id='pm' inline
                                                label="오후 (13:00~18:00)" value="A" name="VAC_HALF" checked={vacHalf === "A"}
                                                onChange={e => setVacHalf(e.target.value)} readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')}
                                            />
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <th className='w-25'>사유</th>
                        <td colSpan={3}>
                            <Form.Control as="textarea" rows={5} id="vacContent" value={vacContent} onChange={e => setVacContent(e.target.value)} readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')} />
                        </td>
                    </tr>
                    {pre_param.vacation_yn === 'Y' && (
                        <>
                            <tr>
                                <th>행선지</th>
                                <td colSpan={3}>
                                    <Form.Control id="destination" type="textBox" value={vacDestination} onChange={e => setVacDestination(e.target.value)} readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')} />
                                </td>
                            </tr>
                            <tr>
                                <th>연락처</th>
                                <td colSpan={3}>
                                    <Form.Control id="tel" type="textBox" autoComplete="off" value={vacPhoneTell} onChange={handlePhoneChange} readOnly={(actionStatusset !== 'DR' && actionStatusset !== 'TS' && actionStatusset !== 'RW' && actionStatusset !== 'RD' && actionStatusset !== 'DARW')} />
                                </td>
                            </tr>
                            <tr>
                                <th>대체근무자</th>
                                <td colSpan={3}>
                                    {location_mode === 'edit' ? (
                                        <InputGroup>
                                            <Button variant="outline-secondary" id="button-addon2" onClick={selectTreeView}>
                                                선택
                                            </Button>
                                            <Form.Control
                                                type="textBox"
                                                id="inputDelegate"
                                                value={delegate}
                                                onChange={(e) => setDelegate(e.target.value)}
                                                readOnly
                                                aria-describedby="basic-addon2"
                                            />
                                        </InputGroup>
                                    ) :
                                        <Form.Control
                                            id="inputDelegate"
                                            type="text"
                                            value={delegate}
                                            onChange={(e) => setDelegate(e.target.value)}
                                            readOnly
                                            aria-describedby="basic-addon2"
                                        />
                                    }
                                </td>
                            </tr>
                        </>
                    )}
                    {location_text !== null &&
                        <tr className='text-center'>
                            <td colSpan={4} className='lh-lg'>
                                상기와 같은 사유로 인하여 {location_text}를 제출하오니 재가 바랍니다.
                            </td>
                        </tr>
                    }
                </tbody>
            </Table>

            <Modal
                show={showModal}
                onHide={closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        결재선 지정
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
            </Modal>

            <Modal show={show} onHide={handleClose} size="sm">
                <Modal.Header closeButton>대체근무자 선택</Modal.Header>
                <ModalBody>
                    <Tree
                        nodes={treeData}
                        idField={"dept_cd"}
                        nameField={"user_nm"}
                        onNodeClick={(node) => {
                            if (node.user_id && node.user_nm) {
                                handleVacWorkerClick(node.user_id);
                                const newMap = new Map(paramUser);
                                newMap.set("user_nm", node.user_nm);
                                newMap.set("user_posit_nm", node.user_posit_nm);
                                newMap.set("dept_nm", node.dept_nm);
                                setParamUser(newMap);
                            }
                        }}
                    />
                </ModalBody>
                <Modal.Footer>
                    <Button variant="primary" onClick={selectButton}>
                        선택
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        닫기
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={apprCheck} onHide={handleSubmitClose} >
                <Modal.Header closeButton>기안상신</Modal.Header>
                <ModalBody>
                    {content}
                </ModalBody>

            </Modal>

            <Modal onHide={handleSubmitClose} size="lg">
                <Modal.Header closeButton>보안문서</Modal.Header>
                <ModalBody>
                    {content}
                </ModalBody>

            </Modal>

            <ToastContainer />
            <AlertModal title={''} body={alertBody} show={showAlertModal} setShow={setShowAlertModal} />
        </div>
    );

}

type deptMemListVO = {
    USER_ID: string;
    USER_NM: string;
    USER_POSIT_NM: string;
    USER_DEPT_NM: string;
    [key: string]: any;
}

type recentApprListVO = {
    SIGN_USER_DEPT_NM: string;
    SIGN_USER_ID: string;
    FILE_UPDE_AUTH_YN: string;
    LINE_UPDE_AUTH_YN: string;
    SIGN_SEQ: number;
    SIGN_USER_POSIT_CD: string;
    DOC_UPDE_AUTH_YN: string;
    SIGN_USER_POSIT_NM: string;
    SIGN_TP_CD: string;
    SIGN_TP_NM: string;
    SIGN_USER_NM: string;
    SIGN_USER_DEPT_CD: string;

    [key: string]: any;
}

type recentReadListVO = {
    READ_TP_CD: string;
    READ_USER_ID: string;
    READ_USER_POSIT_CD: string;
    READ_USER_POSIT_NM: string;
    READ_USER_DEPT_CD: string;
    READ_USER_DEPT_NM: string;
    READ_USER_NM: string;
    READ_DEPT_NM: string;
    READ_DEPT_CD: string;
    [key: string]: any;
}

type recentRecvListVO = {
    RECV_TP_CD: string;
    RECV_DEPT_CD: string;
    RECV_DEPT_NM: string;
    RECV_USER_ID: string;
    RECV_USER_NM: string;
    RECV_USER_POSIT_CD: string;
    RECV_USER_POSIT_NM: string;


    [key: string]: any;
}

type AttchFiles = {
    [key: string]: any;
}

type signUserListVO = {
    SIGN_USER_DEPT_NM: string;
    SIGN_USER_ID: string;
    FILE_UPDE_AUTH_YN: string;
    LINE_UPDE_AUTH_YN: string;
    SIGN_SEQ: number;
    SIGN_USER_POSIT_CD: string;
    DOC_UPDE_AUTH_YN: string;
    SIGN_USER_POSIT_NM: string;
    SIGN_TP_CD: string;
    SIGN_TP_NM: string;
    SIGN_USER_NM: string;
    SIGN_USER_DEPT_CD: string;

    [key: string]: any;
}

const iconMap = {
    excel: <RiFileExcelLine size={20} className='mb-1' />,
    word: <RiFileWordLine size={20} className='mb-1' />,
    ppt: <RiFilePptLine size={20} className='mb-1' />,
    hwp: <RiFileHwpLine size={20} className='mb-1' />,
    pdf: <RiFilePdf2Line size={20} className='mb-1' />,
    img: <RiFileImageLine size={20} className='mb-1' />,
    video: <RiFileVideoLine size={20} className='mb-1' />,
    txt: <RiFileTextLine size={20} className='mb-1' />,
    etc: <RiFile3Line size={20} className='mb-1' />
}
// TableProps 인터페이스 정의
interface TableProps {
    children: ReactNode;
}
const InnerStyledTable: React.FC<TableProps> = ({ children }) => {
    return (
        <div className='' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <Table className='m-0 table-normal-border-top'>
                <thead>
                    <tr>
                        <th style={{ minWidth: '130px' }}>품명</th>
                        <th style={{ minWidth: '130px' }}>수량</th>
                        <th style={{ minWidth: '130px' }}>단가</th>
                        <th style={{ minWidth: '130px' }}>금액</th>
                        <th style={{ minWidth: '130px' }}>요청인</th>
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </Table>
        </div>
    );
};
export default ApprInfoArea;