import { Button, Modal, Form, FloatingLabel } from 'react-bootstrap';
import axios from 'axios';
import { useEffect, useState } from 'react';
import FileUpload from './FileUpload';
import noImg from '../../images/icon_noimages.gif'

const pwMap = new Map<string, string>();
const infoMap = new Map<string, string>();

const PopupShow = ({ show, setShow, category, infoData, setInfoData, setCategory }: { show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, category: number, infoData: { [key: string]: any; }, setInfoData: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>, setCategory: React.Dispatch<React.SetStateAction<number>> }): React.ReactElement => {

    interface Priv {
        PRIV_NM: string;
        PRIV_CD: string;
    }

    //직책, 부서, 직급 코드
    interface Detail {
        DETAIL_CODE: string;
        DETAIL_CODE_NM: string;
    }

    const [showModal, setShowModal] = useState(false);

    const [dupCheck, setDupCheck] = useState(false);
    const [privList, setPrivList] = useState<Priv[]>([]);
    const [jobTitleList, setJobTitleList] = useState<Detail[]>([]);
    const [positionList, setPositionList] = useState<Detail[]>([]);
    const [deptList, setDeptList] = useState<Detail[]>([]);

    //오늘 날짜 (YYYY-MM-DD)
    const today = `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`;

    //실행될 때 변수 초기화
    useEffect(() => {
        if (category === 2 || category === 4) {
            const getMyInfo = () => {
                try {
                    axios.post(
                        '/api/myinfo/getInfoSelectList'
                    ).then(response => {
                        setPrivList(response.data.privList);
                        setJobTitleList(response.data.jobTitleList);
                        setPositionList(response.data.positionList);
                        setDeptList(response.data.deptList);
                    }
                    );
                } catch (error) {
                    alert("정보 불러오기에 실패하였습니다. 관리자에게 문의 부탁드립니다.");
                }
            };
            getMyInfo();
        }

        infoMap.clear();
        pwMap.clear();
        setDupCheck(false);
    }, [show, category]);

    //비밀번호 변경
    const updatePw = async () => {
        const user_pw = pwMap.get('user_pw');
        const new_pw = pwMap.get('new_pw');
        const new_pw_chk = pwMap.get('new_pw_chk');

        try {
            if ((!pwMap.has('user_pw') || user_pw === '') && sessionStorage.getItem('priv_cd') !== 'WIDE_ADMIN') {
                alert('현재 비밀번호를 입력해주세요');
                document.getElementById('now_user_pw')?.focus();
            } else if (!pwMap.has('new_pw') || new_pw === '') {
                alert('변경할 비밀번호를 입력해주세요');
                document.getElementById('new_pw')?.focus();
            } else if (new_pw !== new_pw_chk) {
                alert('변경할 비밀번호가 일치하지 않습니다.');
                document.getElementById('new_pw_chk')?.focus();
            } else if (window.confirm('비밀번호를 변경하시겠습니까?')) {
                let pwVO = {};
                if (sessionStorage.getItem('priv_cd') === 'WIDE_ADMIN') {
                    pwVO = {
                        'user_id': infoData.user_id,
                        'new_pw': new_pw,
                        'priv_cd': sessionStorage.getItem('priv_cd')
                    }
                } else {
                    pwVO = {
                        'user_id': sessionStorage.getItem("user_id"),
                        'user_pw': user_pw,
                        'new_pw': new_pw
                    }
                }

                const response = await axios.post(
                    '/api/myinfo/updatePw',
                    pwVO,
                    { headers: { 'Content-Type': 'application/json' } }
                );
                const result = await response.data;
                if (result === 'Failed') {
                    alert('기존 비밀번호가 일치하지 않습니다.');
                    document.getElementById('now_user_pw')?.focus();
                } else if (result === 'Error') {
                    alert('비밀번호 변경에 실패하였습니다. 관리자에게 문의 부탁 드립니다.');
                } else {
                    alert('비밀번호가 정상적으로 변경되었습니다.');
                    setShow(false);
                }
            }

        } catch (error) {
            alert("정보 불러오기에 실패하였습니다. 관리자에게 문의 부탁 드립니다.");
        }
    };

    //유효성 공통 검증
    const commonDataCheck = () => {
        let user_name_input = (document.getElementById('user_nm_pop') as HTMLInputElement)?.value?.trim();
        let mobl_phone_input = (document.getElementById('mobl_phone_pop') as HTMLInputElement)?.value?.trim();
        let email_input = (document.getElementById('email_pop') as HTMLInputElement)?.value?.trim();

        if (user_name_input === '') {
            alert('이름을 입력해주세요.');
            document.getElementById('user_nm_pop')?.focus();
            return false;
        }
        const regex = /^\d{0,3}-?\d{0,4}-?\d{0,4}$/;
        if (mobl_phone_input === '') {
            alert('휴대폰 번호를 입력해주세요.');
            document.getElementById('mobl_phone_pop')?.focus();
            return false;
        } else if (!regex.test(mobl_phone_input)) {
            alert('-과 숫자만 입력할 수 있습니다.');
            document.getElementById('mobl_phone_pop')?.focus();
            return false;
        }

        if (email_input === '') {
            alert('이메일을 입력해주세요.');
            document.getElementById('email_pop')?.focus();
            return false;
        }

        return true;
    }

    //아이디 중복 체크
    const idCheck = async () => {

        let user_id_input = (document.getElementById('user_id_pop') as HTMLInputElement);

        if (!user_id_input?.value) {
            alert('아이디를 입력해주세요.');
            document.getElementById('user_id_pop')?.focus();
            return;
        }

        const response = await axios.post(
            '/api/admin/userIdCheck',
            user_id_input?.value,
            { headers: { 'Content-Type': 'application/text' } }
        );

        if (response.data === 1) {
            alert('중복된 아이디 입니다. 다른 아이디를 입력해주세요.');
            document.getElementById('user_id_pop')?.focus();
            return;
        } else {
            alert('사용하실 수 있는 아이디입니다.');
            document.getElementById('user_pw_pop')?.focus();
        }

        setDupCheck(true);
    }

    //사용자 등록 함수
    const registInfo = async () => {
        let user_pw_input = (document.getElementById('user_pw_pop') as HTMLInputElement)?.value?.trim();

        if (!dupCheck) {
            alert('아이디 중복확인을 해주세요');
            document.getElementById('user_id_pop')?.focus();
            return false;
        }

        if (user_pw_input === '') {
            alert('비밀번호를 입력해주세요.');
            document.getElementById('user_pw_pop')?.focus();
            return false;
        }

        if (!commonDataCheck()) {
            return;
        }

        if (window.confirm('이대로 등록하시겠습니까?')) {
            const formData = new FormData();
            const fileInput = document.getElementById('pictureChangeBtn') as HTMLInputElement;
            if (fileInput && fileInput.files && fileInput.files[0] && infoData.photo_path) {
                formData.append("file", fileInput.files[0]);
            }
            let userVO = {
                'priv_cd': (document.getElementById('priv_cd_pop') as HTMLInputElement)?.value,
                'user_id': (document.getElementById('user_id_pop') as HTMLInputElement)?.value,
                'user_pw': (document.getElementById('user_pw_pop') as HTMLInputElement)?.value,
                'user_nm': (document.getElementById('user_nm_pop') as HTMLInputElement)?.value,
                'dept_cd': (document.getElementById('dept_cd_pop') as HTMLInputElement)?.value,
                'position_cd': (document.getElementById('position_cd_pop') as HTMLInputElement)?.value,
                'job_title_cd': (document.getElementById('job_title_cd_pop') as HTMLInputElement)?.value,
                'office_phone': (document.getElementById('office_phone_pop') as HTMLInputElement)?.value,
                'mobl_phone': (document.getElementById('mobl_phone_pop') as HTMLInputElement)?.value,
                'fax': (document.getElementById('fax_pop') as HTMLInputElement)?.value,
                'email': (document.getElementById('email_pop') as HTMLInputElement)?.value,
                'gender': (document.querySelector('input[name="gender"]:checked') as HTMLInputElement)?.value,
                'birth_dt': (document.getElementById('birth_dt_pop') as HTMLInputElement)?.value,
                'lunar_yn': (document.querySelector('input[name="lunar_yn"]:checked') as HTMLInputElement)?.value,
                'hire_dt': (document.getElementById('hire_dt_pop') as HTMLInputElement)?.value,
                'retire_dt': (document.getElementById('date_retire') as HTMLInputElement)?.value,
                'retire_yn': (document.querySelector('input[name="retire_yn"]:checked') as HTMLInputElement)?.value,
                'zipcode_tp': (document.querySelector('input[name="zipcode_tp"]:checked') as HTMLInputElement)?.value,
                'zipcode': (document.getElementById('code') as HTMLInputElement)?.value,
                'address_tp': (document.querySelector('input[name="address_tp"]:checked') as HTMLInputElement)?.value,
                'address': (document.getElementById('addr') as HTMLInputElement)?.value,
                'use_yn': (document.getElementById('use_yn_pop') as HTMLInputElement)?.value,
                'reg_user_id': sessionStorage.getItem("user_id"),
                'edit_user_id': sessionStorage.getItem("user_id")
            };

            formData.append("data", JSON.stringify(userVO));

            const response = await axios.post(
                '/api/admin/registUserInfo',
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            const result = await response.data;

            if (result === 'success') {
                alert('사용자 등록이 완료되었습니다.');
                setShow(false);
            } else if (result === 'holidayFailed') {
                alert('사용자 등록은 성공하였지만, 기본 휴가일수 지정 실패.');
                setShow(false);
            } else {
                alert('사용자 등록에 실패했습니다. 관리자에게 문의바랍니다.');
            }
        }

    }

    //사용자 수정 함수
    const updateInfo = async (infoData: { [key: string]: any }) => {
        if (!commonDataCheck()) {
            return;
        };

        if (category === 1) {
            if (window.confirm('수정하시겠습니까?')) {

                const response = await axios.post(
                    '/api/myinfo/updateMyInfo',
                    {
                        'user_id': sessionStorage.getItem("user_id") == null ? (infoData.user_id == null ? '' : infoData.user_id) : sessionStorage.getItem("user_id"),
                        'user_nm': infoMap.get('user_nm') == null ? (infoData.user_nm == null ? '' : infoData.user_nm) : infoMap.get('user_nm'),
                        'office_phone': infoMap.get('office_phone') == null ? (infoData.office_phone == null ? '' : infoData.office_phone) : infoMap.get('office_phone'),
                        'mobl_phone': infoMap.get('mobl_phone') == null ? (infoData.mobl_phone == null ? '' : infoData.mobl_phone) : infoMap.get('mobl_phone'),
                        'fax': infoMap.get('fax') == null ? (infoData.fax == null ? '' : infoData.fax) : infoMap.get('fax'),
                        'email': infoMap.get('email') == null ? (infoData.email == null ? '' : infoData.email) : infoMap.get('email'),
                        'gender': infoMap.get('gender') == null ? (infoData.gender == null ? '' : infoData.gender) : infoMap.get('gender'),
                        'birth_dt': infoMap.get('birth_dt') == null ? (infoData.birth_dt == null ? '' : infoData.birth_dt) : infoMap.get('birth_dt'),
                        'lunar_yn': infoMap.get('lunar_yn') == null ? (infoData.lunar_yn == null ? '' : infoData.lunar_yn) : infoMap.get('lunar_yn'),
                        'hire_dt': infoMap.get('hire_dt') == null ? (infoData.hire_dt == null ? '' : infoData.hire_dt) : infoMap.get('hire_dt'),
                        'zipcode_tp': infoMap.get('zipcode_tp') == null ? (infoData.zipcode_tp == null ? '' : infoData.zipcode_tp) : infoMap.get('zipcode_tp'),
                        'zipcode': infoMap.get('zipcode') == null ? (infoData.zipcode == null ? '' : infoData.zipcode) : infoMap.get('zipcode'),
                        'address_tp': infoMap.get('address_tp') == null ? (infoData.address_tp == null ? '' : infoData.address_tp) : infoMap.get('address_tp'),
                        'address': infoMap.get('address') == null ? (infoData.address == null ? '' : infoData.address) : infoMap.get('address'),
                        'edit_user_id': sessionStorage.getItem("user_id") == null ? (infoData.user_id == null ? '' : infoData.user_id) : sessionStorage.getItem("user_id")
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                const result = await response.data;
                if (result === 'Success') {
                    alert('내 정보가 정상적으로 수정되었습니다.');
                    infoMap.forEach((value, key) => { infoData[key] = value; });
                    setShow(false);
                } else {
                    alert('내 정보 수정에 실패하였습니다. 관리자에게 문의 부탁 드립니다.');
                }
            }

        } else if (category === 2 && sessionStorage.getItem('priv_cd')) {
            if (window.confirm('수정하시겠습니까?')) {
                const response = await axios.post(
                    '/api/myinfo/updateUserInfoByAdmin',
                    {
                        'priv_cd': infoMap.get('priv_cd') == null ? (infoData.priv_cd == null ? '' : infoData.priv_cd) : infoMap.get('priv_cd'),
                        'user_id': infoData.user_id,
                        'user_nm': infoMap.get('user_nm') == null ? (infoData.user_nm == null ? '' : infoData.user_nm) : infoMap.get('user_nm'),
                        'dept_cd': infoMap.get('dept_cd') == null ? (infoData.dept_cd == null ? '' : infoData.dept_cd) : infoMap.get('dept_cd'),
                        'position_cd': infoMap.get('position_cd') == null ? (infoData.position_cd == null ? '' : infoData.position_cd) : infoMap.get('position_cd'),
                        'job_title_cd': infoMap.get('job_title_cd') == null ? (infoData.job_title_cd == null ? '' : infoData.job_title_cd) : infoMap.get('job_title_cd'),
                        'office_phone': infoMap.get('office_phone') == null ? (infoData.office_phone == null ? '' : infoData.office_phone) : infoMap.get('office_phone'),
                        'mobl_phone': infoMap.get('mobl_phone') == null ? (infoData.mobl_phone == null ? '' : infoData.mobl_phone) : infoMap.get('mobl_phone'),
                        'fax': infoMap.get('fax') == null ? (infoData.fax == null ? '' : infoData.fax) : infoMap.get('fax'),
                        'email': infoMap.get('email') == null ? (infoData.email == null ? '' : infoData.email) : infoMap.get('email'),
                        'gender': infoMap.get('gender') == null ? (infoData.gender == null ? '' : infoData.gender) : infoMap.get('gender'),
                        'birth_dt': infoMap.get('birth_dt') == null ? (infoData.birth_dt == null ? '' : infoData.birth_dt) : infoMap.get('birth_dt'),
                        'lunar_yn': infoMap.get('lunar_yn') == null ? (infoData.lunar_yn == null ? '' : infoData.lunar_yn) : infoMap.get('lunar_yn'),
                        'hire_dt': infoMap.get('hire_dt') == null ? (infoData.hire_dt == null ? '' : infoData.hire_dt) : infoMap.get('hire_dt'),
                        'retire_dt': infoMap.get('retire_dt') == null ? (infoData.retire_dt == null ? '' : infoData.retire_dt) : infoMap.get('retire_dt'),
                        'retire_yn': infoMap.get('retire_yn') == null ? (infoData.retire_yn == null ? '' : infoData.retire_yn) : infoMap.get('retire_yn'),
                        'zipcode_tp': infoMap.get('zipcode_tp') == null ? (infoData.zipcode_tp == null ? '' : infoData.zipcode_tp) : infoMap.get('zipcode_tp'),
                        'zipcode': infoMap.get('zipcode') == null ? (infoData.zipcode == null ? '' : infoData.zipcode) : infoMap.get('zipcode'),
                        'address_tp': infoMap.get('address_tp') == null ? (infoData.address_tp == null ? '' : infoData.address_tp) : infoMap.get('address_tp'),
                        'address': infoMap.get('address') == null ? (infoData.address == null ? '' : infoData.address) : infoMap.get('address'),
                        'use_yn': infoMap.get('use_yn') == null ? (infoData.use_yn == null ? '' : infoData.use_yn) : infoMap.get('use_yn'),
                        'edit_user_id': sessionStorage.getItem("user_id")
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                const result = await response.data;
                if (result === 'Success') {
                    alert('사용자 정보가 정상적으로 수정되었습니다.');
                    // infoMap.forEach((value, key) => {infoData[key] = value;});
                    setShow(false);
                } else {
                    alert('사용자 정보 수정에 실패하였습니다. 개발자에게 문의 바랍니다.');
                }
            }
        }
    }

    //프로필 사진 삭제
    const deleteUserPicture = async () => {
        if (category === 4) {
            setInfoData({
                ...infoData,
                photo_path: null
            });
            infoData.photo_path = null;
            return;
        } else {
            if (window.confirm('프로필 사진을 삭제하시겠습니까?')) {
                if (!infoData.photo_path) {
                    alert('등록된 사진이 없습니다.');
                    return;
                }
                const response = await axios.post(
                    '/api/myinfo/removePicture',
                    {
                        'user_id': infoData.user_id
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                const result = await response.data;
                if (result === 'success') {
                    alert('사진이 정상적으로 삭제되었습니다.');
                    setInfoData({
                        ...infoData,
                        photo_path: null
                    });
                    infoData.photo_path = null;
                    return false;
                } else {
                    alert('사진 삭제에 실패하였습니다. 개발자에게 문의 부탁 드립니다.');
                    return true;
                }
            }
        }
    }

    //비밀번호 변경
    if (category === 0) {
        return (
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{'비밀번호 변경'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="userInfo">
                        {sessionStorage.getItem('priv_cd') !== "WIDE_ADMIN" && (
                            <FloatingLabel controlId='now_user_pw' label="현재 비밀번호" className="mb-3">
                                <Form.Control type='password' name='user_pw' onChange={(e) => { pwMap.set(e.target.name, e.target.value); }}
                                    onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); document.getElementById('new_pw')?.focus(); } }} />
                            </FloatingLabel>
                        )}
                        <FloatingLabel controlId='new_pw' label="변경할 비밀번호" className="mb-3">
                            <Form.Control type='password' name='new_pw' onChange={(e) => { pwMap.set(e.target.name, e.target.value); }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); document.getElementById('new_pw_chk')?.focus(); } }} />
                        </FloatingLabel>
                        <FloatingLabel controlId='new_pw_chk' label="변경할 비밀번호 확인" className="mb-3">
                            <Form.Control type='password' name='new_pw_chk' onChange={(e) => { pwMap.set(e.target.name, e.target.value); }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); updatePw(); } }} />
                        </FloatingLabel>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={updatePw}>
                        수정
                    </Button>
                    <Button variant='secondary' onClick={() => setShow(false)}>
                        닫기
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else {
        return (
            <>
                <Modal show={show} onHide={() => { setCategory(-1); setShow(false); }}>
                    <Modal.Header closeButton>
                        {category === 1 ? (
                            <Modal.Title>{'내 정보 수정'}</Modal.Title>
                        ) : category === 2 ? (
                            <Modal.Title>{'사용자 정보 수정'}</Modal.Title>
                        ) : category === 4 && (
                            <Modal.Title>{'사용자 정보 등록'}</Modal.Title>
                        )}
                    </Modal.Header>
                    <Modal.Body>
                        <Form id="userInfo">
                            {(category === 2 || category === 4) && (
                                <>
                                    <FloatingLabel controlId='priv_cd_pop' label="권한" className="mb-3">
                                        <Form.Select name='priv_cd' onChange={(e) => {
                                            infoMap.set(e.target.name, e.target.value);
                                        }}
                                            onKeyDown={(e) => { if (e.key === 'Enter') document.getElementById('user_id_pop')?.focus(); }}
                                            defaultValue={infoData.priv_cd} >
                                            {privList.map((priv, index) => (
                                                <option key={index} value={priv.PRIV_CD} >
                                                    {priv.PRIV_NM}({priv.PRIV_CD})
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                    <div className='adminPicture'>
                                        {infoData.photo_path ?
                                            <div className='myInfoPicture' style={{ backgroundImage: `url(${infoData.photo_path})` }}></div>
                                            :
                                            <div className='myInfoPicture' style={{ backgroundImage: `url(${noImg})` }} />
                                        }
                                        <div className='myInfoButtonBox'>
                                            <FileUpload infoData={infoData} setInfoData={setInfoData} category={category} />
                                            {infoData.photo_path && (
                                                <Button className='p-2 rounded-5' onClick={deleteUserPicture}>삭제</Button>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}

                            {category === 4 ? (
                                <FloatingLabel controlId='user_id_pop' label="아이디" className="mb-3">
                                    <Form.Control type="textBox" name='user_id' autoComplete='off' style={{ width: '80%', display: 'inline-block' }}
                                        onChange={(e) => { infoMap.set(e.target.name, e.target.value); setDupCheck(false); }}
                                        onKeyDown={(e) => { if (e.key === 'Enter') { document.getElementById('idCheck')?.focus(); } }} />
                                    <Button id='idCheck' size='sm' variant={dupCheck ? 'success' : 'danger'} onClick={() => idCheck()}>  {dupCheck ? "확인완료" : "중복확인"}</Button>
                                </FloatingLabel>
                            ) : (
                                <FloatingLabel controlId='user_id_pop' label="아이디" className="mb-3">
                                    <Form.Control type="textBox" className='cursorDefault' value={infoData.user_id ? infoData.user_id : ''} readOnly />
                                </FloatingLabel>
                            )}

                            {category === 4 ? (
                                <FloatingLabel controlId='user_pw_pop' label="비밀번호" className="mb-3">
                                    <Form.Control type="password" name='user_pw' autoComplete='off'
                                        onChange={(e) => { infoMap.set(e.target.name, e.target.value); }}
                                        onKeyDown={(e) => { if (e.key === 'Enter') { document.getElementById('user_nm_pop')?.focus(); } }} />
                                </FloatingLabel>
                            ) : category === 2 && (
                                <FloatingLabel controlId='user_pw_modify' label="비밀번호" className="mb-3">
                                    <Form.Control role='button' type="password" className='cursorDefault' name='user_pw' defaultValue={infoData.user_pw} readOnly onClick={() => { setShowModal(true); }} />
                                </FloatingLabel>
                            )}

                            <FloatingLabel controlId='user_nm_pop' label="이름" className="mb-3">
                                <Form.Control type="textBox" name='user_nm' defaultValue={infoData.user_nm ? infoData.user_nm : ''} autoComplete='off'
                                    onChange={(e) => { infoMap.set(e.target.name, e.target.value); }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && (category === 4 || category === 2)) {
                                            document.getElementById('dept_cd_pop')?.focus();
                                        } else if (e.key === 'Enter' && category === 1) { document.getElementById('office_phone_pop')?.focus() }
                                    }} />
                            </FloatingLabel>

                            {category === 4 || category === 2 ? (
                                <FloatingLabel controlId='dept_cd_pop' label="부서명" className="mb-3">
                                    <Form.Select name='dept_cd'
                                        onChange={(e) => {
                                            infoMap.set(e.target.name, e.target.value);
                                            if (category === 2) {
                                                infoMap.set('dept_nm', Array.from(e.target.children).find(child =>
                                                    child.textContent?.includes(e.target.value)
                                                )?.textContent?.replace('(' + e.target.value + ')', '') as string);
                                            }
                                        }
                                        }
                                        onClick={(e) => { if (e.button === -1) { document.getElementById('position_cd_pop')?.focus(); } }}
                                        defaultValue={infoData.dept_cd}>
                                        {deptList.map((detail, index) => (
                                            <option key={index} value={detail.DETAIL_CODE} className={detail.DETAIL_CODE_NM} >
                                                {detail.DETAIL_CODE_NM}({detail.DETAIL_CODE})
                                            </option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            ) : (
                                <FloatingLabel controlId='dept_nm_pop' label="부서명" className="mb-3">
                                    <Form.Control type="textBox" className='cursorDefault' value={infoData.dept_nm} readOnly />
                                </FloatingLabel>
                            )}

                            {category === 4 || category === 2 ? (
                                <FloatingLabel controlId='position_cd_pop' label="직급" className="mb-3">
                                    <Form.Select name='position_cd'
                                        onChange={(e) => {
                                            infoMap.set(e.target.name, e.target.value);
                                        }}
                                        onClick={(e) => { if (e.button === -1) { document.getElementById('job_title_cd_pop')?.focus(); } }}
                                        defaultValue={infoData.position_cd}>
                                        {positionList.map((detail, index) => (
                                            <option key={index} value={detail.DETAIL_CODE} >
                                                {detail.DETAIL_CODE_NM}({detail.DETAIL_CODE})
                                            </option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            ) : (
                                <FloatingLabel controlId='position_nm_pop' label="직급" className="mb-3">
                                    <Form.Control type="textBox" className='cursorDefault' value={infoData.position_nm ? infoData.position_nm : ''} readOnly />
                                </FloatingLabel>
                            )}

                            {category === 4 || category === 2 ? (
                                <FloatingLabel controlId='job_title_cd_pop' label="직책" className="mb-3">
                                    <Form.Select name='job_title_cd'
                                        onChange={(e) => {
                                            infoMap.set(e.target.name, e.target.value);
                                        }}
                                        onClick={(e) => { if (e.button === -1) { document.getElementById('office_phone_pop')?.focus(); } }}
                                        defaultValue={infoData.job_title_cd}>
                                        {jobTitleList.map((detail, index) => (
                                            <option key={index} value={detail.DETAIL_CODE} >
                                                {detail.DETAIL_CODE_NM}({detail.DETAIL_CODE})
                                            </option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            ) : (
                                <FloatingLabel controlId='job_title_pop' label="직책" className="mb-3">
                                    <Form.Control type="textBox" className='cursorDefault' value={infoData.job_title ? infoData.job_title : ''} readOnly />
                                </FloatingLabel>
                            )}

                            <FloatingLabel controlId='office_phone_pop' label="사무실번호" className="mb-3">
                                <Form.Control type="text" name='office_phone' defaultValue={infoData.office_phone ? infoData.office_phone : ''} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} autoComplete='off'
                                    onKeyDown={(e) => { if (e.key === 'Enter') { document.getElementById('mobl_phone_pop')?.focus(); } }} />
                            </FloatingLabel>

                            <FloatingLabel controlId='mobl_phone_pop' label="휴대폰번호" className="mb-3">
                                <Form.Control type="textBox" name='mobl_phone' defaultValue={infoData.mobl_phone ? infoData.mobl_phone : ''} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} autoComplete='off'
                                    onKeyDown={(e) => { if (e.key === 'Enter') { document.getElementById('fax_pop')?.focus(); } }} />
                            </FloatingLabel>
                            <FloatingLabel controlId='fax_pop' label="팩스" className="mb-3">
                                <Form.Control type="textBox" name='fax' defaultValue={infoData.fax ? infoData.fax : ''} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} autoComplete='off'
                                    onKeyDown={(e) => { if (e.key === 'Enter') { document.getElementById('email_pop')?.focus(); } }} />
                            </FloatingLabel>
                            <FloatingLabel controlId='email_pop' label="이메일" className="mb-3">
                                <Form.Control type="textBox" name='email' defaultValue={infoData.email ? infoData.email : ''} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} autoComplete='off'
                                    onKeyDown={(e) => { if (e.key === 'Enter') { document.getElementById('gender_pop')?.focus(); } }} />
                            </FloatingLabel>

                            <FloatingLabel controlId='gender_pop' label="성별" className="mb-3">
                                <Form.Control className='cursorDefault' readOnly />
                                <div className="notTextGroup">
                                    <Form.Check label="남자" id="genderMM" type='radio' name='gender' value={'M'} defaultChecked={infoData.gender !== 'F'} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} inline onClick={() => { document.getElementById('birth_dt_pop')?.focus(); }} />
                                    <Form.Check label="여자" id="genderFF" type='radio' name='gender' value={'F'} defaultChecked={infoData.gender === 'F'} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} inline onClick={() => { document.getElementById('birth_dt_pop')?.focus(); }} />
                                </div>
                            </FloatingLabel>

                            <FloatingLabel controlId='birth_dt_pop' label="생년월일" className="mb-3">
                                <Form.Control type='date' name='birth_dt' defaultValue={infoData.birth_dt ? infoData.birth_dt : today} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} />
                                <div className='notTextGroup mb-4' style={{ marginLeft: '4rem' }}>
                                    <Form.Check label="양력" id="lunar_ynS" type='radio' name='lunar_yn' value={'S'} defaultChecked={infoData.lunar_yn !== 'L'} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} inline onClick={() => { document.getElementById('hire_dt_pop')?.focus(); }} />
                                    <Form.Check label="음력" id="lunar_ynL" type='radio' name='lunar_yn' value={'L'} defaultChecked={infoData.lunar_yn === 'L'} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} inline onClick={() => { document.getElementById('hire_dt_pop')?.focus(); }} />
                                </div>
                            </FloatingLabel>

                            <FloatingLabel controlId='hire_dt_pop' label="입사일자" className="mb-3">
                                <Form.Control type='date' name='hire_dt' defaultValue={infoData.hire_dt ? infoData.hire_dt : today} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} />
                            </FloatingLabel>

                            {(category === 4 || category === 2) && (
                                <FloatingLabel controlId='retire_dt_pop' label="퇴사일자" className="mb-3">
                                    <Form.Control type='date' name='retire_dt' defaultValue={infoData.retire_dt ? infoData.retire_dt : ''} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} />
                                    <div className='notTextGroup mb-4' style={{ marginLeft: '4rem' }}>
                                        <Form.Check label="Y" id="retireY" type='radio' name='retire_yn' value={'Y'} defaultChecked={infoData.retire_yn === 'Y'} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} inline onClick={() => { document.getElementById('code')?.focus(); }} />
                                        <Form.Check label="N" id="retireN" type='radio' name='retire_yn' value={'N'} defaultChecked={infoData.retire_yn !== 'Y'} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} inline onClick={() => { document.getElementById('code')?.focus(); }} />
                                    </div>
                                </FloatingLabel>
                            )}

                            <FloatingLabel controlId='code' label="우편번호" className="mb-3">
                                <Form.Control type='text' name='zipcode' defaultValue={infoData.zipcode ? infoData.zipcode : ''} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} autoComplete='off' onKeyDown={(e) => { if (e.key === 'Enter') { document.getElementById('zipcode_tp0_pop')?.focus(); } }} />
                                <div className='notTextGroup mb-4' style={{ marginLeft: '4rem' }}>
                                    <Form.Check label="신" id="zipcode_tp0_pop" type='radio' name='zipcode_tp' value={'0'} defaultChecked={infoData.zipcode_tp !== '1'} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} inline onClick={() => { document.getElementById('addr')?.focus(); }} />
                                    <Form.Check label="구" id="zipcode_tp1_pop" type='radio' name='zipcode_tp' value={'1'} defaultChecked={infoData.zipcode_tp === '1'} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} inline onClick={() => { document.getElementById('addr')?.focus(); }} />
                                </div>
                            </FloatingLabel>

                            <FloatingLabel controlId='addr' label="주소" className="mb-3">
                                <Form.Control type='text' name='address' defaultValue={infoData.address ? infoData.address : ''} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} autoComplete='off' onKeyDown={(e) => { if (e.key === 'Enter') { document.getElementById('address_tp0_pop')?.focus(); } }} />
                                <div className='notTextGroup mb-4' style={{ marginLeft: '4rem' }}>
                                    <Form.Check label="지번" id="address_tp0_pop" type='radio' name='address_tp' value={'0'} defaultChecked={infoData.address_tp !== '1'} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} inline onClick={() => { document.getElementById('use_yn_pop')?.focus(); }} />
                                    <Form.Check label="도로명" id="address_tp1_pop" type='radio' name='address_tp' value={'1'} defaultChecked={infoData.address_tp === '1'} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }} inline onClick={() => { document.getElementById('use_yn_pop')?.focus(); }} />
                                </div>
                            </FloatingLabel>

                            {(category === 4 || category === 2) && (
                                <FloatingLabel controlId='use_yn_pop' label="활성여부" className="mb-3">
                                    <Form.Select name='use_yn_pop' defaultValue={infoData.use_yn} onChange={(e) => { infoMap.set(e.target.name, e.target.value); }}>
                                        <option value={'Y'}> 활성</option>
                                        <option value={'N'}> 비활성</option>
                                    </Form.Select>
                                </FloatingLabel>
                            )}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => { category === 4 ? registInfo() : updateInfo(infoData) }}>
                            {category === 4 ? '등록' : '수정'}
                        </Button>
                        <Button variant='secondary' onClick={() => { setCategory(-1); setShow(false); }}>
                            닫기
                        </Button>
                    </Modal.Footer>
                </Modal>
                {category === 2 &&
                    <PopupShow show={showModal} setShow={setShowModal} category={0} infoData={infoData} setInfoData={setInfoData} setCategory={setCategory} />
                }
            </>
        );
    }

}

export default PopupShow;