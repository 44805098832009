import { Route, Routes } from "react-router-dom";
import Receipt from "./Receipt";
import MyProfile from "./MyProfile";
import HolidayHist from "./HolidayHist";

function Myinfo() {
    return (
        <div className="content-container">
            <Routes>
                <Route path="/myProfile" element={< MyProfile />} />
                <Route path="/holidayHist" element={<HolidayHist />} />
                <Route path="/receipt" element={< Receipt />} />
            </Routes>
        </div>
    );
}
export default Myinfo;
