import { Navigate } from "react-router-dom";


function Root() {

    return (
        <>
            {!localStorage.getItem('login_token') ?
                <Navigate to="/login" replace={true} /> :
                localStorage.getItem('extern_yn') === 'N' ?
                    <Navigate to="/main" replace={true} /> :
                    <Navigate to="/exter/board" replace={true} />}
        </>
    );
};

export default Root;