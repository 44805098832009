import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CellProps } from 'react-table';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import DataTableV2 from '../../module/DataTableV2';
import Paginate from '../../module/Paginate';
import Loading from '../../module/Loading';
import SearchBarV2 from '../../module/SearchBarV2';
import AlertModal from '../../module/AlertModal';
import { TreeComponent } from '../../module/TreeViewParentSelect';
import { Menu } from '../Header';

function MngPriv({ getHeader }: { getHeader: () => void; }) {
    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });

    //큰 화면용 list를 위한 변수
    const [detailPriv, setDetailPriv] = useState<PrivVO[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    //작은 화면용 list를 위한 변수
    const [simplePriv, setSimplePriv] = useState<PrivVO[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);

    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    //Modal Popup 변수
    const [showModal, setShowModal] = useState(false);
    const [isMod, setIsMod] = useState(false);
    const [selectPriv, setSelectPriv] = useState<PrivVO>({
        priv_cd: '',
        priv_nm: '',
        priv_expl: '',
        use_yn: '',
        use_str: ''
    });
    //유저 권한 Modal 변수
    const [showUserPrivModal, setShowUserPrivModal] = useState(false);

    //AlertModal 변수
    const [showAlert, setShowAlert] = useState(false);

    //AlertModal 변수
    const [showMenuModal, setShowMenuModal] = useState(false);

    //권한 삭제함수 변수
    const [deleteCd, setDeleteCd] = useState('');

    //큰화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
    //DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
    const detail_columns = [
        {
            Header: '순서',
            accessor: 'no'
        },
        {
            Header: '권한코드',
            accessor: 'priv_cd'
        },
        {
            Header: '권한명',
            accessor: 'priv_nm'
        },
        {
            Header: '사용여부',
            accessor: 'use_str'
        },
        {
            Header: '사용자설정',
            accessor: 'option1',
            Cell: ({ row }: CellProps<PrivVO>) => (
                <Button size="sm" onClick={(e) => userEdit(row.original)}>
                    사용자설정
                </Button>
            ),
        },
        {
            Header: '메뉴설정',
            accessor: 'option2',
            Cell: ({ row }: CellProps<PrivVO>) => (
                <Button size="sm" onClick={(e) => menuEdit(row.original)}>
                    메뉴설정
                </Button>
            ),
        },
        {
            Header: '삭제',
            accessor: 'option3',
            Cell: ({ row }: CellProps<PrivVO>) => (
                <Button size="sm" onClick={(e) => deleteFunc(row.original)}>
                    삭제
                </Button>
            ),
        }
    ];

    //작은화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
    //DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
    const simple_columns = [
        {
            Header: '권한코드',
            accessor: 'priv_cd'
        },
        {
            Header: '사용여부',
            accessor: 'use_str'
        },
        {
            Header: '설정',
            accessor: 'option',
            Cell: ({ row }: CellProps<PrivVO>) => (
                <div style={{ display: 'grid' }}>
                    <Button size="sm" onClick={(e) => { e.stopPropagation(); userEdit(row.original); }}>
                        사용자설정
                    </Button>
                    <Button size="sm" onClick={(e) => { e.stopPropagation(); menuEdit(row.original); }}>
                        메뉴설정
                    </Button>
                    <Button size="sm" onClick={(e) => { e.stopPropagation(); deleteFunc(row.original); }}>
                        삭제
                    </Button>
                </div>
            ),
        }
    ];

    //권한 리스트 검색 함수
    const selectPrivList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const searchStr = searchMap.get("searchStr");
        const searchCol = searchMap.get("searchCol");
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/admin/selectPrivList',
            {
                "currPage": currPage,
                "rowSize": rowSize,
                "searchStr": searchStr,
                "searchCol": searchCol,
                "useYn": useYn,
                "sortStr": sortStr,
                "sortDir": sortDir
            },
            { headers: { "Content-Type": 'application/json' } }
        );
        const data = await response.data;
        if (isDetail) {
            setDetailPriv(data.content);
            setPageCount(data.totalPages);
            setRowCount(data.rowCount);
        } else {
            if (simplePage === 0) {
                setSimplePriv(data.content);
            } else {
                setSimplePriv(prevPosts => [...prevPosts, ...data.content]);
            }
            setHasMore(!data.last);
        }
        setLoading(false);
    };

    function popReg() {
        setSelectPriv({
            priv_cd: '',
            priv_nm: '',
            priv_expl: '',
            use_yn: 'Y',
            use_str: '사용'
        });
        setIsMod(false);
        setShowModal(true);
    };

    function search() {
        if (detailPage === 0) {
            selectPrivList(0, rowSize, true);
        } else {
            setDetailPage(0);
        }
        if (simplePage === 0) {
            selectPrivList(0, 25, false);
        } else {
            setSimplePage(0);
        }
    };

    //searchMap에는 검색어와 검색컬럼이 SearchBar에서 세팅된다.
    //검색어는 SearchBar에 searchstr로 지정되어있으므로 바꿀수 없고,
    //select box 선택시 검색컬럼 지정은 하위 selectTypes의 key 에 매핑(해당페이지는 searchCol)
    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map<string, string>([['searchCol', 'priv_cd']]));
    const [useYn, setUseYn] = useState<string>('');

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectPrivList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectPrivList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            search();
        };
        loadPosts();
    }, [sort]);

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    const deleteFunc = async (vo: PrivVO) => {
        setDeleteCd(vo.priv_cd);
        setShowAlert(true);
    }

    async function confirm() {
        const response = await axios.post("/api/admin/deletePriv", null, { params: { "priv_cd": deleteCd } });
        const data = response.data;
        if (data > -1) {
            toast("삭제가 완료되었습니다.", { position: 'top-center', autoClose: 2000 });
            setShowAlert(false);
            search();
        } else {
            toast("삭제 실패", { position: 'top-center', autoClose: 2000 });
        }
    }

    const detailModalPopup = (vo: PrivVO) => {
        setSelectPriv(vo);
        setIsMod(true);
        setShowModal(true);
    }
    const userEdit = async (vo: PrivVO) => {
        setSelectPriv(vo);
        setShowUserPrivModal(true);
    }
    const menuEdit = async (vo: PrivVO) => {
        setSelectPriv(vo);
        setShowMenuModal(true);
    }

    return (
        <div className='content'>
            <h2>권한관리</h2>
            <h5>권한의 추가 등록 및 관리를 할 수 있습니다.</h5>
            <SearchBarV2
                setRowSize={setRowSize}
                rowCount={rowCount}
                searchMap={searchMap}
                setSearchMap={setSearchMap}
                search={search}
                leftChildren={
                    <>
                        <Col sm="auto">
                            <Form.Select name='searchCol' value={searchMap.get('searchCol')} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option value='priv_cd'>권한코드</option>
                                <option value='priv_nm'>권한명</option>
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select name='useYn' value={useYn} onChange={(e) => { setUseYn(e.target.value); }}>
                                <option value=''>전체</option>
                                <option value='Y'>사용</option>
                                <option value='N'>미사용</option>
                            </Form.Select>
                        </Col>
                    </>
                }
                rightChildren={
                    <>
                        <Col xs="auto">
                            <Button onClick={() => popReg()}>등록</Button>
                        </Col>
                    </>
                } />
            {isDesktop ? (
                <>
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailPriv} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => detailModalPopup(vo)} />
                    {pageCount > 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simplePriv.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={simple_columns} data={simplePriv} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => detailModalPopup(vo)} />
                </InfiniteScroll>
            )}
            <PopupModal show={showModal} setShow={setShowModal} isMod={isMod} selectPriv={selectPriv} setSelectPriv={setSelectPriv} search={search} />
            <UserPrivModal show={showUserPrivModal} setShow={setShowUserPrivModal} selectPriv={selectPriv} setSelectPriv={setSelectPriv} getHeader={getHeader} />
            <MenuCheckModal show={showMenuModal} setShow={setShowMenuModal} selectPriv={selectPriv} getHeader={getHeader} />
            <AlertModal title='정말 삭제하시겠습니까?' show={showAlert} setShow={setShowAlert} agree='삭제' func={() => confirm()} />
        </div>
    );
}

const PopupModal = ({ show, setShow, isMod, selectPriv, setSelectPriv, search }: { show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, isMod: boolean, selectPriv: PrivVO, setSelectPriv: React.Dispatch<React.SetStateAction<PrivVO>>, search: () => void; }): React.ReactElement => {

    const [dupCheck, setDupCheck] = useState(false);

    async function deptPost() {
        if (selectPriv.priv_cd === '') {
            toast("권한코드를 입력해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        if (!isMod && !dupCheck) {
            toast("권한코드 중복확인을 해주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        if (selectPriv.priv_nm === '') {
            toast("권한명을 입력해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        const url = isMod ? '/api/admin/updatePriv' : '/api/admin/insertPriv';
        const response = await axios.post(url, selectPriv, { headers: { "Content-Type": 'application/json' }, params: { "userId": sessionStorage.getItem("user_id") } });
        const data = response.data;
        if (data === 1) {
            toast(isMod ? "수정이 완료되었습니다." : "등록이 완료되었습니다.", { position: 'top-center', autoClose: 2000 });
            setShow(false);
            search();
        } else {
            toast(isMod ? "수정 실패" : "등록 실패", { position: 'top-center', autoClose: 2000 });
        }

    };

    async function deptDupCheck() {
        if (selectPriv.priv_cd === "") {
            toast("권한코드가 공백입니다.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        const response = await axios.post('/api/admin/privDupCheck', null, { params: { "priv_cd": selectPriv.priv_cd } });
        const data = response.data;
        if (data === 0) {
            setDupCheck(true);
        } else {
            toast("중복된 권한코드 입니다.", { position: 'top-center', autoClose: 2000 });
        }
    };

    function changePrivMap(e: React.ChangeEvent<any>) {
        setSelectPriv({ ...selectPriv, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setDupCheck(false);
    }, [show]);

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{isMod ? '권한관리 - 상세조회' : '권한관리 - 등록'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col xs='4'>
                            권한코드
                        </Col>
                        {isMod ?
                            <Col>
                                <Form.Control type="textBox" name="priv_cd" value={selectPriv.priv_cd} disabled />
                            </Col>
                            :
                            <>
                                <Col>
                                    <Form.Control type="textBox" name="priv_cd" value={selectPriv.priv_cd} onChange={(e) => { setDupCheck(false); changePrivMap(e); }} />
                                </Col>
                                <Col xs="auto">
                                    <Button size="sm" variant={dupCheck ? 'success' : 'danger'} onClick={() => deptDupCheck()}>  {dupCheck ? "확인완료" : "중복확인"}</Button>
                                </Col>
                            </>
                        }
                    </Row>
                    <Row>
                        <Col xs='4'>
                            권한명
                        </Col>
                        <Col>
                            <Form.Control type="textBox" name="priv_nm" value={selectPriv.priv_nm} onChange={(e) => changePrivMap(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            설명
                        </Col>
                        <Col>
                            <Form.Control type="textBox" name="priv_expl" value={selectPriv.priv_expl ? selectPriv.priv_expl : ''} onChange={(e) => changePrivMap(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            사용여부
                        </Col>
                        <Col>
                            <Form.Select name='use_yn' value={selectPriv.use_yn} onChange={(e) => changePrivMap(e)}>
                                <option value='Y'>사용</option>
                                <option value='N'>미사용</option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    닫기
                </Button>
                <Button variant="primary" onClick={() => deptPost()}>
                    {isMod ? '수정' : '등록'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const UserPrivModal = ({ show, setShow, selectPriv, setSelectPriv, getHeader }: { show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, selectPriv: PrivVO, setSelectPriv: React.Dispatch<React.SetStateAction<PrivVO>>, getHeader: () => void }): React.ReactElement => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const size = isDesktop ? 'xl' : undefined;

    //큰 화면용 list를 위한 변수
    const [detailPriv, setDetailPriv] = useState<UserPrivVO[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    //작은 화면용 list를 위한 변수
    const [simplePriv, setSimplePriv] = useState<UserPrivVO[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);

    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    const [privCd, setPrivCd] = useState('');
    const [privList, setPrivList] = useState<PrivVO[]>();
    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map<string, string>([['searchCol', 'priv_cd']]));

    const popup_columns = [
        {
            Header: '권한',
            accessor: 'priv_cd'
        },
        {
            Header: '권한명',
            accessor: 'priv_nm'
        },
        {
            Header: '아이디',
            accessor: 'user_id'
        },
        {
            Header: '이름',
            accessor: 'user_nm'
        }
    ];
    const popup_simple_columns = [
        {
            Header: '권한',
            accessor: 'priv_cd'
        },
        {
            Header: '이름',
            accessor: 'user_nm'
        }
    ];

    //권한 유저리스트 검색 함수
    const selectPrivUserList = async (currPage: number, rowSize: number, isDetail: boolean, priv_cd?: string) => {
        let searchStr = searchMap.get("searchStr");
        if (priv_cd) {
            searchStr = priv_cd;
        }
        const searchCol = searchMap.get("searchCol");
        const sortStr = sort[0];
        const sortDir = sort[1];
        //todo url 변경
        const response = await axios.post(
            '/api/admin/selectUserPrivList',
            {
                "currPage": currPage,
                "rowSize": rowSize,
                "searchStr": searchStr,
                "searchCol": searchCol,
                "sortStr": sortStr,
                "sortDir": sortDir
            },
            { headers: { "Content-Type": 'application/json' } }
        );
        const data = await response.data;
        if (isDetail) {
            setDetailPriv(data.content);
            setPageCount(data.totalPages);
            setRowCount(data.rowCount);
        } else {
            if (simplePage === 0) {
                setSimplePriv(data.content);
            } else {
                setSimplePriv(prevPosts => [...prevPosts, ...data.content]);
            }
            setHasMore(!data.last);
        }
        setLoading(false);
        setCheckedItems({});
    };

    function search(priv_cd?: string) {
        if (detailPage === 0) {
            selectPrivUserList(0, rowSize, true, priv_cd);
        } else {
            setDetailPage(0);
        }
        if (simplePage === 0) {
            selectPrivUserList(0, 25, false, priv_cd);
        } else {
            setSimplePage(0);
        }
    };

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectPrivUserList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectPrivUserList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            search();
        };
        loadPosts();
    }, [sort]);

    //팝업창에 들어올때 검색어 세팅 후 리스트 재검색
    useEffect(() => {
        const loadPosts = async () => {
            search(selectPriv.priv_cd);
        };
        setPrivCd(selectPriv.priv_cd);
        setSearchMap(new Map<string, string>([['searchCol', 'priv_cd'], ['searchStr', selectPriv.priv_cd]]));
        loadPosts();
    }, [selectPriv]);

    useEffect(() => {
        const loadPosts = async () => {
            const response = await axios.post("/api/admin/selectPrivSelectList");
            const data = response.data;
            setPrivList(data);
        };
        loadPosts();
    }, []);

    useEffect(() => {
        if (!show) {
            setSelectPriv({
                priv_cd: '',
                priv_nm: '',
                priv_expl: '',
                use_yn: '',
                use_str: ''
            });
        }
    }, [show]);

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    //DataTable 좌측 CheckBox 생성시 필요한 변수
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});

    async function setUserPriv() {
        const user_id: string[] = [];
        for (const checkedKey in checkedItems) {
            if (checkedItems[checkedKey]) user_id.push(checkedKey);
        }
        if (user_id.length < 1) {
            toast("한명 이상의 권한을 수정해야 합니다.", { position: 'top-center', autoClose: 2000 });
            return;
        }

        const response = await axios.post('/api/admin/userPrivSet', user_id, { headers: { "Content-Type": 'application/json' }, params: { "userId": sessionStorage.getItem("user_id"), "priv_cd": privCd } });
        const data = response.data;
        if (data === 1) {
            toast("사용자 권한 설정이 완료되었습니다.", { position: 'top-center', autoClose: 2000 });
            getHeader();
            setShow(false);
        } else {
            toast("사용자 권한 설정 실패", { position: 'top-center', autoClose: 2000 });
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} size={size} >
            <Modal.Header closeButton>
                <Modal.Title>사용자 권한 설정</Modal.Title>
            </Modal.Header>
            <Modal.Body id='scrollableModalBody' style={{ height: '74vh', flexGrow: 1, overflow: 'auto' }}>
                <SearchBarV2
                    setRowSize={setRowSize}
                    rowCount={rowCount}
                    searchMap={searchMap}
                    setSearchMap={setSearchMap}
                    search={search}
                    leftChildren={
                        <>
                            <Col sm="auto">
                                <Form.Select name='searchCol' value={searchMap.get('searchCol')} onChange={(e) => {
                                    const newMap = new Map(searchMap);
                                    newMap.set(e.target.name, e.target.value);
                                    setSearchMap(newMap);
                                }}>
                                    <option value='priv_cd'>권한코드</option>
                                    <option value='priv_nm'>권한명</option>
                                    <option value='user_id'>아이디</option>
                                    <option value='user_nm'>이름</option>
                                </Form.Select>
                            </Col>
                        </>
                    } />

                {isDesktop ? (
                    <>
                        <DataTableV2 loading={loading} columns={popup_columns} data={detailPriv} sort={sort} setSortDir={setSortDir} keyField='user_id' checkedItems={checkedItems} setCheckedItems={setCheckedItems}
                            onRowClick={(vo) => {
                                setCheckedItems!((prevState: { [key: string]: boolean; }) => {
                                    return { ...prevState, [vo.user_id]: !prevState[vo.user_id] }
                                });
                            }} />
                        {pageCount > 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                    </>
                ) : (
                    <InfiniteScroll
                        dataLength={simplePriv.length}
                        next={loadMore}
                        hasMore={hasMore}
                        loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                        scrollableTarget="scrollableModalBody"
                    >
                        <DataTableV2 loading={loading} columns={popup_simple_columns} data={simplePriv} sort={sort} setSortDir={setSortDir} keyField='user_id' checkedItems={checkedItems} setCheckedItems={setCheckedItems}
                            onRowClick={(vo) => {
                                setCheckedItems!((prevState: { [key: string]: boolean; }) => {
                                    return { ...prevState, [vo.user_id]: !prevState[vo.user_id] }
                                });
                            }} />
                    </InfiniteScroll>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Form.Select name='priv_cd' value={privCd} onChange={(e) => { setPrivCd(e.target.value); }}>
                    {privList?.map((item) =>
                        <option key={item.priv_cd} value={item.priv_cd} >{item.priv_nm}</option>
                    )}
                </Form.Select>
                <Button variant="primary" onClick={() => setUserPriv()}>
                    적용
                </Button>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    닫기
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
const MenuCheckModal = ({ show, setShow, selectPriv, getHeader }: { show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, selectPriv: PrivVO, getHeader: () => void }): React.ReactElement => {

    //TreeView 좌측 CheckBox 생성시 필요한 변수
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
    const [menuList, setMenuList] = useState<Menu[]>([]);

    useEffect(() => {
        const getMenuList = async () => {
            const response = await axios.post("/api/allMenu", null);
            setMenuList(response.data);
        };
        getMenuList();

    }, []);

    useEffect(() => {
        const getMenuList = async () => {
            const response = await axios.post("/api/selectMenuPriv", null, { params: { "priv_cd": selectPriv.priv_cd } });
            setCheckedItems(response.data);
        };
        if (show) getMenuList();

    }, [menuList, selectPriv, show]);

    async function savePrivMenu() {
        const menu_id_list: string[] = [];
        for (const checkedKey in checkedItems) {
            if (checkedItems[checkedKey]) menu_id_list.push(checkedKey);
        }
        const response = await axios.post('/api/admin/menuPrivSet', menu_id_list, { headers: { "Content-Type": 'application/json' }, params: { "userId": sessionStorage.getItem("user_id"), "priv_cd": selectPriv.priv_cd } });
        const data = response.data;
        if (data === 1) {
            toast("메뉴 권한 설정이 완료되었습니다.", { position: 'top-center', autoClose: 2000 });
            getHeader();
            setShow(false);
        } else {
            toast("메뉴 권한 설정 실패", { position: 'top-center', autoClose: 2000 });
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{'메뉴권한 설정'}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '74vh', flexGrow: 1, overflow: 'auto' }}>
                <TreeComponent defaultExpend={false} nodes={menuList} idField={"menu_id"} nameField={"menu_nm"} onNodeClick={(node) => setCheckedItems((prevState: { [key: string]: boolean; }) => { return { ...prevState, [node.menu_id]: !prevState[node.menu_id] } })} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    닫기
                </Button>
                <Button variant="primary" onClick={() => savePrivMenu()}>
                    적용
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

//DataTable에서 사용하는 데이터의 형식 (최하단의 [key: string]: any; 는 공통로직에서 key값에 map으로 접근하기 위해 필수)
type PrivVO = {
    no?: number;
    priv_cd: string;
    priv_nm: string;
    priv_expl: string;
    use_yn: string;
    use_str: string;
    [key: string]: any;
}

//DataTable에서 사용하는 데이터의 형식 (최하단의 [key: string]: any; 는 공통로직에서 key값에 map으로 접근하기 위해 필수)
type UserPrivVO = {
    priv_cd: string;
    priv_nm: string;
    user_id: string;
    user_nm: string;
    [key: string]: any;
}

export default MngPriv;
