import { Button, Col, Container, Form, Row } from "react-bootstrap"

interface SearchBarProps {
    setRowSize: (size: number) => void;
    rowCount: number;
    searchMap: Map<string, string>;
    setSearchMap: (searchMap: Map<string, string>) => void;
    search: Function;
    leftChildren?: React.ReactNode;
    rightChildren?: React.ReactNode;
}

export default function SearchBarV2({ setRowSize, rowCount, searchMap, setSearchMap, search, leftChildren, rightChildren }: SearchBarProps) {
    return (
        <Container fluid>
            <Row className="gx-2 mb-1">
                {leftChildren &&
                    leftChildren
                }
                <Col>
                    <Form.Control type="textBox" name="searchStr" defaultValue={searchMap.get('searchStr')} onChange={(e) => {
                        const newMap = new Map(searchMap);
                        newMap.set(e.target.name, e.target.value);
                        setSearchMap(newMap);
                    }} onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); search() } }} />
                </Col>
                <Col sm="auto" xs={12} className="d-grid gap-2">
                    <Button name="searchBtn" onClick={() => search()}>검색</Button>
                </Col>
                </Row>
                <Row className="d-flex justify-content-end gx-2 gy-1">
                {rightChildren &&
                    rightChildren
                }
                <Col md={{order: "first", span: "auto"}} sm={"auto"} className="rowCount me-md-auto">
                    <p><span style={{color: '#fb0404'}}>Total</span>{rowCount === 0 ? ` 0` : ` ${rowCount}`}</p>
                    {/* <p>{(currPage * rowSize) + 1} - {totalPage - 1 === currPage ? <>{rowCount}</> : <>{(currPage + 1) * rowSize}</>} of {rowCount}</p> */}
                    
                </Col>

                <Col sm="auto" className="d-none d-md-block">
                    <Form.Select name="countSelecter" onChange={(e) => setRowSize(Number(e.target.value))}>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                    </Form.Select>
                </Col>
            </Row>
        </Container>
    )
}
