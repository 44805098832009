import React, { useEffect, useState } from 'react';
import DataTable from '../../module/DataTable';
import axios from 'axios';
import { Button, Col, Form, Modal, Table } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import InfiniteScroll from 'react-infinite-scroll-component';
import Paginate from '../../module/Paginate';
import Loading from '../../module/Loading';
import SearchBarV2 from '../../module/SearchBarV2';
import { CellProps } from 'react-table';
import DataTableV2 from '../../module/DataTableV2';
import ApprInfoArea from '../appr/signbox/ApprInfoArea';
import { toast } from 'react-toastify';

function DaysSettings() {

    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });

    //큰 화면용 list를 위한 변수
    const [detailHolidayState, setDetailHolidayState] = useState<DetailHolidayState[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    //작은 화면용 list를 위한 변수
    const [simpleHolidayState, setSimpleHolidayState] = useState<DetailHolidayState[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);

    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    const [selectedHolidayState, setSelectedHolidayState] = useState<DetailHolidayState>()
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map());
    const [deptList, setDeptList] = useState<DeptVO[]>([]);
    const [selectedDept, setSelectedDept] = useState<DeptVO>({
        dept_cd: '',
        dept_nm: ''
    });
    const [selectedDay, setSelectedDay] = useState<any>();
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [personalHolidayState, setPersonalHolidayState] = useState<DetailHolidayState>();
    const [personalHolidayStateList, setPersonalHolidayStateList] = useState<DetailHolidayState[]>([]);

    // 휴가사용현황 상세보기
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setShow(true); }
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const handleUpdateClose = () => setShowUpdateModal(false);
    const handleUpdateShow = (vo: DetailHolidayState) => {
        setShowUpdateModal(true);
        setSelectedHolidayState(vo);
        setSelectedDay(vo.total_holiday);
        if (vo.start_date) {
            setStartDate(vo.start_date);
            setEndDate(vo.end_date);
        } else {
            setStartDate(selectedYear + '-01-01');
            setEndDate(selectedYear + '-12-31');
        }
    }
    const [holidayRange, setHolidayRange] = useState([]);
    const [selectedHolidayRange, setSelectedHolidayRange] = useState('');
    // 날짜
    const currentYear = new Date().getFullYear();
    const yearOptions = Array.from({ length: currentYear + 3 - 2014 }, (_, i) => 2015 + i);
    const dayList = Array.from({ length: (30.0 - 0.0) / 0.5 + 1 }, (_, i) => (0.0 + i * 0.5));

    // 결재문서 상세보기
    const [showApprDetail, setShowApprDetail] = useState(false);
    const [apprDetail, setApprDetail] = useState<{ sign_id: string, frm_id: string, frm_name: string, mode: string, vacation_yn: string, cooperation_yn: string } | null>(null);

    // 리스트 데이터 가져오기
    const selectDataList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const searchStr = searchMap.get("searchStr");
        var sortStr = sort[0];
        const sortDir = sort[1];
        if (sortStr === 'date_range') sortStr = 'START_DATE||END_DATE'
        const response = await axios.post(
            '/api/holiday/selectDaysList',
            {
                "year": selectedYear,
                'dept_cd': selectedDept.dept_cd,
                "lang": "ko",
                "comm_code": "HR005",
                "user_nm": searchStr,
                "currPage": currPage,
                "rowSize": rowSize,
                "sortStr": sortStr,
                "sortDir": sortDir,
            },
            { headers: { "Content-Type": 'application/json' } }
        )
        setDeptList(response.data.deptList);
        if (isDetail) {
            setDetailHolidayState(response.data.content)
            setPageCount(response.data.totalPages);
            setRowCount(response.data.rowCount);
        } else {
            if (simplePage === 0) {
                setSimpleHolidayState(response.data.content);
            } else {
                setSimpleHolidayState(prevPosts => [...prevPosts, ...response.data.content]);
            }
            setHasMore(!response.data.last);
        }
        setLoading(false);
    }
    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDataList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectDataList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            search();
        };
        loadPosts();
    }, [sort]);

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    function changeYear(e: React.ChangeEvent<any>) {
        setSelectedYear(e.target.value);
    }
    function changeDeptMap(e: React.ChangeEvent<any>) {
        const dept_code = e.target.value;
        if (dept_code !== 'none') {
            const dept_name = deptList.find(option => option.dept_cd === dept_code)?.dept_nm
            setSelectedDept({ dept_cd: dept_code, dept_nm: dept_name ?? '' });
        } else {
            setSelectedDept({ dept_cd: '', dept_nm: '' });
        }
    };

    function changeDay(e: React.ChangeEvent<any>) {
        const day = e.target.value;
        setSelectedDay(day);
    }

    // 휴가 지급 일수 변경
    const updateTotalHoliday = async () => {
        await axios.post(
            '/api/holiday/updateTotalHoliday',
            {
                year: selectedYear.toString(),
                user_id: selectedHolidayState?.user_id,
                reg_user_id: sessionStorage.getItem("user_id"),
                edit_user_id: sessionStorage.getItem("user_id"),
                total_holiday: selectedDay,
                start_date: startDate,
                end_date: endDate,
                mod_yn: selectedHolidayState?.start_date ? "Y" : "N"
            },
            { headers: { "Content-Type": 'application/json' } }
        )
        handleUpdateClose();
        search();
    }

    function search() {
        if (detailPage === 0) {
            selectDataList(0, rowSize, true);
        } else {
            setDetailPage(0);
        }
        if (simplePage === 0) {
            selectDataList(0, 25, false);
        } else {
            setSimplePage(0);
        }
    };
    let autoCreateFlag = true;
    async function autoCreate() {
        if (autoCreateFlag) {
            autoCreateFlag = false;
            await axios.post('/api/holiday/holidayAutoCreate', null, { params: { "year": selectedYear } }).then(_ => {
                toast("자동 생성이 완료 되었습니다.", { position: 'top-center', autoClose: 2000 });
                autoCreateFlag = true;
                search();
            });
        } else {
            toast("해당년도 휴가일수 자동 생성 중 입니다.", { position: 'top-center', autoClose: 2000 });
        }
    }

    // modal 휴가사용현황, 휴가사용내역 불러오기
    const openModal = async (vo: any) => {
        const response = await axios.post('/api/admin/selectPersonalHolidayRange', null, { params: { "user_id": vo.user_id } });
        setHolidayRange(response.data);
        var range = ''
        if (vo.start_date === null || vo.start_date === '') range = response.data[0];
        else range = vo.start_date + ' ~ ' + vo.end_date;
        setSelectedHolidayRange(range);
        selectPersonalPopup(range, vo.user_id);
        handleShow();
    }

    // 모달 연도 변경 감지 
    const handleRangeChange = async (e: React.ChangeEvent<any>) => {
        selectPersonalPopup(e.target.value, personalHolidayState?.user_id);
    };

    const selectPersonalPopup = async (date_range: string, user_id?: string) => {
        const response = await axios.post(
            '/api/admin/selectPersonalHolidayState',
            { start_date: date_range.split(' ~ ')[0], end_date: date_range.split(' ~ ')[1], user_id: user_id },
            { headers: { "Content-Type": 'application/json' } }
        )
        const addData = response.data.recordList.map((item: { terms_days: string; start_date: string; end_date: string; harf_vacation_tp: string; holiday_type: string; minus_yn: string; holiday_resn: string; }) => ({
            ...item,
            vacation_date: item.harf_vacation_tp != null ? `${item.start_date} ${item.harf_vacation_tp}` : parseInt(item.terms_days) > 1 ? `${item.start_date} ~ ${item.end_date}` : `${item.start_date}`
        }));
        setPersonalHolidayState(response.data.recordInfo);
        setPersonalHolidayStateList(addData);
    };
    //큰화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
    //DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
    const detail_columns = [
        {
            Header: 'NO',
            accessor: 'no'
        },
        {
            Header: '직급',
            accessor: 'detail_code_nm'
        },
        {
            Header: '부서명',
            accessor: 'dept_nm'
        },
        {
            Header: '이름',
            accessor: 'user_nm'
        },
        {
            Header: '휴가기간',
            accessor: 'date_range',
            Cell: ({ row }: CellProps<DetailHolidayState>) => row.original.start_date ? <>{row.original.start_date} ~ {row.original.end_date}</> : <>미지정</>
        },
        {
            Header: '휴가일수',
            accessor: 'total_holiday'
        },
        {
            Header: '수정',
            accessor: 'option1',
            Cell: ({ row }: CellProps<DetailHolidayState>) => (
                <Button size="sm" onClick={(e) => handleUpdateShow(row.original)}>
                    수정
                </Button>
            ),
        },
    ];

    const simple_columns = [
        {
            Header: 'NO',
            accessor: 'no'
        },
        {
            Header: '이름',
            accessor: 'user_nm'
        },
        {
            Header: '휴가기간',
            accessor: 'date_range',
            Cell: ({ row }: CellProps<DetailHolidayState>) => row.original.start_date ? <>{row.original.start_date} ~ {row.original.end_date}</> : <>'미지정'</>
        },
        {
            Header: '휴가일수',
            accessor: 'total_holiday'
        },
        {
            Header: '수정',
            accessor: 'option1',
            Cell: ({ row }: CellProps<DetailHolidayState>) => (
                <Button size="sm" onClick={(e) => handleUpdateShow(row.original)}>
                    수정
                </Button>
            ),
        },
    ];

    // 결재문서 클릭
    const handleApprRowClick = (vo: any) => {
        setApprDetail({
            sign_id: vo.sign_id,
            frm_id: vo.frm_id,
            frm_name: vo.holiday_type,
            mode: 'sView',
            vacation_yn: 'Y',
            cooperation_yn: 'N',
        });
    };
    // 결재문서 값이 바뀌면 상세보기 열기
    useEffect(() => {
        if (apprDetail) {
            setShowApprDetail(true);
        }
    }, [apprDetail]);

    return (
        <div className='content'>
            <h2>휴가일수설정</h2>
            <SearchBarV2
                setRowSize={setRowSize}
                rowCount={rowCount}
                searchMap={searchMap}
                setSearchMap={setSearchMap}
                search={search}
                leftChildren={
                    <>
                        <Col sm="auto">
                            <Form.Select id="selectedYear" value={selectedYear} onChange={(e) => changeYear(e)}>
                                {yearOptions?.map((item) =>
                                    <option key={item} value={item}>{item}</option>
                                )}
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select name='dept_code' value={selectedDept?.dept_cd} onChange={(e) => { changeDeptMap(e); }}>
                                <option value="none">부서전체</option>
                                {deptList?.map((item: DeptVO) =>
                                    <option key={item.dept_cd} value={item.dept_cd}>{item.dept_nm}</option>
                                )}
                            </Form.Select>
                        </Col>
                    </>
                }
                rightChildren={
                    <Col xs="auto" >
                        <Button name="autoCreate" onClick={() => autoCreate()}>해당연도 자동생성</Button>
                    </Col>
                }
            />
            {isDesktop ? (
                <>
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailHolidayState} onRowClick={(vo) => openModal(vo)} sort={sort} setSortDir={setSortDir} />
                    {pageCount > 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleHolidayState.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={simple_columns} data={simpleHolidayState} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => openModal(vo)} />
                </InfiniteScroll>
            )}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>[{personalHolidayState?.position_nm} {personalHolidayState?.user_nm}] 휴가 사용 현황</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive style={{ textAlign: 'center', fontSize: '0.9em' }}>
                        <thead className='align-middle'>
                            <tr>
                                <th style={{ backgroundColor: '#f4f4f4', fontWeight: 'normal' }}>휴가기간</th>
                                <td>
                                    <Form.Select id='selectedHolidayRange' value={selectedHolidayRange} onChange={(e) => { setSelectedHolidayRange(e.currentTarget.value); handleRangeChange(e) }} size="sm">
                                        {holidayRange?.map((item) =>
                                            <option key={item} value={item}>{item}</option>
                                        )}
                                    </Form.Select>
                                </td>
                                <th style={{ backgroundColor: '#f4f4f4' }}>입사일</th>
                                <td>{personalHolidayState?.hire_dt}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th style={{ backgroundColor: '#f4f4f4' }}>휴가지급일수</th>
                                <td>{personalHolidayState?.total_holiday}일</td>
                                <th style={{ backgroundColor: '#f4f4f4' }}>휴가 총 사용일/잔여일</th>
                                <td>{personalHolidayState?.used_holiday}일/{personalHolidayState?.rest_holiday}일</td>
                            </tr>
                        </tbody>
                    </Table>
                    <h6>휴가사용내역</h6>
                    <div className="text-center" style={{ height: '180px', fontSize: '0.9em', overflow: 'auto' }}>
                        <DataTable loading={loading} columns={modal_columns} data={personalHolidayStateList} sort={[]} setSortDir={function (sort: string[]): void { }} onRowClick={(vo) => handleApprRowClick(vo)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} style={{ width: '70px' }}>취소</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showUpdateModal} onHide={handleUpdateClose} >
                <Modal.Header closeButton>
                    <Modal.Title>[{selectedHolidayState?.detail_code_nm} {selectedHolidayState?.user_nm}] 휴가 일수 설정 </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type='date' name='start_date' defaultValue={startDate} min={selectedYear + '-01-01'} max={selectedYear + '-12-31'} onChange={(e) => { setStartDate(e.target.value); }} />
                    <Form.Control type='date' name='end_date' defaultValue={endDate} min={startDate} onChange={(e) => { setEndDate(e.target.value); }} />

                    <Form.Select name='personalHoliday' value={selectedDay} onChange={(e) => changeDay(e)}>
                        {dayList?.map((item) =>
                            <option key={item} value={item}>{item}</option>
                        )}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleUpdateClose}>취소</Button>
                    <Button onClick={updateTotalHoliday}>수정</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showApprDetail} onHide={() => { setShowApprDetail(false); }} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {apprDetail?.frm_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ApprInfoArea
                        boxTpSignUserListCheck={''}
                        recentApprListCheck={[]}
                        mode={'view'}
                        currUserInfo={{
                            USER_POSIT_NM: "",
                            DRFT_DT: "",
                            USER_NM: "",
                            USER_POSIT_CD: "",
                            USER_DEPT_NM: "",
                            APPR_SIGNATURE_USE_YN: "",
                            USER_ID: "",
                            USER_DEPT_CD: "",
                            APPR_SIGNATUR_PATH: ""
                        }}
                        signForm={{
                            sign_line_type: "",
                            retention: 0,
                            cooperation_yn: "",
                            receive_yn: "",
                            fixReceptionList: [],
                            fix_dept_nm: "",
                            fix_dept_cd: "",
                            deptList: [],
                            dept_auth_cd: "",
                            dept_auth_nm: "",
                            formCompList: [],
                            modify_yn: "",
                            frm_ctg_name: "",
                            sec_level_cd: "",
                            output_file: "",
                            use_yn: "",
                            frm_num: "",
                            revision_num: 0,
                            doc_tp_cd: ""
                        }}
                        subWorkerList={[]}
                        actionStatus={JSON.stringify(apprDetail)}
                        varStatus={false}
                        status={false}
                        lastSeq={0}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setShowApprDetail(false);}}>닫기</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

//큰화면에서 DataTable에서 사용하는 데이터의 형식 (최하단의 [key: string]: any; 는 공통로직에서 key값에 map으로 접근하기 위해 필수)
type DetailHolidayState = {
    no?: number;
    dept_cd: string;
    dept_nm: string;
    position_cd: string;
    detail_code_nm: string; // position
    user_nm: string;
    user_id?: string
    hire_dt: string;
    total_holiday: number;
    used_holiday: number;
    rest_holiday: number;
    sign_id: string;
    start_date?: string;
    end_date?: string;
    [key: string]: any;
}

type DeptVO = {
    dept_nm: string;
    dept_cd: string;
    [key: string]: any;
}

const modal_columns = [
    {
        Header: '휴가종류',
        accessor: 'holiday_type'
    },
    {
        Header: '휴가차감내역',
        accessor: 'minus_yn'
    },
    {
        Header: '휴가일수',
        accessor: 'terms_days'
    },
    {
        Header: '사용기간',
        accessor: 'vacation_date' // start_date harf_vacation_tp end_date
    },
];


export default DaysSettings;