import { Button, Modal, Form } from 'react-bootstrap';

const PopupShow = ({ show, setShow, formData}: { show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, formData: {[key: string]: any;}}): React.ReactElement => {
return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{'영수증 사진 미리보기'}</Modal.Title>
            </Modal.Header>
            <Form>
                <img className="previewReceipt" src={formData.imagePreviewUrl} alt="이미지 미리보기..." />
            </Form>
            <Modal.Footer>
                <Button onClick={() => {
                    formData.imagePreviewUrl = '';
                    formData.imageFile = null;
                    formData.note = '';
                    setShow(false);
                    let imgFile = document.getElementById('imageUpload') as HTMLInputElement;
                    if (imgFile != null) {
                        imgFile.value = '';
                    }
                }}>삭제
                </Button>
                <Button variant='secondary' onClick={() => setShow(false)}>
                    닫기
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PopupShow;