import '../../css/exter/Board.css'
import axios from "axios";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, FormGroup, Row, Table } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { SiMicrosoftexcel, SiMicrosoftword, SiMicrosoftpowerpoint } from "react-icons/si";
import { RiFileHwpLine, RiFileImageLine, RiFileVideoLine, RiFileList2Line, RiFileLine, RiFilePdf2Line } from "react-icons/ri";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import JoditEditor from "jodit-react";
import { RxCross2 } from "react-icons/rx";
import { toast } from 'react-toastify';
import AlertModal from '../../module/AlertModal';

interface BoardProps {
    bbs_id: string,
    ntt_id: string|null,
    closeFunc: Function,
    menu_nm?: string,
    setReady: Function
}

function BoardDetailPage({bbs_id, ntt_id, closeFunc, menu_nm, setReady}: BoardProps) {
    
    const location = useLocation();

    const isDesktop = useMediaQuery({ minWidth: 768 });
    const [showAlertModal, setShowAlertModal] = useState(false);
    const isPage = location.pathname === '/board/reg';
    
    const [boardVO, setBoardVO] = useState<BoardVO|null>(null);

    const [nttSj, setNttSj] = useState('');
    const [rgsde, setRgsde] = useState(() => {
        const dt = new Date();
        return dt.getFullYear() + '-' + ((dt.getMonth() + 1) > 9 ? '' : '0') + (dt.getMonth() + 1) + '-' + (dt.getDate() > 9 ? '' : '0') + dt.getDate();
    });
    const [ntceEndde, setNtceEndde] = useState(() => {
        const dt = new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000));
        return dt.getFullYear() + '-' + ((dt.getMonth() + 1) > 9 ? '' : '0') + (dt.getMonth() + 1) + '-' + (dt.getDate() > 9 ? '' : '0') + dt.getDate();
    });
    const [isReserved, setReserved] = useState(false);
    const [isNotice, setNotice] = useState(false);
    const [isPerm, setPerm] = useState(true);
    const [nttCn, setNttCn] = useState('');
    const [nttId] = useState(() => {
        let id: string|null = ntt_id;
        if (!ntt_id) {
            if (location.state && location.state.ntt_id) {
                id = location.state.ntt_id;
            }
        }
        return id;
    });

    const [fileList, setFileList] = useState<object[]>();
    const [flSize, setFlSize] = useState('0 MB');

    const navigate = useNavigate();

    const [showAlert, setShowAlert] = useState(false);

    const joditConfig = useMemo(() => ({
        height: 500
    }), []
    );

    const [uploadImgList, setUploadImgList] = useState<string[]>([]);

    function removeAttch(key?: string) {
        const dt = new DataTransfer();
        if (key) {
            let nKey: number;
            if (/^[0-9]+$/.test(key)) nKey = Number(key);
            else return;
            const files = (document.getElementById('attchFilesInp') as HTMLInputElement).files;
            const arr = Array.from(files || []);
            arr.splice(nKey, 1);
            arr.forEach(file => dt.items.add(file));
            (document.getElementById('attchFilesInp') as HTMLInputElement).files = dt.files;
        }
        const newFiles = dt.files;
        const newArr: object[] = [];
        let size = 0;
        if (newFiles) {
            for (let i = 0; i < newFiles.length; i++) {
                size += newFiles[i].size;
                newArr.push({ [i]: newFiles[i].name });
            }
        }
        setFlSize(calcSize(size));
        setFileList(newArr);
    }

    function modOrder(key: string) {
        const dt = new DataTransfer();
        let nKey: number;
        if (/^[0-9]+$/.test(key)) nKey = Number(key);
        else return;
        const files = (document.getElementById('attchFilesInp') as HTMLInputElement).files;
        const arr = Array.from(files || []);
        for (let i = 0; i < arr.length; i++) {
            if (i === nKey - 1) {
                dt.items.add(arr[i + 1]);
            } else if (i === nKey) {
                dt.items.add(arr[i - 1]);
            } else
                dt.items.add(arr[i]);
        }
        (document.getElementById('attchFilesInp') as HTMLInputElement).files = dt.files;
        const newFiles = dt.files;
        const newArr: object[] = [];
        if (newFiles) {
            for (let i = 0; i < newFiles.length; i++) {
                newArr.push({ [i]: newFiles[i].name });
            }
        }
        setFileList(newArr);
    }

    function calcSize(byteSize: number) {
        const mbSize = Math.round(byteSize * 10 / Math.pow(2, 20)) / 10.0;
        const kbSize = Math.round(byteSize * 10 / Math.pow(2, 10)) / 10.0;
        if (byteSize === 0)
            return '0 MB';
        else if (kbSize === 0)
            return byteSize + ' Bytes';
        else if (mbSize === 0)
            return (kbSize > 10 ? Math.round(kbSize) : kbSize) + ' KB';
        else
            return (mbSize > 10 ? Math.round(mbSize) : mbSize) + ' MB';
    }

    function setIcon(ext: string) {
        let res: ReactNode;
        if (!ext) res = iconMap.etc;
        else {
            switch (ext.toLowerCase().trim()) {
                case 'xls': case 'xlsx':
                    res = iconMap.excel;
                    break;
                case 'doc': case 'docx':
                    res = iconMap.word;
                    break;
                case 'ppt': case 'pptx':
                    res = iconMap.ppt;
                    break;
                case 'hwp': case 'hwpx':
                    res = iconMap.hwp;
                    break;
                case 'pdf':
                    res = iconMap.pdf;
                    break;
                case 'jpg': case 'jpeg': case 'png':
                case 'gif': case 'bmp': case 'svg':
                    res = iconMap.img;
                    break;
                case 'mp4': case 'mov': case 'wmv':
                case 'avi': case 'flv': case 'f4v':
                case 'swf': case 'mkv':
                    res = iconMap.video;
                    break;
                case 'txt': case 'md':
                    res = iconMap.txt;
                    break;
                default:
                    res = iconMap.etc;
                    break;
            }
        }
        return res;
    }

    function uploadImg(comp: object, tg: HTMLElement) {
        // image 업로드 시 순서 변경 막기
        document.querySelectorAll<HTMLElement>('.mod-att-order').forEach(div => {
            const title = div.nextElementSibling?.nextElementSibling as HTMLElement;
            if (title.classList.contains('col-7')) {
                title.classList.remove('col-7');
                title.classList.add('col-8');
            } else if (title.classList.contains('col-9')) {
                title.classList.remove('col-9');
                title.classList.add('col-10');
            } else {
                return;
            }
            div.remove();
        });
        const files = (document.getElementById('attchFilesInp') as HTMLInputElement).files;
        const arr = Array.from(files || []);
        const uploadFile = arr[Number(Object.keys(comp)[0])];
        const reqBody = new FormData();
        reqBody.append('uploadFile', uploadFile);
        axios.post('/api/board/uploadEditorImg/' + sessionStorage.getItem('user_id'),
            reqBody, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                const result = response.data;
                switch (result) {
                    case 'SUC000':
                        const url = window.location.origin + '/api/board/temp/' + sessionStorage.getItem('user_id') + '/' + uploadFile.name;
                        navigator.clipboard.writeText(url)
                            .then(() => {
                                toast('클립보드에 복사되었습니다.', { position: 'top-center', autoClose: 2000 });
                            });
                        tg.classList.add('hidden');
                        tg.nextElementSibling?.classList.remove('hidden');
                        // img list 추가
                        setUploadImgList(prev => {
                            prev.push(url);
                            return prev;
                        });
                        // 편집기에 추가
                        setNttCn(prev => {
                            return `${prev}<p><img src="${url}" /></p>`;
                        })
                        break;
                    case 'ERR001':
                        break;
                    case 'ERR002':
                    case 'ERR003':
                        toast('파일이 이미 존재합니다.', { position: 'top-center', autoClose: 2000 });
                        break;
                    default:
                        toast('문제가 발생했습니다. 잠시 후 다시 시도해주세요.', { position: 'top-center', autoClose: 2000 });
                        break;
                }
            });

    }

    function reg() {
        const formData = new FormData();
        formData.append('bbs_id', bbs_id);
        formData.append('user_id', sessionStorage.getItem('user_id') as string);
        formData.append('ntt_sj', nttSj);
        formData.append('ntt_cn', nttCn);
        formData.append('rgsde', rgsde);
        formData.append('prmanent_at', isPerm ? 'Y' : 'N');
        if (!isPerm)
            formData.append('ntce_endde', ntceEndde.replaceAll('-', ''));
        formData.append('main_view_at', isNotice ? 'Y' : 'N');
        for (let i = 0; i < uploadImgList.length; i++) {
            formData.append('imgList[' + i + ']', uploadImgList[i]);
        }
        const attchFiles = (document.getElementById('attchFilesInp') as HTMLInputElement);
        if (attchFiles.files) {
            for (let i = 0; i < attchFiles.files.length; i++) {
                formData.append('uploadFiles[' + i + ']', attchFiles.files[i]);
            }
        }
        axios.post('/api/board/regArticle', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
            setShowAlertModal(true);
        });
    }

    function mod() {
        const path = '/api/board/editArticle';
        const params = {
            bbs_id: bbs_id,
            ntt_id: nttId,
            user_id: sessionStorage.getItem('user_id'),
            ntt_sj: nttSj,
            ntt_cn: nttCn,
            prmanent_at: isPerm ? 'Y' : 'N',
            ntce_endde: !isPerm ? ntceEndde.replaceAll('-', '') : '',
            main_view_at: isNotice ? 'Y' : 'N',
        }
        axios.post(path, params, {headers: {'Content-Type': 'application/json'}}).then(() => {
            setShowAlertModal(true);
        });
    }

    useEffect(() => {
        const modal = document.querySelector('[role="dialog"]');
        if (modal) {
            (modal as HTMLElement).removeAttribute('tabindex');
        }

        // temp 파일 삭제
        axios.post('/api/board/removeTempFiles',
            sessionStorage.getItem('user_id'),
            { headers: { 'Content-Type': 'text/html' } });
    }, []);

    useEffect(() => {
        if (bbs_id && nttId) {
            const path = `/api/board/edit`;
            const params = {bbs_id: bbs_id, ntt_id: nttId}
            axios.post(path, params, {headers: {'Content-Type': 'application/json'}})
            .then(response => {
                if (response.data) {
                    const vo = response.data;
                    setBoardVO(vo);
                    setNttSj(vo.ntt_sj);
                    const perm = vo.prmanent_at === 'Y';
                    setPerm(perm);
                    if (!perm) setNtceEndde(vo.ntce_endde);
                    setNttCn(vo.ntt_cn);
                    setReady(true);
                    setNotice(vo.main_view_at === 'Y');
                }
            });
        } else {
            setReady(true);
        }
    }, [nttId]);
    
    return (
        <Form className='board-detail-form' >
            {isPage && <Row>
                <Col className='board-title'>
                    <h4>{menu_nm}</h4>
                </Col>
            </Row>}
            <Table>
                <tbody>
                    <tr>
                        <td className='board-detail-info-2'>제목</td>
                        <td className='board-detail-data-2'><Form.Control id={'nttSj'} defaultValue={nttSj} onChange={e => setNttSj(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <td className='board-detail-info-2'>게시자</td>
                        <td className='board-detail-data-2'>
                            <Form.Control
                                id={'userNm'}
                                value={boardVO ? boardVO.ntt_wrter : sessionStorage.getItem('user_nm')}
                                readOnly={true} tabIndex={-1} onFocus={e => e.target.blur()}
                                style={{ border: 'none' }} />
                        </td>
                    </tr>
                    <tr>
                        <td className='board-detail-info-2'>작성일자</td>
                        <td className='board-detail-data-2'>
                            {boardVO ?
                            <Form.Control
                                id={'rgsde'}
                                value={boardVO.rgsde}
                                readOnly={true} tabIndex={-1} onFocus={e => e.target.blur()}
                                style={{border : 'none'}} />
                            : <FormGroup>
                                <Form.Check id={'isReserved'} className='date-switch' type='switch' checked={isReserved} label='예약게시' onChange={e => setReserved(prev => {
                                    if (prev) {
                                        const now = new Date();
                                        setRgsde(now.getFullYear() + '-'
                                            + (now.getMonth() + 1 > 9 ? '' : '0') + (now.getMonth() + 1) + '-'
                                            + (now.getDate() > 9 ? '' : '0') + now.getDate());
                                    }
                                    return e.target.checked;
                                })} />
                                <Form.Control id={'rgsde'} className='date-control' type='date' value={rgsde} readOnly={!isReserved} onChange={e => setRgsde(prev => {
                                    if (new Date().getTime() > new Date(e.target.value).getTime()) {
                                        toast('현재 날짜 이전은 선택할 수 없습니다.', { position: 'top-center', autoClose: 2000 });
                                        return prev;
                                    }
                                    return e.target.value
                                })} />
                            </FormGroup>}
                        </td>
                    </tr>
                    <tr>
                        <td className='board-detail-info-2'>게시종료</td>
                        <td className='board-detail-data-2'>
                            <FormGroup>
                                <Form.Check id={'isPerm'} className='date-switch' type='switch' checked={isPerm} label='영구게시' onChange={e => setPerm(e.target.checked)} />
                                <Form.Control id={'ntceEndde'} className={'date-control'} type='date' value={ntceEndde} onChange={e => setNtceEndde(prev => {
                                    if (new Date(rgsde).getTime() > new Date(e.target.value).getTime()) {
                                        toast('게시 시작일 이전은 선택할 수 없습니다.', { position: 'top-center', autoClose: 2000 });
                                        return prev;
                                    }
                                    return e.target.value
                                })} disabled={isPerm} />
                            </FormGroup>
                        </td>
                    </tr>
                    <tr>
                        <td className='board-detail-info-2'>메인노출</td>
                        <td className='board-detail-data-2'>
                            <FormGroup>
                                <Form.Check id={'isNotice'} className='notice-switch' type='switch' checked={isNotice} onChange={e => setNotice(e.target.checked)} label={isNotice ? '노출' : '감추기'} />
                                {isDesktop && <Form.Control className={'notice-control'} style={{background: 'none', border: 'none'}} disabled={true} />}
                            </FormGroup>
                        </td>
                    </tr>
                    {!boardVO && <>
                        <tr>
                            <td className='board-detail-info-2'>첨부파일</td>
                            <td className='board-detail-data-2'>
                                <Form.Control id='attchFilesInp' type='file' multiple onChange={e => {
                                    const files = (e.target as HTMLInputElement).files ?
                                        (e.target as HTMLInputElement).files : [];
                                    const arr: object[] = [];
                                    let size = 0;
                                    if (files) {
                                        if (files.length > 5) {
                                            toast('파일은 최대 5개까지 업로드 가능합니다.', { position: 'top-center', autoClose: 2000 });
                                            e.target.value = '';
                                            removeAttch();
                                            setFlSize('0 MB');
                                            return;
                                        }
                                        for (let i = 0; i < files.length; i++ ) {
                                            size += files[i].size;
                                            arr.push({[i] : files[i].name});
                                        }
                                        if (size > Math.pow(2, 20) * 50) {
                                            toast('파일 용량이 50MB를 초과했습니다.', { position: 'top-center', autoClose: 2000 });
                                            e.target.value = '';
                                            removeAttch();
                                            setFlSize('0 MB');
                                            return;
                                        }
                                    }
                                    setFlSize(calcSize(size));
                                    setFileList(arr);
                                }} />
                                {fileList && fileList.length > 0 && <div className="attch-wrap">
                                    {fileList.map(fileComp => {
                                        const key = Object.keys(fileComp)[0];
                                        const val = Object.values(fileComp)[0];
                                        const ext = val.substring(val.lastIndexOf('.') + 1).toLowerCase();
                                        const isImg = /jpg|jpeg|png|gif|bmp|svg/.test(ext.toLowerCase())
                                        return (
                                            <Row key={key} className='attch-file'>
                                                <Col xs={1} className='ta-r pr-n' title='삭제' onClick={() => removeAttch(key)}><RxCross2 /></Col>
                                                <Col xs={1} className='ta-r pr-n mod-att-order' title='순서변경' onClick={() => modOrder(key)}>
                                                    {key !== '0' && <MdOutlineKeyboardDoubleArrowUp />}
                                                </Col>
                                                <Col xs={1} className='ta-r pd-n'>{setIcon(ext)}</Col>
                                                <Col xs={isImg ? 7 : 9}><i>{val}</i></Col>
                                                {isImg && <Col xs={2}>
                                                    <Button className='btn-sm' onClick={e => uploadImg(fileComp, e.target as HTMLElement)}>
                                                        본문에 사용
                                                    </Button>
                                                    <Button className='btn-sm btn-secondary hidden' onClick={e => {
                                                        navigator.clipboard.writeText(window.location.origin + '/api/board/temp/' + sessionStorage.getItem('user_id') + '/' + val)
                                                            .then(() => {
                                                                toast('복사되었습니다.', { position: 'top-center', autoClose: 2000 });
                                                        });
                                                    }}>
                                                        링크 복사
                                                    </Button>
                                                </Col>}
                                            </Row>
                                        )
                                    })}
                                    <hr />
                                    <p><span>파일 갯수 : {fileList.length} / 5 , 파일 용량: {flSize} / 50 MB</span></p>
                                </div>}
                            </td>
                        </tr>
                    </>}
                    <tr>
                        <td className="board-content" colSpan={2} >
                            <JoditEditor
                                value={nttCn}
                                config={joditConfig}
                                onBlur={val => setNttCn(val)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='board-table-space' />
                    </tr>
                </tbody>
            </Table>
            <Row className='button-wrap'>
                {boardVO ? <Col><Button className='btn-sm' variant='primary' onClick={mod}>저장</Button></Col>
                :<Col><Button className='btn-sm' variant='primary' onClick={reg}>저장</Button></Col>}
                {!isDesktop && <Col><Button id="menu" variant="secondary" className='btn-sm' onClick={() => closeFunc()}>목록으로</Button></Col>}
            </Row>
            {!isPage && <AlertModal show={showAlert} setShow={setShowAlert} title="" body="잘못된 접근입니다." closeFunc={() => navigate('/', {replace: true})} />}
            <AlertModal show={showAlertModal} setShow={setShowAlertModal} title="" body="게시물이 등록되었습니다." closeFunc={closeFunc} />
        </Form>
    );

}

type BoardVO = {
    [key: string]: any;
}

const iconMap = {
    excel: <SiMicrosoftexcel />,
    word: <SiMicrosoftword />,
    ppt: <SiMicrosoftpowerpoint />,
    hwp: <RiFileHwpLine />,
    pdf: <RiFilePdf2Line />,
    img: <RiFileImageLine />,
    video: <RiFileVideoLine />,
    txt: <RiFileList2Line />,
    etc: <RiFileLine />
}

export default BoardDetailPage;