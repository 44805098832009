import { Route, Routes } from "react-router-dom";
import EasMain from "./EasMain";
import SignAppr from "./signbox/SignAppr";
import SignBoxList from "./signbox/SignBoxList";
import ApprSignList from "./signbox/ApprSignList";
import { useState } from "react";
import DeptApprBoxList from "./signbox/DeptApprBoxList";

function Appr() {
    const [show, setShow] = useState(true);
    return (
        <div className="content-container">
            <Routes>
                <Route path="/easMain" element={<EasMain />} />
                <Route path="/ApprSignList" element={<ApprSignList show={show} setShow={setShow} vac_id="" />} />
                <Route path="/SignAppr" element={<SignAppr show={show} setShow={setShow} mode="edit" />} />
                {/* 결재대기함 */}
                <Route path="/signbox/apprWaitBox" element={<SignBoxList />} />
                {/* 결재진행함 */}
                <Route path="/signbox/apprProcBox" element={<SignBoxList />} />
                {/* 반송함 */}
                <Route path="/signbox/apprRejectBox" element={<SignBoxList />} />
                {/* 결재완료함 */}
                <Route path="/signbox/apprCompBox" element={<SignBoxList />} />
                {/* 임시저장함 */}
                <Route path="/signbox/apprTempBox" element={<SignBoxList />} />
                {/* 참조확인함 */}
                <Route path="/signbox/apprReadBox" element={<SignBoxList />} />
                {/* 반송배포함 */}
                <Route path="/signbox/apprReturnBox" element={<SignBoxList />} />
                {/* 도착문서함 */}
                <Route path="/signbox/apprArrivalBox" element={<SignBoxList />} />
                {/* 접수문서함 */}
                <Route path="/signbox/apprReceiptBox" element={<SignBoxList />} />

                {/* 부서완료문서 */}
                {/* 품의함 */}
                <Route path="/deptApprBox/deptReqBox" element={<DeptApprBoxList />} />
            </Routes>

        </div>
    );
}
export default Appr;
