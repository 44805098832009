import { Modal } from "react-bootstrap";
import { ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import ApprModal from "./ApprModal";
import AlertModal from "../../module/AlertModal";

interface ApprProps {
    show: boolean,
    setShow: Function,
    vac_id: string,
    searchFunc?: Function,
    currPage?: number, 
    rowSize?: number, 
    isDetail?: boolean
}

function ExterAppr({show, setShow, vac_id, searchFunc, currPage, rowSize, isDetail}: ApprProps) {
    const location = useLocation();
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isPage = location.pathname === '/exter/appr';
    let searchMap: Map<String, string>;
    const state = location.state;

    if (state) {
        vac_id = state.vac_id ? state.vac_id : vac_id;
        if (!isDesktop) {
            currPage = typeof(state.currPage) != 'undefined' ? state.currPage : currPage;
            rowSize = typeof(state.rowSize) != 'undefined' ? state.rowSize : rowSize;
        }
        searchMap = state.searchMap ? state.searchMap : new Map<string, string>();
        
        if (!searchMap.get('searchStr'))
            searchMap.set('searchStr', '');
        if (!searchMap.get('docStatus'))
            searchMap.set('docStatus', 'all');
        if (!searchMap.get('searchCol'))
            searchMap.set('searchCol', 'vac_title');
    }
    const navigate = useNavigate();

    
    function closeModal() {
        if (isPage) {
            if (location.state)
                navigate('/exter/board', {state: {
                                                currPage: currPage,
                                                rowSize: rowSize,
                                                isDetail: false,
                                                searchMap: searchMap
                                            }, replace: true});
            else
                navigate('/exter/board');
        } else {
            setShow(false);
            if (searchFunc && typeof(currPage) !== 'undefined' 
                && typeof(rowSize) !== 'undefined' && typeof(isDetail) !== 'undefined')
                searchFunc(currPage, rowSize, true);
        };
    }

    function modalWrap(content: any) {
        return (
            <Modal show={show} onHide={() => closeModal()}
                size="xl" aria-labelledby="contained-modal-title-vcenter" 
                backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {vac_id ? '정기휴가계 상세보기' : '정기휴가계'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
            </Modal>
        )
    }

    const rtn: ReactElement = modalWrap(<ApprModal vac_id={vac_id} show={show} closeFunc={closeModal} />);

    if (show && !vac_id && sessionStorage.getItem('priv_cd') !== 'EXTER_USER') {
        return <AlertModal show={true} setShow={() => false} title="" body="외부 인력 전용 페이지입니다." closeFunc={() => navigate('/', {replace: true})} />;
    } else if (!isDesktop) {
        return (
            <div className="content">
                <ApprModal vac_id={vac_id} show={show} closeFunc={closeModal} />
            </div>
        )
    } else {
        return rtn;
    }
}

export default ExterAppr;