import { Modal } from "react-bootstrap";
import { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import AlertModal from "../../module/AlertModal";
import BoardDetailPage from "./BoardDetailPage";
import Loading from "../../module/Loading";

interface BoardProps {
    show: boolean,
    setShow: Function,
    bbs_id: string,
    ntt_id: string,
    menu_nm?: string,
    searchFunc?: Function,
    currPage?: number, 
    rowSize?: number, 
    isDetail?: boolean,
    searchMap?: Map<string, string>,
    setEdit: Function
}

function BoardDetail({show, setShow, bbs_id, ntt_id, menu_nm, searchFunc, currPage, rowSize, isDetail, searchMap, setEdit}: BoardProps) {
    
    const location = useLocation();
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isPage = /\/board\/[0-9]+\/[0-9]+/.test(location.pathname);

    const [isReady, setReady] = useState(false);

    const state = location.state;
    if (state) {
        show = typeof(state.show) != 'undefined' ? !state.show : show;
        currPage = typeof(state.currPage) != 'undefined' ? state.currPage : currPage;
        rowSize = typeof(state.rowSize) != 'undefined' ? state.rowSize : rowSize;
        if (!searchMap) {
            searchMap = state.searchMap ? state.searchMap : new Map<string, string>();
            if (!searchMap?.get('searchStr'))
                searchMap?.set('searchStr', '');
            if (!searchMap?.get('searchKey'))
                searchMap?.set('searchKey', 'BSH001');
            if (!searchMap?.get('searchYear'))
                searchMap?.set('searchYear', '');
            if (!searchMap?.get('searchMonth'))
                searchMap?.set('searchMonth', '');
        }
        state['searchMap'] = searchMap;
        menu_nm = typeof(state.menu_nm) != 'undefined' ? state.menu_nm : menu_nm;
    }

    const navigate = useNavigate();

    
    function closeModal() {
        if (isPage) {
            if (location.state && location.state.prevPath)
                navigate(location.state.prevPath, {state: {
                                                currPage: currPage,
                                                rowSize: rowSize,
                                                isDetail: false,
                                                searchMap: searchMap
                                            }, replace: true});
            else
                navigate('/');
        } else {
            setShow(false);
            if (searchFunc && typeof(currPage) !== 'undefined' 
                && typeof(rowSize) !== 'undefined' && typeof(isDetail) !== 'undefined')
                searchFunc(currPage, rowSize, true);
        };
    }

    function modalWrap(content: any) {
        return (
            <Modal show={show} onHide={() => closeModal()}
                size="xl" aria-labelledby="contained-modal-title-vcenter" 
                centered>
                <Modal.Header className='board-title' closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {menu_nm}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
            </Modal>
        )
    }
    const rtn: ReactElement = <BoardDetailPage closeFunc={closeModal} bbs_id={bbs_id} ntt_id={ntt_id}
                                menu_nm={menu_nm} state={location.state} setReady={setReady} setEdit={setEdit} />;

    useEffect(() => {
        const modal: HTMLElement | null = document.querySelector('.fade.modal');
        const modalBack: HTMLElement | null = document.querySelector('.fade.modal-backdrop');
        if (modal && modalBack) {
            if (isReady) {
                modal.style.display = 'block';
                modalBack.style.display = 'block';
            } else {
                modal.style.display = 'none';
                modalBack.style.display = 'none';
            }
        }
    }, [isReady]);

    if (show && !ntt_id && localStorage.getItem('extern_yn') === 'Y') {
        return <AlertModal show={true} setShow={() => false} title="" body="WideT&S 전용 페이지입니다." closeFunc={() => navigate('/', {replace: true})} />;
    } else {
        return (
            <>
                <div className={'loading-wrap ' + (isReady ? 'hidden' : 'show')}>
                    {!isReady && <Loading />}
                </div>
                {!isDesktop ? <div className="content">{rtn}</div> : modalWrap(rtn)}
            </>
        );
    }

}

export default BoardDetail;