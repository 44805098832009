import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";

function AlertModal({ title, body, agree, show, setShow, func, closeFunc }: AlertModalProps): React.ReactElement {
    return (
        <Modal show={show} onHide={() => !closeFunc ? setShow(false) : closeFunc()}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {body &&
                <Modal.Body>
                    {body}
                </Modal.Body>
            }
            <Modal.Footer>
                <Button variant="secondary" onClick={() => !closeFunc ? setShow(false) : closeFunc()}>
                    닫기
                </Button>
                {agree && func &&
                    <Button variant="primary" onClick={() => func()}>
                        {agree}
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

interface AlertModalProps {
    title: string;
    body?: string;
    agree?: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    func?: Function;
    closeFunc?: Function;
}

export default AlertModal;