import axios from "axios";
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase-config";


export default async function login(id: String, pw: String, exterLogin: boolean) {
    let flag = 1;
    const url = (!exterLogin ? '/api/login' : '/api/exter/login');
    const params = {
        user_id: id,
        user_pw: pw,
        mobile_yn: localStorage.getItem('mobile_yn') ? localStorage.getItem('mobile_yn') : '',
        login_token: localStorage.getItem('login_token') ? localStorage.getItem('login_token') : ''
    }
    await axios.post(url, params, { headers: { "Content-Type": 'application/json' } })
        .then(async response => {
            // Handle successful login
            const rtn = response.data;
            if (rtn.result === "success") {

                sessionStorage.setItem("user_id", rtn.user_id);
                sessionStorage.setItem("user_nm", rtn.user_nm);
                sessionStorage.setItem("priv_cd", rtn.priv_cd);
                if (exterLogin) {
                    sessionStorage.setItem("mobile_phone", rtn.mobile_phone.replaceAll('-', ''));
                    sessionStorage.setItem("email", rtn.email);
                    sessionStorage.setItem("approver", rtn.approver);
                    sessionStorage.setItem("extern_yn", rtn.extern_yn);
                } else {
                    sessionStorage.setItem("position_cd", rtn.position_cd);
                    sessionStorage.setItem("position_nm", rtn.position_nm);
                    sessionStorage.setItem("dept_cd", rtn.dept_cd);
                    sessionStorage.setItem("dept_nm", rtn.dept_nm);
                    sessionStorage.setItem("photo_path", rtn.photo_path);
                }

                // Add the public key generated from the console here.
                const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                localStorage.setItem("extern_yn", exterLogin ? "Y" : "N");
                localStorage.setItem("mobile_yn", isMobile ? "Y" : "N");
                localStorage.setItem("login_token", rtn.login_token);
                
                //앱으로 실행 + 모바일일때만 token 전송(fcm push 전송을 위해)
                if((window.matchMedia('(display-mode: standalone)').matches) && isMobile){
                    await getToken(messaging, { vapidKey: "BLV84-HykXpnEsjGC-m-Og_zXVUakOPpmzHb18c_Jn-jaUBNMEyGFOO5EH8OXbC0QNBBA1ZZi_5PDzXrHlQP9ME" })
                        .then(async (token) => {
                            await axios.post('/api/insert_fcm_token', { "user_id": rtn.user_id, "fcm_token": token, extern_yn: exterLogin ? 'Y' : 'N' })
                            localStorage.setItem("token", token);
                        })
                        .catch(() => {
                        });
                        //token은 FCM이 안되는 환경에서 실패할수 있으므로 성공하나 실패하나 메인페이지로 넘어감
                }

                flag = 0;
            } else if (rtn.result === "failed") {
                flag = 2;
            }
        })
        .catch(error => {
            // Handle login failure
            console.error('Failed login');
        });
    return flag;
};