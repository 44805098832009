import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import Paginate from '../../module/Paginate';
import Loading from '../../module/Loading';
import { Button, Col, Form } from 'react-bootstrap';
import DataTableV2 from '../../module/DataTableV2';
import SearchBarV2 from '../../module/SearchBarV2';
import { toast } from 'react-toastify';
import BoardDetail from '../board/BoardDetail';
import { useNavigate } from 'react-router-dom';
import BoardReg from '../board/BoardReg';

function PostMngList() {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    // 큰 화면용 list를 위한 변수
    const [detailDept, setDetailDept] = useState<boardArticleVO[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isEdit, setEdit] = useState(false);
    
    // 작은 화면용 list를 위한 변수
    const [simpleDept, setSimpleDept] = useState<boardArticleVO[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);


    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    const [boardList, setBoardList] = useState<BoardMngVO[]>([]);
    const [selectBoard, setSelectBoard] = useState<BoardMngVO>({
        bbs_id: '1',
        bbs_nm: 'CEO 메시지'
    });

    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
    const [selectNtt, setSelectNtt] = useState<any>();
    const [showPost, setShowPost] = useState<boolean>(false);
    const [showReg, setShowReg] = useState(false);

    const navigate = useNavigate();

    const fetchPosts = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const sortStr = sort[0];
        const sortDir = sort[1];
        let searchStr = searchMap.get("searchStr");
        let searchCol = searchMap.get("searchCol");

        if (searchCol === undefined) { searchCol = "BSH001"; searchStr = "" }

        const res = await axios.post("/api/board/boardArticle/boardArticleList"
            , {
                "bbs_id": selectBoard.bbs_id,
                "searchStr": searchStr,
                "searchCol": searchCol,
                "nw_indict_de": "",
                "currPage": currPage,
                "rowSize": rowSize,
                "sortStr": sortStr,
                "sortDir": sortDir,

            }
            , { headers: { "Content-Type": 'application/json' } })
        const data = res.data;
        const addData = res.data.content.map((item: boardArticleVO) => ({
            ...item,
            useYn: item.ntt_delete_at === 'Y' ? 'close' : 'open'
        }));
        setCheckedItems({});
        setBoardList(data.boardList)
        if (isDetail) {
            setDetailDept(addData);
            setPageCount(data.totalPages);
            setRowCount(data.rowCount);
        } else {
            if (simplePage === 0) {
                setSimpleDept(addData);
            } else {
                setSimpleDept(prevPosts => [...prevPosts, ...addData]);
            }
            setHasMore(!data.last);
        }

        setLoading(false);
    };

    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map());

    useEffect(() => {
        const loadPosts = async () => {
            fetchPosts(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    useEffect(() => {
        const loadPosts = async () => {
            fetchPosts(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    useEffect(() => {
        const loadPosts = async () => {
            search();
        };
        loadPosts();
    }, [sort]);

    useEffect(() => {
        if (isEdit) setShowReg(true);
    }, [isEdit]);

      
    const openCloseFunc = async (yn: string) => { // y: open, n: close
        let ntt_id_list: string[] = [];
        const data = isDesktop ? detailDept : simpleDept;
        for (const checkedKey in checkedItems) {
            if (checkedItems[checkedKey] && data.some((item) => item.ntt_delete_at === yn))
                ntt_id_list.push(checkedKey);
        }
        if (ntt_id_list.length > 0) {
            axios.post("/api/board/boardArticle/boardArticleUpdate"
                , {
                    bbs_id: selectBoard?.bbs_id,
                    ntt_id: ntt_id_list,
                    ntt_delete_at: yn === 'Y' ? 'N' : 'Y',
                    updusr: sessionStorage.getItem("user_id"),
                }
                , { headers: { "Content-Type": 'application/json' } })
            search();
        } else {
            toast("상태를 변경할 게시글을 선택해 주세요.", { position: 'top-center', autoClose: 2000 });
        }
    };


    function search() {
        setCheckedItems({});
        if (detailPage === 0) {
            fetchPosts(0, rowSize, true);
        } else {
            setDetailPage(0);
        }
        if (simplePage === 0) {
            fetchPosts(0, 25, false);
        } else {
            setSimplePage(0);
        }
    }
    return (
        <div className='content'>
            <h2>게시글 관리</h2>
            <SearchBarV2
                setRowSize={setRowSize}
                rowCount={rowCount}
                searchMap={searchMap}
                setSearchMap={setSearchMap}
                search={search}
                leftChildren={
                    <>
                        <Col sm='auto'>
                            <Form.Select name='bbs_id' value={selectBoard?.bbs_id} onChange={(e) => { setSelectBoard(boardList.find(option => option.bbs_id === e.target.value)!) }}>
                                {boardList?.map((item: BoardMngVO) =>
                                    <option key={item.bbs_id} value={item.bbs_id}>{item.bbs_nm}</option>
                                )}
                            </Form.Select>
                        </Col>
                        <Col xs='auto'>
                            <Form.Select name='searchCol' value={searchMap.get('searchCol')} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option key={'BSH001'} value={'BSH001'}>제목</option>
                                <option key={'BSH002'} value={'BSH002'}>내용</option>
                                <option key={'BSH003'} value={'BSH003'}>제목+내용</option>
                                <option key={'BSH004'} value={'BSH004'}>작성자</option>
                            </Form.Select>
                        </Col>
                    </>
                }
                rightChildren={
                    <>
                        <Col xs='auto'>
                            <Button onClick={() => openCloseFunc('Y')}>open</Button>
                        </Col>
                        <Col xs='auto'>
                            <Button onClick={() => openCloseFunc('N')}>close</Button>
                        </Col>
                    </>
                } />

            {isDesktop ? (
                <>
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailDept} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => { setSelectNtt(vo.ntt_id); setShowPost(true) }}
                        keyField='ntt_id' checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
                    {pageCount > 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleDept.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailDept} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => {
                        const path = '/board/' + selectBoard.bbs_id + '/' + vo.ntt_id;
                        navigate(path, {
                            state: {
                                ntt_id: vo.ntt_id,
                                show: showPost,
                                currPage: simplePage,
                                rowSize: rowSize,
                                isDetail: false,
                                searchMap: searchMap,
                                prevPath: window.location.pathname
                            }, replace: false
                        });
                    }}
                        keyField='ntt_id' checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
                </InfiniteScroll>

            )}
            {/* 게시글 상세보기 */}
            {showPost && <BoardDetail bbs_id={selectBoard.bbs_id} ntt_id={selectNtt} show={showPost} setShow={setShowPost} setEdit={setEdit} />}
            {showReg && <BoardReg bbs_id={selectBoard.bbs_id} ntt_id={isEdit ? selectNtt : null} menu_nm={selectBoard.menu_nm}
                show={showReg} setShow={setShowReg} searchFunc={search} setEdit={setEdit}
                currPage={detailPage} rowSize={rowSize} isDetail={true} searchMap={searchMap} />}
        </div>
    );
}

type boardArticleVO = {
    no?: number;
    ntt_id: string;
    ntt_sj: string;
    ntt_wrter: string;
    ntt_delete_at: string;
    rgsde: string;
    [key: string]: any;
}

type BoardMngVO = {
    bbs_id: string;
    bbs_nm?: string;
    [key: string]: any;
}

const detail_columns = [
    {
        Header: 'NO',
        accessor: 'no'
    },
    {
        Header: '제목',
        accessor: 'ntt_sj'
    },
    {
        Header: '게시일',
        accessor: 'rgsde'
    },
    {
        Header: '상태',
        accessor: 'useYn'
    },

];


export default PostMngList;