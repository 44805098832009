import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiCalendar } from 'react-icons/fi';
import '../css/DatePickerCustom.css';

interface CommonDatePickerProps {
    selectedDate: Date | null;
    handleChange: (date: Date | null) => void;
    disabled?: boolean;
    placeholderText?: string;
    dateFormat?: string;
    icon?: React.ReactNode; // icon을 React.ReactElement 타입으로 정의
}

const CommonDatePicker: React.FC<CommonDatePickerProps> = ({
    selectedDate,
    handleChange,
    disabled = false,
    placeholderText = '날짜 선택',
    dateFormat = 'yyyy-MM-dd',
    icon = <FiCalendar />
}) => {
    // react.clonElement : 기존 react 엘리먼트 복제 하고 추가 props 를 병합하여 새로운 속성 추가, 덮어쓰기 가능
    const styledIcon = icon ? React.cloneElement(icon as React.ReactElement, { style: { marginTop: '2px' } }) : null;

    return (
        <DatePicker
            showPopperArrow={false}
            fixedHeight
            selected={selectedDate}
            onChange={handleChange}
            dateFormat={dateFormat}
            className="form-control"
            disabled={disabled}
            locale="ko"
            placeholderText={placeholderText}
            isClearable={true}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            withPortal
            showIcon
            icon={styledIcon}
        />
    );
};

export default CommonDatePicker;
