import { useEffect, useState } from 'react';
import DataTable from '../../module/DataTable';
import axios from 'axios';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import SearchBarV2 from '../../module/SearchBarV2';
import Paginate from '../../module/Paginate';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../module/Loading';
import * as XLSX from 'xlsx';
import ApprInfoArea from '../appr/signbox/ApprInfoArea';

function HolidaySearch() {
    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });
    
     //큰 화면용 list를 위한 변수
    const [detailHolidaySearchList, setDetailHolidaySearchList] = useState<DetailHolidayVO[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    
    //작은 화면용 list를 위한 변수
    const [simpleHolidaySearchList, setSimpleHolidaySearchList] = useState<SimpleHolidayVO[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    
    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map<string, string>([['searchCol', 'user_nm']]));
    const [holidayTypeList, setHolidayTypeList] = useState<{ FRM_ID: string; FRM_NAME: string; }[]>([]);
    const [selectedHolidayType, setSelectedHolidayType] = useState<string>(''); // 휴가계종류
    const [selectedSts, setSelectedSts] = useState<string>(''); // 승인여부
    const [selectedRetire, setSelectedRetire] = useState<boolean>(false); // 퇴사자 포함
    // date
    const offset = 1000 * 60 * 60 * 9
    const todayKR = (new Date((new Date()).getTime() + offset)).toISOString().split("T")[0];
    const [startDate, setStartDate] = useState<string>(new Date().getFullYear().toString()+'01');
    const [endDate, setEndDate] = useState<string>(new Date().getFullYear().toString()+(new Date().getMonth()+1).toString().padStart(2, '0'))

    // 결재문서 상세보기
    const [showApprDetail, setShowApprDetail] = useState(false);
    const [apprDetail, setApprDetail] = useState<{sign_id: string, frm_id: string, frm_name: string, mode: string, vacation_yn: string, cooperation_yn: string} | null>(null);
    
    // 리스트 데이터 가져오기
    const selectHolidaySearchList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const searchStr = searchMap.get("searchStr");
        const searchCol = searchMap.get("searchCol");
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/admin/selectHolidaySearchList',
            {
                "start_date" : startDate,
                "end_date" : endDate,
                "retire_yn" : selectedRetire,
                "holiday_type" : selectedHolidayType,
                "doc_sts_cd" : selectedSts,
                "searchCol" : searchCol,
                "searchStr" : searchStr,
                "currPage": currPage,
                "rowSize": rowSize,
                "sortStr": sortStr,
                "sortDir": sortDir,
            },
            { headers: { "Content-Type": 'application/json' } }
        )
        const addData = response.data.content.map((item: { reg_dt: string; holiday_type: string; position_nm: string; user_nm: string; dept_nm: string; start_date: string; end_date: string; terms_days: string; harf_vacation_tp: string; detail_code_nm: string; sign_id: string;}) => ({
            ...item,
            vacation_date: item.harf_vacation_tp != null ? `${item.start_date} ${item.harf_vacation_tp}` : parseInt(item.terms_days) > 1 ? `${item.start_date} ~ ${item.end_date}` : `${item.start_date}` 
        }));
        setHolidayTypeList(response.data.typeList)
        if(isDetail) {
            setDetailHolidaySearchList(addData)
            setPageCount(response.data.totalPages);
            setRowCount(response.data.rowCount);
        } else {
            if (simplePage === 0) {
                setSimpleHolidaySearchList(addData);
            } else {
                setSimpleHolidaySearchList(prevPosts => [...prevPosts, ...addData]);
            }
            setHasMore(!response.data.last);
        }
        setLoading(false);
    }

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectHolidaySearchList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectHolidaySearchList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            search();
        };
        loadPosts();
    }, [sort]);

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    // checkbox 변경
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRetire(e.target.checked);
    };

    // 검색
    function search() {
        if (detailPage === 0) {
            selectHolidaySearchList(0, rowSize, true);
        } else {
            setDetailPage(0);
        }
        if (simplePage === 0) {
            selectHolidaySearchList(0, 25, false);
        } else {
            setSimplePage(0);
        }
    }

    //엑셀 다운
    const excelDown = async () => {
        const searchStr = searchMap.get("searchStr");
        const searchCol = searchMap.get("searchCol");
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/admin/selectHolidaySearchList',
            {
                "start_date" : startDate,
                "end_date" : endDate,
                "retire_yn" : selectedRetire,
                "holiday_type" : selectedHolidayType,
                "doc_sts_cd" : selectedSts,
                "searchCol" : searchCol,
                "searchStr" : searchStr,
                "currPage": 0,
                "sortStr": sortStr,
                "sortDir": sortDir,
                "rowSize": rowCount,
            },
            { headers: { "Content-Type": 'application/json' } }
        )
        const newArray: { [key: string]: any }[] = response.data.content.map((item: DetailHolidayVO ) => ({
            '신청일': item.reg_dt,
            '신청종류': item.holiday_type,
            '직급': item.position_nm,
            '이름': item.user_nm,
            '부서': item.dept_nm,
            '휴가일': (item.terms_days === '1')? item.start_date : parseInt(item.terms_days) > 1 ? `${item.start_date} ~ ${item.end_date}` : `${item.start_date} ${item.harf_vacation_tp}`,
            '사용일수': item.terms_days,
            '승인여부': item.detail_code_nm,
        }));
        const workbook = XLSX.utils.book_new(); // 객체 생성
        const worksheet = XLSX.utils.json_to_sheet(newArray); // data
        XLSX.utils.book_append_sheet(workbook, worksheet, "휴가전체검색");
        XLSX.writeFile(workbook, "휴가전체검색 "+todayKR+".xlsx");
    }

    // 결재문서 클릭
    const handleApprRowClick = (vo: any) => {
        setApprDetail({
            sign_id: vo.sign_id,
            frm_id: vo.frm_id,
            frm_name: vo.holiday_type,
            mode: 'sView',
            vacation_yn: 'Y',
            cooperation_yn: 'N',
        });
    };
    // 결재문서 값이 바뀌면 상세보기 열기
    useEffect(() => {
        if (apprDetail) {
            setShowApprDetail(true);
        }
    }, [apprDetail]);

    return (
        <div className='content'>
            <h2>휴가전체검색</h2>
            <SearchBarV2  setRowSize={setRowSize} rowCount={rowCount} searchMap={searchMap} setSearchMap={setSearchMap} search={search}
            leftChildren={
                <>
                    <Col xs="auto">
                        <Form.Select name='holiday_type' value={selectedHolidayType} onChange={(e) => {setSelectedHolidayType(e.target.value)}}>
                            <option value={''}>휴가계종류</option>
                            {holidayTypeList.map((item) =>
                                <option key={item.FRM_ID} value={item.FRM_ID}>{item.FRM_NAME}</option>
                            )}
                        </Form.Select>
                    </Col>
                    <Col xs="auto">
                        <Form.Select name='holiday_sts' value={selectedSts} onChange={(e) => {setSelectedSts(e.target.value)}}>
                            <option value={''}>승인여부</option>
                            <option value={'S1'}>진행</option>
                            <option value={'S9'}>반송</option>
                            <option value={'SS'}>완료</option>
                        </Form.Select>
                    </Col>
                    <Col xs="auto">
                        <Form.Control name='monthStart' type='month' defaultValue={todayKR.substring(0,5)+'01'} min={'2015-01'} max={endDate.substring(0,4)+'-'+endDate.substring(4)} onChange={(e) => { setStartDate(e.target.value.replace("-",'')); }}/>
                    </Col>
                    <Col xs="auto">
                        <span>~</span>
                    </Col>
                    <Col xs="auto">
                        <Form.Control name='monthEnd' type='month' defaultValue={todayKR.substring(0,7)} min={startDate.substring(0,4)+'-'+startDate.substring(4)} max={todayKR.substring(0,7)} onChange={(e) => { setEndDate(e.target.value.replace("-",'')); }}/>
                    </Col>
                    <Col xs="auto">
                        <Form.Check label='퇴사자 포함' id='retire' checked={selectedRetire} onChange={(e) => handleCheckboxChange(e)}/>
                    </Col>
                    <Col xs="auto">
                        <Form.Select name='searchCol' value={searchMap.get('searchCol')} onChange={(e) => {
                            const newMap = new Map(searchMap);
                            newMap.set(e.target.name, e.target.value);
                            setSearchMap(newMap);
                        }}>
                            <option value={'user_nm'}>이름</option>
                            <option value={'dept_nm'}>부서</option>
                            <option value={'position_nm'}>직급</option>
                        </Form.Select>
                    </Col>
                </>
            }
            rightChildren={
                <Col xs="auto">
                    <Button onClick={excelDown}>엑셀 다운로드</Button>
                </Col>
            }/>
            {isDesktop ? (
                <>
                    <DataTable loading={loading} columns={detail_columns} data={detailHolidaySearchList} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => { handleApprRowClick(vo) }}/>
                    {pageCount > 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleHolidaySearchList.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTable loading={loading} columns={simple_columns} data={simpleHolidaySearchList} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => { handleApprRowClick(vo) }} />
                </InfiniteScroll>
            )}
            <Modal show={showApprDetail} onHide={()=>{setShowApprDetail(false);}} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {apprDetail?.frm_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ApprInfoArea 
                        boxTpSignUserListCheck={''}
                        recentApprListCheck={[]}
                        mode={'view'}
                        currUserInfo={{
                            USER_POSIT_NM: "",
                            DRFT_DT: "",
                            USER_NM: "",
                            USER_POSIT_CD: "",
                            USER_DEPT_NM: "",
                            APPR_SIGNATURE_USE_YN: "",
                            USER_ID: "",
                            USER_DEPT_CD: "",
                            APPR_SIGNATUR_PATH: ""
                        }} 
                        signForm={{
                            sign_line_type: "",
                            retention: 0,
                            cooperation_yn: "",
                            receive_yn: "",
                            fixReceptionList: [],
                            fix_dept_nm: "",
                            fix_dept_cd: "",
                            deptList: [],
                            dept_auth_cd: "",
                            dept_auth_nm: "",
                            formCompList: [],
                            modify_yn: "",
                            frm_ctg_name: "",
                            sec_level_cd: "",
                            output_file: "",
                            use_yn: "",
                            frm_num: "",
                            revision_num: 0,
                            doc_tp_cd: ""
                        }} 
                        subWorkerList={[]} 
                        actionStatus= {JSON.stringify(apprDetail)}
                        varStatus={false} 
                        status={false} 
                        lastSeq={0}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setShowApprDetail(false);}}>닫기</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


type DetailHolidayVO = {
    reg_dt: string;
    holiday_type: string;
    position_nm: string;
    user_nm: string;
    dept_nm: string;
    start_date: string;
    end_date: string;
    terms_days: string;
    harf_vacation_tp: string;
    detail_code_nm: string;
    sign_id: string;
    [key: string]: any;
}

type SimpleHolidayVO = {
    holiday_type: string;
    user_nm: string;
    start_date: string;
    end_date: string;
    terms_days: string;
    detail_code_nm: string;
    sign_id: string;
    [key: string]: any;
}

const detail_columns = [
    {
        Header: 'NO',
        accessor: 'no'
    },
    {
        Header: '신청일',
        accessor: 'reg_dt'
    },
    {
        Header: '신청종류',
        accessor: 'holiday_type'
    },
    {
        Header: '직급',
        accessor: 'position_nm'
    },
    {
        Header: '이름',
        accessor: 'user_nm'
    },
    {
        Header: '부서',
        accessor: 'dept_nm'
    },
    {
        Header: '휴가일',
        accessor: 'vacation_date'
    },
    {
        Header: '사용일수',
        accessor: 'terms_days'
    },
    {
        Header: '승인여부',
        accessor: 'detail_code_nm'
    },
];

const simple_columns = [
    {
        Header: '신청종류',
        accessor: 'holiday_type'
    },
    {
        Header: '이름',
        accessor: 'user_nm'
    },
    {
        Header: '휴가일',
        accessor: 'vacation_date'
    },
    {
        Header: '승인여부',
        accessor: 'detail_code_nm'
    },
];

export default HolidaySearch;