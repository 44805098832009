import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CellProps } from 'react-table';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import DataTableV2 from '../../module/DataTableV2';
import Paginate from '../../module/Paginate';
import Loading from '../../module/Loading';
import SearchBarV2 from '../../module/SearchBarV2';
import AlertModal from '../../module/AlertModal';

function MngMenu({ getHeader }: { getHeader: () => void; }) {
    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });

    //큰 화면용 list를 위한 변수
    const [detailMenu, setDetailMenu] = useState<MenuVO[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    //작은 화면용 list를 위한 변수
    const [simpleMenu, setSimpleMenu] = useState<MenuVO[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);

    //data table 정렬용 변수
    const [sort, setSortDir] = useState(['', '']);

    //Modal Popup 변수
    const [showModal, setShowModal] = useState(false);
    const [isMod, setIsMod] = useState(false);
    const [selectMenu, setSelectMenu] = useState<MenuVO>({
        menu_id: 0,
        menu_cd: '',
        menu_nm: '',
        hgr_menu_id: 0,
        hgr_menu_cd: '',
        hgr_menu_nm: '',
        menu_url: '',
        menu_var: '',
        menu_tp: 'M',
        menu_str: '메뉴',
        menu_expl: '',
        use_yn: 'Y',
        use_str: '사용',
        menu_lvl: 0,
        array_ord: 0,
    });

    //AlertModal 변수
    const [showAlert, setShowAlert] = useState(false);

    //문서 삭제함수 변수
    const [deleteId, setDeleteId] = useState(0);

    //큰화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
    //DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
    const detail_columns = [
        {
            Header: '순서',
            accessor: 'no'
        },
        {
            Header: '상위메뉴코드',
            accessor: 'hgr_menu_cd'
        },
        {
            Header: '상위메뉴명',
            accessor: 'hgr_menu_nm'
        },
        {
            Header: '메뉴코드',
            accessor: 'menu_cd'
        },
        {
            Header: '메뉴명',
            accessor: 'menu_nm'
        },
        {
            Header: '메뉴URL',
            accessor: 'menu_url'
        },
        {
            Header: '메뉴유형',
            accessor: 'menu_str'
        },
        {
            Header: '사용여부',
            accessor: 'use_str'
        },
        {
            Header: '삭제',
            accessor: 'option1',
            Cell: ({ row }: CellProps<MenuVO>) => (
                <Button size="sm" onClick={(e) => deleteFunc(row.original)}>
                    삭제
                </Button>
            ),
        }
    ];

    //작은화면의 DataTable에서 사용하는 Header와 data 매핑하는 구조체
    //DataTable에 뿌려줄 순서대로 기입해야하고, Header에는 보여줄 이름, accessor에는 위의 데이터형식에 있는 값을 적어줘야함
    const simple_columns = [
        {
            Header: '상위메뉴명',
            accessor: 'hgr_menu_nm'
        },
        {
            Header: '메뉴명',
            accessor: 'menu_nm'
        },
        {
            Header: '사용여부',
            accessor: 'use_str'
        },
        {
            Header: '삭제',
            accessor: 'option1',
            Cell: ({ row }: CellProps<MenuVO>) => (
                <Button size="sm" onClick={(e) => deleteFunc(row.original)}>
                    삭제
                </Button>
            ),
        }
    ];

    //메뉴 리스트 검색 함수
    const selectMenuList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const searchStr = searchMap.get("searchStr");
        const searchCol = searchMap.get("searchCol");
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/admin/selectMenuList',
            {
                "currPage": currPage,
                "rowSize": rowSize,
                "searchStr": searchStr,
                "searchCol": searchCol,
                "useYn": useYn,
                "sortStr": sortStr,
                "sortDir": sortDir
            },
            { headers: { "Content-Type": 'application/json' } }
        );
        const data = await response.data;
        if (isDetail) {
            setDetailMenu(data.content);
            setPageCount(data.totalPages);
            setRowCount(data.rowCount);
        } else {
            if (simplePage === 0) {
                setSimpleMenu(data.content);
            } else {
                setSimpleMenu(prevPosts => [...prevPosts, ...data.content]);
            }
            setHasMore(!data.last);
        }
        setLoading(false);
    };

    function popReg() {
        setSelectMenu({
            menu_id: 0,
            menu_cd: '',
            menu_nm: '',
            hgr_menu_id: 0,
            hgr_menu_cd: '',
            hgr_menu_nm: '',
            menu_url: '',
            menu_var: '',
            menu_tp: 'M',
            menu_str: '메뉴',
            menu_expl: '',
            use_yn: 'Y',
            use_str: '사용',
            menu_lvl: 0,
            array_ord: 0,
        });
        setIsMod(false);
        setShowModal(true);
    };

    function search() {
        if (detailPage === 0) {
            selectMenuList(0, rowSize, true);
        } else {
            setDetailPage(0);
        }
        if (simplePage === 0) {
            selectMenuList(0, 25, false);
        } else {
            setSimplePage(0);
        }
    };

    //searchMap에는 검색어와 검색컬럼이 SearchBar에서 세팅된다.
    //검색어는 SearchBar에 searchstr로 지정되어있으므로 바꿀수 없고,
    //select box 선택시 검색컬럼 지정은 하위 selectTypes의 key 에 매핑(해당페이지는 searchCol)
    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map<string, string>([['searchCol', 'hgr_menu_cd']]));
    const [useYn, setUseYn] = useState<string>('');

    //큰화면의 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectMenuList(detailPage, rowSize, true);
        };
        loadPosts();
    }, [detailPage, rowSize]);

    //작은화면의 변수가 변경될때 리스트를 아래에 추가하기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            selectMenuList(simplePage, 25, false);
        };
        loadPosts();
    }, [simplePage]);

    //정렬 변수가 변경될때 리스트를 새로 불러오기 위한 hook
    useEffect(() => {
        const loadPosts = async () => {
            search();
        };
        loadPosts();
    }, [sort]);

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };

    const deleteFunc = async (vo: MenuVO) => {
        setDeleteId(vo.menu_id);
        setShowAlert(true);
    }

    async function confirm() {
        await axios.post("/api/admin/deleteMenu", null, { params: { "menu_id": deleteId } });
        toast("삭제가 완료되었습니다.", { position: 'top-center', autoClose: 2000 });
        getHeader();
        setShowAlert(false);
        search();
    }

    const detailModalPopup = (vo: MenuVO) => {
        setSelectMenu({ ...vo, before_array_ord: vo.array_ord });
        setIsMod(true);
        setShowModal(true);
    }

    return (
        <div className='content'>
            <h2>메뉴관리</h2>
            <h5>메뉴의 추가 등록 및 관리를 할 수 있습니다.</h5>
            <SearchBarV2
                setRowSize={setRowSize}
                rowCount={rowCount}
                searchMap={searchMap}
                setSearchMap={setSearchMap}
                search={search}
                leftChildren={
                    <>
                        <Col sm="auto">
                            <Form.Select name='searchCol' value={searchMap.get('searchCol')} onChange={(e) => {
                                const newMap = new Map(searchMap);
                                newMap.set(e.target.name, e.target.value);
                                setSearchMap(newMap);
                            }}>
                                <option value='hgr_menu_cd'>상위메뉴코드</option>
                                <option value='hgr_menu_nm'>상위메뉴명</option>
                                <option value='menu_cd'>메뉴코드</option>
                                <option value='menu_nm'>메뉴명</option>
                            </Form.Select>
                        </Col>
                        <Col sm="auto">
                            <Form.Select name='useYn' value={useYn} onChange={(e) => { setUseYn(e.target.value); }}>
                                <option value=''>전체</option>
                                <option value='Y'>사용</option>
                                <option value='N'>미사용</option>
                            </Form.Select>
                        </Col>
                    </>
                }
                rightChildren={
                    <>
                        <Col xs="auto">
                            <Button onClick={() => popReg()}>등록</Button>
                        </Col>
                    </>
                } />
            {isDesktop ? (
                <>
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailMenu} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => detailModalPopup(vo)} />
                    {pageCount > 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleMenu.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={simple_columns} data={simpleMenu} sort={sort} setSortDir={setSortDir} onRowClick={(vo) => detailModalPopup(vo)} />
                </InfiniteScroll>
            )}
            <PopupModal show={showModal} setShow={setShowModal} isMod={isMod} selectMenu={selectMenu} setSelectMenu={setSelectMenu} search={search} getHeader={getHeader} />
            <AlertModal title='정말 삭제하시겠습니까?' show={showAlert} setShow={setShowAlert} agree='삭제' func={() => confirm()} />
        </div>
    );
}

const PopupModal = ({ show, setShow, isMod, selectMenu, setSelectMenu, search, getHeader }: { show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, isMod: boolean, selectMenu: MenuVO, setSelectMenu: React.Dispatch<React.SetStateAction<MenuVO>>, search: () => void, getHeader: () => void; }): React.ReactElement => {

    const [menuCdList, setMenuCdList] = useState<MenuVO[]>();
    const [dupCheck, setDupCheck] = useState(false);

    const selectDetailMenu = async () => {
        const response = await axios.post("/api/admin/selectMenuCdList");
        const data = response.data;
        setMenuCdList(data);
    };

    async function menuPost() {
        if (selectMenu.hgr_menu_id === 0) {
            toast("상위메뉴를 선택해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        if (!isMod && !dupCheck) {
            toast("메뉴코드 중복확인을 해주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        if (selectMenu.menu_nm === '') {
            toast("메뉴명을 입력해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        if (selectMenu.mngr_id === '') {
            toast("매니저를 선택해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        const url = isMod ? '/api/admin/updateMenu' : '/api/admin/insertMenu';
        const response = await axios.post(url, selectMenu, { headers: { "Content-Type": 'application/json' }, params: { "userId": sessionStorage.getItem("user_id") } });
        const data = response.data;
        if (data === 1) {
            toast(isMod ? "수정이 완료되었습니다." : "등록이 완료되었습니다.", { position: 'top-center', autoClose: 2000 });
            getHeader();
            setShow(false);
            search();
        } else {
            toast(isMod ? "수정 실패" : "등록 실패", { position: 'top-center', autoClose: 2000 });
        }

    };

    async function getMenuSub(e: React.ChangeEvent<any>) {
        const hgr_menu_id = Number(e.target.value);
        if (hgr_menu_id !== 0) {
            const hgr_menu_nm = menuCdList!.find(option => option.menu_id === hgr_menu_id)!.menu_nm
            const response = await axios.post('/api/admin/getMenuSub', null, { params: { "hgr_menu_id": hgr_menu_id } });
            const data = await response.data;
            setSelectMenu({ ...selectMenu, menu_lvl: data.subMenuData.menu_lvl, array_ord: data.subMenuData.array_ord, hgr_menu_id: hgr_menu_id, hgr_menu_nm: hgr_menu_nm });
        }
    };

    async function menuDupCheck() {
        if (selectMenu.menu_cd === "") {
            toast("메뉴코드가 공백입니다.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        const response = await axios.post('/api/admin/menuDupCheck', null, { params: { "menu_cd": selectMenu.menu_cd } });
        const data = response.data;
        if (data === 0) {
            setDupCheck(true);
        } else {
            toast("중복된 메뉴코드 입니다.", { position: 'top-center', autoClose: 2000 });
        }
    };

    function changeMenuMap(e: React.ChangeEvent<any>) {
        setSelectMenu({ ...selectMenu, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const loadPosts = async () => {
            selectDetailMenu();
        };
        loadPosts();
    }, []);

    useEffect(() => {
        setDupCheck(false);
    }, [show]);

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{isMod ? '메뉴관리 - 상세조회' : '메뉴관리 - 등록'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col xs='4'>
                            상위메뉴
                        </Col>
                        <Col>
                            <Form.Select name='hgr_menu_id' value={selectMenu.hgr_menu_id} onChange={(e) => { getMenuSub(e); }}>
                                <option value='0'>상위메뉴선택</option>
                                {menuCdList?.map((item: MenuVO) =>
                                    <option key={item.menu_id} value={item.menu_id}>{item.menu_nm}</option>
                                )}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            메뉴코드
                        </Col>
                        {isMod ?
                            <Col>
                                <Form.Control type="textBox" name="menu_cd" value={selectMenu.menu_cd} disabled />
                            </Col>
                            :
                            <>
                                <Col>
                                    <Form.Control type="textBox" name="menu_cd" value={selectMenu.menu_cd} onChange={(e) => { setDupCheck(false); changeMenuMap(e); }} />
                                </Col>
                                <Col xs="auto">
                                    <Button size="sm" variant={dupCheck ? 'success' : 'danger'} onClick={() => menuDupCheck()}>  {dupCheck ? "확인완료" : "중복확인"}</Button>
                                </Col>
                            </>
                        }
                    </Row>
                    <Row>
                        <Col xs='4'>
                            메뉴레벨
                        </Col>
                        <Col>
                            <Form.Control type="number" name="menu_lvl" value={selectMenu.menu_lvl} disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            메뉴명
                        </Col>
                        <Col>
                            <Form.Control type="textBox" name="menu_nm" value={selectMenu.menu_nm} onChange={(e) => changeMenuMap(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            메뉴URL
                        </Col>
                        <Col>
                            <Form.Control type="textBox" name="menu_url" value={selectMenu.menu_url} onChange={(e) => changeMenuMap(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            메뉴값
                        </Col>
                        <Col>
                            <Form.Control type="textBox" name="menu_var" value={selectMenu.menu_var} onChange={(e) => changeMenuMap(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            메뉴유형
                        </Col>
                        <Col>
                            <Form.Select name='menu_tp' value={selectMenu.menu_tp} onChange={(e) => changeMenuMap(e)}>
                                <option value='M'>메뉴</option>
                                <option value='P'>페이지</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            메뉴설명
                        </Col>
                        <Col>
                            <Form.Control type="textBox" name="menu_expl" value={selectMenu.menu_expl} onChange={(e) => changeMenuMap(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            사용여부
                        </Col>
                        <Col>
                            <Form.Select name='use_yn' value={selectMenu.use_yn} onChange={(e) => changeMenuMap(e)}>
                                <option value='Y'>사용</option>
                                <option value='N'>미사용</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='4'>
                            배치순서
                        </Col>
                        <Col>
                            <Form.Control type="number" name="array_ord" value={selectMenu.array_ord} onChange={(e) => changeMenuMap(e)} />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    닫기
                </Button>
                <Button variant="primary" onClick={() => menuPost()}>
                    {isMod ? '수정' : '등록'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

//DataTable에서 사용하는 데이터의 형식 (최하단의 [key: string]: any; 는 공통로직에서 key값에 map으로 접근하기 위해 필수)
type MenuVO = {
    no?: number;
    menu_id: number;
    menu_cd: string;
    menu_nm: string;
    hgr_menu_id: number;
    hgr_menu_cd: string;
    hgr_menu_nm: string;
    menu_url: string;
    menu_var: string;
    menu_tp: string;
    menu_str: string;
    menu_expl: string;
    use_yn: string;
    use_str: string;
    menu_lvl: number;
    array_ord: number;
    before_array_ord?: number;
    [key: string]: any;
}

export default MngMenu;
