import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import BBS from "./BBS";
import BoardDetail from "./BoardDetail";
import InquiryList from "./InquiryList";
import BoardReg from "./BoardReg";
import axios from "axios";

function Board() {
    const location = useLocation();
    const [show, setShow] = useState(true);

    // board list
    const [listPath, setListPath] = useState('');

    // board detail
    const [detailPath, setDetailPath] = useState('');
    const [bbsId, setBbsId] = useState('');
    const [nttId, setNttId] = useState('');
    const [isEdit, setEdit] = useState(false);

    useEffect(() => {
        axios.post('/api/board/getBoardUrls').then(response => {
            const boardLi: string[] | null = response.data;
            const path = location.pathname.replace('/board', '');
            const detailPattern = /\/[0-9]+\/[0-9]+/
            if (boardLi && boardLi.indexOf(path) > -1)
                setListPath(path);
            else if (detailPattern.test(path)) {
                setDetailPath(path);
                setBbsId(path.split('/')[1]);
                setNttId(path.split('/')[2]);
            }
        });
    }, [location]);
    return (
        <>
            {/* <Side toggleHeader={toggleHeader} index={0} /> */}
            <div className="content-container">
                <Routes>
                    <Route path='/' element={<Navigate to={'/api/board/ceo'} />} />
                    <Route path={listPath} element={<BBS state={location.state} />} />
                    <Route path={detailPath} element={<BoardDetail bbs_id={bbsId} ntt_id={nttId} show={show} setShow={setShow} setEdit={setEdit} />} />
                    <Route path='/inquiryList' element={<InquiryList bbs_id={bbsId} ntt_id={nttId} show={show} setShow={setShow} />} />
                    <Route path='/reg' element={<BoardReg bbs_id={bbsId} show={show} setShow={setShow} ntt_id={isEdit ? nttId : null} />} />
                </Routes>
            </div>
        </>
    );
}
export default Board;
