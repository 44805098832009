import axios from "axios";
import { useEffect, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import AlertModal from "../../module/AlertModal";
import { useNavigate } from "react-router-dom";

interface InfoProps {
    show: boolean,
    closeFunc: Function
}

function MyPageModal({show, closeFunc}: InfoProps) {

    const isDesktop = useMediaQuery({ minWidth: 768 });

    const [name, setName] = useState(sessionStorage.getItem('user_nm') as string);
    const [phone, setPhone] = useState(sessionStorage.getItem('mobile_phone') as string);
    const [email, setEmail] = useState(sessionStorage.getItem('email') as string);
    const [approver, setApprover] = useState(sessionStorage.getItem('approver') as string);

    const [currPw, setCurrPw] = useState('');
    const [userPw, setUserPw] = useState('');
    const [pwChk, setPwChk] = useState('');

    const [alertBody, setAlertBody] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const [procSelector, setProcSelector] = useState('');

    const [approverList, setApproverList] = useState<string[]|null>(null);

    const navigate = useNavigate();

    function logout() {
        const mobile_yn = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'Y' : 'N';
        axios.post('/api/logout', {
            user_id: sessionStorage.getItem('user_id'),
            extern_yn: localStorage.getItem('extern_yn'),
            mobile_yn: mobile_yn
        }).then(() => {
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login', { replace: true });
        });
    }

    function checkPhoneNum(phNum: string) {
        if (!/^[+]{0,1}[0-9]{0,}$/.test(phNum)) {
            setAlertBody('전화번호는 국가번호 및 숫자만 입력 가능합니다.');
            setShowAlert(true);
            setPhone(phone);
            return;
        }
        setPhone(phNum);
    }
    
    function mod(selector: string) {
        setProcSelector(selector);

        if (selector === 'I') {
            document.querySelectorAll<HTMLElement>('#userInfo input:not([type="password"]):not(#userId), #userInfo select').forEach(el => {
                el.removeAttribute('readOnly');
                el.removeAttribute('disabled');
            });
        } else {
            document.querySelectorAll<HTMLElement>('#userInfo .form-floating:has(input:not([type="password"]), select)').forEach(el => {
                el.classList.add('hidden');
            });
            document.querySelectorAll<HTMLElement>('#userInfo .form-floating:has(input[type="password"])').forEach(el => {
                el.classList.remove('hidden');
            });
        }
        document.getElementById('mod')?.classList.add('hidden');
        document.getElementById('modPw')?.classList.add('hidden');
        if (!isDesktop)
            document.getElementById('menu')?.classList.add('hidden');
        document.getElementById('update')?.classList.remove('hidden');
        document.getElementById('cancel')?.classList.remove('hidden');
    }

    function cancel() {
        const selector = procSelector;
        if (selector === 'I') {
            document.querySelectorAll<HTMLElement>('#userInfo input:not([type="password"]):not(#userId), #userInfo select').forEach(el => {
                if (el.matches('input'))
                    (el as HTMLInputElement).readOnly = true;
                else
                    (el as HTMLSelectElement).disabled = true;
            });
            setName(sessionStorage.getItem('user_nm') as string);
            setPhone((sessionStorage.getItem('mobile_phone') as string).replace('-', ''));
            setEmail(sessionStorage.getItem('email') as string);
            setApprover(sessionStorage.getItem('approver') as string);
        } else {
            document.querySelectorAll<HTMLElement>('#userInfo .form-floating:has(input:not([type="password"]), select)').forEach(el => {
                el.classList.remove('hidden');
            });
            document.querySelectorAll<HTMLElement>('#userInfo .form-floating:has(input[type="password"])').forEach(el => {
                el.classList.add('hidden');
            });
            setCurrPw('');
            (document.getElementById('newPassword') as HTMLInputElement).value = '';
            changePassowrd('');
        }

        document.getElementById('mod')?.classList.remove('hidden');
        document.getElementById('modPw')?.classList.remove('hidden');
        if (!isDesktop)
            document.getElementById('menu')?.classList.remove('hidden');

        document.getElementById('update')?.classList.add('hidden');
        document.getElementById('cancel')?.classList.add('hidden');
        setProcSelector('');
    }

    function pwCheck(inp: HTMLInputElement) {
        const lab = document.querySelector(`label[for="${inp.id}"]`);
        if (lab) {
            if (inp.value.length === 0) {
                inp.removeAttribute('style');
                lab.textContent = 'New Password Check';
            } else if (inp.value !== userPw) {
                inp.setAttribute('style', 'background: #ffaaaa;');
                lab.textContent = "새로운 비밀번호가 일치하지 않습니다.";
            } else {
                inp.setAttribute('style', 'background: #aaffaa;');
                lab.textContent = "새로운 비밀번호가 확인되었습니다.";
            }
        }
        setPwChk(inp.value);
    }

    function changePassowrd(pw: string) {
        setUserPw(pw);
        const chk = (document.getElementById('newPasswordCheck') as HTMLInputElement);
        chk.value = '';
        pwCheck(chk);
    }

    function update() {
        const selector = procSelector;
        if (selector === 'I') {
            if (!name.trim()) {
                setAlertBody('이름을 입력 해 주세요.');
                setShowAlert(true);
                document.getElementById('userNm')?.focus();
            } else if (!phone.trim()) {
                setAlertBody('휴대폰 번호를 입력 해 주세요.');
                setShowAlert(true);
                document.getElementById('phone')?.focus();
            } else if (!email.trim()) {
                setAlertBody('E-mail 주소를 입력 해 주세요.');
                setShowAlert(true);
                document.getElementById('email')?.focus();
            } else {
                const params = {
                    user_id : sessionStorage.getItem('user_id'),
                    user_nm : name,
                    mobile_phone : phone,
                    email : email,
                    approver : approver
                }
                axios.post('/api/exter/updateUserInfo', params, {headers : {'Content-Type' : 'application/json'}}).then(() => {
                    sessionStorage.setItem("user_nm", name);
                    sessionStorage.setItem("mobile_phone", phone);
                    sessionStorage.setItem("email", email);
                    sessionStorage.setItem("approver", approver);
                    setAlertBody('정보가 변경되었습니다.');
                    setShowAlert(true);
                    cancel();
                });
            }
        } else {
            if (!currPw.trim()) {
                setAlertBody('현재 비밀번호를 입력 해 주세요.');
                setShowAlert(true);
                document.getElementById('currPassword')?.focus();
            } else if (!userPw.trim()) {
                setAlertBody('새로운 비밀번호를 입력 해 주세요.');
                setShowAlert(true);
                document.getElementById('newPassword')?.focus();
            } else if (userPw !== pwChk) {
                setAlertBody('새로운 비밀번호가 일치하지 않습니다.');
                setShowAlert(true);
                document.getElementById('newPasswordCheck')?.focus();
            } else {
                axios.post('/api/exter/currPwCheck', {
                    user_id: sessionStorage.getItem('user_id'),
                    user_pw : currPw
                }, {headers : {'Content-Type' : 'application/json'}}).then(response => {
                    const res = response.data;
                    if (res) {
                        const params = {
                            user_id : sessionStorage.getItem('user_id'),
                            user_pw : userPw
                        }
                        axios.post('/api/exter/updatePw', params, {headers : {'Content-Type' : 'application/json'}}).then(() => {
                            for (let i = 5; i > 0; i--) {
                                setTimeout(() => {
                                    setAlertBody(`비밀번호가 변경되었습니다.${i}초 후 자동 로그아웃 됩니다.`);
                                    setShowAlert(true);
                                }, 5000 - (1000 * i));
                            }
                            setTimeout(() => {
                                logout();
                            }, 5000);
                        });
                    } else {
                        setAlertBody('현재 비밀번호가 일치하지 않습니다.');
                        setShowAlert(true);
                        document.getElementById('currPassword')?.focus();
                    }
                });
            }
        }

    }

    useEffect(() => {
        if (show) {
            if (sessionStorage.getItem('priv_cd') === 'EXTER_USER') {
                axios.post('/api/exter/getApproverList').then(response => {
                    setApproverList(response.data);
                });
            }
        }
    }, [show]);
    
    return (
        <>
            <Form id="userInfo">
                <FloatingLabel controlId="userId" label="USER ID" className="mb-3" >
                    <Form.Control type="textBox" value={String(sessionStorage.getItem('user_id'))} readOnly />
                </FloatingLabel>
                <FloatingLabel controlId="userNm" label="Name" className="mb-3" >
                    <Form.Control type="textBox" value={name} onChange={e => setName(e.target.value)} readOnly />
                </FloatingLabel>
                <FloatingLabel controlId="phone" label="Phone" className="mb-3" >
                    <Form.Control type="textBox" value={phone.replaceAll('-', '')} onChange={e => checkPhoneNum(e.target.value)} autoComplete="on" readOnly />
                </FloatingLabel>
                <FloatingLabel controlId="email" label="E-mail" className="mb-3" >
                    <Form.Control type="textBox" value={email} onChange={e => setEmail(e.target.value)} autoComplete="on" readOnly />
                </FloatingLabel>
                {sessionStorage.getItem('priv_cd') === 'EXTER_USER' && (
                    <FloatingLabel controlId="approver" label="담당자" className="mb-3" >
                        <Form.Select value={approver} onChange={e => setApprover(e.target.value)} disabled >
                            {approverList && approverList.map(obj => {
                                const id = Object(obj).user_id;
                                const name = Object(obj).user_nm;
                                return (<option key={id} value={id}>{name}</option>);
                            })}
                        </Form.Select>
                    </FloatingLabel>
                )}
                <FloatingLabel controlId="currPassword" label="Current Password" className="mb-3 hidden">
                    <Form.Control type="password" onChange={e => setCurrPw(e.target.value)} />
                </FloatingLabel>
                <FloatingLabel controlId="newPassword" label="New Password" className="mb-3 hidden">
                    <Form.Control type="password" onChange={e => changePassowrd(e.target.value)} />
                </FloatingLabel>
                <FloatingLabel controlId="newPasswordCheck" label="New Password Check" className="mb-3 hidden">
                    <Form.Control type="password" onChange={e => pwCheck(e.target as HTMLInputElement)} />
                </FloatingLabel>
                
            </Form>

            <div className="button-wrap">
                <Button id="mod" onClick={() => mod('I')}>수정</Button>
                <Button id="modPw" onClick={() => mod('P')}>PW 변경</Button>
                <Button id="update" className="hidden" onClick={() => update()}>저장</Button>
                <Button id="cancel" className="hidden" variant="danger" onClick={() => cancel()}>취소</Button>
                {!isDesktop && (
                    <Button id="menu" variant="secondary" onClick={() => closeFunc()}>목록으로</Button>
                )}
            </div>
            <AlertModal title="" body={alertBody} show={showAlert} setShow={setShowAlert} />
        </>
        
    )
}

export default MyPageModal;