import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, FloatingLabel, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import ReceiptDetail from './ReceiptDetail';
import ReceiptRegist from './ReceiptRegist';

interface ReceiptProps {
    receipt_id: string,
    user_id: string,
    category: string,
    use_date: string,
    use_history: string,
    use_user: string,
    use_place: string,
    price: string,
    proof: string,
    note: string,
    aid: string
}

function Receipt() {
    const [selectedDate, setSelectedDate] = useState('');
    const [receiptList, setReceiptList] = useState<ReceiptProps[]>([]);
    const [receiptDetail, setReceiptDetail] = useState<ReceiptProps>();
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const navigate = useNavigate();

    const getReceiptList = (date: any) => {
        setSelectedDate(date);
        setReceiptList([]);

        try {
            axios.post('/api/myinfo/getReceiptList',
                {
                    "user_id": sessionStorage.getItem("user_id"),
                    "use_date": date as String
                },
                { headers: { "Content-Type": "application/json" } }
            ).then(response => {
                setReceiptList(response.data);
            });
        } catch (error) {
            alert('정보 불러오기에 실패하였습니다. 관리자에게 문의 부탁드립니다.')
        }

    }

    // 현재 날짜
    
    // 6개월 전까지의 년/월 생성
    const getDateOptions = useCallback(() => {
        const currentDate = new Date();
        const options = [];
        for (let i = 0; i < 6; i++) {
            const date = new Date();
            date.setMonth(currentDate.getMonth() - i);
            const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
            options.push(yearMonth);
        }
        return options;
    }, []);

    useEffect(() => {
        const defaultDate = getDateOptions()[0];
        setSelectedDate(defaultDate);
        getReceiptList(defaultDate);
    }, [getDateOptions]);

    const handleButtonClick = () => {
        try {
            axios.post('/api/receipt/createDocument.do',
                {
                    "user_id": sessionStorage.getItem("user_id"),
                    "user_nm": sessionStorage.getItem("user_nm"),
                    "user_posit_cd": sessionStorage.getItem("position_cd"),
                    "user_posit_nm": sessionStorage.getItem("position_nm"),
                    "dept_cd": sessionStorage.getItem("dept_cd"),
                    "dept_nm": sessionStorage.getItem("dept_nm"),
                    "use_date": selectedDate
                },
                { headers: { "Content-Type": "application/json" } }
            ).then(response => {
                toast("임시문서가 생성되었습니다.", { position: 'top-center', autoClose: 2000 });
                //to-do 임시저장함으로 이동
                navigate("/appr/signbox/apprTempBox", { state: { menu_cd: 'GW0501', menu_var: 'sign_box_cd=B09&returnPage=/appr/signbox/apprTempBox', menu_nm: '임시저장함' } });
            });
        } catch (error) {
            toast("정보 불러오기에 실패하였습니다. 관리자에게 문의 부탁드립니다.", { position: 'top-center', autoClose: 2000 });
        }
    };

    return (
        <div className='content'>
            <h2>영수증 관리<Button className='createReceipt' variant='primary' onClick={() => {
                setShowModal2(true);
            }}>영수증 등록하기</Button></h2>

            <Form className='receipt'>
                <FloatingLabel controlId='createDate' label="영수증 생성 년/월 선택" className='mb-3'>
                    <Form.Select name='createDate' value={selectedDate} onChange={(e) => getReceiptList(e.target.value)}>
                        {getDateOptions().map((dateOption, index) => (
                            <option key={index} value={dateOption}>
                                {dateOption}
                            </option>
                        ))}
                    </Form.Select>
                </FloatingLabel>
            </Form>

            {receiptList.length !== 0 ?
                <div className='receiptLists'>
                    {receiptList.map((receipt, index) => (
                        <div key={index} className='receiptList' onClick={() => {
                            setShowModal(true);
                            setReceiptDetail(receiptList[index]);
                        }}>
                            <div>{receipt.use_date}</div>
                            <div className='receiptOne'>
                                <div>{receipt.use_history}</div>
                                <div>{receipt.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</div>
                            </div>
                        </div>
                    ))}
                </div>
                : (selectedDate === '' ?
                    <div className='receiptLists'></div>
                    :
                    <div className='receiptLists'>해당 월에 등록된 영수증이 없습니다.</div>
                )
            }


            <button className='createDocBtn' onClick={handleButtonClick} disabled={receiptList.length === 0} style={{ backgroundColor: receiptList.length === 0 ? 'gray' : '#4CAF50' }}>경비내역서 임시저장 문서 생성</button>
            {receiptDetail && (
                <ReceiptDetail show={showModal} setShow={setShowModal} receiptDetail={receiptDetail} listRefreshFn={getReceiptList} date={selectedDate} />
            )}
            <ReceiptRegist show={showModal2} setShow={setShowModal2} listRefreshFn={getReceiptList} />
        </div>
    );
}

export default Receipt;