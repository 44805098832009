
import { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";

import React from 'react';

interface ApprProps {
    resultComment: any[],
    closeFunc: Function,
    
}
// 기안작성 > 결재진행함 모듈
function DraftConfirm({resultComment, closeFunc}: ApprProps) {

        
    const closeButton = () => {
        closeFunc();
    }
    
    const [commentList, setCommentList] = useState<commentListVO[]>([]);
    useEffect(() => {
        
        const getData_main = () => {
            setCommentList(resultComment);

        };
        getData_main();
    }, []);

    return (
        <>
        <React.Fragment>
            <Form id="layerPopForm">
                <Table>
                <tr>
                <td className="middle_contents_td">
                    <table className="appr_comfirm_table">
                    <tbody>                    

                    <tr>
                        {commentList && commentList.length > 0 ? (
                            commentList.map((item, index) => (
                            <>
                                <div key={index}>
                                <div>
                                    <div
                                    style={{
                                        display: 'inline-block',
                                        marginTop: '2px',
                                        background: 'blue',
                                        color: 'white',
                                        borderRadius: '100%',
                                        textAlign: 'center',
                                    }}
                                    >
                                    {item.SIGN_TP_CD_NM}
                                    </div>

                                    {item.PHOTO_PATH === null ? (
                                    <img
                                        style={{
                                        display: 'inline-block',
                                        marginTop: '2px',
                                        marginLeft: '30px',
                                        marginRight: '30px',
                                        }}
                                        className="comment_profile_img"
                                        src={`${process.env.PUBLIC_URL}/images/appr/profile_unname.png`}
                                        alt=''
                                    />
                                    ) : (
                                    <img
                                        className="comment_profile_img"
                                        src={item.PHOTO_PATH}
                                        alt=''
                                    />
                                    )}
                                    {item.OPINION}
                                    <span>
                                    <p>by {item.USER_NM} / {item.REG_DT.substring(0, 16)}</p>
                                    </span>
                                </div>

                                <div></div>
                                </div>
                            </>
                            ))
                        ) : (
                            <div>
                            의견 정보가 없습니다
                            </div>
                        )}
                        </tr>
                    

                    </tbody>
                    </table>
                </td>
                </tr>

                </Table>
                <div style={{justifyContent: "flex-end", display: "flex"}}>
                <Button variant="secondary" onClick={closeButton}>
                    닫기
                </Button>
                </div>
            </Form>
            
            </React.Fragment>
        </>
    
    );
    
}

type commentListVO = {
    SIGN_ID: string;
    SIGN_SEQ: number;
    USER_NM: string;
    OPN_TP_CD: string;
    SIGN_TP_CD: string;
    OPINION: string;
    REG_DT: string;
    DAY: string;
    COMP_DAY: string;
    TODAY: string;
    PHOTO_PATH: string;
    SIGN_TP_CD_NM: string;

    [key: string]: any;
}

export default DraftConfirm;

