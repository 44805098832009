import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, FormControl, Modal, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { TreeComponent } from '../../module/TreeViewParentSelect';
import axios from 'axios';
import DataTableV2 from '../../module/DataTableV2';

function MngBoardMenu({ getHeader }: { getHeader: () => void; }) {

    //기기사이즈 Medium 이상인지 검사
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const [menuList, setMenuList] = useState<BoardMenuVO[]>([]);
    const [selectMenu, setSelectMenu] = useState<BoardMenuVO>();
    const [newMenu, setNewMenu] = useState<BoardMenuVO>({
        menu_cd: '',
        menu_id: 0,
        menu_nm: '',
        array_ord: '',
        menu_tp: '',
        hgr_menu_id: 0,
        hgr_menu_nm: '',
        use_yn: ''
    });

    // 모달
    const [show, setShow] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [modalType, setModalType] = useState<string>();
    // modalType = addMenu : 메뉴 추가, addBoard : 게시판 추가, edit : 수정, delete : 삭제

    const [portalAuthorNm, setPortalAuthorNm] = useState('');
    const [authList, setAuthList] = useState<BoardMngAuthorVO[]>([]); // 권한목록
    const [commList, setCommList] = useState<[]>([]); // 권한종류
    const [detailAuthSearch, setDetailAuthSearch] = useState<BoardMngAuthorVO[]>([]); // 권한검색
    const [bbsAuthorCl, setBbsAuthorCl] = useState('');
    const [searchType, setSearchType] = useState('name');
    const [showSearchDept, setSearchDept] = useState<boolean>(false);
    const [deptList, setDeptList] = useState<BoardMngAuthorVO[]>([]);
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({}); // 권한 추가
    const [checkedItems2, setCheckedItems2] = useState<{ [key: string]: boolean }>({}); // 권한 삭제
    // 하위노드 데이터 추가
    function buildTree(data: BoardMenuVO[], parentId: number): BoardMenuVO[] {
        return data
            .filter(item => item.hgr_menu_id === parentId)
            .map(item => ({
                ...item,
                children: buildTree(data, item.menu_id)
            }));
    }

    // 리스트 데이터 가져오기
    const selectDataList = async () => {
        const response = await axios.post(
            '/api/admin/selectBoardMenuList',
            {},
            { headers: { "Content-Type": 'application/json' } }
        )
        const newData = buildTree(response.data.content, 1)
        setMenuList(newData)
        setSelectMenu({ ...newData[0], register: sessionStorage.getItem("user_id")! })
    }

    useEffect(() => {
        const loadPosts = async () => {
            selectDataList();
        };
        loadPosts();
    }, []);

    function clickMenu(e: any) {
        setSelectMenu({ ...e, register: sessionStorage.getItem("user_id")! })
    }

    function updateData() { // 수정 버튼 클릭 시 데이터 교체
        setNewMenu(selectMenu as BoardMenuVO)
    }

    function changeMenu(e: any) {
        setNewMenu({ ...newMenu, [e.target.name]: e.target.value });
    }
    // 게시판메뉴 추가
    const insertBoardMenu = async () => {
        if (newMenu.array_ord === '' || newMenu.menu_nm === '') {
            toast("빈칸을 입력해 주세요.", { position: 'top-center', autoClose: 2000 });
            return;
        }
        await axios.post(
            '/api/admin/insertBoardMenu',
            newMenu,
            { headers: { "Content-Type": 'application/json' } }
        )
        selectDataList();
        getHeader();
        setShow(false);
    }
    // 게시판메뉴 수정
    const updateBoardMenu = async () => {
        await axios.post(
            '/api/admin/updateBoardMenu',
            newMenu,
            { headers: { "Content-Type": 'application/json' } }
        )
        selectDataList()
        setSelectMenu({
            menu_cd: '',
            menu_id: 0,
            menu_nm: '',
            array_ord: '',
            menu_tp: '',
            hgr_menu_id: 0,
            hgr_menu_nm: '',
            use_yn: ''
        })
        getHeader();
        setShow(false);
    }
    // 게시판메뉴 삭제
    const deleteBoardMenu = async () => { // menu_id
        await axios.post(
            '/api/admin/deleteBoardMenu',
            selectMenu,
            { headers: { "Content-Type": 'application/json' } }
        )
        selectDataList()
        setSelectMenu({
            menu_cd: '',
            menu_id: 0,
            menu_nm: '',
            array_ord: '',
            menu_tp: '',
            hgr_menu_id: 0,
            hgr_menu_nm: '',
            use_yn: ''
        })
        getHeader();
        setShow(false);
    }
    // 부서 목록
    const searchDept = async () => {
        setSearchDept(true);
        const response = await axios.post('/api/board/boardMng/searchAuthDeptList', {}, { headers: { "Content-Type": 'application/json' } });
        setDeptList(response.data.list);
        setCheckedItems({});
    }
    // 권한 종류 설정
    const getDetailAuthList = (e: React.ChangeEvent<any>) => {
        setBbsAuthorCl(e.target.value);
    }

    // 검색어
    const getPortalAuthorNm = (e: React.ChangeEvent<any>) => {
        setPortalAuthorNm(e.target.value);
    };
    //사용자 검색
    const searchUserFunc = () => {
        setSearchDept(false)
        if (portalAuthorNm !== '') {
            axios.post("/api/board/boardMng/searchAuthUserInfo",
                {
                    searchType: searchType,
                    searchWord: portalAuthorNm,
                }, { headers: { "Content-Type": 'application/json' } })
                .then(res => {
                    const data2 = res.data;
                    setDetailAuthSearch(data2.list);
                })
                .catch(error => {
                    console.error(error);
                });
            setCheckedItems({})
        } else {
            toast("검색어를 입력하세요", { position: 'top-center', autoClose: 2000 });
        }
    };

    const addAuthFunc = async () => {
        let author_nm_list: string[] = [];
        let author_ty_list: string[] = [];

        for (const checkedKey in checkedItems) {
            if (checkedItems[checkedKey]) {
                author_nm_list.push(checkedKey);
                showSearchDept === false ? author_ty_list.push('UGN001') : author_ty_list.push('UGN003');
            }
        }

        if (bbsAuthorCl !== '' && author_nm_list.length > 0) {
            const response = await axios.post("/api/board/boardMng/boardMngAuthorWrite"
                , {
                    menu_id: selectMenu?.menu_id
                    , bbs_author_cl: bbsAuthorCl
                    , portal_author_ty: author_ty_list.toString()
                    , portal_author_nm: author_nm_list.toString()
                    , register: sessionStorage.getItem("user_id")
                }
                , { headers: { "Content-Type": 'application/json' } })
            setCheckedItems({})
            setAuthList(response.data.list.list);
        } else {
            if (author_nm_list.length === 0)
                toast("대상을 선택하세요", { position: 'top-center', autoClose: 2000 });
            if (bbsAuthorCl === '')
                toast("권한을 선택하세요", { position: 'top-center', autoClose: 2000 });
        }

    }
    //사용자 권한 > 삭제
    const delAuthFunc = async () => {
        const bbs_author_sn_list: string[] = [];
        for (const checkedKey in checkedItems2) {
            if (checkedItems2[checkedKey]) {
                bbs_author_sn_list.push(checkedKey);
            }
        }
        if (bbs_author_sn_list.length > 0) {
            const response = await axios.post("/api/board/boardMng/boardMngAuthorChkDelete",
                {
                    menu_id: selectMenu?.menu_id
                    , bbs_author_sn: bbs_author_sn_list.toString()
                }
                , { headers: { "Content-Type": 'application/json' } })
            setCheckedItems2({})
            setAuthList(response.data.list.list);
        } else {
            toast("삭제할 권한을 선택하세요", { position: 'top-center', autoClose: 2000 });
        }
    }

    return (
        <div className="content">
            <h2>게시판관리</h2>
            <Row>
                <Col sm={6} xs={12} style={{ marginBottom: isDesktop ? '' : '1rem' }}>
                    <Card>
                        <Card.Header as="h4">게시판 선택</Card.Header>
                        <Card.Body style={{ minHeight: '318px' }}>
                            <TreeComponent nodes={menuList} idField={"menu_id"} nameField={"menu_nm"} onNodeClick={(node) => { clickMenu(node); }} defaultExpend={true} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header as="h4">{selectMenu?.menu_nm}</Card.Header>
                        <Card.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{ width: '40%' }}>상위분류</th>
                                        <td>{selectMenu?.hgr_menu_nm}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>유형</th>
                                        <td>{selectMenu?.menu_tp === 'M' ? '메뉴' : selectMenu?.menu_tp === 'P' ? '게시판' : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>메뉴명</th>
                                        <td>{selectMenu?.menu_nm}</td>
                                    </tr>
                                    <tr>
                                        <th>정렬순서</th>
                                        <td>{selectMenu?.array_ord}</td>
                                    </tr>
                                    <tr>
                                        <th>사용여부</th>
                                        <td>{selectMenu?.use_yn === 'Y' ? '사용' : selectMenu?.use_yn === 'N' ? '미사용' : ''}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <div className='d-flex gap-1'>
                                {selectMenu?.menu_tp === 'M' ?
                                    <Button onClick={() => {
                                        setShow(true); setModalType('add');
                                        setNewMenu({
                                            menu_cd: selectMenu.menu_cd,
                                            menu_id: 0,
                                            menu_nm: '',
                                            array_ord: selectMenu.child_cnt!,
                                            child_cnt: selectMenu.child_cnt!,
                                            menu_tp: 'P',
                                            menu_lvl: Number(selectMenu.menu_lvl!) + 1,
                                            hgr_menu_id: selectMenu.menu_id,
                                            hgr_menu_nm: selectMenu.menu_nm,
                                            register: selectMenu.register,
                                            sj_indict_sntnc_co: 10,
                                            inqire_view_at: 'Y',
                                            answer_at: 'N',
                                            annymty_bbs_at: 'N',
                                            use_yn: 'Y'
                                        })
                                    }}>추가</Button>
                                    : selectMenu?.menu_tp === 'P' ? <Button onClick={async () => {
                                        const response = await axios.post('/api/board/boardMng/boardMngAuthorList',
                                            { menu_id: selectMenu?.menu_id },
                                            { headers: { "Content-Type": 'application/json' } });
                                        setAuthList(response.data.list);
                                        setCommList(response.data.commList);
                                        setShowAuthModal(true);
                                        setDetailAuthSearch([]);
                                        setPortalAuthorNm('');
                                        setBbsAuthorCl('');
                                        setSearchDept(false);
                                        setCheckedItems({})
                                        setCheckedItems2({})
                                    }}>권한관리</Button> : <></>}
                                {selectMenu?.menu_id !== 0 && selectMenu != null ?
                                    <>
                                        <Button onClick={(e) => { setShow(true); setModalType('edit'); updateData() }}>수정</Button>
                                        <Button onClick={(e) => { setShow(true); setModalType('delete') }}>삭제</Button>
                                    </>
                                    : <></>}
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>게시판메뉴관리 {modalType === 'add' ? '추가'
                        : modalType === 'edit' ? '수정' : '삭제'}</Modal.Title>
                </Modal.Header>
                {modalType !== 'delete' ?
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>상위분류</Col>
                                <Col><FormControl type='text' name='hgr_menu_nm' value={newMenu.hgr_menu_nm} disabled /></Col>
                            </Row>
                            <Row>
                                <Col>유형</Col>
                                <Col>
                                    <Form.Select name='menu_tp' value={newMenu.menu_tp} onChange={(e) => changeMenu(e)} disabled={modalType === 'edit'} >
                                        <option value={'M'}>메뉴</option>
                                        <option value={'P'}>게시판</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col>메뉴명</Col>
                                <Col>
                                    <FormControl type='text' name='menu_nm' value={newMenu?.menu_nm} onChange={(e) => { changeMenu(e) }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>정렬순서</Col>
                                <Col><FormControl type='number' name='array_ord' value={newMenu?.array_ord} onChange={(e) => { changeMenu(e) }} /></Col>
                            </Row>
                            <Row>
                                <Col>사용여부</Col>
                                <Col>
                                    <Form.Select name='use_yn' value={newMenu?.use_yn} onChange={(e) => { changeMenu(e) }}>
                                        <option value={'Y'}>사용</option>
                                        <option value={'N'}>미사용</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            {
                                newMenu.menu_tp === "P" && <>
                                    <Row>
                                        <Col>제목 글자수 제한</Col>
                                        <Col>
                                            <FormControl type='number' id='sj_indict_sntnc_co' name='sj_indict_sntnc_co' value={newMenu?.sj_indict_sntnc_co} onChange={(e) => { changeMenu(e) }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>조회현황</Col>
                                        <Col>
                                            <Form.Check type='radio' name='inqire_view_at' id='inqire_view_at1' value={'Y'} onChange={(e) => { changeMenu(e) }} label='사용' inline checked={newMenu?.inqire_view_at === 'Y'} />
                                            <Form.Check type='radio' name='inqire_view_at' id='inqire_view_at2' value={'N'} onChange={(e) => { changeMenu(e) }} label='미사용' inline checked={newMenu?.inqire_view_at === 'N'} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>댓글쓰기</Col>
                                        <Col>
                                            <Form.Check type='radio' name='answer_at' id='answer_at1' value={'Y'} onChange={(e) => { changeMenu(e) }} label='사용' inline checked={newMenu?.answer_at === 'Y'} />
                                            <Form.Check type='radio' name='answer_at' id='answer_at2' value={'N'} onChange={(e) => { changeMenu(e) }} label='미사용' inline checked={newMenu?.answer_at === 'N'} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>익명게시판</Col>
                                        <Col>
                                            <Form.Check type='radio' name='annymty_bbs_at' id='annymty_bbs_at1' value={'Y'} onChange={(e) => { changeMenu(e) }} label='사용' inline checked={newMenu?.annymty_bbs_at === 'Y'} />
                                            <Form.Check type='radio' name='annymty_bbs_at' id='annymty_bbs_at2' value={'N'} onChange={(e) => { changeMenu(e) }} label='미사용' inline checked={newMenu?.annymty_bbs_at === 'N'} />
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Container>
                    </Modal.Body>
                    : <Modal.Body>삭제하시겠습니까?</Modal.Body>
                }
                <Modal.Footer>
                    <Button onClick={modalType === 'edit' ? updateBoardMenu : modalType === 'delete' ? deleteBoardMenu : insertBoardMenu}>확인</Button>
                    <Button variant='secondary' onClick={() => setShow(false)}>닫기</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showAuthModal} onHide={() => setShowAuthModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>권한 관리</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={2} xs={12}>권한</Col>
                        <Col sm='auto' xs={12}>
                            <Form.Select name='bbs_author_cl' onChange={(e) => { getDetailAuthList(e); }}>
                                <option value=''>선택</option>
                                {commList?.map((item: any) =>
                                    <option key={item.DETAIL_CODE} value={item.DETAIL_CODE}>{item.DETAIL_CODE_NM}</option>
                                )}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2} xs={12}>대상</Col>
                        <Col sm={3} xs={12}>
                            <Form.Select name="searchType" value={searchType} onChange={(e) => { setSearchType(e.target.value); }}>
                                <option value="name">이름</option>
                                <option value="id">아이디</option>
                                <option value="dept">부서</option>
                            </Form.Select>
                        </Col>
                        <Col sm={5} xs={12}>
                            <Form.Control type="text" name="portal_author_nm" value={portalAuthorNm} onChange={(e) => { getPortalAuthorNm(e); }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); searchUserFunc() } }}
                            />
                        </Col>
                        <Col sm={2} xs={12} className='mt-1 d-grid gap-2'>
                            <Button onClick={searchUserFunc}>검색</Button>
                        </Col>
                    </Row>
                    <Row className='mt-1 d-flex justify-content-end'>
                        <Col xs='auto'>
                            <Button onClick={searchDept}>부서목록</Button>
                        </Col>
                    </Row>
                    <span>검색결과</span>

                    <div style={{ maxHeight: '10rem', overflow: 'auto' }}>
                        {showSearchDept === false ? (
                            <DataTableV2 loading={false} columns={user_auth_search_columns} data={detailAuthSearch} sort={[]} setSortDir={() => { }}
                                keyField='USER_ID' checkedItems={checkedItems} setCheckedItems={setCheckedItems}
                                onRowClick={(vo) => setCheckedItems((prevState: { [key: string]: boolean; }) => {
                                    return { ...prevState, [vo.USER_ID]: !prevState[vo.USER_ID] }
                                })}
                            />
                        ) : (
                            <DataTableV2 loading={false} columns={dept_auth_search_columns} data={deptList} sort={[]} setSortDir={() => { }}
                                keyField='DEPT_CD' checkedItems={checkedItems} setCheckedItems={setCheckedItems}
                                onRowClick={(vo) => setCheckedItems((prevState: { [key: string]: boolean; }) => {
                                    return { ...prevState, [vo.DEPT_CD!]: !prevState[vo.DEPT_CD!] }
                                })}
                            />
                        )}
                    </div>
                    <span>권한</span>
                    <div style={{ maxHeight: '10rem', overflow: 'auto' }}>
                        <DataTableV2 loading={false} columns={detail_auth_columns} data={authList} sort={[]} setSortDir={() => { }}
                            keyField='bbs_author_sn' checkedItems={checkedItems2} setCheckedItems={setCheckedItems2}
                            onRowClick={(vo) => setCheckedItems2((prevState: { [key: string]: boolean; }) => {
                                return { ...prevState, [vo.bbs_author_sn]: !prevState[vo.bbs_author_sn] }
                            })}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={addAuthFunc}>추가</Button>
                    <Button onClick={delAuthFunc}>삭제</Button>
                    <Button variant="secondary" onClick={() => setShowAuthModal(false)}>닫기</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

type BoardMenuVO = {
    menu_cd: string;
    menu_id: number;
    menu_nm: string;
    array_ord: string;
    menu_tp: string;
    hgr_menu_id: number;
    hgr_menu_nm: string;
    use_yn: string;
    menu_lvl?: number;
    child_cnt?: string;
    children?: BoardMenuVO[];
    register?: string;
    sj_indict_sntnc_co?: number;
    inqire_view_at?: string;
    answer_at?: string;
    annymty_bbs_at?: string;
    [key: string]: any;
}
type BoardMngAuthorVO = {
    rowno: number;
    bbs_id: string;
    bbs_author_sn: string; // 순서?
    bbs_author_cl: string; // 권한종류
    portal_author_ty: string; // UGN001/UGN003
    portal_author_nm: string; // 아이디/부서코드
    DEPT_CD?: string;
    DEPT_NM?: string;
    [key: string]: any;
}
const detail_auth_columns = [
    {
        Header: '타입',
        accessor: 'portal_author_ty'
    },
    {
        Header: '대상',
        accessor: 'portal_author_nm'
    },
    {
        Header: '권한',
        accessor: 'bbs_author_cl'
    },
];

const user_auth_search_columns = [
    {
        Header: '이름',
        accessor: 'USER_NM'
    },
    {
        Header: '아이디',
        accessor: 'USER_ID'
    },
    {
        Header: '부서',
        accessor: 'DEPT_NM'
    },
];

const dept_auth_search_columns = [
    {
        Header: '부서',
        accessor: 'DEPT_NM'
    },
    {
        Header: '코드',
        accessor: 'DEPT_CD'
    },
];

export default MngBoardMenu;