import '../../../css/appr/Board.css'
import axios from "axios";
import { useEffect, useState } from "react";
import AlertModal from '../../../module/AlertModal';
import { Node } from "../../cmn/Tree";
import { TreeComponent } from "../../../module/TreeViewParentSelect";
import { useNavigate } from "react-router-dom";
interface ApprProps {
    vac_id: string,
    show: boolean,
    closeFunc: Function
    toggleHeader: SignTree[];
    setToggleHeader: (menu: SignTree[]) => void;
}

export interface SignTree {
    id: string;
    text: string;
    parent: string;
    type: string;
    retention: string;
    cooperation_yn: string;
    receive_yn: string;
    frm_desc: string;
    dept_auth_cd: string;
    fix_dept_nm: string;
}

function ApprMenu({ vac_id, show, closeFunc, toggleHeader, setToggleHeader }: ApprProps) {

    const [treeData, setTreeData] = useState<SignTree[]>([]);
    const navigate = useNavigate();
    const alertBody = '';
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [checkBackGroundColorId, setCheckBackGroundColorId] = useState("");

    const handleButtonClick = (user_id: string) => {

        if (checkBackGroundColorId !== user_id) {
            const myDiv_old = document.getElementById(checkBackGroundColorId);
            const myDiv_new = document.getElementById(user_id);

            if (myDiv_old) {
                myDiv_old.style.backgroundColor = "white";
            }
            if (myDiv_new) {
                myDiv_new.style.backgroundColor = "lightblue";
            }
        } else {

        }
        setCheckBackGroundColorId(user_id);

    };

    function goPage(node: Node) {
        navigate("/appr/SignAppr", { state: { id: node["id"], text: node["text"], vacation_yn: node["vacation_form_yn"], cooperation_yn: node["cooperation_yn"] } });
    }
    useEffect(() => {
        const getData_main = async () => {
            try {
                const response = await axios.post(
                    "/api/appr/signForm/apprList.ajax",
                    null,
                    {
                        params: { "priv_cd": sessionStorage.getItem("priv_cd") }
                    });
                setToggleHeader(response.data.toggleHeader);
                const rtn = response.data;
                setTreeData(rtn);
            } catch (error) {
                alert("Failed axios : ");
            }
        };
        getData_main();
    }, [sessionStorage.getItem('priv_cd')]);

    return (
        <>
            <div className='content'>
                <div style={{ maxHeight: '500px', minHeight: '500px', overflow: 'auto' }}>
                    <TreeComponent nodes={treeData} idField={"id"} nameField={"text"}
                        onNodeClick={(node) => { if (node.type === 'F') { handleButtonClick(node.id); goPage(node); } }} defaultExpend={true} />
                </div>
            </div>

            <AlertModal title="로 이동 하시겠습니까?" body={alertBody} show={showAlertModal} setShow={setShowAlertModal} />
        </>
    );

}

export default ApprMenu;