import { Modal } from 'react-bootstrap';
import ReceiptForm from './ReceiptForm';

interface ReceiptProps {
    receipt_id: string,
    user_id: string,
    category: string,
    use_date: string,
    use_history: string,
    use_user: string, 
    use_place: string, 
    price: string,
    proof: string
    note: string
    aid: string
}

const ReceiptDetail = ({ show, setShow, receiptDetail, listRefreshFn, date}: { show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, receiptDetail: ReceiptProps, listRefreshFn: Function, date: string}): React.ReactElement => {

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{'영수증 수정'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReceiptForm variable={receiptDetail} setShow={setShow} listRefreshFn={listRefreshFn} date={date}/>
            </Modal.Body>
        </Modal>
    );

}

export default ReceiptDetail;