
import { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import axios from 'axios';
import React from 'react';

interface ApprProps {
    draftType: string,
    conFirmFunc: Function,
    closeFunc: Function,
}
// 기안작성 > 결재진행함 모듈
function DraftConfirm({ draftType, conFirmFunc, closeFunc }: ApprProps) {

    // 중복전송 체크
    const [submitFlag, setSubmitFlag] = useState(false);
    const [isUrgent, setIsUrgent] = useState(false);
    const [secLevelCodeList, setSecLevelCodeList] = useState<CommCodeVO[]>([]);
    const [selectedSecurityLevel, setSelectedSecurityLevel] = useState('GN'); // 기본값 설정
    const [comment, setComment] = useState('');
    const [isSecurityApproval, setIsSecurityApproval] = useState(false);

    // 날짜 선택
    const [dateRange, setDateRange] = useState({
        start: new Date(), // 현재 날짜로 시작
        end: new Date(),   // 현재 날짜로 종료
    });

    // 날짜 범위 변경 핸들러
    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const newStartDate = new Date(event.target.value);

        // 유효성 체크: 시작일이 종료일보다 나중인지 확인
        if (dateRange.end && newStartDate > dateRange.end) {
            alert("시작일은 종료일보다 이전이어야 합니다.")
        } else {
            setDateRange((prevRange) => ({
                ...prevRange,
                start: newStartDate,
            }));
        }
    };

    const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEndDate = new Date(event.target.value);

        // 유효성 체크: 종료일이 시작일보다 이전인지 확인
        if (dateRange.start && newEndDate < dateRange.start) {
            alert("종료일은 시작일보다 이후여야 합니다.");
        } else {
            setDateRange((prevRange) => ({
                ...prevRange,
                end: newEndDate,
            }));
        }
    };

    const fn_confirm = () => {
        //중복 전송을 막기위한 처리
        if (submitFlag) {
            return
        }

        //중복 전송을 막기 위한 처리
        setSubmitFlag(true);
        conFirmFunc(false);

    }

    const closeButton = () => {
        closeFunc();
    }


    const handleCheckboxChange = (event: { target: { checked: any; }; }) => {
        const checked = event.target.checked;
        setIsUrgent(checked);
    };

    useEffect(() => {

        const getData_main = async () => {
            try {
                const response = await axios.post(
                    "/api/appr/signForm/draftConfirm.do",
                    {
                        draftType: draftType,
                        lang: "ko",
                    },
                    { headers: { "Content-Type": "application/json" } }
                );
                const rtn = response.data;
                setSecLevelCodeList(rtn.secLevelCodeList);

            } finally {

            }
        };
        getData_main();
    }, []);


    return (
        <>
            <React.Fragment>
                <Form id="layerPopForm">
                    <table className="appr_comfirm_table">
                        <colgroup>
                            <col style={{ width: '40%' }} />
                            <col style={{ width: '60%' }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className="subject_td">&nbsp;중요도</td>
                                <td className="contents_td">
                                    <input
                                        type="checkbox"
                                        id="chkUrgencyYn"
                                        style={{ margin: '0' }}
                                        checked={isUrgent}
                                        onChange={handleCheckboxChange}
                                    />
                                    긴급 결재문서
                                </td>
                            </tr>
                            <tr>
                                <td className="subject_td">&nbsp;보안등급</td>
                                <td className="contents_td">
                                    <Form.Select name="approvalType" value={selectedSecurityLevel} onChange={(e) => { setSelectedSecurityLevel(e.target.value) }}>
                                        {secLevelCodeList.map((secLevel) => (
                                            <option value={secLevel.detail_code}>{secLevel.detail_code_nm}</option>
                                        ))}
                                    </Form.Select>
                                    <input id="selected_security_level_cd_Value" value={selectedSecurityLevel} hidden></input>
                                </td>
                            </tr>
                            <tr>
                                <td className="subject_td">&nbsp;기안자 의견</td>
                                <td className="contents_td">
                                    <textarea
                                        id="comment"
                                        className="comment_area"
                                        value={comment}
                                        placeholder="의견을 입력하세요."
                                        onChange={(e) => setComment(e.target.value)}
                                        style={{
                                            width: '100%'
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="subject_td">&nbsp;보안결재</td>
                                <td className="contents_td">
                                    <input
                                        type="checkbox"
                                        id="chkSecurityAppr"
                                        style={{ margin: '0' }}
                                        checked={isSecurityApproval}
                                        onChange={() => setIsSecurityApproval(!isSecurityApproval)}
                                    />
                                    보안결재
                                    &nbsp;
                                    {isSecurityApproval && (
                                        <span id="securityDateSpan">
                                            <Form.Control
                                                id="security_bgnde"
                                                className={'half-width'}
                                                type="date"
                                                value={dateRange.start.toISOString().slice(0, 10)}
                                                onChange={handleStartDateChange}
                                            />

                                            ~
                                            <Form.Control
                                                id="security_endde"
                                                className={'hidden half-width'}
                                                style={{ display: 'inline-block' }}
                                                type="date"
                                                value={dateRange.end.toISOString().slice(0, 10)}
                                                onChange={handleEndDateChange}
                                            />
                                        </span>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ justifyContent: "flex-end", display: "flex" }}>
                        <Button variant="primary" style={{ marginRight: "0.5rem" }} onClick={fn_confirm} >
                            확인
                        </Button>
                        <Button variant="secondary" onClick={closeButton}>
                            닫기
                        </Button>
                    </div>
                </Form>

            </React.Fragment>
        </>

    );

}

type CommCodeVO = {
    comm_code: string;
    detail_code: string;
    lang: string;
    detail_code_nm: string;
    detail_code_expl: string;
    regsd: string;
    register: string;
    updde: string;
    updusr: string;

    [key: string]: any;
}

export default DraftConfirm;