
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from 'axios';
import React from 'react';

interface ApprProps {
    processType: string,
    actionStatus: string,
    sign_id: string,
    conFirmFunc: Function,
    closeFunc: Function,

}
// 기안작성 > 결재진행함 모듈
function ApprovalConfirm({ processType, actionStatus, sign_id, conFirmFunc, closeFunc }: ApprProps) {

    const [comment, setComment] = useState('');

    const closeButton = () => {
        closeFunc();
    }

    // 셋팅 시작
    const [visibleOptions, setVisibleOptions] = useState({
        appr: false,
        reject: false,
        prevStepReject: false,
        hold: false,
        afterAppr: false,
        sAgree: false,
        pAgree: false,
        audit: false,
        pReject: false,
    });
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    // 초기 텍스트 색상
    const [textColor, setTextColor] = useState('#c0c0c0');
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
    // 중복전송을 막기위한 플래그변수
    const [submitFlag, setSubmitFlag] = useState(false);

    // 초기 select 셋팅
    const [selected, setSelected] = useState('');

    // 비밀번호 셋팅
    const [layerApprPassword, setLayerApprPassword] = useState("");
    useEffect(() => {

        const getData_main = async () => {
            try {
                apprRadioBtnShow();
                apprRadioBtnChecked();
                const response = await axios.post(
                    "/api/appr/signForm/approvalConfirm.do",
                    {
                        sign_id: sign_id,
                        processType: processType,
                        user_id: sessionStorage.getItem('user_id')
                    },
                    { headers: { "Content-Type": "application/json" } }
                );
                const rtn = response.data;
                layerApprPasswordShow(rtn.passwordUseYn);
                afterApprovalShow(rtn.nextApprChangeYn);
            } finally {

            }
        };
        getData_main();
    }, []);

    const apprRadioBtnShow = () => {
        const options = {
            appr: false,
            reject: false,
            prevStepReject: false,
            hold: false,
            afterAppr: false,
            sAgree: false,
            pAgree: false,
            audit: false,
            pReject: false,
        };

        if (actionStatus === 'AS' || actionStatus === 'CF') {
            options.appr = true;
            options.reject = true;
            options.prevStepReject = true;
            options.hold = true;
        } else if (actionStatus === 'HAS' || actionStatus === 'HCF') {
            options.appr = true;
            options.reject = true;
            options.prevStepReject = true;
        } else if (actionStatus === 'AA') {
            options.afterAppr = true;
        } else if (actionStatus === 'SA') {
            options.sAgree = true;
            options.reject = true;
            options.hold = true;
        } else if (actionStatus === 'HSA') {
            options.sAgree = true;
            options.reject = true;
        } else if (actionStatus === 'PA') {
            options.pAgree = true;
            options.pReject = true;
            options.hold = true;
        } else if (actionStatus === 'HPA') {
            options.pAgree = true;
            options.pReject = true;
        } else if (actionStatus === 'SAD') {
            options.audit = true;
            options.reject = true;
            options.hold = true;
        } else if (actionStatus === 'HSAD') {
            options.audit = true;
            options.reject = true;
        }

        setVisibleOptions(options);
    };

    const apprRadioBtnChecked = () => {
        switch (processType) {
            case 'appr':
                setSelected('appr');
                break;
            case 'after_appr':
                setSelected('after_appr');
                break;
            case 'SA_appr':
                if (actionStatus === 'SA' || actionStatus === 'HSA') {
                    setSelected('sAgree');
                } else {
                    setSelected('audit');
                }
                break;
            case 'PA_appr':
                setSelected('pAgree');
                break;
            case 'PA_reject':
                setSelected('pReject');
                break;
            case 'reject':
                setSelected('reject');
                break;
            case 'prevReject':
                setSelected('prevReject');
                break;
            case 'hold':
                setSelected('hold');
                break;
            default:
                setSelected('');
                break;
        }
    }


    const layerApprPasswordShow = (passwordUseYn?: any) => {
        if (passwordUseYn === 'Y') {
            setIsPasswordVisible(true);
        } else {
            setIsPasswordVisible(false);
        }
    }
    const afterApprovalShow = (nextApprChangeYn?: any) => {
        if (nextApprChangeYn === 'Y' && processType === 'appr') {
            setIsCheckboxDisabled(false);
            setTextColor('#333333');
        } else {
            setIsCheckboxDisabled(true);
            setTextColor('#c0c0c0');
        }
    }

    const fn_confirm = () => {
        const newMap = new Map();
        //중복전송 체크
        if (submitFlag) {
            return;
        }
        //유효성 체크
        if (!fn_check()) {
            return;
        }
        setSubmitFlag(true);
        //passwordUseYn === 'Y'와 같음
        if (isPasswordVisible) {
            newMap.set("apprPassword", layerApprPassword);
        }

        if (isChecked) {
            newMap.set("chgNextUserAAType", "Y");
        }

        newMap.set("apprComment", comment);
        conFirmFunc(selected);
    }

    const fn_check = () => {
        //결재암호 사용유무가 Y인데 결재암호를 입력 안한 경우
        //isPasswordVisible === 즉 passwordUseYn === 'Y'와 같음
        if (isPasswordVisible && layerApprPassword === "") {
            alert("암호를 입력하세요.");
            document.getElementById("layerApprPassword")?.focus();
            return false;
        }
        return true;

    }

    return (
        <React.Fragment>
            <Form id="layerPopForm">
                <table className="appr_comfirm_table">
                    <colgroup>
                        <col style={{ width: '40%' }} />
                        <col style={{ width: '60%' }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className="subject_td">&nbsp;결재</td>
                            <td className="contents_td">
                                <Form.Select name="approvalType" value={selected} onChange={(e) => { setSelected(e.target.value) }}>
                                    {visibleOptions.appr && (<option value="appr">결재</option>)}
                                    {visibleOptions.afterAppr && (<option value="after_appr">후결</option>)}
                                    {visibleOptions.pAgree && (<option value="audit">감사</option>)}
                                    {visibleOptions.sAgree && (<option value="pAgree">합의</option>)}
                                    {visibleOptions.reject && (<option value="reject">반려</option>)}
                                    {visibleOptions.prevStepReject && (<option value="prevReject">전단계 반려</option>)}
                                </Form.Select>
                            </td>
                        </tr>
                        {isPasswordVisible && (
                            <tr id="appr_password_area">
                                <td className="subject_td">
                                    &nbsp;결재암호
                                </td>
                                <td className="contents_td">
                                    &nbsp;
                                    <input
                                        type="password"
                                        id="layerApprPassword"
                                        value={layerApprPassword}
                                        onChange={(e) => setLayerApprPassword(e.target.value)}
                                        style={{
                                            width: '180px',
                                            border: '1px solid #D6DFE6',
                                            height: '83%',
                                            paddingLeft: '8px',
                                        }}
                                    />
                                </td>
                            </tr>
                        )}

                        <tr>
                            <td className="subject_td">&nbsp;결재자 의견</td>

                            <td className="contents_td">
                                <textarea
                                    id="apprComment"
                                    className="comment_area"
                                    value={comment}
                                    placeholder="의견을 입력하세요."
                                    onChange={(e) => setComment(e.target.value)}
                                    style={{
                                        width: '100%'
                                    }}
                                />
                            </td>

                        </tr>

                        <tr>
                            <td className="subject_td">&nbsp;후결처리</td>
                            <td className="contents_td">
                                &nbsp;
                                <input
                                    type="checkbox"
                                    id="chkNextApprAA"
                                    value="Y"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    disabled={isCheckboxDisabled}
                                />
                                <span id="afterApprTextSpan" style={{ color: textColor }}>
                                    다음 결재자 후결
                                </span>
                            </td>
                        </tr>
                    </tbody>

                </table>
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                    <Button variant="primary" style={{ marginRight: "0.5rem" }} onClick={fn_confirm} >
                        확인
                    </Button>
                    <Button variant="secondary" onClick={closeButton}>
                        닫기
                    </Button>
                </div>
            </Form>

        </React.Fragment>
    );

}

export default ApprovalConfirm;

