import '../../css/exter/Board.css'
import axios from "axios";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, NavDropdown, Row } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import AlertModal from '../../module/AlertModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowUp, MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { SiMicrosoftexcel, SiMicrosoftword, SiMicrosoftpowerpoint } from "react-icons/si";
import { RiFileHwpLine, RiFileImageLine, RiFileVideoLine, RiFileList2Line, RiFileLine, RiFilePdf2Line } from "react-icons/ri";
import InquiryList from './InquiryList';
import JoditEditor from "jodit-react";
import { BsHandThumbsUp, BsHandThumbsUpFill, BsList, BsThreeDotsVertical } from 'react-icons/bs';
import { FaAngleLeft } from "react-icons/fa6";

interface BoardProps {
    bbs_id: string,
    ntt_id: string,
    closeFunc: Function,
    menu_nm?: string,
    state?: any,
    setReady: Function,
    setEdit: Function
}

let recFlag = false;


function BoardDetailPage({bbs_id, ntt_id, closeFunc, menu_nm, state, setReady, setEdit}: BoardProps) {
    
    const location = useLocation();
    
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isAdmin = sessionStorage.getItem('priv_cd')==='WIDE_ADMIN';
    const [alertBody, setAlertBody] = useState('');
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showAuthAlertModal, setShowAuthAlertModal] = useState(false);
    const [showNoticeAlertModal, setShowNoticeAlertModal] = useState(false);
    const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false);
    const [showResultAlertModal, setShowResultAlertModal] = useState(false);
    const [showClosingAlertModal, setShowClosingAlertModal] = useState(false);
    const [showInquiryModal, setShowInquiryModal] = useState(false);
    const isPage = /\/board\/[0-9]+\/[0-9]+/.test(location.pathname);
    const [nttId, setNttId] = useState(ntt_id);
    
    const [boardVO, setBoardVO] = useState<BoardVO|null>(null);
    const [commentVoLi, setCommentVoLi] = useState<CommentVO[]|null>(null);
    const [attchFiles, setAttchFiles] = useState<AttchFiles[]|null>(null);
    const [manageVO, setManageVO] = useState<ManageVO|null>(null);
    
    const [reply, setReply] = useState('');
    const [reportCn, setReportCn] = useState('');

    const [cmSn, setCmSn] = useState('');
    const [delCmSn, setDelCmSn] = useState('');
    const [isRecommend, setIsRecommend] = useState(false);
    const [isReport, setIsReport] = useState(false);
    const [isNotice, setIsNotice] = useState(false);

    const [isBoard, setBoard] = useState(false);

    const isReserved = ntt_id.charAt(0) === '0';
    
    const navigate = useNavigate();

    const joditConfig = useMemo(() => ({
            readonly: true,
            toolbar: false,
            statusbar: false
        }), []);

    function changeArticle(newNttId: string) {
        if (!isPage) {
            (document.querySelector('.modal-body') as HTMLElement).scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            setNttId(newNttId);
        } else {
            const path = '/board/' + bbs_id + '/' + newNttId;
            state.ntt_id = newNttId;
            navigate(path , {state: state, replace: false});
        }
    }

    function showCommentReply(cm_sn: number, btn: HTMLElement) {
        cancelCommentReply();
        setCmSn('');
        btn.style.display = 'none';
        const commWrap = (document.getElementById('replyComment_' + cm_sn) as HTMLElement);
        document.querySelectorAll<HTMLElement>('.reply-comment-wrap').forEach(el => {
            if (el === commWrap)
                el.classList.remove('hidden');
        });
        moveFocus('#replyComment_' + cm_sn + ' #replyComment');
    }

    async function cancelCommentReply() {
        document.querySelectorAll<HTMLElement>('.reply-comment-wrap').forEach(el => {
            if (!el.classList.contains('hidden'))
                el.classList.add('hidden');
            (el.querySelector('textarea') as HTMLTextAreaElement).value = '';
        });
        document.querySelectorAll<HTMLElement>('button[id^=reply_]').forEach(btn => btn.removeAttribute('style'));
    }

    function regReply(isSub: boolean, upper_ntt?: string) {
        const content = (isSub ? (document.getElementById('replyCommentTa_' + upper_ntt) as HTMLTextAreaElement)?.value : reply);
        if (content.trim() === '') {
            setAlertBody('빈 댓글은 등록 할 수 없습니다.');
            setShowAlertModal(true);
            return;
        }
        const params = {
            bbs_id: bbs_id,
            ntt_id: nttId,
            ntt_cm: content,
            ntt_cm_level: (isSub ? '1' : '0'),
            upper_ntt_cm_sn: (isSub ? upper_ntt : '0'),
            register: sessionStorage.getItem('user_id')
        }
        axios.post('/api/board/regReply', params, {headers : {'Content-Type' : 'application/json'}}).then(response => {
            setReply('');
            setCmSn('');
            cancelCommentReply();
            setCommentVoLi(response.data);
        });
    }

    function delChk(cm_sn: string, register: string) {
        setCmSn('');
        if (!isAdmin && register !== sessionStorage.getItem('user_id')) {
            setAlertBody('권한이 없습니다.');
            setShowAuthAlertModal(true);
        } else {
            setDelCmSn(cm_sn);
            setAlertBody('댓글을 삭제하시겠습니까?');
            setShowAlertModal(true);
        }
    }

    function resetCmSn() {
        setCmSn('');
        setDelCmSn('');
        setShowAlertModal(false);
    }
    
    function deleteReply() {
        const params = {
            bbs_id: bbs_id,
            ntt_id: nttId,
            cm_sn: cmSn ? cmSn : delCmSn
        }
        axios.post('/api/board/delReply', params, {headers: {'Content-Type' : 'application/json'}}).then(response => {
            setReply('');
            cancelCommentReply();
            setCmSn('');
            setDelCmSn('');
            setCommentVoLi(response.data);
            setShowAlertModal(false);
        })
    }

    function modChk(cm_sn: string, btn: HTMLElement) {
        cancelCommentReply();
        setCmSn(cm_sn);
    }

    function modReply(cm_sn: string) {
        const ntt_cm = (document.querySelector('#modReply_' + cm_sn) as HTMLTextAreaElement).value;
        if (ntt_cm.trim() === '') {
            setAlertBody('빈 댓글은 등록 할 수 없습니다.');
            setShowAlertModal(true);
            return;
        }
        const params = {
            cm_sn: cm_sn,
            bbs_id: bbs_id,
            ntt_id: nttId,
            ntt_cm: ntt_cm,
            updusr: sessionStorage.getItem('user_id')
        }
        axios.post('/api/board/modReply', params, {headers: {'Content-Type' : 'application/json'}}).then(response => {
            setAlertBody('수정이 완료되었습니다.');
            setShowAuthAlertModal(true);
            setCmSn('');
            setCommentVoLi(response.data);
        });
    }

    function moveFocus(query: string) {
        const inp = document.querySelector(query) as HTMLTextAreaElement;
        if (inp) {
            inp.focus();
        } else {
            setTimeout(() => {
                moveFocus(query);
            }, 1);
        }
    }

    function recommendArticle() {
        if (!recFlag) {
            recFlag = !recFlag;
            const params = {
                bbs_id : bbs_id,
                ntt_id : nttId,
                user_id : sessionStorage.getItem('user_id')
            }
            axios.post('/api/board/recommend', params, {headers: {'Content-Type' : 'application/json'}}).then(() => {
                setIsRecommend(prev => {
                    setAlertBody(prev ? '추천이 취소되었습니다.' : '게시글을 추천 하셨습니다.');
                    setShowAuthAlertModal(true);
                    recFlag = !recFlag;
                    return !prev;
                });
            });
        }
    }

    function showReport() {
        if (isReport) {
            setAlertBody('이미 신고한 게시물입니다.');
            setShowAuthAlertModal(true);
        } else {
            document.querySelectorAll<HTMLElement>('.button-wrap:not(.button-wrap:has(#menu))').forEach(div => div.classList.add('hidden'));
            document.querySelector<HTMLElement>('.report-wrap')?.classList.remove('hidden');
            moveFocus('#reportCn');
        }
    }

    function cancelReport() {
        document.querySelectorAll<HTMLElement>('.button-wrap:not(.button-wrap:has(#menu))').forEach(div => div.classList.remove('hidden'));
        document.querySelector<HTMLElement>('.report-wrap')?.classList.add('hidden');
        setReportCn('');
    }

    function report() {
        if (isReport) {
            setAlertBody('이미 신고한 게시물입니다.');
            setShowAuthAlertModal(true);
            cancelReport();
            return;
        } else if (!reportCn.trim()) {
            setAlertBody('사유를 입력해주세요.');
            setShowAuthAlertModal(true);
            setReportCn('');
            moveFocus('#reportCn');
            return;
        }
        const params = {
            bbs_id : bbs_id,
            ntt_id : nttId,
            report_cn : reportCn,
            register : sessionStorage.getItem('user_id')
        }
        axios.post('/api/board/report', params, {headers: {'Content-Type' : 'application/json'}}).then(response => {
            if (!response.data){
                setAlertBody('신고처리 되었습니다.');
                cancelReport();
                setIsReport(true);
            } else if (response.data === 1) {
                setAlertBody('이미 신고한 게시물입니다.');
                cancelReport();
                setIsReport(true);
            } else {
                setAlertBody('문제가 발생했습니다. 잠시 후 다시 시도해주세요.');
            }
            setShowAuthAlertModal(true);
        });
    }

    function toNotice() {
        if (isNotice)
            setAlertBody('게시물을 공지 해제 하시겠습니까?');
        else
            setAlertBody('게시물을 공지로 등록하시겠습니까?');
        setShowNoticeAlertModal(true);
    }

    function toNoticeProc() {
        setShowNoticeAlertModal(false);
        const params = {
            bbs_id : bbs_id,
            ntt_id : nttId,
            user_id : sessionStorage.getItem('user_id'),
            ntt_notice_at : (isNotice ? 'N' : 'Y')
        }
        axios.post('/api/board/notice', params, {headers: {'Content-Type' : 'application/json'}}).then(response => {
            if (response.data) {
                setAlertBody('문제가 발생했습니다. 잠시 후 다시 시도해주세요.');
            } else {
                if (isNotice)
                    setAlertBody('게시물이 공지 해제되었습니다.');
                else
                    setAlertBody('게시물이 공지 등록되었습니다.');
                setIsNotice(prev => !prev);
            }
            setShowAuthAlertModal(true);
        });
    }

    function viewList() {
        if (isDesktop) {
            setShowInquiryModal(true);
        } else {
            state['articlePath'] = location.pathname;
            navigate('/board/inquiryList', {state: state, replace: true});
        }
    }

    function setIcon(ext: string) {
        let res: ReactNode;
        if (!ext) res = iconMap.etc;
        else {
            switch (ext.toLowerCase().trim()) {
                case 'xls': case 'xlsx':
                    res = iconMap.excel;
                    break;
                case 'doc': case 'docx': 
                    res = iconMap.word;
                    break;
                case 'ppt': case 'pptx':
                    res = iconMap.ppt;
                    break;
                case 'hwp': case 'hwpx':
                    res = iconMap.hwp;
                    break;
                case 'pdf':
                    res = iconMap.pdf;
                    break;
                case 'jpg': case 'jpeg': case 'png':
                case 'gif': case 'bmp': case 'svg':
                    res = iconMap.img;
                    break;
                case 'mp4': case 'mov': case 'wmv':
                case 'avi': case 'flv': case 'f4v':
                case 'swf': case 'mkv':
                    res = iconMap.video;
                    break;
                case 'txt': case 'md':
                    res = iconMap.txt;
                    break;
                default:
                    res = iconMap.etc;
                    break;
            }
        }
        return res;
    }

    function download(attch_sn: number, flnm: string) {
        const params = {
            bbs_id : bbs_id,
            ntt_id : nttId,
            ntt_atchmnfl_sn : attch_sn
        }
        axios.post('/api/board/download', params, {headers: {'Content-Type': 'application/json'}, responseType: 'blob'}).then(response => {
            const rtn = response.data;
            rtn.text().then((res: string) => {
                if (res === '1') {
                    setAlertBody('오류가 발생했습니다. 관리자에게 문의 바랍니다.');
                    setShowAuthAlertModal(true);
                } else if (res === '2') {
                    setAlertBody('파일을 찾을 수 없습니다.');
                    setShowAuthAlertModal(true);
                } else {
                    const el = document.createElement('a');
                    el.download = flnm;
                    el.href = window.URL.createObjectURL(new Blob([rtn]));
                    el.click();
                }
            });
        });
    }

    function modDeleteYn() {
        setShowDeleteAlertModal(false);
        if (!boardVO) return;
        const isManaging = (state && state.prevPath && state.prevPath.includes('/admin/postMngList'))
                                || (window.location.pathname.includes('/admin/postMngList')) ? 'Y' : 'N';
        const params = {
            bbs_id: boardVO.bbs_id,
            ntt_id: boardVO.ntt_id,
            ntt_delete_at: boardVO.ntt_delete_at === 'Y' ? 'N' : 'Y',
            updusr: sessionStorage.getItem("user_id"),
        }
        axios.post('/api/board/modArticleDeleteAt',
                params,
                {headers : {'Content-Type' : 'application/json'}}
        ).then(() => {
            const path = `/api/board/${bbs_id}/${nttId}`;
            const params = {
                user_id : sessionStorage.getItem('user_id'),
                searchStr : state ? state.searchMap ? state.searchMap.get('searchStr') : '' : '',
                searchKey : state ? state.searchMap ? state.searchMap.get('searchKey') : 'BSH001' : 'BSH001',
                searchYear : state ? state.searchMap ? state.searchMap.get('searchYear') : '' : '',
                searchMonth : state ? state.searchMap ? state.searchMap.get('searchMonth') : '' : '',
                admin_yn : isAdmin ? 'Y' : 'N',
                isManaging: isManaging,
            }
            axios.post(path, params, {headers : {'Content-Type' : 'application/json'}})
            .then(response => {
                    if (!response.data.BoardVO.resultMsg) {
                        setBoardVO(response.data.BoardVO);
                        setCommentVoLi(response.data.CommentVO);
                        setAttchFiles(response.data.AttchFiles);
                        setManageVO(response.data.ManageVO);
                        setIsRecommend(response.data.BoardVO.rec_yn === 'Y');
                        setIsReport(response.data.BoardVO.report_yn === 'Y');
                        setIsNotice(response.data.BoardVO.ntt_notice_at === 'Y');
                        setAlertBody('게시물이 ' + (response.data.BoardVO.ntt_delete_at === 'Y' ? '삭제' : '복구') + '되었습니다.');
                        setShowResultAlertModal(true);
                    } else {
                        const resMsg = response.data.BoardVO.resultMsg;
                        if (resMsg === 'PSERR') {
                            setAlertBody('잘못된 url입니다.');
                            setShowClosingAlertModal(true);
                        } else if (resMsg === 'INVBBS') {
                            setAlertBody('게시판을 찾을 수 없습니다.');
                            setShowClosingAlertModal(true);
                        } else if (resMsg === 'INVNTT') {
                            setAlertBody('게시글이 삭제되었습니다.');
                            isManaging === 'N' ? setShowClosingAlertModal(true) : setShowAlertModal(true);
                        }
                    }
                });
        });
    }

    function editArticle() {
        if (isDesktop) {
            setEdit(true);
            closeFunc();
        } else {
            state['ntt_id'] = nttId;
            navigate('/board/reg', {state: state, replace: true});
        }
    };
    
    useEffect(() => {
        if (nttId && bbs_id) {
            const path = `/api/board/${bbs_id}/${nttId}`;
            let isManaging = 'N';
            if ((state && state.prevPath && state.prevPath.includes('/admin/postMngList'))
                || (window.location.pathname.includes('/admin/postMngList')))
                isManaging = 'Y';
            const params = {
                user_id : sessionStorage.getItem('user_id'),
                searchStr : state ? state.searchMap ? state.searchMap.get('searchStr') : '' : '',
                searchKey : state ? state.searchMap ? state.searchMap.get('searchKey') : 'BSH001' : 'BSH001',
                searchYear : state ? state.searchMap ? state.searchMap.get('searchYear') : '' : '',
                searchMonth : state ? state.searchMap ? state.searchMap.get('searchMonth') : '' : '',
                isManaging : isManaging,
                admin_yn : isAdmin ? 'Y' : 'N',
            }
            axios.post(path, params, {headers : {'Content-Type' : 'application/json'}})
            .then(response => {
                    if (!response.data.BoardVO.resultMsg) {
                        setBoardVO(response.data.BoardVO);
                        setCommentVoLi(response.data.CommentVO);
                        setAttchFiles(response.data.AttchFiles);
                        setManageVO(response.data.ManageVO);
                        setIsRecommend(response.data.BoardVO.rec_yn === 'Y');
                        setIsReport(response.data.BoardVO.report_yn === 'Y');
                        setIsNotice(response.data.BoardVO.ntt_notice_at === 'Y');
                    } else {
                        const resMsg = response.data.BoardVO.resultMsg;
                        if (resMsg === 'PSERR') {
                            setAlertBody('잘못된 url입니다.');
                            setShowAlertModal(true);
                        } else if (resMsg === 'INVBBS') {
                            setAlertBody('게시판을 찾을 수 없습니다.');
                            setShowAlertModal(true);
                        } else if (resMsg === 'INVNTT') {
                            setAlertBody('게시글을 찾을 수 없습니다.');
                            setShowAlertModal(true);
                        }
                    }
                });
        } else {
            setAlertBody('요청을 완료할 수 없습니다. 잠시 후 다시 시도해 주세요.');
            setShowAlertModal(true);
        }
    }, [nttId]);
    
    useEffect(() => {
        if (boardVO) {
            document.querySelectorAll<HTMLElement>('.jodit-wysiwyg [style]:not(textarea)').forEach(el => {
                const style = el.getAttribute('style');
                let newAttr = '';
                style?.split(';').forEach(attr => {
                    attr = attr.trim();
                    if (!isDesktop) {
                        if (attr.includes('width:'))
                            attr = 'width: 85vw'
                        else if (attr.includes('height:'))
                            attr = '';
                    }
                    if (attr)
                        newAttr += attr + '; ';
                });
                newAttr += "display: block; margin: 0 auto;";
                el.setAttribute('style', newAttr);
            });
            axios.post('/api/board/getBoardUrls').then(response => {
                const boardLi: string[] | null = response.data;
                const prevPath = state ? state.prevPath ? state.prevPath.replace('/board', '') : '' : '';
                const path = window.location.pathname.replace('/board', '');
                setBoard(boardLi ? ((boardLi.indexOf(prevPath) > -1) || (boardLi.indexOf(path) > -1) || prevPath.includes('/admin/postMngList')
                || path.includes('/admin/postMngList')) : false);
            })
            setReady(true);
        }
    }, [boardVO]);
    
    return (
        boardVO && manageVO ? <>
            <Form className='board-detail-form' >
                {isPage && 
                    <a href='#!' className='text-decoration-none' style={{color: '#fb0404'}} onClick={() => closeFunc()}><FaAngleLeft size={15} color='#fb0404' className='mb-1'/> {menu_nm}</a>
                }
                <div className='d-flex justify-content-between align-items-center mt-1'>
                    <h3 className='mb-0 fs-3'>{boardVO.ntt_sj}</h3>
                    <NavDropdown title={<BsThreeDotsVertical size={25} color='gray'/>} id='board-dropdown'>
                        {isAdmin && !isReserved && <NavDropdown.Item onClick={toNotice}>{isNotice ?'공지 해제':'공지 등록'}</NavDropdown.Item>}
                        {manageVO.inqire_view_at === 'Y' && isAdmin && !isReserved && <NavDropdown.Item onClick={viewList}>조회 현황</NavDropdown.Item> }
                        {(isAdmin || sessionStorage.getItem('user_id') === boardVO.register) && 
                            <>
                                {(isBoard && (sessionStorage.getItem('user_id') === boardVO.register)) &&
                                    <NavDropdown.Item onClick={editArticle}>수정</NavDropdown.Item>
                                }
                                {boardVO.ntt_delete_at === 'N' && 
                                    <NavDropdown.Item onClick={() => {setAlertBody('게시물을 삭제하시겠습니까?');setShowDeleteAlertModal(true);}}>삭제</NavDropdown.Item>
                                }
                                {isAdmin && boardVO.ntt_delete_at === 'Y' &&
                                    <NavDropdown.Item onClick={() => {setAlertBody('게시물을 복구하시겠습니까?');setShowDeleteAlertModal(true);}}>복구</NavDropdown.Item>
                                }
                            </>
                        }
                        {sessionStorage.getItem('user_id') !== boardVO.register && !isReserved && <>
                            {(isAdmin || (manageVO.inqire_view_at === 'Y' && isAdmin) || ((isAdmin || sessionStorage.getItem('user_id') === boardVO.register) && ((isBoard && (sessionStorage.getItem('user_id') === boardVO.register || isAdmin)) || boardVO.ntt_delete_at === 'N' || (isAdmin && boardVO.ntt_delete_at === 'Y'))))
                                && <NavDropdown.Divider/>}
                            <NavDropdown.Item onClick={showReport}>신고</NavDropdown.Item>
                        </>}
                    </NavDropdown>
                </div>

                <div>
                    <span className='d-block mt-1' style={{fontSize: '18px'}}>{boardVO.ntt_wrter}</span>
                    <div className='d-flex'>
                        <span className='board-sub-info'>{boardVO.rgsde + ' ~ ' + boardVO.ntce_endde}</span>
                        {boardVO.updde ?
                            <>
                                <span className='ms-auto board-sub-info'>마지막 수정: {boardVO.updde}</span>
                                <span className='ms-2 board-sub-info'>조회 {boardVO.inquiry_cnt}</span>
                            </>
                            :
                            <span className='ms-auto board-sub-info'>조회 {boardVO.inquiry_cnt}</span>
                        }
                    </div>
                    <hr/>
                    {attchFiles && attchFiles.length > 0 && 
                        <div className='bg-body-tertiary border'>
                            <p className='mb-0 text-secondary' style={{fontSize: '14px'}}>첨부파일</p>
                            {attchFiles.map(fl => {
                                return (
                                    <div key={fl.ntt_atchmnfl_sn} role='button' className='px-2' title='다운로드' style={{maxWidth: 'max-content'}} onClick={() => download(fl.ntt_atchmnfl_sn, fl.file_real_nm)}>
                                        <span className='me-2'>{setIcon(fl.file_ext)}</span>
                                        <i>{fl.file_real_nm}</i>
                                    </div>
                                );
                            })}
                        </div>
                    }
                    <JoditEditor className='border border-0 border-bottom-0' value={boardVO.ntt_cn} config={joditConfig} />
                    <div className='d-flex'>
                        <a className='text-decoration-none text-body' onClick={() => closeFunc()} href='#!'><BsList size={18} className='mb-1'/> 목록</a>
                        {!isReserved && <a className='text-decoration-none text-danger ms-auto' onClick={recommendArticle} href='#!'>{isRecommend ? <BsHandThumbsUpFill className='mb-1'/> : <BsHandThumbsUp className='mb-1'/>} LIKE {boardVO.ntt_recomend_co}</a>}
                    </div>
                    {boardVO.post_ntt_sj && 
                        <div className="d-flex justify-content-between align-items-center border-top border-bottom" onClick={() => changeArticle(boardVO.post_ntt_id) }>
                            <span className='pe-1' style={{minWidth: '84px'}}><MdKeyboardArrowUp size={24}/>다음글</span>
                            <span className='pe-1'>{boardVO.post_ntt_sj}</span>
                            <span className='board-sub-info'>{boardVO.post_rgsde}</span>
                        </div>
                    }
                    { boardVO.prev_ntt_sj && 
                        <div className="d-flex justify-content-between align-items-center border-top border-bottom" onClick={() => changeArticle(boardVO.prev_ntt_id) } >
                            <span className='pe-1' style={{minWidth: '84px'}}><MdKeyboardArrowDown size={24}/>이전글</span>
                            <span className='pe-1'>{boardVO.prev_ntt_sj}</span>
                            <span className='board-sub-info'>{boardVO.prev_rgsde}</span>
                        </div>
                    }
                </div>
                <div className="report-wrap hidden">
                    <Row>
                        <Col>
                            신고하기
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col xs={8} sm={10}>
                            <Form.Control as="textarea" rows={3} id="reportCn" defaultValue={reportCn} onChange={e => setReportCn(e.target.value)} />
                        </Col>
                        <Col xs={4} sm={2} style={{textAlign: 'center'}}>
                            <Button className='btn-sm' variant='danger' onClick={report}>신고</Button>{isDesktop && <>&nbsp;&nbsp;</>}
                            <Button className='btn-sm' variant="secondary" onClick={cancelReport}>취소</Button>
                        </Col>
                    </Row>
                </div>
                {manageVO.answer_at === 'Y' && !isReserved &&  <>
                    {/* <hr /> */}
                    <div className="reply-wrap">
                        <p className='mb-0 mt-3'>댓글</p>
                        <Row className='border-top pt-3'>
                            <Col xs={8} sm={10}>
                                <Form.Control as="textarea" rows={3} id="reply" value={reply} onChange={e => setReply(e.target.value)} />
                            </Col>
                            <Col xs={4} sm={2} style={{textAlign: 'center'}}>
                                <Button onClick={() => regReply(false)}>등록</Button>
                            </Col>
                        </Row>
                        <div id='replyContainer'>
                            {commentVoLi && commentVoLi.map((vo: CommentVO) => {
                                const isReply = vo.ntt_cm_level !== '0';
                                const isDelete = vo.ntt_cm_delete_at === 'Y';
                                return (!isDelete ? <div key={vo.cm_sn} className={'comment-wrap' + (isReply ? ' sub-reply' : '')}>
                                    <p>{isReply && <MdKeyboardArrowRight />}<span style={{color: '#229'}}>{vo.register}</span>&nbsp;&nbsp;<small style={{fontSize: '0.8rem', color: '#555'}}>{vo.rgsde}</small></p>
                                    <div><div id="nttCm">
                                        {cmSn !== vo.cm_sn && <span id={'nttCm_' + vo.cm_sn}>{vo.ntt_cm}</span>}
                                        {(vo.register === sessionStorage.getItem('user_id')) && cmSn === vo.cm_sn &&
                                        <Row id={'nttCmTa_' + vo.cm_sn}>
                                            <Col xs={8} sm={10}>
                                                <Form.Control as="textarea" rows={3} id={'modReply_' + vo.cm_sn} defaultValue={vo.ntt_cm} />
                                            </Col>
                                            <Col xs={4} sm={2} style={{textAlign: 'center'}}>
                                                <Button className='btn-sm' onClick={() => modReply(vo.cm_sn)}>수정</Button>{isDesktop && <>&nbsp;&nbsp;</>}
                                                <Button className='btn-sm' id='modCancelBtn' variant="secondary" onClick={() => setCmSn('')}>취소</Button>
                                            </Col>
                                        </Row>}
                                    </div></div>
                                    {cmSn !== vo.cm_sn && <div id="btnWrap" style={{textAlign: 'right'}}>
                                        {!isReply && <Button id={'reply_' + vo.cm_sn} className='btn-sm' onClick={(e) => showCommentReply(vo.cm_sn, e.target as HTMLElement)}>답글</Button>}
                                        {(vo.register === sessionStorage.getItem('user_id')) && <>
                                            &nbsp;&nbsp;<Button variant='primary' className='btn-sm' onClick={(e) => modChk(vo.cm_sn, e.target as HTMLElement)}>수정</Button>
                                            &nbsp;&nbsp;<Button variant='primary' className='btn-sm' onClick={() => delChk(vo.cm_sn, vo.register)}>삭제</Button>
                                        </>}
                                    </div>}
                                    {!isReply && <div className="comment-reply">
                                        <div id={'replyComment_' + vo.cm_sn} className="reply-comment-wrap hidden">
                                            <Row><Col>답글</Col></Row>
                                            <hr />
                                            <Row>
                                                <Col xs={8} sm={10}>
                                                    <Form.Control as="textarea" rows={2} id={'replyCommentTa_' + vo.cm_sn} />
                                                </Col>
                                                <Col xs={4} sm={2} style={{textAlign: 'center'}}>
                                                    <Button className='btn-sm' onClick={(e) => regReply(true, String(vo.cm_sn))}>등록</Button>
                                                    {isDesktop && <>&nbsp;&nbsp;</>}
                                                    <Button variant='secondary' className='btn-sm' onClick={cancelCommentReply}>취소</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>}
                                </div> : <div key={vo.cm_sn} className={'comment-wrap' + (isReply ? ' sub-reply' : '')}>
                                    <p><span>삭제된 댓글입니다.</span></p>
                                </div>);
                            })}
                        </div>
                    </div>
                </>}
            </Form>
            <AlertModal title='' body={alertBody} show={showAlertModal} setShow={setShowAlertModal} agree='확인' func={deleteReply} closeFunc={resetCmSn} />
            <AlertModal title='' body={alertBody} show={showAuthAlertModal} setShow={setShowAuthAlertModal} />
            <AlertModal title='' body={alertBody} show={showNoticeAlertModal} setShow={setShowNoticeAlertModal} agree='확인' func={toNoticeProc} />
            <AlertModal title='' body={alertBody} show={showDeleteAlertModal} setShow={setShowDeleteAlertModal} agree='확인' func={modDeleteYn} />
            <AlertModal title='' body={alertBody} show={showResultAlertModal} setShow={setShowResultAlertModal} />
            <AlertModal title='' body={alertBody} show={showClosingAlertModal} setShow={setShowClosingAlertModal} agree='확인' func={closeFunc} />
            {isDesktop && showInquiryModal && <InquiryList show={showInquiryModal} setShow={setShowInquiryModal} bbs_id={bbs_id} ntt_id={nttId} />}
        </> : <>
            <AlertModal title='' body={alertBody} show={showAlertModal} setShow={setShowAlertModal} closeFunc={closeFunc} />
        </>
    );
    
}

type BoardVO = {
    [key: string]: any;
}

type CommentVO = {
    [key: string]: any;
}

type AttchFiles = {
    [key: string]: any;
}

type ManageVO = {
    [key: string]: any;
}

const iconMap = {
    excel : <SiMicrosoftexcel />,
    word : <SiMicrosoftword />,
    ppt : <SiMicrosoftpowerpoint />,
    hwp : <RiFileHwpLine />,
    pdf : <RiFilePdf2Line />,
    img : <RiFileImageLine />,
    video : <RiFileVideoLine />,
    txt : <RiFileList2Line />,
    etc : <RiFileLine />
}

export default BoardDetailPage;