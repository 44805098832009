import '../../css/exter/Board.css'
import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import AlertModal from '../../module/AlertModal';
import Paginate from '../../module/Paginate';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../module/Loading';
import DataTableV2 from '../../module/DataTableV2';
import { CellProps } from 'react-table';
import SearchBarV2 from '../../module/SearchBarV2';

interface MemberProps {
    show: boolean,
    closeFunc: Function
}

function MemberModal({show, closeFunc}: MemberProps) {

    const isDesktop = useMediaQuery({ minWidth: 768 });


    //큰 화면용 list를 위한 변수
    const [detailMem, setDetailMem] = useState<MemberCols[]>([]);
    const [detailPage, setDetailPage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    
    //작은 화면용 list를 위한 변수
    const [simpleMem, setSimpleMem] = useState<MemberCols[]>([]);
    const [simplePage, setSimplePage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);

    const [sort, setSortDir] = useState(['', '']);

    const [searchMap, setSearchMap] = useState<Map<string, string>>(new Map());

    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertBody, setAlertBody] = useState('');
    const [userId, setUserId] = useState('');

    const detail_columns = [
        {
            Header: 'ID',
            accessor: 'user_id'
        },
        {
            Header: 'NAME',
            accessor: 'user_nm'
        },
        {
            Header: 'PHONE',
            accessor: 'mobile_phone'
        },
        {
            Header: 'E-MAIL',
            accessor: 'email'
        },
        {
            Header: '삭제',
            accessor: 'option1',
            Cell: ({ row }: CellProps<MemberCols>) => (
                <Button size="sm" onClick={(e) => deleteFunc(row.original)}>
                    삭제
                </Button>
            ),
        }
    ];
    
    const simple_columns = [
        {
            Header: 'ID',
            accessor: 'user_id'
        },
        {
            Header: 'NAME',
            accessor: 'user_nm'
        },
        {
            Header: '삭제',
            accessor: 'option1',
            Cell: ({ row }: CellProps<MemberCols>) => (
                <Button size="sm" onClick={(e) => deleteFunc(row.original)}>
                    삭제
                </Button>
            ),
        }
    ];
    
    const deleteFunc = (vo: MemberCols) => {
        const user_id = vo.user_id;
        setAlertBody(user_id + '(' + vo.user_nm + ') 사용자를 삭제하시겠습니까?')
        setUserId(user_id);
        setShowAlertModal(true);
    }

    function deleteUser() {
        axios.post('/api/exter/deleteExterUser', {user_id: userId}, {headers: {'Content-Type' : 'application/json'}}).then(() => {
            setUserId('');
            setShowAlertModal(false);
            search();
        });
    }
    
    function search() {
        const loadPosts = async () => {
            if (detailPage === 0) {
                selectMemberList(0, rowSize, true);
            } else {
                setDetailPage(0);
            }
            setSimpleMem([]);
            setHasMore(true);
            if (simplePage === 0) {
                selectMemberList(0, 25, false);
            } else {
                setSimplePage(0);
            }
        };
        loadPosts();
    }

    //문서 리스트 검색 함수
    const selectMemberList = async (currPage: number, rowSize: number, isDetail: boolean) => {
        const searchStr = searchMap.get("searchStr");
        const sortStr = sort[0];
        const sortDir = sort[1];
        const response = await axios.post(
            '/api/exter/selectMemberList',
            {
                "currPage": currPage,
                "rowSize": rowSize,
                "searchStr": searchStr,
                "user_id" : sessionStorage.getItem('user_id'),
                "sortStr": sortStr,
                "sortDir": sortDir
            },
            { headers: { "Content-Type": 'application/json' } }
        );
        const data = await response.data;
        if (isDetail) {
            setDetailMem(data.content);
            setPageCount(data.totalPages);
            setRowCount(data.rowCount);
        } else {
            if (currPage)
                setSimpleMem(prevPosts => [...prevPosts, ...data.content]);
            else
                setSimpleMem(data.content);
            setHasMore(!data.last);
        }
        setLoading(false);
    };

    //infinite scroll 에서 가장 아래에 도달했을때 다음 페이지를 불러오기 위한 함수
    const loadMore = () => {
        setSimplePage(prevPage => prevPage + 1);
    };
    
    useEffect(() => {
        if (show)
            search();
    }, [show, detailPage, rowSize, simplePage, sort]);
    
    return (
        <>
            <SearchBarV2
                setRowSize={setRowSize}
                rowCount={rowCount}
                searchMap={searchMap}
                setSearchMap={setSearchMap}
                search={search} />
            {isDesktop ? (
                <>
                    <DataTableV2 loading={loading} columns={detail_columns} data={detailMem} sort={sort} setSortDir={setSortDir} />
                    {pageCount !== 0 && <Paginate setDetailPage={setDetailPage} pageCount={pageCount} detailPage={detailPage} />}
                </>
            ) : (
                <InfiniteScroll
                    dataLength={simpleMem.length}
                    next={loadMore}
                    hasMore={hasMore}
                    loader={<div style={{ height: 40, width: 40 }}><Loading /></div>}
                >
                    <DataTableV2 loading={loading} columns={simple_columns} data={simpleMem} sort={sort} setSortDir={setSortDir} />
                </InfiniteScroll>
            )}
            {!isDesktop && (
                <div className="button-wrap">
                    <Button id="menu" variant="secondary" onClick={() => closeFunc()}>목록으로</Button>
                </div>
            )}
            <AlertModal show={showAlertModal} setShow={setShowAlertModal} title='' body={alertBody} agree='확인' func={() => deleteUser()} />
        </>
    );
    
}

type MemberCols = {
    user_id: string,
    user_nm: string,
    mobile_phone: string,
    email: string,
    [key: string]: any;
}

export default MemberModal;