import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Row, Col, Container, Modal, ModalBody, InputGroup, } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tree } from "./Tree";
import { LuDelete } from "react-icons/lu";
import { format } from 'date-fns';
import { FaRegCalendarAlt } from "react-icons/fa";
import Loading from "../../module/Loading";
import { toast } from 'react-toastify';
import CommonDatePicker from "../../module/CommonDatePicker";
export interface DeptTree {
    dept_cd: string;
    dept_nm: string;
    dept_level: string;
    dept_upper_code: string;
    ordr_code: string;
    office_phone: string;
    lvl: string;
    user_id: string;
    user_nm: string;
    position_cd: string;
    job_title: string;
    email: string;
    user_office_phone: string;
    gender: string;
    children?: DeptTree[];
}

function ViewProxyUserInfo() {
    const [useDelegate, setUseDelegate] = useState<string>("disable");
    const [delegate, setDelegate] = useState<string>("");
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [reason, setReason] = useState<string>("");

    const [treeData, setTreeData] = useState<DeptTree[]>([]);
    const [loading, setLoading] = useState(false);

    const [paramUser, setParamUser] = useState(new Map<string, string>());

    const [checkBackGroundColorId, setCheckBackGroundColorId] = useState("");

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleStartDateChange = (date: Date | null) => {
        if (!date || !endDate || date <= endDate) {
            setStartDate(date);
        } else {
            // 선택할 수 없는 상태 처리
            alert("시작일이 종료일보다 큽니다.");

        }
    };

    const handleEndDateChange = (date: Date | null) => {
        if (!date || !startDate || date >= startDate) {
            setEndDate(date);
        } else {
            // 선택할 수 없는 상태 처리
            alert("종료일이 시작일보다 작습니다.");
        }
    };

    function selectButton() {

        setDelegate(paramUser.get("user_nm") +
            "/" +
            paramUser.get("user_posit_nm") +
            "/" +
            paramUser.get("dept_nm"))
        handleClose();
    }

    const handleUseDelegateChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setUseDelegate(event.target.value);
    };

    useEffect(() => {
        const getData_main = async () => {
            try {
                setLoading(true);
                const response = await axios.post(
                    "/api/cmn/setting/viewProxyUserInfo.menu",
                    {
                        user_id: sessionStorage.getItem("user_id"),
                    },
                    { headers: { "Content-Type": "application/json" } }
                );
                const rtn = response.data;

                const myProxyUserInfo = rtn.myProxyUserInfo;

                // useDelegate === 'disable' ? 'N' : 'Y'; enable;
                myProxyUserInfo.proxy_sign_yn === 'Y' ? setUseDelegate('enable') : setUseDelegate('disable');
                myProxyUserInfo.proxy_user_id === null ? setDelegate('') : setDelegate(myProxyUserInfo.proxy_user_nm + "/" + myProxyUserInfo.proxy_position_nm + "/" + myProxyUserInfo.proxy_dept_nm);

                const parseDate = (dateString: string | null) => {
                    return dateString ? new Date(dateString) : null;
                };

                myProxyUserInfo.st_dt === null ? setStartDate(null) : setStartDate(parseDate(myProxyUserInfo.st_dt));
                myProxyUserInfo.ed_dt === null ? setEndDate(null) : setEndDate(parseDate(myProxyUserInfo.ed_dt));

                myProxyUserInfo.proxy_sign_resn === null ? setReason('') : setReason(myProxyUserInfo.proxy_sign_resn);

            } catch (error) {
                alert(
                    "서버 오류로 인한 " +
                    " 추출 실패, 관리자에게 문의 부탁 드립니다."
                );
            }

            try {
                const response2 = await axios.post(
                    "/api/cmn/setting/selectDeptTree.axios",
                    {},
                    { headers: { "Content-Type": "application/json" } }
                );
                const rtn2 = response2.data;
                setTreeData(rtn2);
            } catch (error) {
                alert("Failed axios : ");
            } finally {
                setLoading(false);
            }
        };

        getData_main();
    }, []);

    const handleButtonClick = (user_id: string) => {
        if (checkBackGroundColorId !== user_id) {
            const myDiv_old = document.getElementById(checkBackGroundColorId);
            const myDiv_new = document.getElementById(user_id);

            if (myDiv_old) {
                myDiv_old.style.backgroundColor = "white";
            }
            if (myDiv_new) {
                myDiv_new.style.backgroundColor = "lightblue";
            }
        } else {

        }
        setCheckBackGroundColorId(user_id);

    };

    const handleDeleteClick = () => {
        setDelegate(''); // 텍스트 박스의 값을 지움
        setCheckBackGroundColorId('');

    };

    async function proxySave() {
        const proxy_sign_yn: string = useDelegate === 'disable' ? 'N' : 'Y';

        if (proxy_sign_yn === 'Y') {

            if (checkBackGroundColorId === '' || null) {
                alert("대결자를 지정하세요.");
                return;
            } else if (startDate === null) {
                alert("대결 시작일을 입력하세요.");
                return;
            } else if (endDate === null) {
                alert("대결 종료일을 입력하세요.");
                return;
            } else if (reason === '') {
                alert("대결 사유를 입력하세요.");
                return;
            }

        }

        const st_dt = startDate === null ? '' : format(startDate, 'yyyy-MM-dd');
        const ed_dt = endDate === null ? '' : format(endDate, 'yyyy-MM-dd');


        try {
            setLoading(true);
            await axios.post(
                "/api/cmn/setting/proxySave.ajax",
                {
                    user_id: sessionStorage.getItem("user_id"),
                    proxy_sign_yn: proxy_sign_yn,
                    st_dt: st_dt,
                    ed_dt: `${ed_dt}235959`,
                    proxy_user_id: checkBackGroundColorId,
                    proxy_sign_resn: reason
                },
                { headers: { "Content-Type": "application/json" } }
            );
            <Loading />

            toast.success("저장이 완료되었습니다.", {
                position: 'top-center',
                autoClose: 2000
            });
        } catch (error) {
            alert("Failed axios ");
        } finally {
            setLoading(false);
        }
    };

    function selectTreeView() {
        setShow(true);
    }

    return (
        <Container>
            {loading && (
                <Loading />
            )}

            {!loading && (

                <Form>
                    <h3 style={{ margin: "15px 0", fontWeight: "bold" }}>
                        대결자 지정
                    </h3>
                    <p>
                        결재권자가 자신의 부재기간 동안 대결자를 지정 할 수 있으며
                        이때 지정된 대결자는 결재권자의 권한을 위임받아 의사결정을
                        대신 처리합니다.
                    </p>
                    <hr />
                    <Form.Group>
                        <div>
                            <Form.Check
                                type="radio"
                                label="사용안함"
                                value="disable"
                                checked={useDelegate === "disable"}
                                onChange={handleUseDelegateChange}
                                inline
                            />
                            <Form.Check
                                type="radio"
                                label="사용"
                                value="enable"
                                checked={useDelegate === "enable"}
                                onChange={handleUseDelegateChange}
                                inline
                            />
                        </div>
                    </Form.Group>

                    <Form.Label column sm={2}>
                        대결자
                    </Form.Label>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={8} lg={6}>
                            <InputGroup className="mb-3 col-lg-9 col-md-10 col-sm-11">
                                <Form.Control
                                    type="text"
                                    value={delegate}
                                    onChange={(e) => setDelegate(e.target.value)}
                                    disabled={useDelegate === "disable"}
                                    readOnly
                                />
                                {delegate && (
                                    <Button variant="outline-secondary" disabled={useDelegate === "disable"} onClick={handleDeleteClick}>
                                        <LuDelete />
                                    </Button>
                                )}
                                <Button
                                    variant="primary"
                                    disabled={useDelegate === "disable"}
                                    onClick={selectTreeView}
                                >
                                    선택
                                </Button>
                            </InputGroup>

                        </Col>
                    </Form.Group>

                    <Form.Label column sm={2}>
                        대결일
                    </Form.Label>
                    <Form.Group as={Row}>
                        <Col xs={6} sm={6} md={4} lg={3}>
                            <CommonDatePicker
                                selectedDate={startDate}
                                handleChange={handleStartDateChange}
                                disabled={useDelegate === 'disable'}
                                placeholderText="시작일"
                            />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={3}>

                            <CommonDatePicker
                                selectedDate={endDate}
                                handleChange={handleEndDateChange}
                                disabled={useDelegate === 'disable'}
                                placeholderText="종료일"
                                icon={<FaRegCalendarAlt />}
                            />
                        </Col>
                    </Form.Group>
                    <div>
                        <Modal show={show} onHide={handleClose} size="sm">
                            <Modal.Header closeButton>대결자 선택</Modal.Header>
                            <ModalBody>
                                <Tree
                                    nodes={treeData}
                                    idField={"dept_cd"}
                                    nameField={"user_nm"}
                                    onNodeClick={(node) => {
                                        if (node.user_id && node.user_nm) {
                                            handleButtonClick(node.user_id);
                                            alert(
                                                "여기는 ?. 사용자 클릭 후 아 값 넘겨야지"
                                            );
                                            // goPage(node.user_id, node.user_nm);

                                            const newMap = new Map(paramUser);
                                            newMap.set("user_nm", node.user_nm);
                                            newMap.set("user_posit_nm", node.user_posit_nm);
                                            newMap.set("dept_nm", node.dept_nm);

                                            setParamUser(newMap);

                                        }
                                    }}
                                />
                            </ModalBody>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    닫기
                                </Button>
                                <Button variant="primary" onClick={selectButton}>
                                    선택
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <Form.Label column sm={2}>
                        대결사유
                    </Form.Label>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={8} lg={6}>
                            <InputGroup className="mb-3 col-lg-9 col-md-10 col-sm-11">

                                <Form.Control
                                    type="text"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    disabled={useDelegate === "disable"}
                                    maxLength={4000}
                                // WG_USER_ENV proxy_sign_resn 컬럼 최대 길이
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>

                    <Button variant="primary" className="mt-3" onClick={proxySave}>
                        저장
                    </Button>
                </Form>
            )}
        </Container>
    );
}

export default ViewProxyUserInfo;
