import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase-config";
import { ToastContainer, toast } from 'react-toastify';
import Root from "./module/Root";
import Auth from "./module/Auth";
import Login from "./pages/Login";
import Header, { Menu } from "./pages/Header";
import Main from "./pages/Main";
import Exter from "./pages/exter/External";
import Board from "./pages/board/Board";
import Appr from "./pages/appr/Appr";
import Myinfo from "./pages/myinfo/Myinfo";
import Admin from "./pages/admin/Admin";
import "./css/App.css";
import Cmn from "./pages/cmn/Cmn";

/** External */
import ExterLogin from "./pages/exter/ExterLogin";
import TopButton from "./module/TopButton";

import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

function App() {
    const [header, setHeader] = useState<Menu[]>([]);
    const [toggleHeader, setToggleHeader] = useState<Menu[]>([]);

    const getHeader = async () => {
        const response = await axios.post("/api/HeaderMenu", null, { params: { "priv_cd": sessionStorage.getItem("priv_cd"), extern_yn: localStorage.getItem('extern_yn'), user_id: sessionStorage.getItem('user_id') } });
        setHeader(response.data.header);
        setToggleHeader(response.data.toggleHeader);
    };

    useEffect(() => {
        //포그라운드 메시징 처리
        if (messaging) {
            onMessage(messaging, (payload) => {
                toast(
                    <div>
                        <strong>{payload.data?.title || 'no title'}</strong>
                        <div>{payload.data?.body || 'no body'}</div>
                    </div>,
                    {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            });
        }
    }, []);

    return (
        <>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<Root />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/exter/login" element={<ExterLogin />} />
                        <Route
                            path="*"
                            element={
                                <>
                                    <Header header={header} toggleHeader={toggleHeader} getHeader={getHeader} />
                                    <div className="main">
                                        <Routes>
                                            <Route element={<Auth />}>
                                                <Route path="/main" element={<Main />} />
                                                <Route path="/board/*" element={<Board />} />
                                                <Route path="/appr/*" element={<Appr />} />
                                                <Route path="/myinfo/*" element={<Myinfo />} />
                                                <Route path="/admin/*" element={<Admin getHeader={getHeader} />} />
                                                <Route path="/exter/*" element={<Exter />} />
                                                <Route path="/cmn/*" element={<Cmn />} />
                                            </Route>
                                        </Routes>
                                        <TopButton />
                                    </div>
                                </>
                            }
                        />
                    </Routes>
                </div>
            </Router>
            <ToastContainer />
        </>
    );
}
export default App;
