import { Route, Routes } from "react-router-dom";
import ExterBoard from "./Board";
import ExterAppr from "./Appr";
import MemberManagement from "./MemberManagement";
import { useState } from "react";
import MyPage from "./MyPage";

function External() {
    const [show, setShow] = useState(true);
    return (
        <div className="content-container">
            <Routes>
                <Route path="/board" element={<ExterBoard />} />
                <Route path="/appr" element={<ExterAppr show={show} setShow={setShow} vac_id="" />} />
                <Route path="/members" element={<MemberManagement show={show} setShow={setShow} />} />
                <Route path="/mypage" element={<MyPage show={show} setShow={setShow} />} />
            </Routes>
        </div>
    );
}
export default External;
